import { post, patch, get } from 'aws-amplify/api'
import { getResponseToJSON } from 'utils/api'

const donationService = () => {
  const payment = async ({ path, body }) => {
    const res = post({
      apiName: 'paymentAPI',
      path,
      options: {
        body,
      },
    })
    return await getResponseToJSON(res)
  }
  const checkOrderStatus = async ({ orderId, method }) => {
    try {
      const res = await get({
        apiName: 'paymentAPI',
        path: `/orderStatus?id=${orderId}&method=${method}`,
      })
      return await getResponseToJSON(res)
    } catch (error) {
      console.log('❌', error)
      return {
        success: false,
        orderStatus: 0,
        data: '{}',
      }
    }
  }

  const checkBasketOrderStatus = async ({ orderId, provider }) => {
    const res = await get({
      apiName: 'paymentAPI',
      path: `/basket?orderId=${orderId}&provider=${provider}`,
    })
    return await getResponseToJSON(res)
  }

  const unsubscribeFromBasketItem = async ({ subscriptionId, projectId }) => {
    try {
      const res = await patch({
        apiName: 'paymentAPI',
        path: `/basket?subscription=${subscriptionId}&project=${projectId}`,
      })
      return await getResponseToJSON(res)
    } catch (error) {
      console.log('❌', error)
      return {
        success: false,
        orderStatus: 0,
        data: '{}',
      }
    }
  }

  const checkPaypalOrder = async ({
    isSubscription,
    transactionId,
    paypalToken,
    paypalSubscriptionId,
  }) => {
    let url = `/paypal?isSubscription=${isSubscription}&transactionId=${transactionId}&paypalToken=${paypalToken}`
    if (paypalSubscriptionId) {
      url += `&paypalSubscriptionId=${paypalSubscriptionId}`
    }

    const res = await get({
      apiName: 'paymentAPI',
      path: url,
    })
    return await getResponseToJSON(res)
  }

  return {
    payment,
    checkOrderStatus,
    checkBasketOrderStatus,
    checkPaypalOrder,
    unsubscribeFromBasketItem,
  }
}

export default donationService
