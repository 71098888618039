export const SIGN_IN_MODAL = 'signIn'
export const SIGN_UP_MODAL = 'signUp'
export const VERIFY_MODAL = 'verify'
export const FORGOT_PASSWORD_MODAL = 'forgotPassword'
export const RESET_VERIFY_MODAL = 'resetVerify'
export const SUBSCRIBE_MODAL = 'subscribe'
export const DRAFT_MODAL = 'draft'
export const DELETE_DRAFT_MODAL = 'deleteDraft'
export const REDEEM_GIFT_CARD_MODAL = 'redeemGiftCard'
export const CONNECT_MODAL = 'connect'
export const LIST_SUBSCRIPTIONS_MODAL = 'listSubscriptions'
export const LIST_BASKET_SUBSCRIPTIONS_MODAL = 'listBasketSubscriptions'
export const APPLY_EXPERT_MODAL = 'applyExpert'
export const EXPERT_NOTIFICATION_MODAL = 'expertNotification'
export const EXPERT_CARD_MODAL = 'expertCard'
export const CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL = 'ChoosePeerToPeerFundraiser'
export const ADD_EDIT_BASKET_MODAL = 'AddEditBasket'
export const MANAGE_BASKET_SUBSCRIPTION_MODAL = 'ManageBasketSubscription'
