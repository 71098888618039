import { useState, useEffect } from 'react'
import { useQueryParams } from 'hooks'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'
import Web from './Web'

export default function CartAmountForm({
  getValues,
  project,
  donation,
  setValue,
  isContribution,
  showStripe,
  giftCardMode,
  isBuyingGiftCard,
  note,
  isRecurring,
  isOneTimeOrMonthly,
  errors,
  register,
  setPercent,
}) {
  const queryParams = useQueryParams()
  const [other, setOther] = useState(false)
  const [perk, setPerk] = useState(null)
  const { t } = useTranslation()
  const { currency, settings } = useCurrencySettings()

  const PRICE = {
    '10$': 5,
    '20$': 7.5,
    '50$': 12.5,
    other: t('donation@other'),
  }

  const handleAmount = (e = '20$') => {
    setOther(false)
    if (e === '10$') {
      setPercent(5)
      perk !== null
        ? (e = (Number(perk.price) * 5) / 100)
        : (e = (Number(donation.amount) * 5) / 100)
    } else if (e === '20$') {
      setPercent(7.5)
      perk !== null
        ? (e = (Number(perk.price) * 7.5) / 100)
        : (e = (Number(donation.amount) * 7.5) / 100)
    } else if (e === '50$') {
      setPercent(12.5)
      perk !== null
        ? (e = (Number(perk.price) * 12.5) / 100)
        : (e = (Number(donation.amount) * 12.5) / 100)
    } else if (e === 'other') {
      setPercent(null)
      return setOther(true)
    }
    return setValue('reArmenia', e)
  }
  useEffect(() => {
    if (!project) {
      return
    }
    let newPerk = null
    if (
      queryParams.has('perkIndex') &&
      project.perks[queryParams.get('perkIndex')]
    ) {
      newPerk = project.perks[queryParams.get('perkIndex')]
      const currencyRate = settings?.currency[currency.current] || 1
      newPerk.price = Number(newPerk.price / currencyRate)
      if (currency !== 'AMD') {
        newPerk.price = Number(newPerk.price).toFixed(2)
      }
    }
    setPerk(newPerk)
  }, [project, settings, currency.current])

  const props = {
    PRICE,
    donation,
    setValue,
    getValues,
    other,
    perk,
    handleAmount,
    project,
    isContribution,
    giftCardMode,
    errors,
    register,
    isBuyingGiftCard,
    note,
    isRecurring,
    isOneTimeOrMonthly,
    showStripe,
  }

  return <Web {...props} />
}
