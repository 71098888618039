import StaticAlert from 'components/StaticAlert'
import { useTranslation } from 'contexts/TranslationContext'

const MonthlyNoticeBanner = ({ isOneTimeOrMonthly }) => {
  const { t } = useTranslation()

  return (
    <StaticAlert
      titleProps={{ fontSize: 'xl' }}
      title={t(
        !isOneTimeOrMonthly
          ? 'Monthly Donations Only'
          : 'Both One-Time and Monthly Donations'
      )}
      descriptionProps={{ fontSize: 'md' }}
      description={t(
        !isOneTimeOrMonthly
          ? 'Donations will be processed on the 1st of each month until you cancel the subscription.'
          : 'This fundraiser welcomes both. Give in the way that works best for you!'
      )}
    />
  )
}

export default MonthlyNoticeBanner
