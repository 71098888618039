import { useParams } from 'react-router-dom'
import Header from 'components/MobileHeader'
import { useTranslation } from 'contexts/TranslationContext'
import ManageBasketSubscriptionComponent from 'components/ManageSubscription'
import { useGetDonationSubscription } from 'hooks'
import Loading from 'components/Loading'
import { Box } from '@chakra-ui/react'
import { useNavigation } from 'pages'

export default function ManageBasketSubscription() {
  const { t } = useTranslation()
  const { id, tab } = useParams()
  const { navigationPush } = useNavigation()
  const {
    data: subscription,
    refetch,
    isLoading,
  } = useGetDonationSubscription(id)

  const onTabChange = (tab) => {
    navigationPush(`/manage-basket-subscription/${id}/${tab}`)
  }

  const onBack = () => {
    navigationPush('/profile/history/non-profit')
  }

  return (
    <Box minH="100vh">
      <Header onBack={onBack} heading={t('edit')} />
      <Box px={5} mt={5}>
        {isLoading ? (
          <Loading />
        ) : (
          <ManageBasketSubscriptionComponent
            subscription={subscription}
            refetch={refetch}
            tab={tab}
            onTabChange={onTabChange}
          />
        )}
      </Box>
    </Box>
  )
}
