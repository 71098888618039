import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { post } from 'aws-amplify/api'
import { useCollabRequest, useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { TextareaField } from 'components/TextareaFIeld'
import { useNavigation } from 'pages'
import NoScrollbar from 'components/NoScrollbar'

import useCustomToast from 'hooks/useCustomToast'
import NeedCard from '../NeedCard'
import { useForm } from 'react-hook-form'
import { getResponseToJSON } from 'utils/api'

const MessageModal = ({
  isOpen,
  onClose,
  initiative,
  selectedCollaboration,
  setSelectedCollaboration,
}) => {
  const toast = useCustomToast()
  const { user } = useUser()
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  const { collaboratorRequest, isLoading } = useCollabRequest()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const onSend = (data) => {
    const fullname =
      user.id === 'guest'
        ? `${data.firstName} ${data.lastName}`
        : `${user.firstName} ${user.lastName}`

    const addresses = initiative?.implementor?.email
      ? [initiative.implementor.email]
      : [initiative?.contactEmail]

    collaboratorRequest(
      {
        userId: user.id,
        initiativeId: initiative?.id ?? '',
        addresses,
        contactEmail: data.email || (user.email ?? ''),
        implementorFullName: initiative.contactFullName,
        applicationTitle: initiative.applicationTitle,
        fullname,
        isCollab: 'collaborator',
        phoneNumber: data.phoneNumber,
        description: data.description,
        language,
        need: selectedCollaboration?.needs,
        needDescription: selectedCollaboration?.needsDescription,
      },
      {
        onSuccess: async () => {
          toast({
            title: t('initiative@messageSent'),
            status: 'success',
            isClosable: true,
            duration: 5000,
          })
          const body = {
            id: initiative.id,
            receiverEmail: data.email || (user.email ?? ''),
            fullname,
            applicationTitle: initiative.applicationTitle,
          }
          try {
            const res = post({
              apiName: 'email',
              path: '/collabThankYou',
              options: {
                body,
              },
            })
            await getResponseToJSON(res)
          } catch (e) {
            console.log(e)
          }

          navigationPush(`
          /donate/thank-you?name=${
            user.id === 'guest'
              ? `${data.firstName} ${data.lastName}`
              : `${user.firstName} ${user.lastName}`
          }&title=${initiative?.['title_' + language] ?? ''}&id=${
            initiative.id
          }&collaboration=true
          `)
          onClose()
        },
      }
    )
  }

  return (
    <Modal
      size="xl"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        setSelectedCollaboration(null)
        onClose()
      }}
      variant="responsive"
    >
      <ModalOverlay />
      <ModalContent
        width="532px"
        borderRadius="16px"
        pb={8}
        boxShadow="0 4px 24px 0 rgba(0,0,0,0.15)"
      >
        <ModalHeader pb="0" textTransform="capitalize">
          {selectedCollaboration?.needs
            ? t('collaborationProposal')
            : t('selectANeed')}
        </ModalHeader>
        <ModalCloseButton />
        <NoScrollbar Component={Box} overflowY="scroll">
          <form onSubmit={handleSubmit(onSend)}>
            <ModalBody>
              <Stack spacing="5">
                {selectedCollaboration?.needs ? (
                  <>
                    <NeedCard
                      collaboration={selectedCollaboration}
                      t={t}
                      lang={language}
                      viewMode
                    />
                    {user.id === 'guest' && (
                      <>
                        <InputField
                          isInvalid={errors.firstName}
                          errorMessage={t(
                            'createEditProject@thisFieldIsRequired'
                          )}
                          label={t('dashboard@name') + '*'}
                          {...register('firstName', {
                            required: true,
                          })}
                          size="sm"
                        />
                        <InputField
                          isInvalid={errors.lastName}
                          errorMessage={t(
                            'createEditProject@thisFieldIsRequired'
                          )}
                          label={t('dashboard@surname') + '*'}
                          {...register('lastName', {
                            required: true,
                          })}
                          size="sm"
                        />
                        <InputField
                          isInvalid={errors.email}
                          errorMessage={t(errors?.email?.message)}
                          label={t('dashboard@email') + '*'}
                          {...register('email', {
                            required: {
                              message: 'createEditProject@thisFieldIsRequired',
                              value: true,
                            },
                            pattern: {
                              message: 'createEditInitiative@emailError',
                              value: /\S+@\S+\.\S+/,
                            },
                          })}
                          size="sm"
                        />
                      </>
                    )}
                    <InputField
                      label={t('dashboard@phoneNumber')}
                      {...register('phoneNumber')}
                      size="sm"
                    />
                    <TextareaField
                      resize="none"
                      height="140px"
                      {...register('description')}
                      label={`${t('additionalInfo')}`}
                    />
                  </>
                ) : (
                  <>
                    {initiative.collaborations.map((collaboration, index) => (
                      <NeedCard
                        key={`collaboration_${index}`}
                        collaboration={collaboration}
                        t={t}
                        selectAction={() =>
                          setSelectedCollaboration(collaboration)
                        }
                        lang={language}
                        mb={0}
                      />
                    ))}
                  </>
                )}
              </Stack>
            </ModalBody>
            {selectedCollaboration?.needs && (
              <ModalFooter>
                <Button
                  isDisabled={!selectedCollaboration?.needs}
                  isLoading={isLoading}
                  w="full"
                  variant="blue"
                  type="submit"
                >
                  {t('send')}
                </Button>
              </ModalFooter>
            )}
          </form>
        </NoScrollbar>
      </ModalContent>
    </Modal>
  )
}

export default MessageModal
