import {
  authRepo,
  transactionRepo,
  initiativeRepo,
  imageRepo,
  hashRepo,
  filesRepo,
  geoRepo,
  settingsRepo,
  blogRepo,
  testimonialRepo,
  successStoryRepo,
  giftCardRepo,
  notificationRepo,
  emailRepo,
  stripeRepo,
  bindingCardRepo,
  updateRepo,
  feedRepo,
  reportRepo,
  donationRepo,
  needsRepo,
} from '../repositories'
import auth from './authUseCase'
import transaction from './transactionUseCase/transactionUseCase'
import initiative from './initiativeUsecase'
import image from './imageUseCase'
import files from './filesUseCase'
import geo from './geoUseCase'
import settings from './settingsUseCase'
import blog from './blogUseCase'
import testimonial from './testimonialUseCase'
import successStory from './successStoryUseCase'
import giftCard from './giftCardUseCase'
import notification from './notificationUseCase'
import email from './emailUseCase'
import bindingCard from './bindingCardUseCase'
import hash from './hashUseCase'
import update from './updateUseCase'
import feed from './feedUseCase'
import report from './reportUseCase'
import needs from './needsUseCase'
import donation from './donationUseCase'
import exportUseCase from './exportUseCase'
import stripeUseCase from './stripeUseCase'
import {
  authService,
  TransactionService,
  inititativeService,
  imageService,
  hashService,
  filesService,
  geoService,
  settingsService,
  contentfulService,
  giftCardService,
  notificationService,
  emailService,
  bindingCardService,
  updateService,
  feedService,
  reportService,
  needsService,
  stripeService,
} from '../services'
import donationService from 'core/services/donationService'
import exportRepo from 'core/repositories/exportRepo'
import exportService from 'core/services/export'

export const hashRepository = hashRepo(hashService())
const imageRepository = imageRepo(imageService())

export const hashUseCase = hash(hashRepo(hashService()))

export const settingsUseCase = settings(settingsRepo(settingsService()))

export const blogUseCase = ({ client }) =>
  blog(blogRepo(contentfulService({ client })))

export const testimonialUseCase = ({ client }) =>
  testimonial(testimonialRepo(contentfulService({ client })))

export const successStoryUseCase = ({ client }) =>
  successStory(successStoryRepo(contentfulService({ client })))

export const authUseCase = auth(authRepo(authService()))

export const transactionUseCase = transaction(
  transactionRepo(TransactionService())
)

export const giftCardUseCase = giftCard(
  giftCardRepo(giftCardService()),
  transactionRepo(TransactionService())
)

export const notificationUseCase = notification(
  notificationRepo(notificationService())
)

export const bindingCardUseCase = bindingCard(
  bindingCardRepo(bindingCardService())
)

export const initiativeUseCase = initiative(
  initiativeRepo(inititativeService()),
  imageRepository,
  hashRepository
)

export const donationUseCase = donation(donationRepo(donationService()))

export const imageUseCase = image(imageRepository)

export const filesUseCase = files(filesRepo(filesService()))

export const geoUseCase = geo(geoRepo(geoService()))

export const emailUseCase = email(emailRepo(emailService()))

export const updateUseCase = update(updateRepo(updateService()))

export const feedUseCase = feed(feedRepo(feedService()))

export const reportUseCase = report(reportRepo(reportService()))

export const needsUseCase = needs(needsRepo(needsService()))

export const exportFactory = exportUseCase(exportRepo(exportService()))

export const stripeFactory = stripeUseCase(stripeRepo(stripeService()))
