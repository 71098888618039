import React from 'react'
import { HStack, Box, Heading, Text, Image, Flex } from '@chakra-ui/react'
import { HiOutlineFolderDownload } from 'react-icons/hi'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Reached } from 'assets/icons/reachedBig.svg'
import { MainLayout } from 'components/Layouts'
import { Footer } from 'components/Footer'
import { useQueryParam } from 'contexts'
import { FUNDRAISER_NONPROFIT } from 'constants/fundraiser'

const Done = () => {
  const { t } = useTranslation()
  const { params } = useQueryParam()

  return (
    <MainLayout>
      <Flex flexDir="column" alignItems="center" py="72px">
        <Reached minH="218px" />

        <Heading my="20px" fontSize="6xl" as="h2" fontWeight={500}>
          {t('createEditProject@step3Heading')}
        </Heading>
        <Text mb="8" fontSize="lg">
          {t('createEditProject@step3Note1')}
        </Text>
        <Text mb="8" color="gray.700" fontSize="md">
          {t('createEditProject@step3Note2')}
        </Text>
        {params.fundraisingType !== FUNDRAISER_NONPROFIT && (
          <>
            <Text mb="8" fontSize="lg">
              {t('createEditProject@step3Note3')}
            </Text>
            <a href="/assets/files/guide.pdf" target="_blank">
              <HStack cursor="pointer">
                <HiOutlineFolderDownload width="20px" color="#FC8600" />
                <Box>
                  <Box fontSize="sm" as="span">
                    {t('createEditProject@downloadGuide')}
                  </Box>
                  <Box fontSize="xs" color="gray.700">
                    {t('createEditProject@fileSize')} 46 kb
                  </Box>
                </Box>
              </HStack>
            </a>
          </>
        )}
        <Image
          ignoreFallback={true}
          w="426px"
          src="/assets/images/done.png"
          alt="done"
        />
      </Flex>
      <Footer />
    </MainLayout>
  )
}

export default Done
