import { Flex, Popover, Text } from '@chakra-ui/react'
import { CheckboxGroupItems } from './ExpertProfileForm'
import PopoverWrapper from 'components/PopoverWrapper'
import { CATEGORY } from '_constants'

//** Icons
import { ReactComponent as ArrowDown } from 'assets/icons/small_chevron_down.svg'

const Header = ({ tt, language }) => (
  <>
    <Flex>
      <Text ml={3} fontSize="md" color="gray.800">
        {tt('Select areas', language)}
      </Text>
    </Flex>
    <ArrowDown />
  </>
)

export const DirectionsPopover = ({
  tt,
  control,
  language,
  isInvalid,
  isDisabled,
}) => (
  <Popover placement="bottom-start">
    {({ isOpen }) => (
      <PopoverWrapper
        isOpen={isOpen}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        Header={() => <Header tt={tt} language={language} />}
      >
        <CheckboxGroupItems
          control={control}
          name="interestedIn"
          errorMessage={tt('createEditProject@thisFieldIsRequired', language)}
          options={CATEGORY[language].map((cat) => ({
            label: tt(`category@` + cat, language),
            value: cat,
          }))}
          rowGap={6}
        />
      </PopoverWrapper>
    )}
  </Popover>
)
