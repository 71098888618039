import { createContext, useContext, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const QueryParamContext = createContext({})

export const useQueryParam = () => useContext(QueryParamContext)

export function QueryParamProvider({ children }) {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const queryString = search.replace(/^\?/, '')
  const contextValue = useMemo(() => {
    const params = queryString
      .replace(/^\?/, '')
      .split('&')
      .reduce((acc, pair) => {
        const [key, value] = pair.split('=')
        if (key) {
          acc[key] = decodeURIComponent(value)
        }
        return acc
      }, {})

    const update = (data, removePrevious = false, render = true) => {
      const clone = removePrevious ? {} : { ...params }

      for (const key in data) {
        if (data[key] === undefined) {
          delete clone[key]
        } else {
          clone[key] = data[key]
        }
      }

      let query = ''
      for (const key in clone) {
        if (!!clone[key]) {
          query += `&${key}=${encodeURIComponent(clone[key])}`
        }
      }

      const queryStringToUpdate = query.replace(/^&/, '')
      const newUrl = !queryStringToUpdate
        ? pathname
        : `${pathname}?${queryStringToUpdate}`

      if (render) {
        history.replace(newUrl)
      } else {
        window.history.replaceState({}, '', newUrl)
      }
    }

    const remove = (key) => update({ [key]: undefined }, true, false)

    return {
      params: params,
      has: (key) => key in params,
      get: (key) => params[key] || null,
      set: (key, value) => update({ [key]: value }),
      update: update,
      remove: remove,
    }
  }, [pathname, queryString])

  return (
    <QueryParamContext.Provider value={contextValue}>
      {children}
    </QueryParamContext.Provider>
  )
}
