import React, { useState } from 'react'
import { post } from 'aws-amplify/api'
import { useForm } from 'react-hook-form'
import {
  Flex,
  Heading,
  Stack,
  Button,
  Box,
  Image,
  Text,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { TextareaField } from 'components/TextareaFIeld'
import { useTranslation } from 'contexts/TranslationContext'
import { useCustomToast } from 'hooks'
import { getResponseToJSON } from 'utils/api'

const Fields = () => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const toast = useCustomToast()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = async (data) => {
    setLoading(true)
    const body = {
      text: data.message,
      email: data.email,
      name: data.name,
    }

    try {
      const res = post({
        apiName: 'email',
        path: '/sendEmail',
        options: {
          body,
        },
      })
      const data = await getResponseToJSON(res)
      if (data.success) {
        setLoading(false)
        toast({ title: t('initiative@messageSent'), status: 'success' })
        reset()
      }
      return data // For unit tests.
    } catch (err) {
      setLoading(false)
      console.log('Error', err)
    }
  }

  return (
    <>
      <Flex direction={{ base: 'column-reverse', lg: 'row' }} align="stretch">
        <Flex
          justify="center"
          w={{ base: '100%', lg: '50%' }}
          py={12}
          px={{ base: 5, lg: 0 }}
        >
          <Box maxW="457px">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex align="center">
                <Box
                  h="23px"
                  w="4px"
                  borderRadius="10px"
                  bg="orange.400"
                  mr={4}
                ></Box>
                <Heading as="h1" fontSize="6xl" fontWeight={500}>
                  {t('Contact us')}
                </Heading>
              </Flex>
              <Text fontSize="xl">{t('contactUs@heading1')}</Text>
              <Stack mt="12" spacing="6">
                <InputField
                  size="sm"
                  isInvalid={!!errors.name}
                  {...register('name', { required: true })}
                  errorMessage={
                    t('dashboard@name') +
                    t('contactUs@form-lastNameErrorMessage')
                  }
                  label={t('dashboard@name')}
                  labelProps={{ textTransform: 'capitalize' }}
                />
                <InputField
                  size="sm"
                  {...register('email', {
                    required: true,
                    pattern:
                      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                  })}
                  isInvalid={!!errors.email}
                  errorMessage={t('contactUs@form-emailAddressErrorMessage')}
                  label={t('contactUs@form-emailAddress')}
                  labelProps={{ textTransform: 'capitalize' }}
                />
                <TextareaField
                  {...register('message')}
                  label={t('contactUs@form-message')}
                />
                <Button
                  isLoading={loading}
                  width="full"
                  type="submit"
                  variant="blue"
                  colorScheme="blue"
                >
                  {t('contactUs@form-submit')}
                </Button>
              </Stack>
            </form>
          </Box>
        </Flex>
        <Flex w={{ base: '100%', lg: '50%' }} align="center" justify="center">
          <Box pos="relative" h="100%">
            <Image
              src="/assets/images/contactUsImg.png"
              h="100%"
              objectFit="cover"
            />
            <Box
              w={24}
              h={24}
              bg="orange.400"
              borderRadius="50%"
              pos="absolute"
              bottom={-12}
              left={{ base: 'unset', lg: 10 }}
              right={{ base: 5, lg: 'unset' }}
            ></Box>
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default Fields
