import { Flex, Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import ImageWrapper from 'components/ImageWrapper'
import { useQueryParams } from 'hooks'
import { getImageUrl } from 'pages/SelectGiftCard/helpers'

const Header = ({ project, isContribution, isBuyingGiftCard }) => {
  const { t, language } = useTranslation()
  const queryParams = useQueryParams()

  return (
    <Box p={6}>
      <Flex flexDirection={{ base: 'column', md: 'row' }} gap={4}>
        <Box maxW="500px" mt={{ base: 4, sm: 0 }}>
          <ImageWrapper
            cursor="pointer"
            objectFit="cover"
            borderTopRadius="12px"
            bgColor="border.100"
            ignoreFallback={true}
            src={
              isContribution
                ? '/assets/images/contribution.svg'
                : isBuyingGiftCard
                ? getImageUrl(queryParams.get('giftCard'))
                : `/thumbnail/small/${project?.coverKey || project?.cover}`
            }
            fallbackSrc={'/assets/images/noIg.svg'}
            borderRadius="10px"
            minWidth={isBuyingGiftCard ? '100%' : '125px'}
            width={isBuyingGiftCard ? '100%' : '125px'}
            height={isBuyingGiftCard ? 'unset' : '100px'}
          />
        </Box>

        {!isBuyingGiftCard && (
          <Box align="center" position="relative">
            {isContribution ? (
              <Heading mb="5" fontSize="sm" as="h2" pl="10px">
                <Box fontWeight="normal" as="span" fontSize="sm">
                  {t('contribute@youAreSupportingReArmenia').split('(...)')[0]}
                </Box>
                {t('rearmenia')}
                <Box fontWeight="normal" as="span" fontSize="sm">
                  {t('contribute@youAreSupportingReArmenia').split('(...)')[1]}
                </Box>
              </Heading>
            ) : (
              <Heading fontSize="lg" as="h2" textAlign="left" noOfLines={4}>
                {project?.title_en
                  ? project[`title_${language}`]
                  : project?.applicationTitle || 'Gift card'}
              </Heading>
            )}
          </Box>
        )}
      </Flex>
    </Box>
  )
}

export default Header
