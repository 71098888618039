import { extendTheme } from '@chakra-ui/react'
import Alert from './components/Alert'
import Button from './components/Button'
import Text from './components/Text'
import Switch from './components/Switch'
import Input from './components/Input'
import Modal from './components/Modal'
import Checkbox from './components/Checkbox'
import Table from './components/Table'
import Radio from './components/Radio'
import Select from './components/Select'
import Menu from './components/Menu'
import Textarea from './components/Textarea'

import colors from './foundations/colors'
import fontSizes from './foundations/fontSizes'
import fonts from './foundations/fonts'
import styles from './foundations/styles'

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme({
  config,
  components: {
    Alert,
    Button,
    Text,
    Switch,
    Input,
    Modal,
    Textarea,
    Table,
    Checkbox,
    Radio,
    Select,
    Menu,
  },
  fonts,
  styles,
  fontSizes,
  colors,
})

export default theme
