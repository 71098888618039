import { Image, Flex, Text, Radio } from '@chakra-ui/react'

const CardSavingSelectionWithChildren = ({
  text,
  isSelected,
  Icon,
  src,
  children,
  isInvalid,
  ...props
}) => {
  const formVisible = isSelected && !!children
  return (
    <Flex
      h={formVisible ? 'auto' : '56px'}
      p={formVisible ? '13px 16px 16px 16px' : '13px 16px'}
      justify="center"
      direction="column"
      borderRadius={16}
      borderWidth="1px"
      borderColor={
        isInvalid && isSelected
          ? 'red.400'
          : isSelected
          ? 'border.400'
          : 'border.500'
      }
      {...props}
    >
      <Flex height={7}>
        <Flex width={9} alignItems="center" paddingLeft="6px">
          <Radio value="1" isChecked={isSelected} />
        </Flex>

        <Flex alignItems="center">
          {src && <Image src={src} h={5} />}
          {Icon && <Icon />}

          <Text ml={4} color="black.100" fontSize="md">
            {text}
          </Text>
        </Flex>
      </Flex>
      {isSelected && children}
    </Flex>
  )
}

export default CardSavingSelectionWithChildren
