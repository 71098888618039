import axios from 'axios'
import { post } from 'aws-amplify/api'
import { getResponseToJSON } from 'utils/api'

const emailService = () => {
  const updatesEmail = async (params) => {
    const res = post({
      apiName: 'email',
      path: '/updatesEmail',
      options: {
        body: params,
      },
    })
    return await getResponseToJSON(res)
  }
  const subscribeMailerLite = async (email, firstName, language = 'en') => {
    const res = post({
      apiName: 'mailerLiteAPI',
      path: '/subscribe',
      options: {
        body: {
          email: email,
          firstName: firstName,
          language: `MAILER_SUB_GROUP_${language.toUpperCase()}`,
        },
      },
    })
    return await getResponseToJSON(res)
  }

  const subscribeMailerLiteNews = async ({ email, language = 'en' }) => {
    const res = post({
      apiName: 'mailerLiteAPI',
      path: '/subscribeNews',
      options: {
        body: {
          email,
          language: `SUBSCRIBE_EMAIL_${language.toUpperCase()}`,
        },
      },
    })
    return await getResponseToJSON(res)
  }

  const subscribeHubspot = ({ email, firstName = '', lastName = '' }) => {
    const params = {
      submittedAt: Date.now(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: firstName,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: lastName,
        },
      ],
    }
    return axios.post(process.env.REACT_APP_HUBSPOT, params)
  }

  return {
    updatesEmail,
    subscribeMailerLite,
    subscribeHubspot,
    subscribeMailerLiteNews,
  }
}

export default emailService
