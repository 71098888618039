import { Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import InfoBlock from './InfoBlock'

export default function NonProfitApplicationDetails({ project }) {
  const { t } = useTranslation()

  return (
    <Box maxWidth="508px" width="100%">
      <Heading fontSize="5xl" as="h1">
        {t('createEditProject@projectApplication')}
      </Heading>
      <Box
        mt={6}
        mb={5}
        fontSize="sm"
        textTransform="uppercase"
        fontWeight="bold"
        color="blue.400"
        display="block"
        as="span"
      >
        {t(`status@${project.status}`)} | {t(`category@${project.category}`)}
      </Box>
      <Heading fontSize="5xl" as="h2">
        {project.organizationName}
      </Heading>
      <InfoBlock
        label={t("Description of the organization's activities")}
        value={project.summary}
      />
      <InfoBlock
        label={t('TIN (tax identification number)')}
        value={project.TIN}
      />
      <InfoBlock
        label={t('createEditProject@category')}
        value={t(`category@${project.category}`)}
      />
      <InfoBlock
        label={t('Contact person (name, surname)')}
        value={project.contactFullName}
      />
      <InfoBlock label={t('Phone number')} value={project.phoneNumber} />
      <InfoBlock label={t('Email')} value={project.contactEmail} />
      <InfoBlock
        label={t(
          'If you have any media coverage about the organization, please attach them here.)'
        )}
        value={project.mediaLink}
      />
      <InfoBlock
        label={t('Helpful Links (Website, Social Media Pages, etc.)')}
        value={project.links}
      />
    </Box>
  )
}
