import { Button, Flex, useDisclosure, useToast } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import AlertDialogModal from '../AlertDialogModal'
import { useUnsubscribeFromBasketItem, useUser } from 'hooks'

export default function UnsubscribeFromAllButton({ subscription, refetch }) {
  const { t } = useTranslation()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { getUser } = useUser()
  const { mutateAsync: unsubscribe, isLoading: isUnsubscribeLoading } =
    useUnsubscribeFromBasketItem()

  const onAlertOpen = (e) => {
    e.preventDefault()
    onOpen()
  }

  const onUnsubscribe = async (e) => {
    e.stopPropagation()
    try {
      await unsubscribe({
        subscriptionId: subscription.id,
        projectId: 'all',
      })
      refetch()
      onClose()
      getUser()
      toast({
        title: t('unsubscribedSuccessfully'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (e) {
      console.log('>>> UNSUBSCRIBE ERROR', e)
    }
  }

  return (
    <Flex
      backgroundColor="white"
      position={{ base: 'fixed', md: 'initial' }}
      p={{ base: 4, md: 0 }}
      left={{ base: 0, md: 'initial' }}
      bottom={{ base: 0, md: 'initial' }}
      mt={{ base: 0, md: 8 }}
      w="100%"
      borderWidth={{ base: 1, md: 0 }}
      borderColor="gray.600"
    >
      <Button variant="white" w="100%" onClick={onAlertOpen}>
        {t('Unsubscribe from all')}
      </Button>
      <AlertDialogModal
        onClose={onClose}
        isOpen={isOpen}
        isLoading={isUnsubscribeLoading}
        onUnsubscribe={onUnsubscribe}
      />
    </Flex>
  )
}
