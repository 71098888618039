import React from 'react'
import { Box, Flex, Center, Stack, Button } from '@chakra-ui/react'
import { LANGUAGES, useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { useUser } from 'hooks'
import { NavList } from '../NavList'
import { NavListItem } from '../NavListItem'
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import { MENU_TOP_LINKS, MENU_BOTTOM_LINKS } from '../../constants'

const Menu = ({
  black,
  language,
  setLanguage,
  signOut,
  isOpen,
  onToggle,
  location,
  itemStyles,
  display,
}) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const isGuest = user.id === 'guest'

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      mr={{ base: 'none', md: '24px' }}
      width={{ base: '100%', md: 'unset' }}
      display={display}
    >
      <Center
        as="button"
        pr="2"
        py="2"
        fontSize="3xl"
        color="gray.600"
        onClick={onToggle}
      >
        {isOpen ? (
          <Box pl={0} height="24px" width="24px">
            <Close style={{ width: '100%', height: '100%' }} />
          </Box>
        ) : (
          <Box pl={0} height="24px" width="24px">
            <MenuIcon
              style={{
                width: '100%',
                height: '100%',
                filter: black ? 'invert(1)' : 'none',
              }}
            />
          </Box>
        )}
      </Center>
      <NavList animate={isOpen ? 'enter' : 'exit'}>
        <Flex
          display="flex"
          position="relative"
          zIndex={1000}
          px="6"
          height="84px"
          as="nav"
          aria-label="Site navigation"
          justify="space-between"
          align="center"
        >
          <Center
            as="button"
            pr="2"
            py="2"
            pl="0"
            fontSize="3xl"
            color="gray.600"
            onClick={onToggle}
          >
            <Box height="24px" width="24px">
              <Close style={{ width: '100%', height: '100%' }} />
            </Box>
          </Center>

          <Box
            position="absolute"
            left={0}
            right={0}
            margin="auto"
            width="166px"
          >
            <Link to="/">
              <Logo width="100%" />
            </Link>
          </Box>
        </Flex>

        <Stack
          position="absolute"
          top="20px"
          justifyContent="center"
          width="100%"
          height="100%"
          py="4"
          spacing="6"
        >
          {MENU_TOP_LINKS.map((link, index) => (
            <NavListItem key={`mobile-nav-${link.href}-${index}`}>
              <Flex {...itemStyles}>
                <Link to={link.href}>{t(link.label)}</Link>
              </Flex>
            </NavListItem>
          ))}
          <Flex py="4" px="3rem" justify="center">
            <Box
              bg="rgba(255, 255, 255, 0.5)"
              height="1px"
              px="12"
              width="100%"
            />
          </Flex>
          {MENU_BOTTOM_LINKS.map((link, index) => (
            <NavListItem key={`mobile-nav-${link.href}-${index}`}>
              <Flex {...itemStyles}>
                <Link to={link.href}>{t(link.label)}</Link>
              </Flex>
            </NavListItem>
          ))}
          {isGuest ? (
            <NavListItem>
              <Flex
                fontSize="5xl"
                fontWeight="bold"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="white"
                borderColor="transparent"
                transition="all 0.2s"
              >
                <Link to={`/sign-in?from=${location.pathname.slice(3)}`}>
                  {t('nav@signIn')}
                </Link>
              </Flex>
            </NavListItem>
          ) : (
            <>
              <NavListItem>
                <Flex
                  fontSize="5xl"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                  borderColor="transparent"
                  transition="all 0.2s"
                >
                  <Link to="/profile/projects">{t('nav@profile')}</Link>
                </Flex>
              </NavListItem>
              <NavListItem>
                <Flex
                  fontSize="5xl"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                  borderColor="transparent"
                  transition="all 0.2s"
                  onClick={() => {
                    signOut()
                    onToggle()
                  }}
                >
                  {t('nav@logout')}
                </Flex>
              </NavListItem>
            </>
          )}
          <Flex justifyContent="center" alignItems="center">
            <NavListItem>
              {Object.keys(LANGUAGES).map((key) => (
                <Button
                  key={`language-switcher-${key}`}
                  color={language === key ? 'blue.400' : 'white'}
                  bg={language === key ? 'white' : 'blue.400'}
                  minHeight="30px"
                  mx={2}
                  borderRadius={5}
                  onClick={() => setLanguage(key)}
                >
                  {t(`language@${key}LabelShort`)}
                </Button>
              ))}
            </NavListItem>
          </Flex>
        </Stack>
      </NavList>
      {isGuest && (
        <Box
          display={{ base: 'block', md: 'none' }}
          fontWeight="bold"
          fontSize="md"
          color={
            location.pathname === `/${language}/` ||
            location.pathname === `/${language}`
              ? 'white'
              : 'gray.800'
          }
        >
          <Link to="/sign-in">{t('nav@signIn')}</Link>
        </Box>
      )}
    </Flex>
  )
}

export default Menu
