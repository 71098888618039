import { useCallback } from 'react'
import {
  useCreateSource,
  useCustomToast,
  useLocalStorage,
  useObjectManipulations,
  useUpdateUser,
  useUser,
} from 'hooks'

const useHelperCreateStripeAttachCard = ({ toggleLoading }) => {
  const { user } = useUser()
  const { updateUser } = useUpdateUser()

  const toast = useCustomToast()
  const { put } = useLocalStorage()

  const { deleteKey } = useObjectManipulations()
  const { mutate: createSource } = useCreateSource()

  const createStripeAttachCard = useCallback(
    ({ customerId, tokenId }) => {
      return new Promise((resolve, reject) => {
        createSource(
          {
            customerId,
            tokenId,
          },
          {
            onSuccess: async (sourceResponse) => {
              if (!sourceResponse.source) {
                // TODO in all cases runs with 623 line's toast
                // toast({
                //   title: t('payment@orderStatus-general'),
                //   status: 'error',
                // })
                return resolve()
              }

              const existingStripeCard = user.stripeAttachedCards?.find(
                ({ last4 }) => last4 === sourceResponse.source.last4
              )
              if (existingStripeCard) {
                return resolve({ source: { id: existingStripeCard.id } })
              }

              const stripeAttachCardInput = {
                id: sourceResponse.source.id,
                type: sourceResponse.source.brand.toLowerCase(),
                last4: sourceResponse.source.last4,
              }
              const updatedAttachedCards = [
                ...(user.stripeAttachedCards || []),
                stripeAttachCardInput,
              ]
              deleteKey(updatedAttachedCards, '__typename')
              const updateUserRes = await updateUser({
                id: user.id,
                stripeAttachedCards: updatedAttachedCards,
              })

              put('rearmenia_user', JSON.stringify(updateUserRes))
              resolve(sourceResponse)
            },
            onError: (err) => {
              console.log(err)
              toast({ title: 'Problem with Stripe', status: 'warning' })
              toggleLoading(false)
              reject(err)
            },
          }
        )
      })
    },
    [user]
  )

  return createStripeAttachCard
}

export default useHelperCreateStripeAttachCard
