import React, { useEffect, useState } from 'react'
import {
  Button,
  Container,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import { useGetExpertsCategories, useUpdateContentItem } from 'hooks'
import { useCustomToast } from 'hooks'

const flags = ['🇺🇸', '🇷🇺', '🇦🇲']
const ApplicationsDashboard = () => {
  const { data: categories } = useGetExpertsCategories()
  const toast = useCustomToast()
  const { mutate: updateCategories, isLoading } = useUpdateContentItem()

  const [updatedCategories, setUpdatedCategories] = useState()
  useEffect(() => {
    if (categories?.length > 0) {
      setUpdatedCategories([...categories])
    }
  }, [categories])

  const onUpdate = () => {
    const input = {
      id: 'categories',
      jsonPageData: JSON.stringify(updatedCategories),
    }
    updateCategories(input, {
      onSuccess(res) {
        console.log(res)
        toast({
          status: 'success',
          title: 'Updated expertise',
        })
      },
      onError(err) {
        toast({
          status: 'error',
          title: err.message,
        })
        console.log('❌', err)
      },
    })
  }
  return (
    <MainLayout>
      <Container w="full" maxW="90%" mt={10} minH="calc(100vh - 96px)">
        <Flex align="center" justify="center">
          <Heading my={10} textAlign="center">
            Expertise
          </Heading>
          <Button
            isDisabled={isLoading}
            ml={4}
            variant="green"
            onClick={onUpdate}
          >
            Submit
          </Button>
        </Flex>
        {categories?.map(({ title_en, tags_en }, catIndex) => (
          <Flex mb={8} direction="column" gap={4} key={title_en}>
            <Flex gap={8} w="full" flexWrap="wrap">
              {['title_en', 'title_ru', 'title_hy'].map((title, index) => (
                <InputGroup minW="300px" w="auto" flex={1} key={title}>
                  <InputLeftElement
                    pt="2"
                    pointerEvents="none"
                    color="gray.700"
                    fontSize="1.2em"
                    children={flags[index]}
                  />
                  <Input
                    _focus={{ borderColor: 'blue.300' }}
                    _hover={{ borderColor: 'blue.300' }}
                    sx={{ boxShadow: 'none !important' }}
                    defaultValue={categories[catIndex][title]}
                    onBlur={(e) => {
                      const arrayToUpdate = [...updatedCategories]
                      arrayToUpdate[catIndex] = {
                        ...arrayToUpdate[catIndex],
                        [title]: e.target.value,
                      }
                      setUpdatedCategories(arrayToUpdate)
                    }}
                    borderColor="border.100"
                    size="lg"
                    fontSize="lg"
                  />
                </InputGroup>
              ))}
            </Flex>
            <InputGroup>
              <InputLeftElement
                pt="2"
                pointerEvents="none"
                color="gray.700"
                fontSize="1.2em"
                children="➡️"
              />
              <Input
                _focus={{ borderColor: 'blue.300' }}
                _hover={{ borderColor: 'blue.300' }}
                sx={{ boxShadow: 'none !important' }}
                defaultValue={tags_en}
                onBlur={(e) => {
                  const arrayToUpdate = [...updatedCategories]
                  arrayToUpdate[catIndex] = {
                    ...arrayToUpdate[catIndex],
                    tags_en: e.target.value,
                  }
                  setUpdatedCategories(arrayToUpdate)
                }}
                borderColor="border.100"
                size="lg"
                fontSize="lg"
              />
            </InputGroup>
          </Flex>
        ))}
      </Container>
    </MainLayout>
  )
}

export default ApplicationsDashboard
