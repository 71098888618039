import { useEffect, useState } from 'react'
import { post } from 'aws-amplify/api'
import {
  Stack,
  Image,
  Button,
  Text,
  IconButton,
  Checkbox,
  Center,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser, useQueryParams, useGetProject } from 'hooks'
import CreditCardItem from './CreditCardItem'
import StripeAndArmenianCard from './StripeAndArmenianCard'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import { ReactComponent as GiftCard } from 'assets/icons/giftCardIcon.svg'
import { ReactComponent as CreditCard } from 'assets/icons/credit-card-v2.svg'
import { PAYMENT_METHODS_DATA } from 'pages/Donation/helpers/constants'
import ApplyGiftCard from './ApplyGiftCard'
import PaymentGuestUserFormDonationPage from 'components/PaymentGuestUserFormDonationPage'
import { getResponseToJSON } from 'utils/api'

import { isSupportedType, isIdramEnabled } from './helpers'
import Wrapper from './Wrapper'
import CardSavingSelectionWithChildren from './CardSavingSelectionWithChildren'
import CardSavingSelection from './CardSavingSelection'

const isLocalhost = ['localhost', '127.0.0.1'].includes(
  window?.location?.hostname
)

export default function CreditCards({
  page,
  fullRedirectUrl,
  setPaymentMethod,
  getValues,
  removable,
  makeDefault,
  privacyPolicy,
  showIcons,
  onClick,
  isGiftCardDisabled,
  showGiftCard,
  setValue,
  paymentMethod,
  isRecurring,
  isContribution,
  donationPage,
  showStripe,
  clearErrors,
  trigger,
  register,
  errors = {},
  isAttachNewCardEnabled,
  amount,
  scheme = 'white',
  setTokenId,
  setNewStripeCard,
  subscriptionId,
  isCharityBucket,
  setErrorLocalCard,
}) {
  const queryParams = useQueryParams()
  const { t, language } = useTranslation()
  const { user } = useUser()
  const [read, toggleRead] = useState(true)
  const [error, setError] = useState(false)
  const [loading, toggleLoading] = useState(false)

  const projectId = queryParams.get('projectId')
  const { data: project } = useGetProject({ projectId })

  const onCardAdd = async () => {
    if (loading) {
      return
    }
    if (!read) {
      return setError(true)
    }
    toggleLoading(true)

    let redirectUrl = `${window.location.protocol}//${window.location.host}/${language}/check-binding`

    if (page === 'donation') {
      redirectUrl += `?donationInfo=${projectId},${queryParams.get('amount')}`
    }
    if (fullRedirectUrl) {
      redirectUrl += `?redirectUrl=${fullRedirectUrl}`
    }

    const data = {
      body: {
        userEmail: user.email,
        description: `Dear ${user.firstName} ${user.lastName}, fill in your card details so we can attach a card to your profile. Transaction ID - `,
        redirectUrl,
        language,
        subscriptionId,
      },
    }

    try {
      const res = post({
        apiName: 'paymentAPI',
        path: '/binding',
        options: data,
      })

      const { formUrl } = await getResponseToJSON(res)
      window.location = formUrl.replace('_binding', '')
    } catch (err) {
      console.log('❌', err)
    } finally {
      toggleLoading(false)
    }
  }

  const onCardClick = (creditCard) => {
    if (typeof setValue === 'function') {
      setValue('paymentMethod', creditCard.bindingId)
    }

    if (typeof onClick === 'function') {
      onClick(creditCard.bindingId)
    }
  }

  useEffect(() => {
    if (donationPage) {
      clearErrors(['firstName', 'lastName', 'email', 'stripe'])
    }
  }, [paymentMethod])

  return (
    <Wrapper
      showIcons={showIcons}
      error={error}
      read={read}
      privacyPolicy={
        privacyPolicy && (!user.creditCards || user.creditCards.length === 0)
      }
      toggleRead={toggleRead}
      setError={setError}
    >
      <Stack spacing={3}>
        {user?.creditCards?.length > 0 &&
          user.creditCards.map((creditCard, index) => (
            <CreditCardItem
              key={`credit-card-${creditCard.pan}-${index}`}
              removable={removable}
              index={index}
              isSelected={creditCard.bindingId === paymentMethod}
              makeDefault={makeDefault}
              creditCard={creditCard}
              onClick={onClick}
              onCardClick={onCardClick}
              scheme={scheme}
            />
          ))}

        {!donationPage &&
          user.stripeAttachedCards?.map(({ id, type, last4 }, index) => (
            <CreditCardItem
              key={`credit-card-${id}-${index}`}
              removable={removable}
              index={index}
              isSelected={id === paymentMethod}
              makeDefault={makeDefault}
              creditCard={{ bindingId: id, type, pan: `**** ${last4}` }}
              onClick={onClick}
              onCardClick={onCardClick}
              scheme={scheme}
            />
          ))}

        {donationPage && (
          <>
            {showStripe &&
              user.stripeAttachedCards?.map(({ id, last4, type }) => (
                <CardSavingSelection
                  key={id}
                  Icon={
                    isSupportedType(type) ? (
                      <Image src={`/assets/images/${type}.png`} h="20px" />
                    ) : (
                      <CreditCard style={{ height: '28px' }} />
                    )
                  }
                  text={`****  ${last4} ${t('savedCard')} `}
                  type="attach"
                  isSelected={paymentMethod === id}
                  onClick={() => onCardClick({ bindingId: id })}
                />
              ))}
            <StripeAndArmenianCard
              showStripe={showStripe}
              setPaymentMethod={setPaymentMethod}
              getValues={getValues}
              register={register}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              user={user}
              amount={amount}
              isRecurring={isRecurring}
              paymentMethod={paymentMethod}
              setTokenId={setTokenId}
              setNewStripeCard={setNewStripeCard}
              isInvalid={
                errors.firstName ||
                errors.lastName ||
                errors.email ||
                errors.stripe
              }
              projectId={projectId}
              setError={setErrorLocalCard}
            />

            {/* Google pay + Apple pay */}
            {!isRecurring && donationPage && showStripe && !isContribution && (
              <CardSavingSelectionWithChildren
                src={PAYMENT_METHODS_DATA.GOOGLE_PAY.image}
                text={PAYMENT_METHODS_DATA.GOOGLE_PAY.title}
                type="GOOGLE_PAY"
                isSelected={paymentMethod === 'GOOGLE_PAY'}
                onClick={() => {
                  setValue('paymentMethod', 'GOOGLE_PAY')
                }}
                isInvalid={errors.firstName || errors.lastName || errors.email}
              >
                {user.id === 'guest' && (
                  <PaymentGuestUserFormDonationPage
                    register={register}
                    errors={errors}
                    isRecurring={isRecurring}
                    emailErrorMessage={t(errors?.email?.message)}
                  />
                )}
              </CardSavingSelectionWithChildren>
            )}
            {!isRecurring &&
              donationPage &&
              showStripe &&
              !isContribution &&
              window.ApplePaySession && (
                <CardSavingSelectionWithChildren
                  src={PAYMENT_METHODS_DATA.APPLE_PAY.image}
                  text={PAYMENT_METHODS_DATA.APPLE_PAY.title}
                  type="APPLE_PAY"
                  isSelected={paymentMethod === 'APPLE_PAY'}
                  onClick={() => {
                    setValue('paymentMethod', 'APPLE_PAY')
                  }}
                  isInvalid={
                    errors.firstName || errors.lastName || errors.email
                  }
                >
                  {user.id === 'guest' && (
                    <PaymentGuestUserFormDonationPage
                      register={register}
                      errors={errors}
                      isRecurring={isRecurring}
                      emailErrorMessage={t(errors?.email?.message)}
                    />
                  )}
                </CardSavingSelectionWithChildren>
              )}

            {/* PayPal */}
            {donationPage &&
              !isCharityBucket &&
              isLocalhost && // @TODO: currently allow only for localhost
              showStripe && (
                <CardSavingSelectionWithChildren
                  src={PAYMENT_METHODS_DATA.PAYPAL.image}
                  text={PAYMENT_METHODS_DATA.PAYPAL.title}
                  type="PAYPAL"
                  isSelected={paymentMethod === 'PAYPAL'}
                  onClick={() => {
                    setValue('paymentMethod', 'PAYPAL')
                  }}
                  isInvalid={
                    errors.firstName || errors.lastName || errors.email
                  }
                >
                  {user.id === 'guest' && (
                    <PaymentGuestUserFormDonationPage
                      register={register}
                      errors={errors}
                      isRecurring={isRecurring}
                      emailErrorMessage={t(errors?.email?.message)}
                    />
                  )}
                </CardSavingSelectionWithChildren>
              )}

            {/* IDram */}
            {!isRecurring && isIdramEnabled(project) && (
              <CardSavingSelectionWithChildren
                src={PAYMENT_METHODS_DATA['IDRAM'].image}
                text={PAYMENT_METHODS_DATA['IDRAM'].title}
                type="attach"
                isSelected={paymentMethod === 'IDRAM'}
                onClick={() => {
                  setValue('paymentMethod', 'IDRAM')
                }}
                isInvalid={errors.firstName || errors.lastName || errors.email}
              >
                {user.id === 'guest' && donationPage && (
                  <PaymentGuestUserFormDonationPage
                    register={register}
                    errors={errors}
                    isRecurring={isRecurring}
                    t={t}
                    emailErrorMessage={t(errors?.email?.message)}
                  />
                )}
              </CardSavingSelectionWithChildren>
            )}

            {/* Gift Card */}
            {!isRecurring && showGiftCard && (
              <CardSavingSelectionWithChildren
                Icon={GiftCard}
                text={t('Gift card')}
                type="attach"
                isSelected={paymentMethod === 'giftCard'}
                onClick={() => {
                  onCardClick({ bindingId: 'giftCard' })
                }}
                isInvalid={errors.giftCardCode}
              >
                <ApplyGiftCard
                  isGiftCardDisabled={isGiftCardDisabled}
                  register={register}
                  getValues={getValues}
                  trigger={trigger}
                />
              </CardSavingSelectionWithChildren>
            )}
          </>
        )}

        {/* {!removable && showStripe && (
          <CardSavingSelectionWithChildren
            src={PAYMENT_METHODS_DATA['MIR'].image}
            text={PAYMENT_METHODS_DATA['MIR'].title}
            type="attach"
            isSelected={paymentMethod === 'MIR'}
            onClick={() => {
              setValue('paymentMethod', 'MIR')
            }}
            isInvalid={errors.firstName || errors.lastName || errors.email}
          >
            {user.id === 'guest' ? (
              <PaymentGuestUserFormDonationPage
                register={register}
                errors={errors}
                isRecurring={isRecurring}
                emailErrorMessage={t(errors?.email?.message)}
              />
            ) : isRecurring ? (
              <Text fontSize="md" mt={3}>
                <b>{t('donation@cardWillBeSaved').split('(bold)')[0]}</b>
                {t('donation@cardWillBeSaved').split('(bold)')[1]}
              </Text>
            ) : (
              <Checkbox mt={3} {...register('attachCard')}>
                <Text fontSize="sm" fontWeight={400}>
                  {t('donation@saveCard').split('(bold)')[0]}
                  <b>{t('donation@saveCard').split('(bold)')[1]}</b>
                  {t('donation@saveCard').split('(bold)')[2]}
                </Text>
              </Checkbox>
            )}
          </CardSavingSelectionWithChildren>
        )} */}
        {removable || isAttachNewCardEnabled ? (
          <>
            {user?.creditCards?.length ? (
              <Center
                py={3}
                px={6}
                borderRadius={8}
                borderWidth="1px"
                borderColor="border.500"
                cursor="pointer"
                bg="border.500"
                onClick={onCardAdd}
              >
                <IconButton
                  w="24px"
                  h="24px"
                  minW="24px"
                  minH="24px"
                  borderRadius="100px"
                  icon={<Plus />}
                  isLoading={loading}
                  variant="blue"
                  mr={3}
                />
                <Text color="blue.400" fontSize="md" fontWeight="700">
                  {t('profile@settings-payments-attach-new-card')}
                </Text>
              </Center>
            ) : (
              <Button
                isLoading={loading}
                onClick={onCardAdd}
                variant="blue"
                borderRadius={{ base: '12px', sm: '8px' }}
                fontSize="md"
                fontWeight="700"
              >
                {t('profile@settings-payments-attach-card')}
              </Button>
            )}
          </>
        ) : (
          <>
            {!donationPage && (
              <CardSavingSelectionWithChildren
                src={PAYMENT_METHODS_DATA['ARCA_VISA_MASTER'].image}
                text={PAYMENT_METHODS_DATA['ARCA_VISA_MASTER'].title}
                type="attach"
                isSelected={paymentMethod === 'ARCA_VISA_MASTER'}
                onClick={() => {
                  setValue('paymentMethod', 'ARCA_VISA_MASTER')
                }}
                isInvalid={errors.firstName || errors.lastName || errors.email}
              >
                {user.id === 'guest' ? (
                  <>
                    {donationPage && (
                      <PaymentGuestUserFormDonationPage
                        register={register}
                        errors={errors}
                        isRecurring={isRecurring}
                        emailErrorMessage={t(errors?.email?.message)}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {isRecurring ? (
                      <Text fontSize="md" mt={3}>
                        <b>
                          {t('donation@cardWillBeSaved').split('(bold)')[0]}
                        </b>
                        {t('donation@cardWillBeSaved').split('(bold)')[1]}
                      </Text>
                    ) : (
                      <Checkbox mt={3} {...register('attachCard')}>
                        <Text fontSize="md" fontWeight={400}>
                          {t('donation@saveCard').split('(bold)')[0]}
                          <b>{t('donation@saveCard').split('(bold)')[1]}</b>
                          {t('donation@saveCard').split('(bold)')[2]}
                        </Text>
                      </Checkbox>
                    )}
                  </>
                )}
              </CardSavingSelectionWithChildren>
            )}

            {/* {!removable && !showStripe && (
              <CardSavingSelectionWithChildren
                src={PAYMENT_METHODS_DATA['MIR'].image}
                text={PAYMENT_METHODS_DATA['MIR'].title}
                type="attach"
                isSelected={paymentMethod === 'MIR'}
                onClick={() => {
                  setValue('paymentMethod', 'MIR')
                }}
                isInvalid={errors.firstName || errors.lastName || errors.email}
              >
                {user.id === 'guest' ? (
                  <PaymentGuestUserFormDonationPage
                    register={register}
                    errors={errors}
                    isRecurring={isRecurring}
                    emailErrorMessage={t(errors?.email?.message)}
                  />
                ) : isRecurring ? (
                  <Text fontSize="md" mt={3}>
                    <b>{t('donation@cardWillBeSaved').split('(bold)')[0]}</b>
                    {t('donation@cardWillBeSaved').split('(bold)')[1]}
                  </Text>
                ) : (
                  <Checkbox mt={3} {...register('attachCard')}>
                    <Text fontSize="sm" fontWeight={400}>
                      {t('donation@saveCard').split('(bold)')[0]}
                      <b>{t('donation@saveCard').split('(bold)')[1]}</b>
                      {t('donation@saveCard').split('(bold)')[2]}
                    </Text>
                  </Checkbox>
                )}
              </CardSavingSelectionWithChildren>
            )} */}
          </>
        )}
      </Stack>
    </Wrapper>
  )
}
