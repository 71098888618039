import React from 'react'
import { Image } from '@chakra-ui/react'

const ImageWrapper = ({ src, ignoreFallback = true, ...props }) => {
  const finalSrc =
    src.startsWith('http://') || src.startsWith('https://')
      ? src
      : `${process.env.REACT_APP_S3_BUCKET_URL}/public${src}`

  return (
    <Image
      {...props}
      draggable={false}
      src={finalSrc}
      fallbackSrc={'/assets/images/noImg.svg'}
      ignoreFallback={ignoreFallback}
    />
  )
}

export default ImageWrapper
