import React, { useEffect, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as FundraisersIcon } from 'assets/icons/fundraisers_icon_light.svg'
import { ReactComponent as ExpertsIcon } from 'assets/icons/experts_icon_light.svg'
import { ReactComponent as InitiatorIcon } from 'assets/icons/initiator_icon_light.svg'
import { ReactComponent as DonorSpaceIcon } from 'assets/icons/donor_space_light.svg'
import { ReactComponent as GoodraiserIcon } from 'assets/icons/goodraiser_icon_light.svg'
import { ReactComponent as InvestorIcon } from 'assets/icons/investor_icon_light.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings_icon_light.svg'
import { ReactComponent as CorporateIcon } from 'assets/icons/corporate_icon_light.svg'
import { ReactComponent as GiftCardIcon } from 'assets/icons/gift_card_icon_light.svg'
import { ReactComponent as DiscoverInactive } from '../../assets/icons/discoverInactive_light.svg'
import { ReactComponent as CollaborationsIcon } from 'assets/icons/collaborations_light.svg'
import { ReactComponent as LanguageIcon } from 'assets/icons/language.svg'
import { ReactComponent as CurrencyIcon } from 'assets/icons/currency.svg'
import { ReactComponent as ArrowRight } from '../../assets/icons/arrowRight.svg'
import { useQueryParams, useSignOut, useUser } from 'hooks'
import TabItem from './TabItem'
import HomepageNavbar from './HomepageNavbar'
import DashboardNavbar from './DashboardNavbar'
import MenuDrawerLayout from './MenuDrawerLayout'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const Menu = ({ isHomepage, title, backTo, titleProps }) => {
  const { user } = useUser()
  const isGuest = user.id === 'guest'

  const location = useLocation()
  const pathname = location.pathname
  const { isOpen, onClose, onOpen } = useDisclosure()
  const queryParams = useQueryParams()

  useEffect(() => {
    if (!queryParams.get('drawer')) {
      onClose()
    }
  }, [window.location.pathname])

  useEffect(() => {
    if (queryParams.get('drawer')) {
      onOpen()
    }
  }, [queryParams.get('drawer')])
  const signOut = useSignOut()

  const { t, language } = useTranslation()
  const NAV_LINKS = [
    { divider: true, show: true },
    {
      label: t('nav@mobile@home'),
      pathname: '/',
      Icon: DiscoverInactive,
      show: true,
      color: [`/${language}/`, `/${language}`].includes(pathname)
        ? '#012F73'
        : '#696A6F',
      fontWeight: [`/${language}/`, `/${language}`].includes(pathname)
        ? 600
        : 400,
    },
    {
      label: t('experts'),
      pathname: '/experts',
      Icon: ExpertsIcon,
      show: true,
      color: location.pathname.includes('/experts') ? '#012F73' : '#696A6F',
      fontWeight: location.pathname.includes('/experts') ? 600 : 400,
    },
    {
      label: t('nav@mobile@fundraisers'),
      pathname: '/projects?type=oneTime',
      Icon: FundraisersIcon,
      show: true,
      color:
        location.pathname.includes('/projects') ||
        location.pathname === `/${language}/fundraisers`
          ? '#012F73'
          : '#696A6F',
      fontWeight:
        location.pathname.includes('/projects') ||
        location.pathname === `/${language}/fundraisers`
          ? 600
          : 400,
    },
    {
      label: t('Collaborations'),
      pathname: '/collaborations',
      Icon: CollaborationsIcon,
      show: true,
      color:
        location.pathname === `/${language}/collaborations`
          ? '#012F73'
          : '#696A6F',
      fontWeight:
        location.pathname === `/${language}/collaborations` ? 600 : 400,
    },
    { divider: true, show: !isGuest },
    { heading: t('nav@mobile@myRoles'), show: !isGuest },
    {
      label: t('profile@expert'),
      Icon: ExpertsIcon,
      pathname: '/profile/expert/card',
      show: !isGuest,
      color: location.pathname.includes('/profile/expert')
        ? '#ED8936'
        : '#696A6F',
      fontWeight: location.pathname.includes('/profile/expert') ? 600 : 400,
      isLocked: !user.expertId,
    },
    {
      label: t('homepage@impactRoles@initiator'),
      Icon: InitiatorIcon,
      pathname: '/profile/initiator-space/collaborations',
      show: !isGuest,
      color: location.pathname.includes('/profile/initiator-space')
        ? '#F56565'
        : '#696A6F',
      fontWeight: location.pathname.includes('/profile/initiator-space')
        ? 600
        : 400,
      isLocked: !user.isInitiator,
    },
    {
      label: t('nav@mobile@fundraiser'),
      Icon: FundraisersIcon,
      pathname: '/profile/fundraisers/oneTime',
      show: !isGuest,
      color: location.pathname.includes('/profile/fundraisers')
        ? '#3182CE'
        : '#696A6F',
      fontWeight: location.pathname.includes('/profile/fundraisers')
        ? 600
        : 400,
      isLocked: !user.isFundraiserCreator,
    },
    {
      label: t('homepage@impactRoles@donor'),
      Icon: DonorSpaceIcon,
      pathname: '/profile/history/impact',
      show: !isGuest,
      color: location.pathname.includes('/profile/history')
        ? '#805AD5'
        : '#696A6F',
      fontWeight: location.pathname.includes('/profile/history') ? 600 : 400,
      isLocked: !user.totalOneTimeDonation && !user.totalRecurringDonation,
    },
    {
      label: t('homepage@impactRoles@investor'),
      Icon: InvestorIcon,
      pathname: '/profile/invest/impact',
      show: !isGuest,
      color: location.pathname.includes('/profile/invest')
        ? '#718096'
        : '#696A6F',
      fontWeight: location.pathname.includes('/profile/invest') ? 600 : 400,
      isLocked: !user.isReArmeniaInvestor,
    },
    {
      label: t('nav@mobile@goodraiser'),
      Icon: GoodraiserIcon,
      pathname: '/profile/in-kind',
      show: !isGuest,
      color: location.pathname.includes('/profile/in-kind')
        ? '#00B5D8'
        : '#696A6F',
      fontWeight: location.pathname.includes('/profile/in-kind') ? 600 : 400,
      isLocked: !user.isGoodraiserCreator,
    },
    { divider: true, show: isGuest },
    {
      label: t('About us'),
      pathname: '/about-us',
      show: isGuest,
      color: '#696A6F',
      fontWeight: 400,
    },
    {
      label: t('footer@contactUs'),
      pathname: '/contact-us',
      show: isGuest,
      color: '#696A6F',
      fontWeight: 400,
    },
    { divider: true, show: true },
    {
      label: t('filters@language'),
      pathname: `/language?from=${location.pathname.slice(3)}`,
      Icon: LanguageIcon,
      show: true,
      ArrowRight,
      color: '#696A6F',
      fontWeight: 400,
    },
    {
      label: t('currency'),
      pathname: `/currency?from=${location.pathname.slice(3)}`,
      Icon: CurrencyIcon,
      show: true,
      ArrowRight,
      color: '#696A6F',
      fontWeight: 400,
    },
    {
      label: t('profile@settings'),
      Icon: SettingsIcon,
      pathname: '/profile/settings/profile-settings',
      show: !isGuest,
      color: location.pathname.includes('/profile/settings')
        ? '#012F73'
        : '#696A6F',
      fontWeight: location.pathname.includes('/profile/settings') ? 600 : 400,
    },
    { divider: true, show: true },
    {
      label: t('nav@mobile@corporate'),
      pathname: '/corporate',
      Icon: CorporateIcon,
      show: true,
      color: '#696A6F',
      fontWeight: 400,
    },
    {
      label: t('nav@mobile@giftCard'),
      pathname:
        process.env.REACT_APP_ENV !== 'production'
          ? '/select-gift-card'
          : '/gift-card',
      Icon: GiftCardIcon,
      show: true,
      color: '#696A6F',
      fontWeight: 400,
    },
    { divider: true, show: !isGuest },
    {
      label: t('About us'),
      pathname: '/about-us',
      show: !isGuest,
      color: '#696A6F',
      fontWeight: 400,
    },
    {
      label: t('footer@contactUs'),
      pathname: '/contact-us',
      show: !isGuest,
      color: '#696A6F',
      fontWeight: 400,
    },
    {
      label: t('nav@logout'),
      pathname: '/',
      onClick: signOut,
      show: !isGuest,
      color: '#696A6F',
      fontWeight: 400,
    },
  ]

  const [padding, setPadding] = useState(5)
  const [logoWidth, setLogoWidth] = useState(166)
  const [bgColor] = useState('blue.400')
  const [buttonDisplayStatus, setButtonDisplayStatus] = useState('none')

  const onScroll = () => {
    if (window.scrollY > 80) {
      setPadding(0)
      setLogoWidth(125)
      setButtonDisplayStatus('block')
    } else if (window.scrollY > 50) {
      setPadding(0)
      setLogoWidth(125)
      setButtonDisplayStatus('none')
    } else {
      setLogoWidth(166)
      setPadding(5)
      setButtonDisplayStatus('none')
    }
  }

  useEffect(() => {
    if (isHomepage) {
      document.addEventListener('scroll', onScroll)
    }

    return () => document.removeEventListener('scroll', onScroll)
  }, [onScroll])
  return (
    <>
      {isHomepage ? (
        <HomepageNavbar
          t={t}
          user={user}
          isGuest={isGuest}
          onOpen={onOpen}
          bgColor={bgColor}
          padding={padding}
          buttonDisplayStatus={buttonDisplayStatus}
          logoWidth={logoWidth}
        />
      ) : (
        <DashboardNavbar
          onOpen={onOpen}
          user={user}
          isGuest={isGuest}
          backTo={backTo}
          title={title}
          titleProps={titleProps}
        />
      )}

      <MenuDrawerLayout
        isOpen={isOpen}
        onClose={onClose}
        t={t}
        isGuest={isGuest}
        user={user}
      >
        {NAV_LINKS.filter((tab) => tab.show).map((props, index) => (
          <TabItem
            key={`${props.label}${index}`}
            t={t}
            onClose={onClose}
            {...props}
          />
        ))}
      </MenuDrawerLayout>
    </>
  )
}

export default Menu
