import { useTranslation } from 'contexts/TranslationContext'
import { useUser } from 'hooks'
import useUpdateGiftCard from 'hooks/giftCard/useUpdateGiftCard'

export const useGiftCard = ({
  isContribution,
  giftCardInfo,
  projectId,
  project,
  peerProjectId,
  peerProject,
}) => {
  const { user } = useUser()
  const { language } = useTranslation()

  const { mutateAsync: updateGiftCardMutation } = useUpdateGiftCard()

  return async ({ paymentMethod, data, reArmenia }) => {
    const isStripe =
      paymentMethod === 'stripe' || paymentMethod.includes('card_')

    let paymentProvider = null
    if (isStripe) {
      paymentProvider = 'stripe'
    }

    const transactionParams = {
      userId: user.id,
      userFirstName: data.firstName,
      userLastName: data.lastName,
      userEmail: data.email,
      data: {
        userId: user.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        isAnonymous: data.isAnonymous,
        isInfoAnonymous: data.isInfoAnonymous,
        amount: (giftCardInfo.amount - reArmenia) / giftCardInfo.currencyRate,
        reArmenia: reArmenia / giftCardInfo.currencyRate,
        language,
        currency: giftCardInfo.currency,
        currencyRate: giftCardInfo.currencyRate,
      },
      paymentMethod,
      projectId: projectId || project?.id,
      peerProjectId,
      peerProject,
      isContribution,
      provider: paymentProvider || 'giftCard',
      isStripe,
    }

    await updateGiftCardMutation({
      giftCardId: giftCardInfo.id,
      transactionParams,
    })
  }
}
