import { Image } from '@chakra-ui/react'
import ImagePersonMobile from './images/person-mobile.png'
import ImagePersonDesktop from './images/person-desktop.png'

const PersonImage = (props) => (
  <Image
    draggable={false}
    position={'absolute'}
    zIndex={-1}
    right={0}
    bottom={0}
    pointerEvents={'none'}
    {...props}
  />
)

const GuidPerson = () => (
  <>
    <PersonImage
      display={{ base: 'none', lg: 'block' }}
      src={ImagePersonDesktop}
      width="380px"
    />

    <PersonImage
      display={{ base: 'block', lg: 'none' }}
      src={ImagePersonMobile}
      width="240px"
    />
  </>
)

export default GuidPerson
