import {
  Flex,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { DisabledBox } from 'components/DisabledBox'

export default function PopoverWrapper({
  children,
  isOpen,
  Header,
  isInvalid,
  isDisabled,
  wrapperProps,
  contentProps = { width: '277px' },
  ...rest
}) {
  return (
    <Flex pos="relative" {...wrapperProps}>
      {isDisabled && <DisabledBox />}
      <PopoverTrigger>
        <Flex
          as="button"
          type="button"
          cursor="pointer"
          h={10}
          align="center"
          p={2}
          border="1px solid"
          borderColor={isInvalid ? 'red.400' : 'border.400'}
          borderRadius={8}
          bg={isOpen ? '#EAECEF' : 'white'}
          w="full"
          justify="space-between"
          {...rest}
        >
          <Header />
        </Flex>
      </PopoverTrigger>
      <PopoverContent border="none" {...contentProps}>
        <PopoverBody
          display="flex"
          direction="column"
          p="16px !important"
          border="1px solid !important"
          borderColor="border.400 !important"
          borderRadius={8}
        >
          <Flex direction="column" w="full">
            {children}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Flex>
  )
}
