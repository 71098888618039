import React from 'react'
import MinimalNavbar from './MinimalNavbar'
import FullNavbar from './FullNavbar'

const OtherNavbar = ({
  shadow = '0 2px 26px 0 rgba(0, 0, 0, 0.1)',
  currentPath,
  onClick,
  disableLinks,
}) => {
  if (currentPath === 'donation' || currentPath === 'gift-card') {
    return <MinimalNavbar />
  }

  return (
    <FullNavbar
      shadow={shadow}
      currentPath={currentPath}
      onClick={onClick}
      disableLinks={disableLinks}
    />
  )
}

export default OtherNavbar
