import React, { forwardRef, useState } from 'react'
import {
  Button,
  Circle,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import ProfileDropdown from '../../ProfileDropdown'
import { useTranslation, LANGUAGES } from 'contexts/TranslationContext'
import { BlueSelect } from 'components/Select/BlueSelect'
import { useModal } from 'providers/ModalProvider'
import HeaderSearch from '../../../../HeaderSearch'
import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications_icon.svg'
import { ReactComponent as NotificationsBlueIcon } from 'assets/icons/notifications_blue_icon.svg'
import { useUser } from 'hooks'
import { NotificationList } from 'components/Notification/NotificationList'
import BasketIconButton from 'components/BasketIconButton'

const Web = ({ disableLinks = false, onClick = () => {} }) => {
  const { t, language, setLanguage } = useTranslation()
  const selectOption = (option) => setLanguage(option)
  const { openModal } = useModal()
  const { user } = useUser()
  const [search, setSearch] = useState(false)
  const { onOpen, onClose, isOpen } = useDisclosure()

  const isGuest = user.id === 'guest'

  return (
    <HStack spacing="5">
      <HeaderSearch search={search} setSearch={setSearch} xl white={false} />

      {!isGuest && (
        <>
          <NotificationsPopover
            unreadNotifications={user?.unreadNotifications}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            t={t}
            onClick={onClick}
          />
          <BasketIconButton />
        </>
      )}

      {!search && (
        <>
          <BlueSelect
            width="auto"
            display="flex"
            type="language"
            variant="noBorder"
            label={t(`language@${language}LabelShort`)}
            options={Object.keys(LANGUAGES).map((key) => ({
              title: t(`language@${key}Label`),
              value: key,
              key,
            }))}
            selectOption={selectOption}
            currentSelectedOption={t(`language@${language}Label`)}
            offset={[0, 7]}
          />
          {!isGuest ? (
            <>
              <Text fontWeight="bold" fontSize="md">
                {user.firstName}
              </Text>
              <ProfileDropdown
                black
                onClick={onClick}
                disableLinks={disableLinks}
              />
            </>
          ) : (
            <Button
              onClick={() => {
                openModal('signIn')
              }}
              fontWeight="bold"
              fontSize="md"
              textTransform="initial"
            >
              {t('nav@signIn')}
            </Button>
          )}

          {isGuest && (
            <Button
              onClick={() => {
                openModal('signUp', null, true)
              }}
              borderRadius="12px"
              boxShadow="none"
              minHeight="40px"
              variant="blue"
              textTransform="capitalize"
            >
              {t('login@signUp')}
            </Button>
          )}
        </>
      )}
    </HStack>
  )
}

export default Web

const NotificationsPopover = ({
  isOpen,
  unreadNotifications = [],
  onOpen,
  onClose,
  t,
  onClick,
}) => {
  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <NotificationsButton
          unreadNotifications={unreadNotifications}
          isOpen={isOpen}
        />
      </PopoverTrigger>
      <PopoverContent w="483px" borderRadius={24} overflow="hidden">
        <PopoverHeader
          p="26px"
          fontSize="4xl"
          color="gray.800"
          fontWeight={700}
        >
          {t('profile@notifications')}
        </PopoverHeader>
        <PopoverBody p={0} maxH="calc(100vh - 180px)" overflowY="scroll">
          <NotificationList onClick={onClick} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const NotificationsButton = forwardRef(
  ({ isOpen, unreadNotifications = [], ...props }, ref) => (
    <IconButton minW="auto" ref={ref} {...props}>
      <>
        {isOpen ? (
          <NotificationsBlueIcon width="24px" height="24px" />
        ) : (
          <NotificationsIcon />
        )}
        {unreadNotifications > 0 && (
          <Circle
            size="11px"
            border="1px solid"
            borderColor="white"
            bg="red.400"
            position="absolute"
            top={0.5}
            right={0.5}
          />
        )}
      </>
    </IconButton>
  )
)
