import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Text,
  useColorModeValue as mode,
  InputGroup,
  InputLeftElement,
  Icon as ChakraIcon,
} from '@chakra-ui/react'
import * as React from 'react'

export const InputField = React.forwardRef((props, ref) => {
  const {
    label,
    description = '',
    labelProps,
    hidden,
    isInvalid,
    errorMessage,
    maxLength,
    formControlProps,
    size = 'xl',
    titleProps = {},
    Icon,
    leftText,
    ...rest
  } = props

  return (
    <FormControl
      position="relative"
      display={hidden ? 'none' : 'block'}
      {...formControlProps}
    >
      {!!isInvalid && (
        <Box
          top="calc(100% - 5px)"
          fontSize="sm"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage || isInvalid?.message}
        </Box>
      )}
      {label && (
        <FormLabel
          fontWeight={!description ? 400 : 700}
          fontSize={size}
          bg={{
            base: mode('transparent', 'gray.800'),
            md: mode('transparent', 'gray.700'),
          }}
          zIndex={2}
          {...labelProps}
        >
          <Text as={'span'} {...titleProps}>
            {label}
          </Text>
          {description && (
            <Text fontSize="sm" fontWeight={400} mt={1} mb={4}>
              {description}
            </Text>
          )}
        </FormLabel>
      )}
      <InputGroup>
        {(Icon || leftText) && (
          <InputLeftElement pointerEvents="none">
            {Icon && <ChakraIcon as={Icon} w="24px" h="24px" />}
            {leftText && (
              <Text color="gray.700" fontSize="sm" pt="2px">
                {leftText}
              </Text>
            )}
          </InputLeftElement>
        )}
        <Input
          _focus={{ borderColor: 'blue.400' }}
          _hover={{ borderColor: 'blue.400' }}
          sx={{ boxShadow: 'none !important' }}
          maxLength={maxLength}
          isInvalid={!!isInvalid}
          hidden={hidden}
          borderColor="gray.600"
          borderWidth="1px"
          borderRadius="6px"
          ref={ref}
          fontSize="lg"
          size={size}
          {...rest}
        />
      </InputGroup>
      {isInvalid && <Box mb={2} />}
    </FormControl>
  )
})

InputField.displayName = 'InputField'
InputField.defaultProps = {
  formControlProps: {},
}
