import { useCallback } from 'react'

const useHelperGetUserInfo = () => {
  const getUserInfo = useCallback(() => {
    const userInfo = {}
    userInfo.userAgent = navigator.userAgent

    // Geolocation
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        userInfo.latitude = position.coords.latitude
        userInfo.longitude = position.coords.longitude
      })
    }

    // Cookies
    userInfo.cookiesEnabled = navigator.cookieEnabled

    // Local Storage and Session Storage
    // userInfo.localStorage = window.localStorage //TODO Too large for sentry
    userInfo.sessionStorage = window.sessionStorage

    // Screen Resolution
    userInfo.screenResolution = `${window.screen.width}x${window.screen.height}`

    // Time Zone
    userInfo.timeZone = new Date().getTimezoneOffset()

    // Web Storage
    // userInfo.localStorageData = localStorage //TODO Too large for sentry
    userInfo.sessionStorageData = sessionStorage

    // Referrer Information
    userInfo.referrer = document.referrer

    return userInfo
  }, [])

  return getUserInfo
}

export default useHelperGetUserInfo
