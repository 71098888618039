import React, { useState } from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
} from '@stripe/react-stripe-js'
import { Checkbox, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import PaymentGuestUserFormDonationPage from 'components/PaymentGuestUserFormDonationPage'
const NonLocalCardForm = ({
  setPaymentMethod,
  getValues,
  clearErrors,
  isRecurring,
  user,
  setTokenId,
  setNewStripeCard,
  register,
  errors,
  elements,
  setError,
}) => {
  const { t } = useTranslation()
  const stripe = useStripe()
  const handleCardElementChange = async (event) => {
    if (event.complete) {
      const cardElement = elements.getElement(CardNumberElement)
      const { token } = await stripe.createToken(cardElement)
      setTokenId(token?.id)
      if (token?.id) {
        clearErrors('stripe')
      }
      setNewStripeCard(token?.card)
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          email: getValues('email'),
          name: `${getValues('firstName')} ${getValues('lastName')}`,
        },
      })
      setPaymentMethod(paymentMethod)
      clearErrors(event.elementType, false)
    } else if (event.error) {
      setError(event.elementType)
    }
  }

  return (
    <Flex
      direction="column"
      sx={{
        '& .StripeElement': {
          width: 'full',
        },
      }}
      gap={3}
    >
      {user.id === 'guest' && (
        <PaymentGuestUserFormDonationPage
          register={register}
          errors={errors}
          isRecurring={isRecurring}
          isStripe
          emailErrorMessage={t(errors?.email?.message)}
        />
      )}
      <SingleInputWithLabel
        label={t('donation@cardNumber')}
        error={errors.cardNumber}
      >
        <CardNumberElement
          onChange={handleCardElementChange}
          id="card-number-element"
          options={cardElementOptions}
        />
      </SingleInputWithLabel>
      <Flex gap={3}>
        <SingleInputWithLabel flex={1} label="MM/YY" error={errors.cardExpiry}>
          <CardExpiryElement
            onChange={handleCardElementChange}
            id="card-element"
            options={cardElementOptions}
          />
        </SingleInputWithLabel>
        <SingleInputWithLabel flex={1} label="CVV" error={errors.cardCvc}>
          <CardCvcElement
            id="card-element"
            onChange={handleCardElementChange}
            options={cardElementOptions}
          />
        </SingleInputWithLabel>
      </Flex>
      {isRecurring ? (
        <Text fontSize="md">
          <b>{t('donation@cardWillBeSaved').split('(bold)')[0]}</b>
          {t('donation@cardWillBeSaved').split('(bold)')[1]}
        </Text>
      ) : (
        <>
          {user.id !== 'guest' && (
            <Checkbox {...register('attachCard')}>
              <Text fontSize="md">
                <b>
                  {
                    t('donation@attachCardForFutureDonations').split(
                      '(bold)'
                    )[0]
                  }
                </b>
                {t('donation@attachCardForFutureDonations').split('(bold)')[1]}
              </Text>
            </Checkbox>
          )}
        </>
      )}
    </Flex>
  )
}

export default NonLocalCardForm

const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#eb1c26',
    },
  },
  hidePostalCode: true,
  disableLink: true,
  iconStyle: 'default',
  placeholder: '',
  disabled: false,
}

const SingleInputWithLabel = ({ children, label, error, ...props }) => (
  <Flex direction="column" mt={2} {...props}>
    <Text fontSize="md" mb={2}>
      {label}
    </Text>
    <Flex
      h={{ base: '38px', sm: '40px' }}
      w="full"
      borderRadius={8}
      borderColor={error ? 'red.400' : 'border.400'}
      borderWidth="1px"
      borderStyle="solid"
      alignItems="center"
      px={3}
    >
      {children}
    </Flex>
  </Flex>
)
