import { Button, HStack } from '@chakra-ui/react'
import { ADD_EDIT_BASKET_MODAL, SIGN_IN_MODAL } from 'constants/modals'
import { useScreenSize } from 'contexts'
import { useBasket } from 'contexts/BasketContext'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser } from 'hooks'
import { useNavigation } from 'pages'
import { useModal } from 'providers/ModalProvider'

export default function BasketAction({ project, height }) {
  const { isInBasket, removeFromBasket } = useBasket()
  const { isMobile } = useScreenSize()
  const { openModal } = useModal()
  const { navigationPush } = useNavigation()
  const { user } = useUser()
  const { t, language } = useTranslation()

  const onAddEdit = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (!user || user?.id === 'guest') {
      if (isMobile) {
        const from = window.location.pathname.replace(`/${language}`, '')
        return navigationPush(`/sign-in?from=${from}`)
      }
      return openModal(SIGN_IN_MODAL, null, false, () => {}, {}, {}, false)
    }

    const modalMaxW = '556px'
    openModal(
      ADD_EDIT_BASKET_MODAL,
      null,
      false,
      () => {},
      { projectId: project.id, maxWidth: modalMaxW },
      { maxWidth: modalMaxW },
      false
    )
  }

  const onRemove = (e) => {
    e.stopPropagation()
    e.preventDefault()
    removeFromBasket(project.id)
  }

  if (isInBasket(project.id)) {
    return (
      <HStack spacing={2}>
        <Button
          variant="transparent"
          color="red.400"
          w="50%"
          onClick={onRemove}
        >
          {t('Remove')}
        </Button>
        <Button variant="blue" w="50%" h={height || 'auto'} onClick={onAddEdit}>
          {t('edit')}
        </Button>
      </HStack>
    )
  }

  return (
    <Button variant="blue" w="100%" h={height || 'auto'} onClick={onAddEdit}>
      {t('Add to Basket')}
    </Button>
  )
}
