import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import { useCustomToast, useQueryParams, useUser } from 'hooks'
import gtag from 'ga-gtag'
import { TRANSACTION_STATUS } from 'pages/Donation/helpers/constants'
import useCheckPaypalOrder from 'hooks/donation/useCheckPaypalOrder'
import amplitude from 'amplitude-js'
import { isMobileDevice } from 'utils'

function jsonParseSafe(stringJson) {
  try {
    if (typeof stringJson !== 'string') {
      console.log('ERROR WHILE PARSING JSON, stringJson is not string')
      return null
    }
    const result = JSON.parse(stringJson)
    return result
  } catch (error) {
    console.log('ERROR WHILE PARSING JSON', error)
    return null
  }
}

const redirectURL = (transaction, success) => {
  const cachedDonatedForm = localStorage.getItem('donate-form')
  const donateForm = jsonParseSafe(cachedDonatedForm)

  const projectId = donateForm?.peerProjectId || donateForm?.projectId
  const transactionId = transaction.id

  if (!success || !transaction) {
    localStorage.removeItem('donate-form')

    if (
      projectId &&
      donateForm?.amount &&
      donateForm?.firstName &&
      donateForm?.lastName &&
      donateForm?.email
    ) {
      return `/donation?projectId=${projectId}&amount=${donateForm?.amount}
      &firstName=${donateForm?.firstName}&lastName=${donateForm?.lastName}&email=${donateForm?.email}`
    }
  }

  return `/donate/thank-you-paypal?projectId=${projectId}&transactionId=${transactionId}`
}

const CheckTransactionPaypal = () => {
  const params = useQueryParams()
  const toast = useCustomToast()
  const { navigationReplace } = useNavigation()
  const { t } = useTranslation()
  const { getUser } = useUser()

  const transactionId = params.get('transactionId')
  const paypalToken = params.get('token')
  const paypalSubscriptionId = params.get('subscription_id')
  const isSubscription = params.get('isSubscription') === 'true'

  const {
    data: transaction,
    isLoading,
    error,
  } = useCheckPaypalOrder({
    transactionId,
    paypalToken,
    paypalSubscriptionId,
    isSubscription,
  })

  useEffect(() => {
    if (error) {
      amplitude
        .getInstance()
        .logEvent(
          `order-declined-paypal-${isMobileDevice() ? 'mobile' : 'desktop'}`
        )
      navigationReplace('/')
      toast({
        title: t('payment@orderStatus-0'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })

      return
    }
    if (isLoading || !transaction) {
      return
    }

    gtag('event', 'purchase', {
      transaction_id: transactionId,
      affiliation: 'Rearmenia donation',
      value:
        Number(transaction.donationToProject) +
        Number(transaction.donationToReArmenia),
      currency: transaction.currency,
      items: [transaction],
    })

    const success = transaction.status === TRANSACTION_STATUS.DONE
    toast({
      status: success ? 'success' : 'error',
      title: success
        ? t('payment@orderStatus-2')
        : t('payment@orderStatus-general'),
      duration: 5000,
      isClosable: true,
    })

    getUser()
    navigationReplace(redirectURL(transaction, success))
  }, [transaction, error])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}

export default CheckTransactionPaypal
