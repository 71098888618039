import { useCreateGiftCard, useUser } from 'hooks'
import { useCallback } from 'react'

const useHelperCreateGiftCard = () => {
  const { user } = useUser()

  const { mutateAsync: createGiftCardMutation } = useCreateGiftCard()

  const createGiftCard = useCallback(
    ({
      message,
      from,
      fromEmail,
      language,
      toEmail,
      image,
      amount,
      currency,
      currencyRate,
    }) => {
      const giftCardInput = {
        userId: user.id,
        amount,
        image,
        currency,
        currencyRate,
        paymentStatus: 'pending',
        used: false,
        message,
        from,
        fromEmail,
        language,
        toEmail,
      }
      return createGiftCardMutation(giftCardInput)
    },
    [user]
  )

  return createGiftCard
}

export default useHelperCreateGiftCard
