import { projectsByIds } from 'elastic/queries'
import moment from 'moment'

const donationSubscriptionUseCase = (
  DonationSubscriptionService,
  projectUseCase
) => {
  const updateDonationSubscription = (donationSubscription) =>
    DonationSubscriptionService.updateDonationSubscription(donationSubscription)

  const unsubscribeDonationSubscription = (donationSubscriptionId) =>
    DonationSubscriptionService.unsubscribeDonationSubscription(
      donationSubscriptionId
    )

  const donationSubscriptionsByUserEmail = async (
    condition,
    loadProjectsData
  ) => {
    const donationSubscriptionsRes =
      await DonationSubscriptionService.donationSubscriptionsByUserEmail(
        condition
      )
    let donationSubscriptions = donationSubscriptionsRes?.items || []

    if (loadProjectsData && donationSubscriptions.length > 0) {
      const projectIds = donationSubscriptions
        .map((item) => item.projectId)
        .filter(Boolean)
        .filter((value, index, array) => array.indexOf(value) === index)

      const projectsRes = await projectUseCase.getProjects(
        projectsByIds(projectIds),
        'subscribed_projects'
      )
      const projects = projectsRes.items

      donationSubscriptions = donationSubscriptions.map((subscription) => ({
        ...subscription,
        project: projects.find(
          (project) => project.id === subscription.projectId
        ),
        amount: Number(subscription.donationToProject),
        formattedDate: moment(subscription.createdAt).format('MMMM DD, YYYY'),
        ...(subscription.donationToReArmenia && {
          amount:
            Number(subscription.donationToProject) +
            Number(subscription.donationToReArmenia),
        }),
      }))
    }

    return donationSubscriptions
  }

  const donationSubscriptionsByProjectId = async (
    condition,
    withProjectDetails
  ) => {
    const donationSubscriptions =
      await DonationSubscriptionService.donationSubscriptionsByProjectId(
        condition
      )
    const items =
      donationSubscriptions.data.donationSubscriptionsByProjectId.items

    if (withProjectDetails) {
      for (const item of items) {
        item.project = await projectUseCase.retrieveProject(item.projectId)
        item.amount = item.donationToProject

        if (item.donationToReArmenia) {
          item.amount += item.donationToReArmenia
        }
      }
    }

    return items
  }

  const getDonationSubscription = (id) =>
    DonationSubscriptionService.getDonationSubscription(id)

  return {
    updateDonationSubscription,
    unsubscribeDonationSubscription,
    donationSubscriptionsByUserEmail,
    donationSubscriptionsByProjectId,
    getDonationSubscription,
  }
}

export default donationSubscriptionUseCase
