import { useEffect, useState } from 'react'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'
import { CountryInfoContext } from './CountryInfoContext'
import { detectInfoByIpAddress, getDefaultValue } from './helpers'

const storageKey = 'CountryProvider@detectedData'

function CountryInfoProvider({ children }) {
  const { changeDefaultCurrency } = useCurrencySettings()
  const { setLanguage } = useTranslation()

  const [info, setInfo] = useState(null)

  useEffect(() => {
    const oneDay = 24 * 3600 * 1000
    const cacheTtl = 30 * oneDay

    try {
      const storageData = localStorage.getItem(storageKey)
      const parsedData = JSON.parse(storageData)

      if (
        parsedData?.willExpire &&
        new Date(parsedData.willExpire).getTime() > Date.now()
      ) {
        setInfo({ ...parsedData, isFetched: false })
        return
      }
    } catch (e) {}

    detectInfoByIpAddress()
      .then((detectedData) => {
        localStorage.setItem(
          storageKey,
          JSON.stringify({
            ...detectedData,
            willExpire: new Date(Date.now() + cacheTtl).toISOString(),
            detectTime: new Date().toISOString(),
          })
        )
        setInfo({ ...detectedData, isFetched: true })

        changeDefaultCurrency(detectedData.currency)
        setLanguage(detectedData.language)
      })
      .catch(() => {
        setInfo({ ...getDefaultValue(), isFetched: false })
      })
  }, [])

  return (
    <CountryInfoContext.Provider value={info}>
      {children}
    </CountryInfoContext.Provider>
  )
}

export default CountryInfoProvider
