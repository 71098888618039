import { Container, Grid } from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'

import React from 'react'

export default function Layout({
  children,
  onFormSubmit,
  isLoading,
  spacingX = true,
  spacingY = true,
}) {
  return (
    <MainLayout>
      <Container
        minH="520px"
        maxW="7xl"
        justifyContent="center"
        alignItems="center"
        display="flex"
        px={!spacingX ? 0 : 5}
        pt={{
          base: !spacingY ? 0 : 5,
          sm: 0,
        }}
      >
        {isLoading ? (
          <>{children}</>
        ) : (
          <form onSubmit={onFormSubmit} noValidate>
            <Grid
              mt={{ base: 0, sm: 10 }}
              position="relative"
              minH="100%"
              templateColumns="repeat(12, 1fr)"
              w={{ base: 'full', sm: '1145px' }}
              gap="17px"
            >
              {children}
            </Grid>
          </form>
        )}
      </Container>
    </MainLayout>
  )
}
