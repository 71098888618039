const expertUseCase = (ExpertService) => {
  const createExpertInitConnection = (params) =>
    ExpertService.createExpertInitConnection(params)

  const getConnection = async (id) => {
    if (!id) {
      return null
    }
    return ExpertService.getConnection(id)
  }

  const updateConnection = (params) => ExpertService.updateConnection(params)

  return {
    createExpertInitConnection,
    getConnection,
    updateConnection,
  }
}

export default expertUseCase
