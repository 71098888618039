import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useGiftCard } from 'contexts/GiftCardContext'

function GiftCardAmount({ donation }) {
  const { t } = useTranslation()
  const { giftCard: giftCardInfo } = useGiftCard()

  return (
    <Flex direction="column" pb={5} px={6}>
      <Flex
        justifyContent="space-between"
        borderTopWidth="1px"
        borderColor="border.100"
        fontWeight="bold"
        pt={4}
      >
        <Text>{t('giftCardAmount')}</Text>
        <Text>
          {giftCardInfo.amountWithCurrency} {giftCardInfo.currency}
        </Text>
      </Flex>
      <Flex justifyContent="space-between" pt={4}>
        <Text>{t('donation@yourDonation')}</Text>
        <Text>
          {Number(
            Number(giftCardInfo.amountWithCurrency) - donation.reArmenia
          ).toFixed(2)}{' '}
          {giftCardInfo.currency}
        </Text>
      </Flex>
      <Flex justifyContent="space-between" pt={4}>
        <Text>{t('donation@reArmeniaTip')}</Text>
        <Text>
          {donation.reArmenia.toFixed(2)} {giftCardInfo.currency}
        </Text>
      </Flex>
    </Flex>
  )
}

export default GiftCardAmount
