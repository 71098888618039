import React, { useEffect } from 'react'
import { useElements } from '@stripe/react-stripe-js'
import { Flex, Radio, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as CreditCard } from 'assets/icons/credit-card-v2.svg'
import ChooseCardRegion from './ChooseCardRegion'
import NonLocalCardForm from './NonLocalCardForm'
import ArmenianCardForm from './ArmenianCardForm'
const CheckoutForm = ({
  showStripe,
  setPaymentMethod,
  getValues,
  setValue,
  clearErrors,
  isRecurring,
  user,
  paymentMethod,
  setTokenId,
  setNewStripeCard,
  register,
  errors,
  isInvalid,
  projectId,
  setError,
}) => {
  const elements = useElements()
  const isSelected = ['ARCA_VISA_MASTER', 'stripe'].includes(paymentMethod)
  const { t } = useTranslation()
  const paymentElement = elements?.getElement('payment')

  useEffect(() => {
    if (!paymentElement) {
      return
    }
    if (paymentMethod !== 'stripe') {
      paymentElement.collapse()
    }
  }, [paymentMethod, paymentElement])

  const onStripeChoose = () => {
    setValue('paymentMethod', 'stripe')
    setValue('bindingId', 'stripe')
  }
  const onArmenianCardChoose = () => {
    setValue('paymentMethod', 'ARCA_VISA_MASTER')
    setValue('bindingId', null)
  }

  /* @FIXME:
    - below it is a quick solution to disable ARCA for a specific project
    - we need a long term and automated solution so admin can decide whether project can use ARCA or not
  */
  const canUseArca = ![
    'fdc74c71-2f70-4722-a721-df496b08467c',
    '8fbc6727-a8af-4527-8a3c-f966f6df052f',
  ].includes(projectId)

  return (
    <Flex
      w="full"
      h={isSelected ? 'auto' : '56px'}
      p={isSelected ? '13px 16px 18px 16px' : '13px 16px'}
      background={isSelected ? 'gray.300' : 'white'}
      borderRadius={16}
      borderWidth="1px"
      borderColor={
        isInvalid && isSelected
          ? 'red.400'
          : isSelected
          ? 'border.400'
          : 'border.500'
      }
      position="relative"
      cursor={isSelected ? 'auto' : 'pointer'}
      direction="column"
      justify="center"
      onClick={() => setValue('paymentMethod', 'ARCA_VISA_MASTER')}
    >
      <Flex w="full" alignItems="center" height="28px">
        <Flex width="36px" alignItems="center" paddingLeft="6px">
          <Radio value="1" isChecked={isSelected} />
        </Flex>

        <Flex alignItems="center">
          <CreditCard style={{ height: '28px' }} />
          <Text ml={4} color="black.100" fontSize="md">
            {t('donation@CreditOrDebitCard')}
          </Text>
        </Flex>
      </Flex>
      {isSelected && (
        <Flex flexDirection="column" gap="16px" marginTop="24px">
          <ChooseCardRegion
            isSelected={paymentMethod === 'ARCA_VISA_MASTER'}
            onSelect={onArmenianCardChoose}
            label={t('donation@chooseCardRegionArmenia')}
            description={t('donation@cardsInArmeniaTax')}
          >
            {canUseArca && (
              <ArmenianCardForm
                isRecurring={isRecurring}
                user={user}
                register={register}
                errors={errors}
              />
            )}
          </ChooseCardRegion>
          {showStripe && (
            <ChooseCardRegion
              isSelected={paymentMethod === 'stripe'}
              isInvalid={isInvalid}
              onSelect={onStripeChoose}
              label={t('donation@chooseCardRegionNonLocal')}
              description={t('donation@forUSCitizens')}
            >
              <NonLocalCardForm
                setPaymentMethod={setPaymentMethod}
                getValues={getValues}
                clearErrors={clearErrors}
                isRecurring={isRecurring}
                user={user}
                setTokenId={setTokenId}
                setNewStripeCard={setNewStripeCard}
                register={register}
                errors={errors}
                elements={elements}
                setError={setError}
                isInvalid={isInvalid}
              />
            </ChooseCardRegion>
          )}
        </Flex>
      )}
    </Flex>
  )
}

export default CheckoutForm
