import { Button, Flex, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUnsubscribeFromBasketItem, useUser } from 'hooks'
import DonationImage from './DonationImage'
import AlertDialogModal from '../AlertDialogModal'

export default function StatusCard({ subscription, basketItem, refetch }) {
  const toast = useToast()
  const { language, t } = useTranslation()
  const { getUser } = useUser()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutateAsync: unsubscribe, isLoading: isUnsubscribeLoading } =
    useUnsubscribeFromBasketItem()

  const onAlertOpen = (e) => {
    e.preventDefault()
    onOpen()
  }

  const onUnsubscribe = async (e) => {
    e.stopPropagation()
    try {
      await unsubscribe({
        subscriptionId: subscription.id,
        projectId: basketItem.projectId,
      })
      onClose()
      getUser()
      refetch()
      toast({
        title: t('unsubscribedSuccessfully'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (e) {
      console.log('>>> UNSUBSCRIBE ERROR', e)
    }
  }

  return (
    <Flex justify="space-between" rounded="lg">
      <Flex align="center" mr="5px">
        <DonationImage image={basketItem?.projectCover} />

        <Text
          fontWeight={600}
          fontSize="lg"
          color="gray.800"
          noOfLines={1}
          height="fit-content"
        >
          {basketItem[`projectTitle_${language}`]}
        </Text>
      </Flex>

      <Flex>
        {basketItem?.unsubscribedDate ? (
          <Button
            height={12}
            minH={12}
            _hover={{}}
            disabled
            color="gray.500"
            px={6}
            flexShrink={0}
          >
            {t('profile@dashboard@investor@recurring@unsubscribed')}
          </Button>
        ) : (
          <Button
            height={12}
            minH={12}
            px={6}
            flexShrink={0}
            color="gray.700"
            isLoading={isUnsubscribeLoading}
            isDisabled={isUnsubscribeLoading}
            onClick={onAlertOpen}
          >
            {t('profile@dashboard@investor@recurring@unsubscribe')}
          </Button>
        )}
      </Flex>
      <AlertDialogModal
        onClose={onClose}
        isOpen={isOpen}
        isLoading={isUnsubscribeLoading}
        onUnsubscribe={onUnsubscribe}
      />
    </Flex>
  )
}
