import { useState } from 'react'
import { post } from 'aws-amplify/api'
import { Box, Button, Stack, useColorModeValue as mode } from '@chakra-ui/react'
import { useQueryParams } from 'hooks'
import { useNavigation } from 'pages'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { authUseCase } from 'core/factories'
import useCustomToast from 'hooks/useCustomToast'
import { getResponseToJSON } from 'utils/api'

export const VerifyForm = () => {
  const params = useQueryParams()
  const [loading, setLoading] = useState(false)
  const {
    register: verify,
    handleSubmit: handleVerify,
    formState: { errors: verifyErrors },
  } = useForm()
  const { navigationPush } = useNavigation()
  const { language, t } = useTranslation()
  const toast = useCustomToast()
  const firstName = params.get('firstName')

  const onResend = async () => {
    try {
      await authUseCase.resendSignUp(params.get('email'))
      toast({
        status: 'success',
        title: t('verify@resend'),
        isClosable: true,
        duration: 5000,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const onVerify = async (data) => {
    setLoading(true)
    try {
      await authUseCase.confirmSignUp({
        code: data.code,
        email: params.get('email'),
      })
      try {
        const res = post({
          apiName: 'mailerLiteAPI',
          path: '/subscribe',
          options: {
            body: {
              email: params.get('email'),
              firstName: params.get('firstName'),
              language: `MAILER_SUB_GROUP_${language.toUpperCase()}`,
            },
          },
        })
        await getResponseToJSON(res)
      } catch (err) {
        console.log('❌', err)
      }
      setLoading(false)
      navigationPush(`/signup/thank-you?firstName=${firstName}`)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  return (
    <>
      <form onSubmit={handleVerify(onVerify)}>
        <Stack spacing="8">
          <InputField
            size="lg"
            isInvalid={!!verifyErrors.code}
            errorMessage={t('verify@codeRequired')}
            {...verify('code', { required: true })}
            label={t('verify@code')}
          />
          <Button
            isLoading={loading}
            type="submit"
            variant="blue"
            size="lg"
            fontSize="md"
          >
            {t('verify@submitButtonText')}
          </Button>
          <Box
            as="button"
            type="button"
            onClick={onResend}
            color={mode('blue.600', 'blue.200')}
            mx="auto"
          >
            {t('verify@resendCode')}
          </Box>
        </Stack>
      </form>
    </>
  )
}
