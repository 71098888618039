import React from 'react'
import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import { useNavigation } from 'pages'
import { ABOUT_DROPDOWN, NAV_LINKS } from '../../../constants'
import { NavLink } from './NavLink'
import { useTranslation } from 'contexts/TranslationContext'
import { BlueSelect } from 'components/Select/BlueSelect'

function Navigation({ currentPath, onClick }) {
  const { navigationPush } = useNavigation()

  const { t } = useTranslation()
  const aboutOptionSelect = (option) => {
    if (typeof onClick === 'function') {
      onClick({ from: 'dropdownLink', link: `/${option}` })
    } else {
      navigationPush(`/${option}`)
    }
  }

  return (
    <HStack spacing="6" display={{ base: 'none', md: 'flex' }}>
      {NAV_LINKS.map((link, index) =>
        link.active === 'about' ? (
          <BlueSelect
            key={`${link}-${index}`}
            width="unset"
            display="flex"
            type="language"
            variant="noBorder"
            label={t(`about@aboutUs`)}
            options={ABOUT_DROPDOWN.map(({ value, title }) => ({
              value,
              title: t(title),
            }))}
            selectOption={aboutOptionSelect}
            offset={[0, 7]}
          />
        ) : link.active === 'experts' ? (
          <Flex key={link.title} alignItems="center" mr={4}>
            <Box
              key={link.title}
              fontWeight="bold"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderColor="transparent"
              transition="all 0.2s"
              mr={2}
            >
              <NavLink.Desktop
                key={link.title}
                onClick={onClick}
                to={link.to}
                active={currentPath === link.active}
              >
                {t(link.title)}
              </NavLink.Desktop>
            </Box>
          </Flex>
        ) : (
          <NavLink.Desktop
            key={link.title}
            onClick={onClick}
            to={link.to}
            active={currentPath === link.active}
          >
            {t(link.title)}
          </NavLink.Desktop>
        )
      )}
    </HStack>
  )
}

export default Navigation
