import { useMemo, useState } from 'react'
import { Box, Flex, Stack, Heading, Checkbox } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { InputField } from 'components/InputField'
import DatePicker from 'components/Date'
import Editor from 'components/Editor'
import Goals from 'components/GoalsForm'
import Options from 'components/OptionsForm'
import Occasion from './Occasion'
import { useQueryParams, useUser } from 'hooks'
import {
  LANG_EN_SHORT,
  useTranslation,
  validateStringLangPatterns,
} from 'contexts/TranslationContext'
import PeerToPeerFundraiserCard from 'components/PeerToPeerFundraiserCard'
import ImageUploader from './ImageUploader'

import './editor.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { useProject } from 'pages/CreateEditProject/ProjectContext'

export default function FormData({
  lang,
  getValues,
  setValue,
  tabIndex,
  register,
  validationErrors,
  image,
  cover,
  date,
  project,
  setDate,
  collaborations,
  errors,
  goals,
  appendGoal,
  removeGoal,
  appendCollaborations,
  removeCollaborations,
  watch,
  onChangePeerToPeerFundraiser,
  // @TODO: uncomment once needed
  // isConnectLoading,
  // isContinueConnectLoading,
  // onConnectStripeAccount,
  // onConnectContinueStripeAccount,
}) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useQueryParams()
  const { peerProject, isEditMode, isPeerToPeerProject } = useProject()
  const { user } = useUser()
  const showBoth = params.has('showBoth')
  const isCollaboration = project.isCollaboration
  const isFundraisers = useMemo(
    () => showBoth || pathname.includes('fundraisers'),
    [pathname]
  )
  const [prependStyle, setPrependStyle] = useState(false)

  const handleChange = (e, index) => {
    const newArray = collaborations.map((item, idx) => {
      const newObj = {
        ...item,
      }
      if (index === idx) {
        newObj.needs = e.target.value
      }
      delete newObj.id
      return newObj
    })
    setValue('collaborations', newArray)
  }

  const onValueUpdate = (value) => {
    setValue(`content_${lang}`, value)
  }

  const isRecurring = project.fundraisingType === 'recurring'

  return (
    <>
      <Box
        mb="10"
        maxWidth="508px"
        width="100%"
        css={{
          label: {
            paddingLeft: 0,
            fontSize: '12px',
          },
        }}
      >
        <Stack spacing={8}>
          {isPeerToPeerProject && peerProject && (
            <>
              <PeerToPeerFundraiserCard
                project={peerProject}
                isEditMode={isEditMode}
                note="The fundraiser supports the following project:"
                onChangePeerToPeerFundraiser={onChangePeerToPeerFundraiser}
              />
              <Occasion
                value={watch('occasion')}
                onChange={(value) => setValue('occasion', value)}
              />
            </>
          )}
          <InputField
            id={`creatorName_${lang}`}
            label={t(
              `${
                isPeerToPeerProject
                  ? 'Your Name and Surname'
                  : 'createEditProject@projectCreatorName'
              }`
            )}
            {...register(`creatorName_${lang}`, {
              required: false,
            })}
            message={t(errors?.[`creatorPhoneNumber`]?.message)}
            isInvalid={!!errors?.[`creatorName_${lang}`]}
          />
          {isPeerToPeerProject && (
            <InputField
              id={`phoneNumber`}
              label={t('Your phone number')}
              {...register(`phoneNumber`, {
                required: false,
                onChange: (e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, '')
                  setValue('phoneNumber', sanitizedValue)
                },
              })}
              pattern="[0-9,]*"
              errorMessage={t(errors?.[`phoneNumber`]?.message)}
              isInvalid={!!errors?.['phoneNumber']}
            />
          )}
          <InputField
            id={`creatorSocialLink_${lang}`}
            label={t(
              `${
                isPeerToPeerProject
                  ? 'Social media link'
                  : 'Fundraiser Creator Link'
              }`
            )}
            {...register('creatorSocialLink')}
          />
          <Box>
            <Box
              as="span"
              display="block"
              fontSize="sm"
              color="gray.800"
              mb={1}
            >
              {t(
                `${
                  isPeerToPeerProject
                    ? 'Your Photo'
                    : 'createEditProject@projectCreatorImage'
                }`
              )}
            </Box>
            <Box as="p" fontSize="sm" color="gray.700" mb={5}>
              {t('createEditProject@projectCreatorImageInfo')}
            </Box>
            <ImageUploader
              type="image"
              label={t('createEditProject@clickToUploadImage')}
              name="creatorImage"
              setValue={setValue}
              image={image}
            />
          </Box>
          <InputField
            id={`title_${lang}`}
            labelProps={{ mt: 6 }}
            {...register(`title_${lang}`, {
              required: false,
              pattern: {
                value:
                  lang !== LANG_EN_SHORT || validateStringLangPatterns(lang),
                message: t(`typeIn@${lang}`),
              },
            })}
            errorMessage={t(errors?.[`title_${lang}`]?.message)}
            isInvalid={!!errors?.[`title_${lang}`]}
            label={t(
              `${
                isPeerToPeerProject
                  ? 'Fundraiser Title'
                  : 'createEditProject@projectTitle'
              }`
            )}
          />
          <InputField
            id={`tagline_${lang}`}
            label={t(
              `${
                isPeerToPeerProject
                  ? 'Tagline'
                  : 'createEditProject@projectTagline'
              }`
            )}
            description={t(
              'Enter a short call to action. It will be displayed below the title.'
            )}
            {...register(`tagline_${lang}`, {
              required: {
                value: false,
              },
            })}
            errorMessage={t(errors?.[`tagline_${lang}`]?.message)}
            isInvalid={!!errors?.[`tagline_${lang}`]}
          />
          <Box>
            <Box
              as="span"
              display="block"
              fontSize="sm"
              color="gray.800"
              mb={1}
            >
              {t(
                `${
                  isPeerToPeerProject
                    ? 'Fundraiser Photo'
                    : 'createEditProject@uploadProjectCover'
                }`
              )}
            </Box>
            <Box as="p" fontSize="sm" color="gray.700" mb={5}>
              {t('createEditProject@uploadProjectCoverInfo')}
            </Box>
            <ImageUploader
              type="cover"
              label={t('createEditProject@clickToUploadImage')}
              name="cover"
              setValue={setValue}
              image={cover}
            />
          </Box>
          <Box as="span" display="block" fontSize="sm" color="gray.800">
            {t(
              `${
                isPeerToPeerProject
                  ? 'Fundraiser Video'
                  : 'createEditProject@embedVideo'
              }`
            )}
          </Box>
        </Stack>
        <Box as="p" fontSize="sm" color="gray.700" mt={1}>
          {t(
            `${
              isPeerToPeerProject
                ? 'You can add a YouTube video link here'
                : 'createEditProject@embedVideoInfo'
            }`
          )}
        </Box>
        <Flex
          mt={3}
          mb={7}
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems="flex-end"
        >
          <InputField id={`video_${lang}`} {...register(`video_${lang}`)} />
        </Flex>
        {!isRecurring && (!isCollaboration || showBoth) && (
          <Stack
            spacing="6"
            mb="70px"
            direction={{ base: 'column', sm: 'row' }}
          >
            <InputField
              id={`goal_${lang}`}
              label={t('createEditProject@goal')}
              pattern="[0-9,]*"
              type="text"
              isInvalid={!!errors?.goal}
              errorMessage={t(errors?.goal?.message)}
              {...register('goal', {
                onChange: (e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, '')
                  setValue('goal', sanitizedValue)
                },
              })}
            />
            <DatePicker
              minDate={new Date()}
              label={t(
                `${
                  isPeerToPeerProject
                    ? 'Campaign duration'
                    : 'createEditProject@campaignImplementationPeriod'
                }`
              )}
              startDate={date}
              setStartDate={setDate}
              zIndex="3"
            />
          </Stack>
        )}
        <Box
          mb="10"
          borderBottom="1px solid"
          borderColor="border.100"
          w="full"
        />
        {isFundraisers && (
          <>
            <Box mb={10}>
              <Heading mb="10" as="h2" fontSize="xl">
                {t('Fundraisers')}
              </Heading>
              <Goals
                isEdit={true}
                goals={goals}
                errors={errors}
                remove={removeGoal}
                tabIndex={tabIndex}
                register={register}
                append={appendGoal}
                getValues={getValues}
                setValue={setValue}
                language={lang}
              />
            </Box>
            {project.fundraisingType === 'recurring' && (
              <Box mb={10}>
                <Checkbox
                  alignItems="flex-start"
                  _focusVisible={{ boxShadow: 'outline' }}
                  _focus={{ boxShadow: 'none' }}
                  {...register(`isOneTimeDisabled`)}
                >
                  {t('projectCreate@allowOneTime')}
                </Checkbox>
              </Box>
            )}
          </>
        )}
        {(isCollaboration ||
          (!!collaborations && collaborations.length > 0)) && (
          <Options
            collaborationFields={collaborations}
            validationErrors={validationErrors}
            appendCollaboration={appendCollaborations}
            removeCollaboration={removeCollaborations}
            handleChange={handleChange}
            register={register}
            formLang={lang}
            setValue={setValue}
            getValues={getValues}
          />
        )}
        {(isFundraisers ||
          isCollaboration ||
          (!!collaborations && collaborations.length > 0)) && (
          <Box borderBottom="1px solid" borderColor="border.100" w="full" />
        )}
      </Box>

      {project.isProject && (
        <Flex justifyContent="space-between" alignItems="flex-end" mb={8}>
          {/* // TODO uncomment when connected accounts activated in Armenia  */}
          {/* <Box>
            <Text fontSize="xl" mb={2}>
              Stripe Id
            </Text>
            <Input
              isDisabled={project.stripeId}
              w="300px"
              type="text"
              value={project.stripeId ? project.stripeId : null}
              borderColor="gray.600"
            />
          </Box> */}

          {/* @TODO: uncomment once feature will work properly */}
          {/* {!project.isStripeOnBoardingDone && (
            <Button
              variant="blue"
              onClick={() =>
                project.stripeId
                  ? onConnectContinueStripeAccount({
                      accountId: project.stripeId,
                    })
                  : onConnectStripeAccount()
              }
              isLoading={
                project.stripeId ? isContinueConnectLoading : isConnectLoading
              }
            >
              {project.stripeId ? 'Continue connect' : 'Stripe connect'}
            </Button>
          )} */}
        </Flex>
      )}
      <Heading mb="5" as="h2" fontSize="xl">
        {t(
          `${
            isPeerToPeerProject
              ? 'Campaign Page Content'
              : 'createEditProject@content'
          }`
        )}
      </Heading>
      {user.isAdmin && (
        <Checkbox
          isChecked={prependStyle}
          onChange={(e) => setPrependStyle(e.target.checked)}
        >
          Remove margin and border radius from paragraphs and images
        </Checkbox>
      )}
      <Editor
        id={`content_${lang}`}
        content={getValues(`content_${lang}`)}
        isInvalid={errors[`content_${lang}`]}
        onValueUpdate={onValueUpdate}
        prependStyle={prependStyle}
      />
    </>
  )
}
