import { Flex } from '@chakra-ui/react'
import { useUser } from 'hooks'
import AuthButtons from './AuthButtons'
import BackButton from './BackButton'
import LanguageSelector from './LanguageSelector'

const TopBar = ({ project, isContribution, isBuyingGiftCard }) => {
  const { user } = useUser()
  const isLoggedIn = user?.id !== 'guest'

  return (
    <Flex
      w="full"
      justifyContent="space-between"
      alignItems="center"
      py={3}
      px={5}
      gap={4}
    >
      <BackButton
        isContribution={isContribution}
        isBuyingGiftCard={isBuyingGiftCard}
        project={project}
      />

      <Flex flexDir="row" alignItems="center" gap={4}>
        <LanguageSelector />

        {!isLoggedIn && <AuthButtons />}
      </Flex>
    </Flex>
  )
}

export default TopBar
