import * as Sentry from '@sentry/react'
import { useScreenSize } from 'contexts/ScreenSizeContext'
import useHelperGetUserInfo from './useHelperGetUserInfo'
import useHelperGetBrowserType from './useHelperGetBrowserType'
import { useGetUserGeo, useUser } from 'hooks'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'

const useHelperReportSentry = ({
  getValues,
  giftCardMode,
  giftCard,
  project,
  appliedGiftCard,
  isContribution,
}) => {
  const { language } = useTranslation()
  const { isMobile } = useScreenSize()
  const { data: userGeo } = useGetUserGeo()
  const { user } = useUser()

  const { currency, settings } = useCurrencySettings()
  const getBrowserType = useHelperGetBrowserType()
  const getUserInfo = useHelperGetUserInfo()

  const reportSentry = ({
    email,
    paymentMethod,
    body,
    data,
    paymentProvider,
  }) => {
    const getFieldValue = (field) =>
      user.id === 'guest' ? data[field] : user[field]

    const currencyRate =
      currency.current === 'AMD' ? 1 : settings?.currency[currency.current]

    const infoForSentry = {
      userAgent: window.navigator.userAgent,
      browser: getBrowserType(),
      email,
      giftCardMode,
      amount: getValues('amount'),
      isBuyingGiftCard: giftCard,
      project_id: project?.id,
      user_id: user?.id,
      currency: JSON.stringify(currency),
      currencyRate,
      firstName: getFieldValue('firstName'),
      lastName: getFieldValue('lastName'),
      language,
      data: JSON.stringify(data),
      paymentType: getValues('paymentType'),
      paymentMethod: paymentMethod,
      paymentProvider: paymentProvider,
      appliedGiftCard: JSON.stringify(appliedGiftCard),
      isContribution,
      isMobile: isMobile,
      body: JSON.stringify(body),
      userGeo: JSON.stringify(userGeo),
      userInfo: JSON.stringify(getUserInfo()),
    }

    Sentry.withScope((scope) => {
      scope.setExtra('Extra Info', infoForSentry)
      Sentry.captureMessage('Second click on donate button', 'error')
    })
  }

  return reportSentry
}

export default useHelperReportSentry
