import { normalizeElasticResponse } from '../../../utils/elastic'

const contentUseCase = (ContentService) => {
  const getExperts = async (variables) => {
    const response = await ContentService.getExperts(variables)
    return response
  }

  const getExpert = async (input) => {
    if (input.includes('@')) {
      const response = await ContentService.getExpertByEmail(input)
      if (response) {
        response.isArmFilled = true
        response.isEngFilled = true
        response.isRusFilled = true
        response.countryCode = 'am'
      }
      return response
    }
    const response = await ContentService.getItemById(input)
    return response
  }

  const getNeeds = async (input) => {
    const response = await ContentService.getNeeds(input)
    return response
  }

  const createContentItem = async (input) => {
    const response = await ContentService.createContentItem(input)
    return response
  }

  const updateContentItem = async (input) => {
    const response = await ContentService.updateContentItem(input)
    return response
  }

  const deleteContentItem = async (input) => {
    const response = await ContentService.deleteContentItem(input)
    return response
  }

  const getItemById = async (input) => {
    if (!input) {
      return null
    }
    const response = await ContentService.getItemById(input)
    return response
  }

  const getItemBySlug = async (input) => {
    if (!input) {
      return null
    }
    const response = await ContentService.getItemBySlug(input)
    return response
  }

  const getContentFromElastic = async (condition, page, hasSearch) => {
    const elasticResponse = await ContentService.getContentFromElastic(
      condition
    )

    const normalizedResponse = normalizeElasticResponse(elasticResponse)
    if (page === 1 && !hasSearch) {
      localStorage.setItem('experts_cache', JSON.stringify(normalizedResponse))
    }

    return normalizedResponse
  }

  const getGiftCard = async (input) => {
    const response = await ContentService.getGiftCard(input)
    const normalizeResponse = JSON.parse(response[0].jsonPageData)
    const result = []
    const sortBy = [
      'card-popular',
      'card-thankyou',
      'card-birthday',
      'card-congrats',
      'card-arm',
      'card-other',
    ]
    for (const key of sortBy) {
      result.push([
        key.split('-')[1],
        normalizeResponse[key].map(
          (item) => item.image?.url.split('/').at(-1).split('?')[0]
        ),
      ])
    }
    return result
  }

  const getHomepageBlog = async (input) => {
    const response = await ContentService.getHomepageBlog(input)
    return response
  }

  const getHomepagePartners = async () => {
    const response = await ContentService.getItemById('partners')
    let normalizeResponse = []
    try {
      if (response.jsonPageData) {
        normalizeResponse = JSON.parse(response.jsonPageData)
      }
    } catch (error) {
      console.log(error)
    }
    return normalizeResponse
  }

  const getHomepageImpacts = async () => {
    const response = await ContentService.getItemById('impacts')
    let normalizeResponse = []
    try {
      if (response.jsonPageData) {
        normalizeResponse = JSON.parse(response.jsonPageData)
      }
    } catch (error) {
      console.log(error)
    }
    return normalizeResponse
  }

  const getHomepageTestimonials = async () => {
    const response = await ContentService.getItemById('testimonials')
    let normalizeResponse = []
    try {
      if (response.jsonPageData) {
        normalizeResponse = JSON.parse(response.jsonPageData)
      }
    } catch (error) {
      console.log(error)
    }
    return normalizeResponse
  }

  const getExpertsCategories = async () => {
    const response = await ContentService.getItemById('categories')
    let normalizeResponse = []
    try {
      if (response.jsonPageData) {
        normalizeResponse = JSON.parse(response.jsonPageData)
      }
    } catch (error) {
      console.log(error)
    }

    return normalizeResponse.map((item) => ({ ...item, isChecked: false }))
  }

  const useGetExpertsCountries = async () => {
    const response = await ContentService.getItemById('countries')
    let normalizeResponse = []
    try {
      if (response.jsonPageData) {
        normalizeResponse = JSON.parse(response.jsonPageData)
      }
    } catch (error) {
      console.log(error)
    }

    return normalizeResponse
  }

  return {
    getExperts,
    getNeeds,
    getItemById,
    getGiftCard,
    getContentFromElastic,
    getHomepageBlog,
    getHomepagePartners,
    getHomepageImpacts,
    getHomepageTestimonials,
    getExpertsCategories,
    useGetExpertsCountries,
    createContentItem,
    updateContentItem,
    deleteContentItem,
    getExpert,
    getItemBySlug,
  }
}

export default contentUseCase
