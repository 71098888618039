import moment from 'moment'
import amplitude from 'amplitude-js'

import { isMobileDevice } from 'utils'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import {
  useAttachPmToCustomer,
  useConfirmPayment,
  useCreateCustomer,
  useCustomToast,
  useGetUserByEmailAsync,
  useLocalStorage,
  usePaymentIntent,
  useUpdateUser,
  useUser,
} from 'hooks'
import useHelperCreateStripeAttachCard from '../useHelperCreateStripeAttachCard'
import {
  LANG_EN_SHORT,
  MOMENT_LANGUAGES,
  useTranslation,
} from 'contexts/TranslationContext'
import { useNavigation } from 'pages'

export const useIntent = ({
  getValues,
  toggleLoading,
  project,
  peerProject,
  projectId,
  peerProjectId,
  paymentMethodData,
  tokenId,
  setClientSecret,
  isBuyingGiftCard,
  isContribution,
}) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const { navigationPush } = useNavigation()
  const { put } = useLocalStorage()

  const { currency } = useCurrencySettings()
  const { user } = useUser()
  const { updateUser } = useUpdateUser()

  const { mutateAsync: getUserByEmailAsync } = useGetUserByEmailAsync()
  const { mutateAsync: createCustomerAsync } = useCreateCustomer()
  const { mutateAsync: attachPmToCustomer } = useAttachPmToCustomer()
  const { mutate: paymentIntent } = usePaymentIntent()
  const { mutate: confirmPayment } = useConfirmPayment()

  const createStripeAttachCard = useHelperCreateStripeAttachCard({
    toggleLoading,
  })

  return async ({ getFieldValue, paymentMethod, body, data }) => {
    const isAttachingStripeCard =
      paymentMethod === 'stripe' &&
      getValues('attachCard') &&
      user.id !== 'guest'

    let userByEmail
    if (user.id === 'guest' && getFieldValue('email').trim()) {
      userByEmail = await getUserByEmailAsync(getFieldValue('email').trim())
    }

    let stripeId = project?.stripeId
    if (isBuyingGiftCard || isContribution) {
      stripeId = process.env.REACT_APP_STRIPE_ACCT
    }

    const paymentIntentInput = {
      amount: Number(data.amount),
      applicationFeeAmount: Number(data.reArmenia),
      currency: currency.current,
      connectedAccountId: stripeId,
      setup_future_usage:
        getValues('paymentType') === 'recurring' ? 'off_session' : undefined,
      metadata: {
        projectId: project?.id,
        peerProjectId: peerProjectId,
        projectTitle_en: project?.title_en,
        userId: user.id,
        transactionParams: body.transactionInfo.transactionParams,
        subscribeForMonthly: body.transactionInfo.subscribeForMonthly,
      },
    }

    if (user.customerId || userByEmail?.customerId) {
      paymentIntentInput.customerId = user.customerId || userByEmail?.customerId
    }
    if (user.id === 'guest' && paymentMethodData?.id) {
      //? Case #1 No user at all (create a new customer with payment_method)
      //? Case #2 User with an existing customerId (attach payment_method to that customer)
      //? Case #3 User without a customerId (create a new customer with payment_method + update user with that customerId)
      if (userByEmail?.customerId) {
        await attachPmToCustomer({
          customerId: userByEmail?.customerId,
          paymentMethod: paymentMethodData.id,
        })
      } else {
        let customerRes
        try {
          customerRes = await createCustomerAsync({
            email: getFieldValue('email'),
            name: `${getFieldValue('firstName')} ${getFieldValue('lastName')}`,
            payment_method: paymentMethodData.id,
          })

          if (userByEmail) {
            const input = {
              id: userByEmail.id,
              customerId: customerRes.customer.id,
            }
            updateUser(input, {
              onSuccess: (newUser) => {
                put('rearmenia_user', JSON.stringify(newUser.data.updateUser))
              },
              onError: (error) => {
                console.log('UPDATE USER ERROR', error)
              },
            })
          }
          paymentIntentInput.customerId = customerRes.customer.id
        } catch (err) {
          console.log('CREATE CUSTOMER ERROR', err)
        }
      }
    }

    let cardId
    if (isAttachingStripeCard) {
      const attachCardResponse = await createStripeAttachCard({
        customerId: user.customerId,
        tokenId,
      })
      cardId = attachCardResponse?.source?.id
    }
    if (paymentMethod.includes('card_')) {
      cardId = paymentMethod
    }
    const foundCard = user.stripeAttachedCards?.find(
      (card) => card.id === cardId
    )
    paymentIntentInput.cardId = cardId
    paymentIntentInput.metadata.cardId = cardId
    paymentIntentInput.metadata.attachCard = isAttachingStripeCard
    paymentIntentInput.metadata.paymentType = paymentMethodData
      ? paymentMethodData.card.brand
      : foundCard.type
    paymentIntentInput.metadata.last4 = paymentMethodData
      ? paymentMethodData.card.last4
      : foundCard.last4
    paymentIntentInput.metadata.donationDate = moment().format('MMM D, YYYY')
    paymentIntentInput.metadata.donationDate_en = moment()
      .locale(MOMENT_LANGUAGES[LANG_EN_SHORT])
      .format('MMM D, YYYY')

    if (paymentMethodData?.id) {
      paymentIntentInput.payment_method = paymentMethodData.id
    }
    body.stripeData = paymentIntentInput

    paymentIntent(paymentIntentInput, {
      onSuccess: async (data) => {
        if (data?.client_secret) {
          setClientSecret(data.client_secret)
          if (data.id) {
            const transaction = data.transaction
            body.transaction = data.transaction
            body.transactionId = data.transaction?.id

            const projectTitle_en = peerProject?.title_en || project?.title_en
            confirmPayment(
              {
                paymentIntentId: data.id,
                payment_method: paymentMethodData?.id || cardId,
                giftCardId: isBuyingGiftCard && transaction?.giftCardId,
                transactionId: transaction.id,
                email: transaction.email,
                name: transaction.firstName,
                donationAmount: transaction.amount,
                donationToProject: transaction.donationToProject,
                donationToReArmenia: transaction.donationToReArmenia,
                currencyRate: transaction.currencyRate,
                currency: transaction.currency,
                projectName: projectTitle_en,
                projectName_en: projectTitle_en,
                projectId: peerProjectId || projectId,
                receiptId: transaction.id,
                donationDate: moment().format('MMM D, YYYY'),
                donationDate_en: moment()
                  .locale(MOMENT_LANGUAGES[LANG_EN_SHORT])
                  .format('MMM D, YYYY'),
                transactionLanguage: transaction.language,
                paymentType: paymentMethodData
                  ? paymentMethodData.card.brand
                  : foundCard.type,
                last4: paymentMethodData
                  ? paymentMethodData.card.last4
                  : foundCard.last4,
                isStripe: true,
                isOneTime: getValues('paymentType') === 'oneTime',
              },
              {
                onSuccess: async ({ message, success, redirectToUrl }) => {
                  if (redirectToUrl) {
                    window.location.href = redirectToUrl
                    return
                  }

                  if (success) {
                    const urlToNav = `/check-transaction-stripe?payment_intent=${data.id}`

                    if (
                      getValues('paymentType') === 'recurring' &&
                      data.subscriptionId
                    ) {
                      toggleLoading(false)
                      navigationPush(
                        `${urlToNav}&subscriptionId=${data.subscriptionId}`
                      )
                    } else {
                      navigationPush(urlToNav)
                    }
                  } else {
                    toggleLoading(false)
                    toast({
                      title: message || t('payment@orderStatus-general'),
                      status: 'error',
                    })
                  }
                },
                onError: (err) => {
                  console.log('❌', err)
                },
              }
            )
          }
        } else {
          if (data?.errorMessage) {
            console.log('❌', data)
            if (data.code === 'amount_too_small') {
              toast({
                title: t('toaster@atLeast50cents'),
                status: 'error',
              })
            } else {
              toast({
                title: data?.errorMessage,
                status: 'error',
              })
            }
            toggleLoading(false)
          }
        }
      },
      onError: (err) => {
        console.log('❌', err)
        amplitude
          .getInstance()
          .logEvent(
            `order-declined-stripe-${isMobileDevice() ? 'mobile' : 'desktop'}`
          )

        toggleLoading(false)
      },
    })
  }
}
