import {
  Box,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react'
import SegmentedControl from 'components/SegmentedControl'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  thousandSeparator,
} from 'contexts/CurrencySettingsContext'

export default function Tip({
  price,
  handleAmount,
  other,
  setValue,
  donation,
  PRICE,
  overwrittenCurrency,
}) {
  const { currency } = useCurrencySettings()
  const { t } = useTranslation()

  return (
    <FormControl>
      <Text
        fontSize="xl"
        fontWeight="semibold"
        color="gray.800"
        bg="white"
        zIndex={2}
        mb={0}
      >
        {t('donation@donateToReArmenia')}
      </Text>
      <Text mb="6" color="gray.700" fontSize="md">
        {t('donation@donateToReArmeniaFee')}
      </Text>

      <SegmentedControl
        width="100%"
        itemHeight={{ base: '48px', md: '52px' }}
        borderRadius={{ base: 16, md: 12 }}
        inWhiteBackground={true}
        name="amount"
        options={PRICE}
        defaultValue="20$"
        onChange={handleAmount}
        renderItem={({ key, value }) => (
          <>
            <Box fontWeight="600" as="span" fontSize={{ base: 'md', sm: 'lg' }}>
              {key === 'other' ? value : `${value}%`}
            </Box>
            <Box as="span" fontSize="sm">
              {key !== 'other' &&
                thousandSeparator(
                  (price * value) / 100,
                  overwrittenCurrency || currency.current
                ) + ` ${overwrittenCurrency || currency.current}`}
            </Box>
          </>
        )}
      >
        {other && (
          <FormControl>
            <InputGroup alignItems="center">
              <InputLeftElement
                top="unset"
                width="100px"
                pointerEvents="none"
                children={
                  <Box as="span" fontSize="xl" fontWeight={500}>
                    ({overwrittenCurrency || currency.current})
                  </Box>
                }
              />
              <Input
                _focus={{
                  boxShadow: 'none',
                }}
                maxLength="12"
                _hover={{
                  borderColor: 'blue.300',
                }}
                bg="white"
                borderRadius="8px"
                value={thousandSeparator(donation.reArmenia)}
                pattern="[0-9,]*"
                onChange={(e) =>
                  setValue(
                    'reArmenia',
                    e.target.value.replace(/[^0-9]|^0+(?!$)/g, '')
                  )
                }
                pl="6.5rem"
                textAlign="right"
                fontSize="xl"
                fontWeight={500}
                height={{ base: '48px', md: '60px' }}
              />
            </InputGroup>
          </FormControl>
        )}
      </SegmentedControl>
    </FormControl>
  )
}
