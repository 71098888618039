import { Box, ModalBody, Flex, Text, IconButton } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as CloseIcon } from 'assets/icons/closeX.svg'
import ManageSubscription from 'components/ManageSubscription'
import { useGetDonationSubscription } from 'hooks'

export default function ManageBasketSubscription({
  subscription: defaultSubscription,
  refetchList,
  closeModal,
}) {
  const { t } = useTranslation()
  const { data: subscription, refetch } = useGetDonationSubscription(
    defaultSubscription.id,
    {
      initialData: defaultSubscription,
    }
  )

  const onRefetch = () => {
    refetch()
    refetchList()
  }

  return (
    <Box p={6} maxW="572px">
      <Flex justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="500">
          {t('edit')}
        </Text>
        <IconButton
          icon={<CloseIcon w="10px" h="10px" stroke="black" />}
          onClick={closeModal}
        />
      </Flex>
      <ModalBody pt={8} px={0}>
        <ManageSubscription subscription={subscription} refetch={onRefetch} />
      </ModalBody>
    </Box>
  )
}
