import { Box, Flex } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  thousandSeparatorWithCurrency,
} from 'contexts/CurrencySettingsContext'
import { useScreenSize } from 'contexts'

function AmountInfo({
  donation,
  isBuyingGiftCard,
  isContribution,
  isGiftCardDisabled,
  isRecurring,
}) {
  const { isMobile } = useScreenSize()
  const { t } = useTranslation()
  const { currency } = useCurrencySettings()

  return (
    <>
      <Flex
        direction="column"
        px={6}
        paddingBottom={{ base: 0, sm: 5 }}
        paddingTop={{ base: isBuyingGiftCard ? 5 : 0, sm: 5 }}
      >
        <Flex justifyContent="space-between">
          <Box
            fontWeight={isContribution ? '500' : 'regular'}
            color="gray.800"
            fontSize={isContribution ? 'xl' : 'lg'}
          >
            {isBuyingGiftCard
              ? t('giftCardAmount')
              : t(
                  `donation@${
                    isGiftCardDisabled || isRecurring
                      ? 'yourMonthlyDonation'
                      : 'yourDonation'
                  }`
                )}
          </Box>
          <Box as="span" fontWeight="medium" fontSize="lg" whiteSpace="nowrap">
            {thousandSeparatorWithCurrency(
              Number(donation.amount),
              currency.current
            )}
          </Box>
        </Flex>

        {!isContribution && (
          <Flex mb="2" justifyContent="space-between">
            <Box fontWeight="regular" color="gray.800" fontSize="lg">
              {isBuyingGiftCard
                ? t('donation@reArmeniaTip')
                : t(
                    `donation@${
                      isGiftCardDisabled || isRecurring
                        ? 'reArmeniaTipMonthly'
                        : 'reArmeniaTip'
                    }`
                  )}
            </Box>
            <Box
              as="span"
              fontWeight="medium"
              fontSize="lg"
              whiteSpace="nowrap"
            >
              {thousandSeparatorWithCurrency(
                Number(donation.reArmenia),
                currency.current
              )}
            </Box>
          </Flex>
        )}

        {!isContribution && (
          <>
            {isMobile && <Box bg="#CDCDCD" w="full" height="1px" my={4} />}
            <Flex justifyContent="space-between">
              <Box fontWeight="bold" color="gray.800" fontSize="lg">
                {isBuyingGiftCard
                  ? t('donation@total')
                  : t(
                      `donation@${
                        isGiftCardDisabled || isRecurring
                          ? 'totalMonthly'
                          : 'total'
                      }`
                    )}
              </Box>
              <Box fontWeight="bold" fontSize="lg">
                {thousandSeparatorWithCurrency(
                  (Number(donation.reArmenia) ?? 0) +
                    (Number(donation.amount) ?? 0),
                  currency.current
                )}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
    </>
  )
}

export default AmountInfo
