import React from 'react'
import { Heading, Box } from '@chakra-ui/react'
import CreditCards from 'components/CreditCards'
import { useTranslation } from 'contexts/TranslationContext'

export default function AttachedCards({
  watch,
  setPaymentMethod,
  getValues,
  setValue,
  onChange,
  donationPage,
  clientSecret,
  isRecurring,
  isContribution,
  showStripe,
  register,
  errors,
  isGiftCardDisabled,
  showGiftCard,
  clearErrors,
  trigger,
  setTokenId,
  setNewStripeCard,
  isBorderVisible = true,
  isCharityBucket = false,
  setError,
  ...rest
}) {
  const { t } = useTranslation()

  return (
    <Box
      borderTopWidth={isBorderVisible ? 1 : 0}
      borderBottomWidth={{ base: 1, sm: 0 }}
      borderColor="gray.400"
      mt={8}
      py={8}
      {...rest}
    >
      {showStripe && (
        <Heading as="h2" fontSize="2xl" fontWeight={500} mb={8}>
          {t('donation@selectMethod')}
        </Heading>
      )}

      <CreditCards
        removable={false}
        page="donation"
        paymentMethod={watch('paymentMethod')}
        amount={Number(watch('amount')) + Number(watch('reArmenia'))}
        onClick={onChange}
        setPaymentMethod={setPaymentMethod}
        clearErrors={clearErrors}
        trigger={trigger}
        getValues={getValues}
        setValue={setValue}
        register={register}
        errors={errors}
        isRecurring={isRecurring}
        isContribution={isContribution}
        showStripe={showStripe}
        showGiftCard={showGiftCard}
        isGiftCardDisabled={isGiftCardDisabled}
        donationPage={donationPage}
        clientSecret={clientSecret}
        setTokenId={setTokenId}
        setNewStripeCard={setNewStripeCard}
        isCharityBucket={isCharityBucket}
        setErrorLocalCard={setError}
      />
    </Box>
  )
}
