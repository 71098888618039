import { TabList, TabPanel, TabPanels, Tabs, Tab } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import Cards from './Cards'
import History from './History'
import Amount from './Amount'
import Status from './Status'
import { useMemo } from 'react'

const tabs = [
  {
    key: 'cards',
    value: 'Cards',
  },
  {
    key: 'history',
    value: 'History',
  },
  {
    key: 'amount',
    value: 'Amount',
  },
  {
    key: 'status',
    value: 'Status',
  },
]

export default function ManageSubscription({
  subscription,
  tab,
  refetch,
  onTabChange,
}) {
  const { t } = useTranslation()

  const defaultIndex = useMemo(() => {
    if (!tab) {
      return 0
    }

    return tabs.findIndex((t) => t.key === tab)
  }, [tab])

  const onChange = (tabIndex) => {
    if (typeof onTabChange === 'function') {
      onTabChange(tabs[tabIndex].key, tabIndex)
    }
  }

  return (
    <Tabs defaultIndex={defaultIndex} onChange={onChange}>
      <TabList borderColor="gray.600">
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            fontWeight="700"
            fontSize="lg"
            color="gray.700"
            _selected={{ color: 'blue.500', borderColor: 'blue.500' }}
          >
            {t(tab.value)}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        <TabPanel pt={8} pb={0} px={0}>
          <Cards subscription={subscription} refetch={refetch} />
        </TabPanel>
        <TabPanel pt={8} pb={0} px={0}>
          <History subscription={subscription} />
        </TabPanel>
        <TabPanel pt={8} pb={0} px={0}>
          <Amount subscription={subscription} refetch={refetch} />
        </TabPanel>
        <TabPanel pt={8} pb={0} px={0}>
          <Status subscription={subscription} refetch={refetch} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
