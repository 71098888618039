import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

export default function AlertDialogModal({
  onClose,
  isOpen,
  onUnsubscribe,
  isLoading,
}) {
  const { t } = useTranslation()

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent p={10}>
        <AlertDialogBody
          p={0}
          mb={5}
          fontSize="2xl"
          fontWeight={600}
          color="black"
        >
          {t('profile@dashboard@investor@recurring@areYouSure')}
        </AlertDialogBody>
        <AlertDialogFooter p={0}>
          <Button
            isDisabled={isLoading}
            minH="20px"
            minW="100px"
            variant="white"
            onClick={onClose}
          >
            {t('profile@dashboard@investor@recurring@no')}
          </Button>
          <Button
            onClick={onUnsubscribe}
            minH="20px"
            minW="100px"
            variant="blue"
            colorScheme="red"
            ml={3}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {t('profile@dashboard@investor@recurring@yes')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
