import { useState, useEffect } from 'react'
import { useNavigation } from 'pages'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  useQueryParams,
  useCustomToast,
  useGetProject,
  useTransactionByOrderId,
} from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { Flex, Spinner, Text } from '@chakra-ui/react'
import usePaymentIntentConfirm from 'hooks/stripe/usePaymentIntentConfirm'

const CheckTransactionStripe = () => {
  const queryParams = useQueryParams()
  const toast = useCustomToast()
  const paymentIntentId = queryParams.get('payment_intent')
  const { data: transactionInfo } = useTransactionByOrderId(paymentIntentId)
  const { navigationReplace } = useNavigation()
  const { language } = useTranslation()
  const { firstName, projectId, peerProjectId, id, giftCardTransactionType } =
    transactionInfo || {}
  const { data: peerProject } = useGetProject({
    projectId: peerProjectId,
  })

  const { mutate: confirmPaymentIntent } = usePaymentIntentConfirm()

  const [message, setMessage] = useState()

  useEffect(() => {
    if (!transactionInfo) {
      return
    }
    if (peerProjectId && !peerProject?.id) {
      return
    }

    confirmPaymentIntent(
      { paymentIntentId },
      {
        onError: (error) => {
          toast({
            title: 'Something went wrong',
            status: 'error',
          })
          console.log(error)
        },
        onSuccess: async ({ status: apiStatus, intent }) => {
          if (apiStatus !== 'ok') {
            return
          }

          switch (intent.status) {
            case 'succeeded':
              setMessage('Success! Payment received.')
              break

            case 'processing':
              setMessage(
                "Payment processing. We'll update you when payment is received."
              )
              break

            case 'requires_payment_method':
              setMessage('Payment failed. Please try another payment method.')
              break

            default:
              setMessage('Something went wrong.')
              break
          }
          let url = `/donate/thank-you-stripe?name=${firstName}&title=${
            transactionInfo?.[`projectTitle_${language}`]
          }&id=${projectId}&monthly=${!!transactionInfo?.isMonthlyCharge}&contribution=${[
            process.env.REARMENIA_ID,
            'rearmenia',
          ].includes(projectId)}&transactionId=${id}`

          if (giftCardTransactionType === 'buy') {
            url = `/gift-card/thank-you`
          }

          const status = intent.parsedStatus
          if (message && status) {
            toast({ title: message, status: status })
          }

          navigationReplace(url)
        },
      }
    )
  }, [transactionInfo, peerProject])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap="16px"
    >
      <Text>Your Payment is Proceeding...</Text>
      <Spinner size="lg" />
    </Flex>
  )
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE)
const PaymentStatusWithStripe = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckTransactionStripe />
    </Elements>
  )
}

export default PaymentStatusWithStripe
