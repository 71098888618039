import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, VStack, useDisclosure, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import UpdateCard from 'components/UpdateCard'
import { DeleteUpdateModal } from 'modals'
import EditUpdateModal from 'modals/EditUpdateModal'
import useEmptyUpdate from 'components/UpdateEditor/useEmptyUpdate'
import { useQueryParams } from 'hooks'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'

const Update = ({
  project,
  updates,
  isUpdatesLoading,
  user,
  refetchUpdates,
  showAvatar = true,
}) => {
  const queryParams = useQueryParams()
  const selectedUpdate = (id) => queryParams.get('updateId') === id

  const generateEmptyUpdate = useEmptyUpdate()
  const [action, setAction] = useState('edit')
  const { language, t } = useTranslation()
  const {
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
    isOpen: isDeleteModalOpen,
  } = useDisclosure()
  const {
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
    isOpen: isEditModalOpen,
  } = useDisclosure()
  const [activeUpdate, setActiveUpdate] = useState()

  const isEditingVisible = useMemo(() => {
    if (!user) {
      return false
    }
    if (user.isAdmin || project?.owner === user.id) {
      return true
    }
    return false
  }, [user, project])

  const onUpdateControl = ({ update, action }) => {
    setActiveUpdate(update)
    setAction(action)
    if (action === 'delete') {
      onDeleteModalOpen()
      return
    }
    onEditModalOpen()

    return
  }
  const ref = useRef()
  useEffect(() => {
    if (!ref.current) {
      return
    }
    polyfill()
    scrollIntoView(ref.current, {
      behavior: 'smooth',
    })
  }, [isUpdatesLoading, updates])
  return (
    <Box>
      {isEditingVisible && (
        <Button
          color="orange.400"
          variant="transparent"
          m={4}
          onClick={() => {
            onUpdateControl({ update: generateEmptyUpdate(), action: 'create' })
          }}
        >
          {t('update@addUpdate')}
        </Button>
      )}
      {project && !isUpdatesLoading && updates && updates?.length > 0 ? (
        <VStack spacing={6}>
          {updates.map((update) => (
            <UpdateCard
              key={update.id}
              t={t}
              onUpdateControl={onUpdateControl}
              isEditingVisible={isEditingVisible}
              update={update}
              creatorImage={project.creatorImage}
              creatorName={project.creatorName}
              language={language}
              ref={selectedUpdate(update.id) ? ref : null}
              showAvatar={showAvatar}
            />
          ))}
        </VStack>
      ) : (
        <Box
          fontWeight="800"
          my="12"
          display="block"
          fontSize="6xl"
          color="blue.300"
          as="span"
        >
          {t('project@updates')}{' '}
          <Box as="span" fontWeight="400">
            {t('project@updatesText')}
          </Box>
        </Box>
      )}
      {project && isEditingVisible && (
        <>
          <DeleteUpdateModal
            isOpen={isDeleteModalOpen}
            onClose={onDeleteModalClose}
            refetchUpdates={refetchUpdates}
            update={activeUpdate}
          />
          <EditUpdateModal
            projectId={project.id}
            isOpen={isEditModalOpen}
            onClose={onEditModalClose}
            refetchUpdates={refetchUpdates}
            update={activeUpdate}
            createdAt={activeUpdate?.createdAt}
            action={action}
          />
        </>
      )}
    </Box>
  )
}

export default Update
