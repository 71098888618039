import { BlueSelect } from 'components/Select/BlueSelect'
import { LANGUAGES, useTranslation } from 'contexts/TranslationContext'

function LanguageSelector() {
  const { t, language, setLanguage } = useTranslation()
  const selectOption = (option) => setLanguage(option)

  return (
    <BlueSelect
      itemsAutofocusDisabled
      width="auto"
      display="flex"
      type="language"
      variant="noBorder"
      label={t(`language@${language}LabelShort`)}
      options={Object.keys(LANGUAGES).map((key) => ({
        title: t(`language@${key}Label`),
        value: key,
        key,
      }))}
      selectOption={selectOption}
      currentSelectedOption={t(`language@${language}Label`)}
      offset={[0, 7]}
    />
  )
}

export default LanguageSelector
