import { position, useToast } from '@chakra-ui/react'
import Toaster from 'components/Toaster'

const useCustomToast = () => {
  const toast = useToast({
    position: 'bottom-right',
  })
  const customToast = ({
    title,
    status,
    duration,
    isClosable,
    position = 'bottom-right',
  }) => {
    toast({
      render: (props) => <Toaster title={title} status={status} {...props} />,
      duration,
      isClosable,
      position,
    })
  }

  return customToast
}

export default useCustomToast
