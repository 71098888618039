import { Box } from '@chakra-ui/react'
import {
  Elements,
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  useCreateGiftCard,
  useCustomToast,
  usePaymentIntent,
  useQueryParams,
} from 'hooks'
import React, { useCallback, useState } from 'react'
import {
  useCurrencySettings,
  convertTo,
} from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'
import { useScreenSize } from 'contexts'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE)

function ExpressForm({
  project,
  peerProject,
  user,
  amount,
  applicationFeeAmount,
  stripeId,
  paymentMethod,
  currency,
  isContribution,
  getValues,
  watch,
  setError,
  trigger,
  clearErrors,
  settings,
}) {
  const queryParams = useQueryParams()
  const projectId = queryParams.get('projectId')
  const giftCard = queryParams.get('giftCard')
  const peerProjectId = queryParams.get('peerProjectId')

  const isBuyingGiftCard = projectId === 'giftCard'
  const { language, t } = useTranslation()
  const { mutate: createPaymentIntent } = usePaymentIntent()
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState(null)
  const toast = useCustomToast()
  const { mutateAsync: createGiftCardMutation } = useCreateGiftCard()

  const { isMobile } = useScreenSize()

  const createGiftCard = useCallback(
    ({
      message,
      from,
      fromEmail,
      language,
      toEmail,
      image,
      amount,
      currency,
      currencyRate,
    }) => {
      const giftCardInput = {
        userId: user.id,
        amount,
        image,
        currency,
        currencyRate,
        paymentStatus: 'pending',
        used: false,
        message,
        from,
        fromEmail,
        language,
        toEmail,
      }
      return createGiftCardMutation(giftCardInput)
    },
    [user]
  )
  const onConfirm = useCallback(
    async (event) => {
      if (!stripe) {
        return
      }
      const currencyRate = currency === 'AMD' ? 1 : settings?.currency[currency]

      const { error } = await elements.submit()
      if (error) {
        setErrorMessage(error.message)
        return
      }

      let createdGiftCard = null
      if (isBuyingGiftCard) {
        createdGiftCard = await createGiftCard({
          message: getValues('yourMessage'),
          from: getValues('from'),
          fromEmail: user.email,
          language,
          toEmail: getValues('toEmail'),
          image: giftCard,
          currency,
          currencyRate,
          amount: Number(
            Number(+amount / 100 - (applicationFeeAmount || 0)) * currencyRate
          ).toFixed(currency.current === 'AMD' ? 0 : 2),
        })
      }

      const perkIndex = queryParams.get('perkIndex')
      let perk = null
      if (queryParams.has('perkIndex')) {
        perk = project?.perks?.[perkIndex]
        delete perk.__typename
      }

      const dataAmount = +watch('amount')
      const paymentIntentInput = {
        amount: dataAmount,
        applicationFeeAmount,
        currency,
        connectedAccountId:
          isBuyingGiftCard || isContribution
            ? process.env.REACT_APP_STRIPE_ACCT
            : stripeId,
        metadata: {
          projectId: project?.id,
          projectTitle_en: project?.title_en,
          userId: user?.id,
          subscribeForMonthly: false,
          transactionParams: {
            userId: user.id,
            userFirstName: getValues('firstName') || user.firstName,
            userLastName: getValues('lastName') || user.lastName,
            userEmail: getValues('email') || user.email,
            data: {
              amount: dataAmount,
              reArmenia: applicationFeeAmount,
              currencyRate,
              currency,
              language,
              isManual: false,
              isAnonymous: false,
              isInfoAnonymous: false,
            },
            createdGiftCard,
            perkIndex,
            perk,
            paymentMethod,
            isBuyingGiftCard,
            projectId: projectId || project?.id,
            peerProjectId,
            isContribution,
            provider: paymentMethod,
            isStripe: true,
          },
        },
      }

      if (peerProjectId) {
        paymentIntentInput.metadata.peerProjectId = peerProjectId
      }

      createPaymentIntent(paymentIntentInput, {
        onSuccess: async (data) => {
          const { client_secret: clientSecret, id: paymentIntentId } = data
          const { error } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
              return_url: `${window.location.origin}/${language}/check-transaction-stripe?payment_intent=${paymentIntentId}`,
            },
          })

          if (error) {
            toast({
              title: 'Error',
              description: error?.message,
              status: 'error',
              duration: 9000,
              isClosable: true,
            })
            return
          }
          toast({
            title: 'Success',
            description: 'Payment successful',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        },
        onError: (err) => {
          console.log('❌', err)
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        },
      })
    },
    [
      stripe,
      elements,
      user,
      amount,
      applicationFeeAmount,
      currency,
      paymentMethod,
    ]
  )
  const isValidAmount = (amount) => {
    return (
      convertTo({
        settings,
        amount,
        from: currency,
        to: 'USD',
      }) > 0.5
    )
  }
  const onError = (amount) => {
    toast({
      title: t(
        amount ? 'donation@labelEnterAmount' : 'donation@addPersonalInfo'
      ),
      status: 'error',
      duration: 3000,
      isClosable: true,
      position: isMobile ? 'top' : undefined,
    })
  }
  const triggerCustomValidation = () => {
    if (!isValidAmount(watch('amount'))) {
      setError('amount')
      onError(true)
    } else if (
      [watch('firstName'), watch('lastName'), watch('email')].some(
        (e) => !e || e === 'guest'
      )
    ) {
      trigger('firstName')
      trigger('lastName')
      trigger('email')
      onError(false)
    } else {
      clearErrors('amount')
    }
  }
  const formIsInvalid =
    [watch('firstName'), watch('lastName'), watch('email')].some(
      (e) => !e || e === 'guest'
    ) || !isValidAmount(watch('amount'))

  return (
    <Box pos="relative">
      {formIsInvalid && (
        <Box
          w="full"
          h="full"
          pos="absolute"
          zIndex={2}
          onClick={triggerCustomValidation}
          cursor="pointer"
        />
      )}
      {paymentMethod === 'GOOGLE_PAY' && (
        <ExpressCheckoutElement
          options={{
            wallets: {
              googlePay: 'always',
              applePay: 'never',
            },
            buttonType: {
              applePay: isBuyingGiftCard ? 'buy' : 'donate',
              googlePay: isBuyingGiftCard ? 'buy' : 'donate',
            },
            paymentMethodOrder: ['googlePay', 'applePay'],
            layout: {
              maxRows: 1,
              maxColumns: 1,
              overflow: 'auto',
            },
          }}
          onConfirm={onConfirm}
        />
      )}
      {paymentMethod === 'APPLE_PAY' && (
        <ExpressCheckoutElement
          options={{
            wallets: {
              googlePay: 'never',
              applePay: 'always',
            },
            buttonType: {
              applePay: isBuyingGiftCard ? 'buy' : 'donate',
              googlePay: isBuyingGiftCard ? 'buy' : 'donate',
            },
            paymentMethodOrder: ['googlePay', 'applePay'],
            layout: {
              maxRows: 1,
              maxColumns: 1,
              overflow: 'auto',
            },
          }}
          onConfirm={onConfirm}
        />
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </Box>
  )
}

function WalletPayButton({
  project,
  peerProject,
  user,
  amount,
  watch,
  trigger,
  setError,
  clearErrors,
  applicationFeeAmount,
  currency,
  isContribution,
  paymentMethod,
  getValues,
}) {
  const { settings } = useCurrencySettings()
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'payment',
        amount: amount === 0 ? 100000 : Math.round(Number(amount) * 100), //TODO 10000 / 100 AMD still smaller than 0.5 cents
        currency: currency.toLowerCase(),
        automatic_payment_methods: { enabled: true },
      }}
    >
      <ExpressForm
        project={project}
        peerProject={peerProject}
        settings={settings}
        user={user}
        amount={amount === 0 ? 100000 : Math.round(Number(amount) * 100)}
        applicationFeeAmount={applicationFeeAmount}
        stripeId={project?.stripeId}
        paymentMethod={paymentMethod}
        currency={currency}
        isContribution={isContribution}
        getValues={getValues}
        watch={watch}
        trigger={trigger}
        setError={setError}
        clearErrors={clearErrors}
      />
    </Elements>
  )
}

export default WalletPayButton
