import Web from './Web'

const Cart = ({
  donation,
  project,
  isGiftCardDisabled,
  isContribution,
  isBuyingGiftCard,
  giftCardMode,
  isRecurring,
}) => {
  const props = {
    donation,
    project,
    isGiftCardDisabled,
    isContribution,
    isBuyingGiftCard,
    giftCardMode,
    isRecurring,
  }

  return <Web {...props} />
}

Cart.defaultProps = {
  type: 'project',
}

export default Cart
