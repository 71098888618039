import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
} from '@chakra-ui/react'

import { ReactComponent as AlertInfo } from './icons/AlertInfo.svg'

const icons = {
  success: null,
  warning: null,
  error: null,
  info: <AlertInfo width="18px" height="18px" />,
}

const StaticAlert = ({
  status = 'info',
  title,
  titleProps = {},
  description,
  descriptionProps = {},
  iconProps = {},
  ...rest
}) => {
  return (
    <Alert {...rest} status={status} variant="static-alert">
      {icons[status] && (
        <AlertIcon>
          <Box padding={!title && description ? 0 : '6px'} {...iconProps}>
            {icons[status]}
          </Box>
        </AlertIcon>
      )}

      <Flex flexDirection="column" gap="8px">
        {title && <AlertTitle {...titleProps}>{title}</AlertTitle>}
        {description && (
          <AlertDescription {...descriptionProps}>
            {description}
          </AlertDescription>
        )}
      </Flex>
    </Alert>
  )
}

export default StaticAlert
