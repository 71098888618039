import { useCallback, useState } from 'react'
import amplitude from 'amplitude-js'
import {
  useCustomToast,
  useLocalStorage,
  usePayment,
  useQueryParams,
  useSubscribeToProjectNewsletter,
  useUser,
} from 'hooks'
import { isMobileDevice, parseApiError } from 'utils'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import { createTransactionBody } from './donationHelpers'
import useHelperCreateGiftCard from './useHelperCreateGiftCard'
import useHelperRedeemGiftCard from './useHelperRedeemGiftCard'
import useHelperReportSentry from './useHelperReportSentry'
import * as helpersStripe from './providers/stripe'

const useDonation = ({
  getValues,
  watch,
  setError,
  giftCardMode,
  giftCard,
  giftCardInfo,
  project,
  projectId,
  peerProject,
  peerProjectId,
  appliedGiftCard,
  isContribution,
  isBuyingGiftCard,
  setGiftCardMode,
  setGiftCard,
}) => {
  const { t, language } = useTranslation()
  const toast = useCustomToast()
  const queryParams = useQueryParams()
  const { put } = useLocalStorage()
  const { navigationPush } = useNavigation()

  const { currency, settings } = useCurrencySettings()
  const { user } = useUser()

  const { mutate: subscribeToProjectNewsLetter } =
    useSubscribeToProjectNewsletter()
  const { mutate: payment } = usePayment()

  const hasPerkIndex = queryParams.has('perkIndex')

  const [isLoading, toggleLoading] = useState(false)
  const [clickedOnce, setClickedOnce] = useState(false)
  const [clientSecret, setClientSecret] = useState(null)
  const [paymentMethodData, setPaymentMethod] = useState()
  const [tokenId, setTokenId] = useState(null)
  const [newStripeCard, setNewStripeCard] = useState()

  const reportSentry = useHelperReportSentry({
    getValues,
    giftCardMode,
    giftCard,
    project,
    appliedGiftCard,
    isContribution,
  })
  const createGiftCard = useHelperCreateGiftCard()
  const redeemGiftCard = useHelperRedeemGiftCard({
    getValues,
    watch,
    toggleLoading,
    projectId,
    project,
    peerProjectId,
    peerProject,
    isContribution,
    giftCard,
    giftCardInfo,
    setGiftCardMode,
    setGiftCard,
  })

  const stripePaymentIntent = helpersStripe.useIntent({
    getValues,
    toggleLoading,
    project,
    peerProject,
    projectId,
    peerProjectId,
    paymentMethodData,
    tokenId,
    setClientSecret,
    isBuyingGiftCard,
    isContribution,
  })

  const onDonate = useCallback(
    async (data) => {
      const getFieldValue = (field) =>
        user.id === 'guest' ? data[field] : user[field]

      const paymentMethod = getValues('paymentMethod')
      const isPayPalPayment = paymentMethod === 'PAYPAL'
      const isIDramPayment = paymentMethod === 'IDRAM'
      const isStripePayment =
        ['stripe', 'GOOGLE_PAY', 'APPLE_PAY'].includes(paymentMethod) ||
        paymentMethod.includes('card_')

      let perk = null
      if (hasPerkIndex) {
        perk = project?.perks?.[queryParams.get('perkIndex')]
        delete perk.__typename
      }

      let paymentProvider = 'arca'
      if (isStripePayment) {
        paymentProvider = 'stripe'
      } else if (isIDramPayment) {
        paymentProvider = 'idram'
      } else if (isPayPalPayment) {
        paymentProvider = 'paypal'
      }

      if (
        isPayPalPayment ||
        isIDramPayment ||
        (!giftCardMode && data.bindingId === 'giftCard')
      ) {
        delete data.bindingId
      }
      if (data.subscribeNews) {
        subscribeToProjectNewsLetter({ projectId, email: data.email })
      }

      if (paymentMethod === 'stripe' && !giftCardMode) {
        if (!tokenId) {
          setError('stripe')
          return
        }
      }
      const email = getFieldValue('email').trim().toLowerCase()
      // save in local storage for later use if donation fails
      const cacheDonationFormData = { ...data, projectId }
      if (peerProjectId) {
        cacheDonationFormData.peerProjectId = peerProjectId
      }
      if (peerProject?.id) {
        cacheDonationFormData.peerProject = peerProject
      }
      put('donate-form', JSON.stringify(cacheDonationFormData))

      if (giftCardMode) {
        await redeemGiftCard(data)
        return
      }
      toggleLoading(true)
      const currencyRate =
        currency.current === 'AMD' ? 1 : settings?.currency[currency.current]

      let createdGiftCard = null
      if (isBuyingGiftCard) {
        createdGiftCard = await createGiftCard({
          message: data.yourMessage,
          from: data.from,
          fromEmail: user.email,
          language,
          toEmail: data.toEmail,
          image: giftCard,
          currency: currency.current,
          currencyRate,
          amount: Number(Number(data.amount) * currencyRate).toFixed(
            currency.current === 'AMD' ? 0 : 2
          ),
        })
      }

      const { body, compareResult } = createTransactionBody({
        isBuyingGiftCard: giftCard,
        projectId,
        project,
        peerProject,
        user,
        formAmount: getValues('amount'),
        currency,
        currencyRate,
        email,
        firstName: getFieldValue('firstName'),
        lastName: getFieldValue('lastName'),
        language,
        data,
        paymentType: getValues('paymentType'),
        paymentMethod: paymentMethod,
        appliedGiftCard,
        isContribution,
        createdGiftCard,
        perk,
        perkIndex: queryParams.get('perkIndex'),
        newStripeCard,
        paymentMethodData,
        peerProjectId,
        isIDramPayment,
        isPayPalPayment,
        provider: paymentProvider,
        isStripe: isStripePayment,
      })

      //? for tracking donate button analytics from here
      if (clickedOnce) {
        reportSentry({
          email,
          paymentMethod,
          body,
          data,
          paymentProvider,
        })
      } else {
        setClickedOnce(true)
      }
      //? for tracking donate button analytics until here

      if (isStripePayment) {
        stripePaymentIntent({ getFieldValue, paymentMethod, body, data })
        return
      }

      if (compareResult.makePayment) {
        let path = ''
        if (isIDramPayment) {
          path = '/idram'
        } else if (isPayPalPayment) {
          const domain = window.location.protocol + '//' + window.location.host
          const redirectUrlParts = [domain, language, '/check-paypal-result']
          body.redirectUrl = redirectUrlParts
            .map((part) => part.replace(/^\//g, '').replace(/$\//g, ''))
            .join('/')

          path = '/paypal'
        } else {
          path = body.localBindingId ? '/bind' : '/payment'
        }

        await payment(
          { path, body },
          {
            onSuccess: async (result) => {
              if (isIDramPayment) {
                if (result.submitUrl) {
                  const form = document.createElement('form')
                  form.setAttribute('method', 'POST')
                  form.setAttribute('action', result.submitUrl)
                  form.style.display = 'none'
                  if (result.submitData) {
                    const addInput = (name, value) => {
                      const input = document.createElement('input')
                      input.setAttribute('type', 'hidden')
                      input.setAttribute('name', name)
                      input.setAttribute('value', value)
                      form.appendChild(input)
                    }

                    Object.entries(result.submitData).forEach(([name, value]) =>
                      addInput(name, value)
                    )
                  }

                  document.body.appendChild(form)
                  form.submit()
                } else {
                  toast({
                    title: 'Project does not have IDram integrated.',
                    status: 'warning',
                  })
                  toggleLoading(false)
                }
                return
              }

              if (isPayPalPayment) {
                if (result.redirectUrl) {
                  window.location.href = result.redirectUrl
                } else {
                  let message = 'Failed to initialize PayPal payment.'
                  const parsedError = parseApiError(result.result)
                  if (parsedError?.name === 'INVALID_AMOUNT') {
                    message = t('toaster@atLeast50cents')
                  }

                  toast({ title: message, status: 'error' })
                  toggleLoading(false)
                }
                return
              }

              setTimeout(() => {
                //? @INFO setTimeout is for loading spinner not to blink before redirecting
                toggleLoading(false)
              }, 800)
              if (result.formUrl) {
                if (path === '/payment') {
                  put('paymentMethod', paymentMethod)
                  window.location = result.formUrl.replace('_binding', '')
                  return
                }
              }
              navigationPush(
                `/check-transaction?orderId=${result.orderId}&method=${paymentMethod}`
              )
            },
            onError: (e) => {
              amplitude
                .getInstance()
                .logEvent(
                  `order-declined-${isMobileDevice() ? 'mobile' : 'desktop'}`
                )
              toggleLoading(false)
              toast({
                title: e?.response?.data?.message || 'Problem with bank',
                status: 'warning',
              })
            },
          }
        )
      }
    },
    [
      project,
      peerProject,
      user,
      currency.current,
      giftCardMode,
      clickedOnce,
      tokenId,
      paymentMethodData,
    ]
  )

  return {
    onDonate,
    isLoading,
    clientSecret,
    setPaymentMethod,
    setTokenId,
    setNewStripeCard,
  }
}

export default useDonation
