import { useState } from 'react'
import { post } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useQuery, useMutation, useInfiniteQuery } from 'react-query'
import useAppSyncRequest from './useAppSyncRequest'
import projectFactory from 'core/factories/project'

export const useProjectFactory = () => {
  const appSyncRequest = useAppSyncRequest()
  const auth = { fetchAuthSession }
  const api = { post }

  return projectFactory({ appSyncRequest, auth, api })
}

export const useCreateProject = () => {
  const mutation = useMutation(useProjectFactory().createProject)

  return mutation
}

export const useDeleteProject = () => {
  const mutation = useMutation(useProjectFactory().deleteProject)

  return mutation
}

export const useGetProject = ({ projectId, isAdmin = false }) => {
  const projectUseCase = useProjectFactory()
  return useQuery(['project', projectId, isAdmin], () =>
    projectUseCase.retrieveProject(projectId, { isAdmin })
  )
}

export const useProjects = (
  initialFilter,
  enabled = true,
  key = 'get_projects',
  initialCacheKey
) => {
  const projectUseCase = useProjectFactory()
  const [filters, setFilters] = useState(initialFilter)
  const [cacheKey, setCacheKey] = useState(initialCacheKey)
  const {
    error,
    refetch,
    isLoading,
    data: projects,
  } = useQuery(
    [key, filters],
    async () => {
      return await projectUseCase.getProjects(filters, cacheKey)
    },
    {
      enabled,
    }
  )

  return {
    projects,
    refetch,
    isLoading,
    filters,
    setFilters,
    setCacheKey,
    error,
  }
}

export const useProjectsByFeatured = (
  initialFilter,
  withNextToken = false,
  enabled = true,
  key = 'get_projects_by_featured'
) => {
  const projectUseCase = useProjectFactory()
  const [filters, setFilters] = useState(initialFilter)

  const {
    error,
    refetch,
    isLoading,
    data: projects,
  } = useQuery(
    [key, filters],
    async () => {
      return await projectUseCase.getProjectsByFeatured(filters, withNextToken)
    },
    {
      enabled,
    }
  )

  return {
    refetch,
    isLoading,
    projects,
    filters,
    setFilters,
    error,
  }
}

export const useProjectsByPopular = (
  initialFilter,
  withNextToken = false,
  enabled = true,
  key = 'get_projects_by_user_id'
) => {
  const projectUseCase = useProjectFactory()
  const [filters, setFilters] = useState(initialFilter)

  const {
    error,
    refetch,
    isLoading,
    data: projects,
  } = useQuery(
    [key, filters],
    async () => {
      return await projectUseCase.getProjectsByPopular(filters, withNextToken)
    },
    {
      enabled,
    }
  )

  return {
    refetch,
    isLoading,
    projects,
    filters,
    setFilters,
    error,
  }
}

export const useProjectsBySlug = (slug) => {
  const projectUseCase = useProjectFactory()
  const {
    error,
    refetch,
    isLoading,
    data: project,
  } = useQuery(['project_slug', slug], async () => {
    return await projectUseCase.getProjectsBySlug(slug)
  })

  return {
    refetch,
    isLoading,
    project,
    error,
  }
}

export const useProjectsByUserId = (
  initialFilter,
  withNextToken = false,
  enabled = true,
  key = 'get_projects_by_user_id'
) => {
  const projectUseCase = useProjectFactory()
  const [filters, setFilters] = useState(initialFilter)

  const {
    error,
    refetch,
    isLoading,
    data: projects,
  } = useQuery(
    [key, filters],
    async () => {
      return await projectUseCase.getProjectsByUserId(filters, withNextToken)
    },
    {
      enabled,
    }
  )

  return {
    refetch,
    isLoading,
    projects,
    filters,
    setFilters,
    error,
  }
}

export const useSearchProjects = (condition = {}) => {
  const projectUseCase = useProjectFactory()
  const query = useInfiniteQuery(
    ['get_projects', condition],
    async ({ pageParam }) => {
      return await projectUseCase.searchProjects({
        ...condition,
        from: pageParam || 0,
      })
    },
    {
      getNextPageParam: (lastPage) => {
        const { total, from, size } = lastPage.meta
        const nextOffset = from + size
        return nextOffset < total ? nextOffset : undefined
      },
    }
  )

  return query
}

export const useUpdateProject = () => {
  const {
    mutate: updateProject,
    data,
    isLoading,
  } = useMutation(useProjectFactory().updateProject)

  return {
    updateProject,
    isLoading,
    data,
  }
}

export const useUpdateProjectPerks = () => {
  const { mutate: updateProjectPerks, isLoading } = useMutation(
    useProjectFactory().updateProjectPerks
  )

  return {
    updateProjectPerks,
    isLoading,
  }
}

export const useUpdateProjectStatus = () => {
  const {
    mutate: updateProjectStatus,
    isLoading,
    error,
    data,
  } = useMutation(useProjectFactory().updateProjectStatus)

  return {
    updateProjectStatus,
    isLoading,
    error,
    data,
  }
}

export const useSubscribeToProjectNewsletter = () => {
  const mutation = useMutation(useProjectFactory().subscribeToProjectNewsletter)

  return mutation
}

export const useCreateMailerLiteFields = () => {
  const mutation = useMutation(useProjectFactory().createMailerLiteFields)

  return mutation
}
