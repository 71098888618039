import {
  createTransaction as createTransactionMutation,
  updateTransaction as updateTransactionMutation,
} from 'graphql/mutations'
import {
  transactionsByEmailDate as transactionsByEmailDateQuery,
  customGetTransactions as customGetTransactionsQuery,
} from 'graphql/queries'

const TransactionService = (appSyncRequest, auth) => {
  const requestAppSync = async ({ query, variables, withAuth = true }) => {
    const request = {
      query: query,
      variables: variables,
    }

    if (withAuth) {
      const currentAuthSession = await auth.fetchAuthSession()
      request.authToken = currentAuthSession?.tokens?.idToken?.toString()
      request.authMode = 'userPool'
    }

    return appSyncRequest(request)
  }

  const requestCustom = async (query, variables) => {
    const result = await requestAppSync({
      withAuth: false,
      query: customGetTransactionsQuery,
      variables: {
        input: {
          query,
          variables:
            typeof variables === 'string'
              ? variables
              : JSON.stringify(variables),
        },
      },
    })

    const response = result.data.customGetTransactions
    if (typeof response === 'string') {
      return { data: { [query]: JSON.parse(response) } }
    }

    return response
  }

  const createTransaction = (input) =>
    requestAppSync({
      query: createTransactionMutation,
      variables: { input },
    })

  const updateTransaction = (input) =>
    requestAppSync({
      query: updateTransactionMutation,
      variables: { input },
    })

  const getTransaction = (id) => requestCustom('getTransaction', { id })

  const listTransactions = (variables) =>
    requestCustom('listTransactions', variables)

  const transactionByOrderId = async (orderId) => {
    const transactionResponse = await requestCustom('transactionsByOrderId', {
      orderId,
    })
    return transactionResponse?.data?.transactionsByOrderId?.items?.[0]
  }

  const listTransactionsByGiftCardCode = (variables) =>
    requestCustom('transactionsByGiftCardCode', variables)

  const listTransactionsByDate = (variables) =>
    requestCustom('transactionsByDate', variables)

  const listTransactionsByPeerProjectId = (variables) =>
    requestCustom('transactionsByPeerProjectId', variables)

  const listTransactionsByBasketId = (variables) =>
    requestCustom('transactionsByBasketId', variables)

  const listTransactionsByTopDonation = (variables) =>
    requestCustom('transactionsByTopProjectDonation', variables)

  const getTransactionsByEmailDate = (variables) =>
    requestCustom('transactionsByEmailDate', variables)

  const getUserTransactionsByProjectIdEmail = (variables) =>
    requestCustom('transactionsByProjectIdEmail', variables)

  const getTransactionsByEmailDateByOwner = (variables) =>
    requestAppSync({
      query: transactionsByEmailDateQuery,
      variables: variables,
    })

  return {
    createTransaction,
    getTransaction,
    listTransactions,
    listTransactionsByGiftCardCode,
    listTransactionsByDate,
    listTransactionsByTopDonation,
    getTransactionsByEmailDate,
    getTransactionsByEmailDateByOwner,
    updateTransaction,
    getUserTransactionsByProjectIdEmail,
    listTransactionsByPeerProjectId,
    listTransactionsByBasketId,
    transactionByOrderId,
  }
}

export default TransactionService
