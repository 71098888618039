import React from 'react'
import { Container, Text, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { MainLayout } from 'components/Layouts'
import { Footer } from 'components/Footer'
import { ReactComponent as Reached } from 'assets/icons/reachedBig.svg'
import { Link } from 'pages'
import { useScreenSize } from 'contexts'
import CreateAccountModal from 'components/CreateAccountModal'

export default function ThankYouGiftCard() {
  const { t } = useTranslation()
  const { isMobile } = useScreenSize()

  return (
    <MainLayout>
      <CreateAccountModal />

      <Container
        my="72px"
        display="flex"
        alignItems="center"
        flexDir="column"
        maxW="750px"
      >
        <Reached minH="218px" />
        <Text fontSize="6xl" textAlign="center" fontWeight={500} mt={4} mb={6}>
          {t('giftCard@thankYou@title')}
        </Text>
        <Text mt={2} textAlign="center" fontSize="md" color="gray.700">
          {t('giftCard@thankYou@description')}
        </Text>
        <Text mt={2} textAlign="center" fontSize="md" color="gray.700">
          {t('giftCard@thankYou@description2')}
        </Text>

        <Button
          variant="blue"
          mt={10}
          px={{ base: 2, sm: 12 }}
          as={Link}
          to="/select-gift-card"
        >
          {t('giftCard@thankYou@button')}
        </Button>
      </Container>

      {!isMobile && <Footer />}
    </MainLayout>
  )
}
