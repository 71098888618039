import { Stepper as ChakraStepper } from '@chakra-ui/react'
import Step from './Step'

export default function Stepper({ steps, activeStep = 0 }) {
  return (
    <ChakraStepper index={activeStep} size="lg">
      {steps.map((step, index) => (
        <Step
          key={index}
          step={step}
          index={index}
          steps={steps}
          activeStep={activeStep}
        />
      ))}
    </ChakraStepper>
  )
}
