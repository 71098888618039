import { useCallback } from 'react'
import { useNavigation } from 'pages'
import { useCustomToast, useGetGiftCard, useLocalStorage } from 'hooks'
import * as helpersGiftCard from './providers/giftCard'

const useHelperRedeemGiftCard = ({
  getValues,
  watch,
  toggleLoading,
  projectId,
  project,
  peerProjectId,
  peerProject,
  isContribution,
  giftCard,
  giftCardInfo,
  setGiftCardMode,
  setGiftCard,
}) => {
  const toast = useCustomToast()

  const { navigationPush } = useNavigation()
  const { remove } = useLocalStorage()

  const { mutateAsync: getGiftCardAsync } = useGetGiftCard()

  const updateGiftCard = helpersGiftCard.useGiftCard({
    isContribution,
    giftCardInfo,
    projectId,
    project,
    peerProjectId,
    peerProject,
  })

  const redeemGiftCard = useCallback(
    async (data) => {
      toggleLoading(true)
      const dbGiftCard = await getGiftCardAsync(giftCardInfo.id)
      if (dbGiftCard.used) {
        toast({
          title: 'Sorry, gift card already used',
          status: 'warning',
        })
        setGiftCardMode(false)
        setGiftCard({})
        remove('giftCard')
        remove('giftCardMode')
        toggleLoading(false)
        return
      }
      const paymentMethod = getValues('paymentMethod')

      try {
        await updateGiftCard({
          paymentMethod,
          data,
          reArmenia: watch('reArmenia'),
        })

        const title =
          peerProject?.title_en ||
          project.projectTitle_en ||
          project.applicationTitle

        setGiftCardMode(false)
        setGiftCard({})
        remove('giftCard')
        remove('giftCardMode')
        navigationPush(`/donate/thank-you?name=${
          data.firstName
        }&title=${encodeURI(title)}&id=${peerProjectId || projectId}
        `)
      } catch (error) {
        toast({ title: 'Something went wrong', status: 'warning' })
        console.log('ERRR', error)
      }
    },
    [project, giftCard, giftCardInfo]
  )

  return redeemGiftCard
}

export default useHelperRedeemGiftCard
