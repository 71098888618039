import LazyLoad from 'react-lazyload'

export default function ImageLazyLoadWrapper({ isLazy, children, ...props }) {
  if (isLazy) {
    return (
      <LazyLoad {...props} resize scroll>
        {children}
      </LazyLoad>
    )
  }

  return children
}
