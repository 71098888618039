import { useMutation, useQuery } from 'react-query'
import { fetchAuthSession } from 'aws-amplify/auth'
import useAppSyncRequest from './useAppSyncRequest'
import expertFactory from 'core/factories/expert'

export const useContentFactory = () => {
  const appSyncRequest = useAppSyncRequest()
  const auth = { fetchAuthSession }

  return expertFactory({ appSyncRequest, auth })
}

export const useConnectExpert = () => {
  const expertUseCase = useContentFactory()
  return useMutation(expertUseCase.createExpertInitConnection)
}

export const useUpdateExpertConnection = () => {
  const expertUseCase = useContentFactory()
  return useMutation(expertUseCase.updateConnection)
}

export const useGetExpertConnectionById = (id) => {
  const expertUseCase = useContentFactory()
  return useQuery([`connection-${id}`], () => expertUseCase.getConnection(id))
}

export default useConnectExpert
