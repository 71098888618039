import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import amplitude from 'amplitude-js'
import { useQueryParams, useSignUp, useUser } from 'hooks'
import { COUNTRY_OBJECTS } from '_constants'
import Mobile from './Mobile'
import Web from './Web'
import useCustomToast from 'hooks/useCustomToast'
import { useScreenSize } from 'contexts'

const Register = () => {
  const { user } = useUser()
  const toast = useCustomToast()
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      subscribeNews: true,
    },
  })
  const { signUp } = useSignUp()
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  const params = useQueryParams()
  const password = useRef({})
  password.current = watch('password', '')

  const from = params.get('from')

  const onSubmit = (data) => {
    const input = {
      ...data,
      country,
    }
    if ((!!phone && phone.length < 5) || (!!phone && phone[0] !== '+')) {
      return setPhoneError(true)
    }

    const phoneFormatted = phone
      .replace(/\s/g, '')
      .replace('(', '')
      .replace(')', '')

    const createdDate = new Date().toISOString()
    delete input.privacy_policy
    delete input.terms_and_conditions
    delete input.confirm_password
    setLoading(true)
    signUp(
      {
        ...input,
        email: data.email.toLowerCase(),
        phone: phoneFormatted,
        createdAt: createdDate,
        updatedAt: createdDate,
        language,
      },
      {
        onSuccess: async () => {
          setLoading(false)

          toast({
            status: 'success',
            title: t('signUp@confirmationEmailMessage'),
            isClosable: true,
            duration: 5000,
          })

          const email = data.email.toLowerCase()
          const returnUrl = encodeURIComponent(from)

          navigationPush(
            `/verify?email=${email}&firstName=${data.firstName}&from=${returnUrl}`
          )
          amplitude.getInstance().logEvent('signup_mobile')
          console.log('sent event to amplitude(signup_mobile)')
        },
        onError: (e) => {
          console.log(e)
          if (e.name === 'InvalidParameterException') {
            return toast({
              status: 'error',
              title: t('signUp@phoneNumberErrorMessage'),
              isClosable: true,
              duration: 5000,
            })
          }
          toast({
            status: 'error',
            title: t(`signUp@${e.name}`),
            isClosable: true,
            duration: 5000,
          })
          setLoading(false)
        },
      }
    )
  }

  useEffect(() => {
    if (!country) return
    const newValue = COUNTRY_OBJECTS.filter((item) => item.name === country)[0]
      ?.dialCode
    setPhone(newValue)
  }, [country])

  const { isMobile } = useScreenSize()
  if (isMobile) {
    return (
      <Mobile
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        loading={loading}
        user={user}
      />
    )
  }
  return (
    <Web
      register={register}
      errors={errors}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      country={country}
      setCountry={setCountry}
      phone={phone}
      setPhone={setPhone}
      phoneError={phoneError}
      loading={loading}
      password={password}
      user={user}
    />
  )
}

export default Register
