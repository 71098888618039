const transactionRepo = (TransactionService) => {
  const getTransaction = async (id) => {
    if (!id) {
      throw Error('ID is required')
    }
    return TransactionService.getTransaction(id)
  }

  const listTransactions = async (variables) =>
    TransactionService.listTransactions(variables)

  const listTransactionsByGiftCardCode = async (variables) => {
    return TransactionService.listTransactionsByGiftCardCode(variables)
  }

  const listTransactionsByDate = async (variables) => {
    return TransactionService.listTransactionsByDate(variables)
  }

  const listTransactionsByPeerProjectId = async (variables) => {
    return TransactionService.listTransactionsByPeerProjectId(variables)
  }

  const listTransactionsByBasketId = async (variables) => {
    return TransactionService.listTransactionsByBasketId(variables)
  }

  const listTransactionsByTopDonation = async (variables) => {
    return TransactionService.listTransactionsByTopDonation(variables)
  }

  const createTransaction = async (input) =>
    TransactionService.createTransaction(input)

  const updateTransaction = async (input) =>
    TransactionService.updateTransaction(input)

  const searchTransactions = (condition) =>
    TransactionService.searchTransactions(condition)

  const getTransactionsByEmailDate = (variables) =>
    TransactionService.getTransactionsByEmailDate(variables)

  const getTransactionsByEmailDateByOwner = (variables) =>
    TransactionService.getTransactionsByEmailDateByOwner(variables)

  const getUserTransactionsByProjectIdEmail = (variables) =>
    TransactionService.getUserTransactionsByProjectIdEmail(variables)

  const transactionByOrderId = (orderId) =>
    TransactionService.transactionByOrderId(orderId)

  return {
    searchTransactions,
    createTransaction,
    getTransaction,
    listTransactions,
    listTransactionsByGiftCardCode,
    listTransactionsByDate,
    listTransactionsByTopDonation,
    getTransactionsByEmailDate,
    getTransactionsByEmailDateByOwner,
    updateTransaction,
    getUserTransactionsByProjectIdEmail,
    listTransactionsByPeerProjectId,
    listTransactionsByBasketId,
    transactionByOrderId,
  }
}

export default transactionRepo
