import { Flex, Box, Heading, Text, Image } from '@chakra-ui/react'
import ImageWrapper from 'components/ImageWrapper'
import { useTranslation } from 'contexts/TranslationContext'
import MonthlyNoticeBanner from '../../MonthlyNoticeBanner'
import TopBar from './TopBar'

const Header = ({
  reArmenia,
  project,
  language,
  isRecurring,
  isOneTimeOrMonthly,
  giftCardInfo,
  giftCardMode,
  GiftCardBanner,
  isContribution,
  isBuyingGiftCard,
  hasPerkIndex,
}) => {
  const { t } = useTranslation()

  const giftCardAmount = giftCardInfo.amountWithCurrency
  const giftCardCurrency = giftCardInfo.currency

  return (
    <Box>
      <TopBar
        project={project}
        isContribution={isContribution}
        isBuyingGiftCard={isBuyingGiftCard}
      />

      {(isRecurring || isOneTimeOrMonthly) && (
        <Box px={5} py={4} background="#fbfbfb">
          <MonthlyNoticeBanner isOneTimeOrMonthly={isOneTimeOrMonthly} />
        </Box>
      )}

      {!isBuyingGiftCard && !hasPerkIndex && (
        <>
          {giftCardMode && (
            <GiftCardBanner
              my={8}
              mx={5}
              minH="60px"
              w="auto"
              fontSize="md"
              giftCardCurrency={giftCardCurrency}
              giftCardAmount={giftCardAmount}
            />
          )}
          <Flex
            py={6}
            px={5}
            bg={'white'}
            justify="space-between"
            alignItems="center"
            gap={1}
          >
            <Box>
              {isContribution ? (
                <Flex w="full" alignItems="center" fontSize="sm" mb={4}>
                  <Image src="/assets/images/rearmenia_avatar.png" />
                  <Text fontWeight={600} ml={4}>
                    {t('reArmeniaCollabPlatform')}
                  </Text>
                </Flex>
              ) : (
                <Heading fontSize="lg" as="h2" fontWeight={700} noOfLines={3}>
                  {project?.title_en
                    ? project[`title_${language}`]
                    : project?.applicationTitle}
                </Heading>
              )}
            </Box>

            {!isContribution && (
              <ImageWrapper
                src={`/thumbnail/small/${project?.cover}`}
                alt="donation"
                objectFit="cover"
                borderRadius="12px"
                minWidth="146px"
                width="146px"
                height="88px"
              />
            )}
          </Flex>

          {!isContribution && giftCardMode && (
            <Flex
              borderColor="border.100"
              borderTopWidth="1px"
              borderBottomWidth="1px"
              direction="column"
              justifyContent="space-between"
              m={4}
              py={4}
            >
              <Flex justifyContent="space-between" pt={4} fontWeight="bold">
                <Text>{t('giftCardAmount')}</Text>
                <Text>{`${giftCardAmount} ${giftCardCurrency}`}</Text>
              </Flex>
              <Flex justifyContent="space-between" pt={4}>
                <Text>{t('donation@yourDonation')}</Text>
                <Text>
                  {(
                    giftCardInfo.amountWithCurrency -
                    reArmenia / giftCardInfo.currencyRate
                  ).toFixed(2)}{' '}
                  {giftCardInfo.currency}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" pt={4}>
                <Text>{t('donation@reArmeniaTip')}</Text>
                <Text>
                  {(reArmenia / giftCardInfo.currencyRate).toFixed(2)}{' '}
                  {giftCardInfo.currency}
                </Text>
              </Flex>
            </Flex>
          )}
        </>
      )}
    </Box>
  )
}

export default Header
