import { useState } from 'react'
import {
  Step as ChakraStep,
  StepIndicator,
  StepStatus,
  Stack,
  StepTitle,
  Progress,
  Text,
} from '@chakra-ui/react'
import { ReactComponent as StepCheckIcon } from 'assets/icons/step-check.svg'
import { useTranslation } from 'contexts/TranslationContext'

export default function Step({ index, step, activeStep, steps }) {
  const { t } = useTranslation()
  const [textWidth, setTextWidth] = useState(0)

  const isActive = index === activeStep
  const isCompleted = index < activeStep
  const isLastStep = index + 1 >= steps.length
  const progressPercent = isCompleted ? 100 : 0

  return (
    <ChakraStep>
      <Stack alignItems="center" spacing={3}>
        <StepIndicator
          sx={{
            '[data-status=complete] &': {
              background: 'blue.400',
              borderColor: 'blue.400',
              color: 'blue.400',
            },
            '[data-status=active] &': {
              background: 'white',
              borderColor: 'blue.400',
              color: 'blue.400',
            },
            '[data-status=incomplete] &': {
              background: 'white',
              borderColor: 'gray.1100',
            },
          }}
        >
          <StepStatus
            complete={<StepCheckIcon />}
            incomplete={'0' + (index + 1)}
            active={'0' + (index + 1)}
          />
        </StepIndicator>

        <StepTitle>
          <Text
            ref={(ref) => setTextWidth(ref?.clientWidth)}
            fontWeight={isActive || isCompleted ? 'bold' : 'medium'}
            color={isActive || isCompleted ? 'blue.400' : 'gray.700'}
          >
            {t(step)}
          </Text>
        </StepTitle>
      </Stack>
      {!isLastStep && (
        <Progress
          value={progressPercent}
          position="absolute"
          height="3px"
          width="full"
          left={`${textWidth / 2 + 10}px`}
          top="40px"
          transform="translateY(-21.5px)"
          bg="gray.1100"
          zIndex={-1}
        />
      )}
    </ChakraStep>
  )
}
