import { fetchAuthSession } from 'aws-amplify/auth'
import { useQuery, useMutation } from 'react-query'
import transactionFactory from 'core/factories/transactionUseCase'
import useAppSyncRequest from './useAppSyncRequest'

export const useTransactionFactory = () => {
  const appSyncRequest = useAppSyncRequest()
  const auth = { fetchAuthSession }

  return transactionFactory({ appSyncRequest, auth })
}

export const useGetReArmeniaOneTimeTransactionsByEmailDate = (variables) => {
  const transactionUseCase = useTransactionFactory()

  return useQuery(
    ['transaction-history-oneTime'],
    () =>
      transactionUseCase.getReArmeniaOneTimeTransactionsByEmailDate(variables),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const useCreateTransaction = () => {
  const transactionUseCase = useTransactionFactory()
  return useMutation(transactionUseCase.createTransaction)
}

export const useUpdateTransaction = () => {
  const transactionUseCase = useTransactionFactory()
  return useMutation(transactionUseCase.updateTransaction)
}

export const useGetTransaction = (id) => {
  const transactionUseCase = useTransactionFactory()
  return useQuery(['transactions', id], () =>
    transactionUseCase.getTransaction(id)
  )
}

export const useGetRecurringTransactionByEmailDate = (variables) => {
  const transactionUseCase = useTransactionFactory()

  return useQuery(
    ['recurring-transaction-history'],
    () => transactionUseCase.getTransactionsByEmailDate(variables),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const useGetTransactionByEmailDate = (variables) => {
  const transactionUseCase = useTransactionFactory()

  return useQuery(
    ['transaction-history-by-owner'],
    () => transactionUseCase.getTransactionsByEmailDate(variables),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const useTransactionByOrderId = (orderId) => {
  const transactionUseCase = useTransactionFactory()

  return useQuery(
    ['transaction-by-order-id', orderId],
    () => transactionUseCase.transactionByOrderId(orderId),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const useGetTransactionByEmailDateByOwner = (variables) => {
  const transactionUseCase = useTransactionFactory()

  return useQuery(
    ['transaction-history-by-owner'],
    () => transactionUseCase.getTransactionsByEmailDateByOwner(variables),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const useGetRecurringTransactionByEmailDateByOwner = (variables) => {
  const transactionUseCase = useTransactionFactory()

  return useQuery(
    ['recurring-transaction-history-owner'],
    () => transactionUseCase.getTransactionsByEmailDateByOwner(variables),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const useGetUserInvestments = (variables, cacheKey) => {
  const transactionUseCase = useTransactionFactory()

  return useQuery(
    [cacheKey],
    () => transactionUseCase.getUserInvestments(variables),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const useListTransaction = () => {
  const transactionUseCase = useTransactionFactory()

  const {
    mutate: listTransactions,
    isLoading,
    error,
    data,
  } = useMutation(transactionUseCase.listTransactions)

  return {
    listTransactions,
    isLoading,
    error,
    data,
  }
}

export const useListTransactionsByBasketId = (variables, options) => {
  const transactionUseCase = useTransactionFactory()

  return useQuery(
    ['transactions-by-basket-id', variables],
    () => transactionUseCase.listTransactionsByBasketId(variables),
    options
  )
}

export const useListTransactionsByDate = (variables, options) => {
  const transactionUseCase = useTransactionFactory()

  return useQuery(
    ['transactions-date', variables],
    () => transactionUseCase.listTransactionsByDate(variables),
    options
  )
}

export const useListTransactionsByGiftCardCode = () => {
  const transactionUseCase = useTransactionFactory()
  return useMutation(transactionUseCase.listTransactionsByGiftCardCode)
}

export const useListTransactionsByPeerProjectId = (variables, options) => {
  const transactionUseCase = useTransactionFactory()

  return useQuery(
    ['transactions-by-peer-project-id', variables],
    () => transactionUseCase.listTransactionsByPeerProjectId(variables),
    options
  )
}

export const useListTransactionsByTopDonation = (variables) => {
  const transactionUseCase = useTransactionFactory()
  return useQuery(['transactions-top', variables], () =>
    transactionUseCase.listTransactionsByTopDonation(variables)
  )
}
