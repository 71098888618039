import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Flex, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUnsubscribeDonationSubscription } from 'hooks'
import { useNavigation } from 'pages'
import useCustomToast from 'hooks/useCustomToast'

export default function UnsubscribeDonation() {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const { navigationReplace } = useNavigation()
  const { subscriptionId } = useParams()

  const { unsubscribeDonationSubscription } =
    useUnsubscribeDonationSubscription()

  useEffect(() => {
    unsubscribeDonationSubscription(subscriptionId, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: t('unsubscribedSuccessfully'),
          isClosable: true,
        })
        navigationReplace('/')
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('unsubscribeError'),
          isClosable: true,
        })
        navigationReplace('/')
      },
    })
  }, [])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}
