import { Box, Flex, Radio, Text } from '@chakra-ui/react'

const ChooseCardRegion = ({
  isSelected,
  onSelect,
  label,
  description,
  children,
  isInvalid,
}) => {
  const onSelectClick = (e) => {
    e.stopPropagation()
    onSelect()
  }

  return (
    <Flex flexDirection="column" gap="8px">
      <Text color="gray.800" fontSize="12px">
        {description}
      </Text>

      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems="flex-start"
        borderRadius={{ base: '12px', sm: '8px' }}
        borderWidth="1px"
        borderColor={
          isInvalid ? 'red.500' : isSelected ? 'gray.700' : 'border.100'
        }
        background="white"
        p={isSelected ? '10px 16px 16px 16px' : '10px 16px'}
        cursor={isSelected ? 'default' : 'pointer'}
        onClick={onSelectClick}
      >
        <Flex alignItems="center" height="28px" paddingX="4px">
          <Flex width="19px">
            <Radio variant="secondary" value="1" isChecked={isSelected} />
          </Flex>

          <Text ml={4} color="black.100" fontSize="md">
            {label}
          </Text>
        </Flex>

        {isSelected && <Box width="100%">{children}</Box>}
      </Flex>
    </Flex>
  )
}

export default ChooseCardRegion
