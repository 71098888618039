import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { useNavigation } from 'pages'
import { useCustomToast, useQueryParams, useUpdateUser, useUser } from 'hooks'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useUpdateContentItem } from 'hooks'

export default function ExpertApplicationLoading() {
  const { t } = useTranslation()
  const { id } = useParams()
  const params = useQueryParams()
  const type = params.get('type')
  const toast = useCustomToast()
  const { navigationPush } = useNavigation()
  const { user } = useUser()
  const { mutate: updateExpert } = useUpdateContentItem()
  const { updateUser } = useUpdateUser()

  useEffect(() => {
    if (!user) {
      return
    }

    const updatedStatus = type === 'approve' ? 'approved' : 'rejected'
    const userId = user.id

    updateExpert(
      { id, expert_status: updatedStatus },
      {
        onSuccess() {
          if (userId) {
            updateUser(
              {
                cognitoId: user.cognitoId,
                id: userId,
                isSeenExpertProfileNotification: false,
                isSeenExpertProfileModal: false,
              },
              {
                onSuccess(data) {
                  console.log('success', data)
                  toast({
                    title: t(`${updatedStatus} successfully`),
                    status: 'success',
                  })
                  navigationPush('/')
                },
                onError(err) {
                  console.log('err', err)
                  toast({ title: `something went wrong`, status: 'error' })
                  navigationPush('/')
                },
              }
            )
          }
        },
        onError(err) {
          console.log('err', err)
          toast({ title: `something went wrong`, status: 'error' })
        },
      }
    )
  }, [user])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}
