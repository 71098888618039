import { Box, Button, Flex, Stack, Text, useToast } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useForm } from 'react-hook-form'
import { useUpdateDonationSubscription, useUpdateUser, useUser } from 'hooks'
import AmountCard from './AmountCard'
import { useEffect, useMemo } from 'react'

const calculateAmountToUpdateInUserAciveBasketSubscription = (
  oldBasket,
  newBasket
) => {
  const amount = newBasket.reduce((acc, basketItem) => {
    const oldBasketItem = oldBasket.find(
      (item) => item.projectId === basketItem.projectId
    )

    return acc + Number(basketItem.amount) - Number(oldBasketItem.amount)
  }, 0)

  return amount
}

export default function Amount({ subscription, refetch }) {
  const { t } = useTranslation()
  const toast = useToast()
  const { user, getUser } = useUser()
  const { updateUser } = useUpdateUser()
  const { updateDonationSubscription, isLoading } =
    useUpdateDonationSubscription()
  const basket = useMemo(
    () => subscription?.basket?.filter((item) => !item.unsubscribedDate),
    [subscription]
  )
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { basket: basket },
  })

  const onSubmit = (values) => {
    const updateDonationSubscriptionInput = {
      id: subscription.id,
      basket: subscription?.basket.map((basketItem) => {
        delete basketItem.__typename
        return {
          ...basketItem,
          amount:
            values.basket.find(
              (item) => item.projectId === basketItem.projectId
            )?.amount || basketItem.amount,
        }
      }),
      donationToProject: values.basket.reduce((acc, basketItem) => {
        return acc + Number(basketItem.amount)
      }, 0),
    }

    const amountToUpdateInUserAciveBasketSubscription =
      calculateAmountToUpdateInUserAciveBasketSubscription(
        basket,
        values.basket
      )

    const updateUserInput = {
      id: user.id,
      investmentBasketMonthlyActive:
        user.investmentBasketMonthlyActive +
        amountToUpdateInUserAciveBasketSubscription,
    }

    const onUpdateDonationSubscriptionSuccess = () => {
      refetch()
      toast({
        title: t('profile@updatedSuccessfully'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    }
    const onUpdateUserSuccess = () => getUser()

    updateDonationSubscription(updateDonationSubscriptionInput, {
      onSuccess: onUpdateDonationSubscriptionSuccess,
    })
    updateUser(updateUserInput, { onSuccess: onUpdateUserSuccess })
  }

  useEffect(() => {
    reset({ basket })
  }, [basket])

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      <Box
        maxH={{ base: 'auto', md: '420px' }}
        overflowY="auto"
        pb={{ base: '100px', md: '4' }}
      >
        <Text mb={2} fontSize="lg" fontWeight="600" color="black.100">
          {t('Amount')}
        </Text>
        <Text mb={5} fontSize="md" fontWeight="400" color="gray.700">
          {t('You can change the amount of your monthly donation here.')}
        </Text>
        <Stack spacing={4}>
          {basket?.map((basketItem, index) => (
            <AmountCard
              key={basketItem.projectId}
              basketItem={basketItem}
              register={register}
              errors={errors}
              index={index}
            />
          ))}
        </Stack>
      </Box>
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="center"
        pt={2}
        backgroundColor="white"
        position={{ base: 'fixed', md: 'initial' }}
        p={{ base: 4, md: 0 }}
        left={{ base: 0, md: 'initial' }}
        bottom={{ base: 0, md: 'initial' }}
        borderWidth={{ base: 1, md: 0 }}
        borderColor="gray.600"
      >
        <Button
          type="submit"
          variant="blue"
          w="100%"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          {t('Save')}
        </Button>
      </Flex>
    </Stack>
  )
}
