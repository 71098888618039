import { Flex, useRadioGroup } from '@chakra-ui/react'
import RadioOption from './RadioOption'

const SegmentedControl = ({
  name,
  options,
  defaultValue,
  onChange,
  renderItem,
  children,
  itemHeight,
  inWhiteBackground,
  borderRadius = 8,
  ...rest
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  })

  return (
    <Flex
      p={{ base: 2, sm: '8px' }}
      gap={{ base: '8px', sm: '10px' }}
      flexDirection="column"
      maxW="full"
      borderRadius={borderRadius}
      overflow="hidden"
      background={inWhiteBackground ? '#EAECEF' : 'white'}
      shadow={inWhiteBackground ? '' : '0px 2px 4px 0px #00000014'}
      {...getRootProps(rest)}
    >
      <Flex width="100%" gap="8px">
        {Object.entries(options).map(([key, value]) => (
          <RadioOption
            key={key}
            {...getRadioProps({ value: key })}
            itemHeight={itemHeight}
            inWhiteBackground={inWhiteBackground}
          >
            <Flex alignItems="center" direction="column" borderRadius="12px">
              {typeof renderItem !== 'function'
                ? value
                : renderItem({ key, value })}
            </Flex>
          </RadioOption>
        ))}
      </Flex>

      {children}
    </Flex>
  )
}

export default SegmentedControl
