import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/hy-am'
import { I18n } from 'aws-amplify/utils'
import { useHistory, useLocation, Redirect } from 'react-router-dom'
import { configure as configureI18n } from '../translations'
import { useLocalStorage } from '../hooks'
import { Box } from '@chakra-ui/react'

import enTranslations from '../translations/messages/en-us.json'
import ruTranslations from '../translations/messages/ru-ru.json'
import hyTranslations from '../translations/messages/hy-am.json'

configureI18n()

const LOCAL_STORAGE_KEY = `${process.env.REACT_APP_ENV}_lang`

export const LANG_EN_SHORT = 'en'
export const LANG_EN_LONG = 'en-US'
export const LANG_HY_SHORT = 'hy'
export const LANG_HY_LONG = 'hy-AM'
export const LANG_RU_SHORT = 'ru'
export const LANG_RU_LONG = 'ru-RU'

const contents = {
  [LANG_EN_SHORT]: enTranslations,
  [LANG_HY_SHORT]: hyTranslations,
  [LANG_RU_SHORT]: ruTranslations,
}

export const LANGUAGES_SHORT = [LANG_EN_SHORT, LANG_HY_SHORT, LANG_RU_SHORT]
export const LANGUAGES_LONG = [LANG_EN_LONG, LANG_HY_LONG, LANG_RU_LONG]

export const isValidShortLang = (lang) => LANGUAGES_SHORT.includes(lang)
export const isValidLongLang = (lang) => LANGUAGES_SHORT.includes(lang)

export const MOMENT_LANGUAGES = {
  [LANG_EN_SHORT]: 'en',
  [LANG_HY_SHORT]: 'hy-am',
  [LANG_RU_SHORT]: 'ru',
}

export const LANGUAGES = {
  [LANG_EN_SHORT]: {
    code: LANG_EN_LONG,
    currency: 'USD',
    currencySymbol: '$',
  },
  [LANG_HY_SHORT]: {
    code: LANG_HY_LONG,
    currency: 'AMD',
    currencySymbol: '$',
  },
  [LANG_RU_SHORT]: {
    code: LANG_RU_LONG,
    currency: 'RUB',
    currencySymbol: '₽',
  },
}

export const DEFAULT_LANG = [LANG_EN_SHORT, LANG_EN_LONG]

const getLangFromURL = (pathname, localStorageLang) => {
  const lang = pathname.split('/')[1]
  if (!isValidShortLang(lang)) {
    if (!localStorageLang) {
      return DEFAULT_LANG
    }
    return [localStorageLang, LANGUAGES[localStorageLang].code]
  }
  if (localStorageLang && localStorageLang === lang) {
    return [localStorageLang, LANGUAGES[localStorageLang].code]
  } else {
    return [lang, LANGUAGES[lang].code]
  }
}

export const TranslationContext = createContext({
  language: '',
  setLanguage: () => {},
  t: () => {},
  tt: () => {},
})

export const useTranslation = () => useContext(TranslationContext)

export const validateStringLang = (text, lang) => {
  text = text.replaceAll('&amp;', '&').replaceAll('&nbsp;', ' ')
  const pattern = validateStringLangPatterns(lang)
  if (pattern) {
    return pattern.test(text)
  }
}

export const validateStringLangPatterns = (lang) => {
  const cyrillicPattern =
    /^[\p{Script=Cyrl}\s\d.,/#!$%'’՝<>?|+^&*;:{}=\-_`~()[\]\\|/՛«»՞"@՜]*$/u
  const armenianPattern =
    /^[\u0531-\u0556\u0561-\u0587\s\d.,/#!$%'’՝<>?|+^&*;:{}=\-_`~()[\]\\|/՛«»՞"@՜]*$/
  const latinPattern =
    /^[a-zA-Z\s\d.,/#!$%'’՝<>?|+^&*;:{}=\-_`~()[\]\\|/՛«»՞"@՜]*$/

  switch (lang) {
    case LANG_RU_SHORT:
      return cyrillicPattern
    case LANG_HY_SHORT:
      return armenianPattern
    case LANG_EN_SHORT:
      return latinPattern
    default:
      return null
  }
}

export const TranslationProvider = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const { put, get } = useLocalStorage()

  const lang = getLangFromURL(location.pathname, get(LOCAL_STORAGE_KEY))
  const [localeShort, localeLong] = lang || DEFAULT_LANG

  const [appLanguage, setAppLanguage] = useState(lang)
  const language = appLanguage[0] || DEFAULT_LANG[0]

  const translate = useCallback((...args) => I18n.get(...args), [])
  const translateTo = (key, languageArg) => contents[languageArg]?.[key] || key

  const setLanguage = useCallback(
    (newLanguage) => {
      if (!isValidShortLang(newLanguage)) {
        return
      }

      const I18Value = LANGUAGES[newLanguage].code
      const redirectURL =
        window.location.pathname.replace(language, newLanguage) +
        window.location.search

      I18n.setLanguage(I18Value)
      moment.locale(MOMENT_LANGUAGES[newLanguage])
      setAppLanguage([newLanguage, I18Value])
      history.push(redirectURL)
      put(LOCAL_STORAGE_KEY, newLanguage)
    },
    [language, setAppLanguage]
  )

  useEffect(() => {
    if (!localeShort) return

    I18n.setLanguage(localeLong)
    if (!get(LOCAL_STORAGE_KEY)) {
      put(LOCAL_STORAGE_KEY, localeShort)
    } else if (get(LOCAL_STORAGE_KEY) !== localeShort) {
      put(LOCAL_STORAGE_KEY, localeShort)
    }
  }, [localeShort, localeLong])

  useEffect(() => {
    const localStorageLang = get(LOCAL_STORAGE_KEY)
    if (isValidShortLang(localStorageLang)) {
      const i18Key = LANGUAGES[localStorageLang].code

      setAppLanguage([localStorageLang, i18Key])
      moment.locale(MOMENT_LANGUAGES[localStorageLang])
    }
  }, [localStorage])

  useEffect(() => {
    if (!LANGUAGES_SHORT.includes(location.pathname.split('/')[1].trim())) {
      const localStorageLang = get(LOCAL_STORAGE_KEY)
      history.push(
        `/${localStorageLang}${
          location.pathname === '/'
            ? window.location.search
            : location.pathname + window.location.search
        }`
      )
    }
  }, [location])

  if (location.pathname === '/') {
    return <Redirect to={`/${localeShort}${window.location.search}`} />
  }

  return (
    <TranslationContext.Provider
      value={{
        setLanguage,
        language,
        t: translate,
        tt: translateTo,
      }}
    >
      <Box>{children}</Box>
    </TranslationContext.Provider>
  )
}
