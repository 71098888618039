import {
  connectExpert as connectExpertMutation,
  updateExpertConnection as updateExpertConnectionMutation,
} from 'graphql/mutations'
import { getExpertInitiatorConnection as getExpertInitiatorConnectionQuery } from 'graphql/queries'

const ExpertService = (appSyncRequest, auth) => {
  const createExpertInitConnection = async (input) => {
    const response = await appSyncRequest({
      query: connectExpertMutation,
      variables: { input },
    })
    return response.data.connectExpert
  }

  const getConnection = async (id) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession?.tokens?.idToken?.toString()

    const response = await appSyncRequest({
      query: getExpertInitiatorConnectionQuery,
      variables: { id },
      authToken: jwtToken,
      authMode: 'userPool',
    })
    return response.data.getExpertInitiatorConnection
  }

  const updateConnection = async (input) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession?.tokens?.idToken?.toString()

    const response = await appSyncRequest({
      query: updateExpertConnectionMutation,
      variables: { input },
      authToken: jwtToken,
      authMode: 'userPool',
    })
    return response.data.updateExpertConnectionMutation
  }

  return {
    createExpertInitConnection,
    getConnection,
    updateConnection,
  }
}

export default ExpertService
