import { post } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useMutation, useQuery } from 'react-query'
import donationSubscriptionFactory from 'core/factories/donationSubscription'
import useAppSyncRequest from './useAppSyncRequest'

export const useDonationSubscriptionFactory = () => {
  const appSyncRequest = useAppSyncRequest()
  const auth = { fetchAuthSession }
  const api = { post }

  return donationSubscriptionFactory({ appSyncRequest, auth, api })
}

export const useDonationSubscriptionsByProjectId = (
  condition,
  withProjectDetails
) => {
  const donationSubscriptionUseCase = useDonationSubscriptionFactory()
  const {
    error,
    refetch,
    isLoading,
    data: donationSubscriptions,
  } = useQuery(
    ['donation_subscriptions_by_project_id', condition],
    async () => {
      return await donationSubscriptionUseCase.donationSubscriptionsByProjectId(
        condition,
        withProjectDetails
      )
    }
  )

  return {
    refetch,
    isLoading,
    donationSubscriptions,
    error,
  }
}

export const useDonationSubscriptionsByUserEmail = (
  condition,
  loadProjectsData = true
) => {
  const donationSubscriptionUseCase = useDonationSubscriptionFactory()
  const {
    error,
    refetch,
    isLoading,
    data: donationSubscriptions,
  } = useQuery(
    ['donation_subscriptions_by_user_email', condition],
    async () => {
      return await donationSubscriptionUseCase.donationSubscriptionsByUserEmail(
        condition,
        loadProjectsData
      )
    }
  )

  return {
    refetch,
    isLoading,
    donationSubscriptions,
    error,
  }
}

export const useGetDonationSubscription = (id, options) => {
  const donationSubscriptionUseCase = useDonationSubscriptionFactory()
  const query = useQuery(
    [`donation_subscription_${id}`],
    async () => {
      const data = await donationSubscriptionUseCase.getDonationSubscription(id)
      return data
    },
    options
  )

  return query
}

export const useReArmeniaRecurringDonationSubscriptions = (
  condition,
  withProjectDetails
) => {
  const donationSubscriptionUseCase = useDonationSubscriptionFactory()
  const {
    error,
    refetch,
    isLoading,
    data: donationSubscriptions,
  } = useQuery(
    ['re_armenia_recurring_donation_subscriptions', condition],
    async () => {
      let response = {}
      response =
        await donationSubscriptionUseCase.donationSubscriptionsByProjectId(
          { ...condition, projectId: 'rearmenia' },
          withProjectDetails
        )
      if (!response.length) {
        response =
          await donationSubscriptionUseCase.donationSubscriptionsByProjectId(
            { ...condition, projectId: '6d530a79-e071-49b6-95ea-51323f1d850c' },
            withProjectDetails
          )
      }
      return response
    }
  )

  return {
    refetch,
    isLoading,
    donationSubscriptions,
    error,
  }
}

export const useUpdateDonationSubscription = () => {
  const {
    mutate: updateDonationSubscription,
    data,
    isLoading,
  } = useMutation(useDonationSubscriptionFactory().updateDonationSubscription)

  return {
    updateDonationSubscription,
    data,
    isLoading,
  }
}

export const useUnsubscribeDonationSubscription = () => {
  const {
    mutate: unsubscribeDonationSubscription,
    data,
    isLoading,
  } = useMutation(
    useDonationSubscriptionFactory().unsubscribeDonationSubscription
  )

  return {
    unsubscribeDonationSubscription,
    data,
    isLoading,
  }
}
