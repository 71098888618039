import { useEffect, useState, memo } from 'react'
import {
  SimpleGrid,
  GridItem,
  Heading,
  Flex,
  Box,
  Spinner,
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import AllProductsWrapper from 'components/AllProductsWrapper'
import ProjectCard from 'components/ProjectCard'
import {
  useProjects,
  useQueryParams,
  calculateProjectDurationLeft,
} from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import MobileCollaborations from './MobileCollaborations'
import NeedCategories from '../NeedCategories'
import { useLocation } from 'react-router-dom'
import { useNavigation } from 'pages'
import { useInView } from 'react-intersection-observer'
import MetaTags from 'components/MetaTags'
import _ from 'lodash'
import {
  useCurrencySettings,
  thousandSeparator,
  convert,
} from 'contexts/CurrencySettingsContext'
import { collaborationsQuery } from 'elastic/queries'
import { useGetNeeds } from 'hooks'
import { useScreenSize } from 'contexts'

const buildFilterKey = (queryParams) => {
  const search = queryParams.get('text')
  return search
    ? `projects_collaborations_${search}`
    : 'projects_collaborations'
}

const getInitiativesFromCache = (queryParams) => {
  try {
    const cacheKey = buildFilterKey(queryParams)
    return JSON.parse(localStorage.getItem(cacheKey))
  } catch (e) {
    console.log(e)
  }
  return []
}

const categoriesFilter =
  (card) =>
  ({ keywords }) => {
    return keywords?.some((keyword) =>
      card.collaborations
        .map(({ needs_en }) => needs_en)
        .some((need) => need === keyword)
    )
  }

const Initiatives = ({
  items,
  cachedItems,
  isLoading: isInitialLoading,
  refetch,
  total,
  updateFilters,
}) => {
  const queryClient = useQueryClient()
  const queryParams = useQueryParams()
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const [isLoading, toggleLoading] = useState(isInitialLoading)
  const [chosenCategories, setChosenCategories] = useState([])
  const [allChosenKeywords, setAllChosenKeywords] = useState([])
  const [projects, setProjects] = useState()
  const [filters, setFilters] = useState({
    category: queryParams.get('category'),
    text: queryParams.get('text'),
    duration: queryParams.get('duration'),
  })
  const { navigationPush } = useNavigation()
  const { pathname } = useLocation()
  const { ref: inViewRef, inView } = useInView()
  const { data: needCategories } = useGetNeeds()
  const chooseCategory = (category) => {
    if (chosenCategories.includes(category)) {
      setChosenCategories(chosenCategories.filter((e) => e !== category))
      setAllChosenKeywords(
        allChosenKeywords.filter((e) => !category.keywords.includes(e))
      )
    } else {
      setAllChosenKeywords([...allChosenKeywords, ...category.keywords])
      setChosenCategories([...chosenCategories, category])
    }
  }
  const onLoadMore = () => {
    toggleLoading(true)
    refetch()
  }
  const filterByCategory = ({ collaborations }) => {
    if (collaborations && collaborations.length) {
      return collaborations.some(
        ({ needs_en }) =>
          allChosenKeywords?.includes(needs_en) || !allChosenKeywords.length
      )
    }
    return false
  }

  useEffect(() => {
    if (total === projects?.length) {
      return
    }
    if (inView) {
      onLoadMore()
    }
  }, [inView])

  useEffect(() => {
    setProjects((projects) => {
      if (!projects) {
        return items
      }
      return _.uniqBy([...projects, ...items], 'id')
    })

    toggleLoading(false)
  }, [items])

  useEffect(() => {
    if (!projects || projects?.length === 0) return
    projects?.map((item) =>
      queryClient.setQueryData(['project_slug', item.slug], item)
    )
    let queryParams = ''
    for (const [key, value] of Object.entries(filters)) {
      if (value !== null && value !== '') {
        queryParams += `&${key}=${value}`
      }
    }
    window.history.replaceState(
      {},
      '',
      window.location.pathname + queryParams.replace('&', '?')
    )
  }, [projects, filters])

  useEffect(() => {
    if (pathname.includes('initiative')) {
      navigationPush('/collaborations')
    }
  }, [])

  /* filters updated */ useEffect(() => {
    if (!projects) {
      return
    }
    if (filters.category !== null) {
      toggleLoading(true)
      setProjects([])
      updateFilters(filters.category)
    }
  }, [filters])

  useEffect(() => {
    /* set projects from cache */
    const cachedProjects = getInitiativesFromCache(queryParams)
    let parsedProjects = []
    try {
      parsedProjects = JSON.parse(cachedProjects)
    } catch (e) {
      console.log(e)
    }
    if (!parsedProjects || parsedProjects.length === 0) {
      toggleLoading(true)
      return
    }
    setProjects(parsedProjects)
  }, [])

  const { isMobile } = useScreenSize()
  if (isMobile) {
    return (
      <>
        <MetaTags
          title={t('meta@collabs@title')}
          description={t('meta@collabs@description')}
        />
        <MobileCollaborations
          total={total}
          isInitialLoading={isInitialLoading}
          isLoading={isLoading}
          onLoadMore={onLoadMore}
          cachedInitiatives={cachedItems}
          initiatives={projects}
          NeedCategories={NeedCategories}
          language={language}
          needCategories={needCategories}
          chooseCategory={chooseCategory}
          chosenCategories={chosenCategories}
          filterByCategory={filterByCategory}
          t={t}
        />
      </>
    )
  }

  return (
    <>
      <MetaTags
        title={t('meta@collabs@title')}
        description={t('meta@collabs@description')}
      />
      <AllProductsWrapper
        type="initiatives"
        filters={filters}
        setFilters={setFilters}
        noBorder
      >
        <NeedCategories
          lang={language}
          categories={needCategories}
          chooseCategory={chooseCategory}
          chosenCategories={chosenCategories}
          t={t}
        />
        {/*
        1. THIS LOADING IS BEING SHOW ONLY ONE TIME, BY THE FIRST
        2. IF REQUEST MADE BUT WE HAVE CACHED PROJECTS WE HIDE LOADING
      */}
        {isLoading && (!projects || projects?.length === 0) ? (
          <Flex minH="520px" w="full" align="center" justifyContent="center">
            <Spinner color="blue.300" />
          </Flex>
        ) : (
          <Box minH="720px" pb={10} position="relative">
            <SimpleGrid
              position="relative"
              pt={8}
              mb={20}
              w="full"
              columns={{
                base: 1,
                md: 2,
                lg: 3,
                xl: 4,
              }}
              spacing="30px"
            >
              {projects?.length > 0 ? (
                <>
                  {_.uniqBy(projects, 'id')
                    .filter(({ status }) => status === 'live')
                    .filter(filterByCategory)
                    .map((card, index) => (
                      <GridItem
                        key={`card-${card.id}-index-${index}`}
                        colSpan={1}
                      >
                        <ProjectCard
                          card={card}
                          needCategories={needCategories?.filter(
                            categoriesFilter(card)
                          )}
                          inNeedOfText={t('inNeedOf')}
                          raisedMoney={thousandSeparator(
                            convert({
                              amount: card.amount,
                              currencyRate:
                                settings?.currency[currency.current],
                            }),
                            currency.current
                          )}
                          thousandSeparatorText={thousandSeparator(
                            currency.current === 'AMD'
                              ? card.amount + Number(card.updateAmount)
                              : card.amount /
                                  Number(settings?.currency[currency.current]) +
                                  Number(card.updateAmount),
                            currency.current
                          )}
                          edit={false}
                          type="collaborations"
                          calculatedProjectDurationLeft={calculateProjectDurationLeft(
                            {
                              t,
                              campaignImplementorPeriod_en:
                                card.campaignImplementorPeriod_en,
                              fundraisingType: card.fundraisingType,
                            }
                          )}
                          language={language}
                          currency={currency}
                          settings={settings}
                          changeDefaultCurrency={changeDefaultCurrency}
                          navigationPush={navigationPush}
                          cardStatusText={t(`status@${card.status}`)}
                          cardCategoryText={t(`category@${card.category}`)}
                          editText={t('edit')}
                          unsubscribeText={t('unsubscribe')}
                          monthlyGoalText={t('monthlyGoal')}
                          raisedText={t('raised')}
                          collaborationProposalsText={t(
                            card?.collaboratorsTotal === 1
                              ? 'collaboration proposal'
                              : 'collaboration proposals'
                          )}
                          collaborationProposalsTextRuEdit={t(
                            'collaboration proposals'
                          )
                            .split('...')[1]
                            .replace('запросов', 'запроса')}
                        />
                      </GridItem>
                    ))}
                  {!chosenCategories.length &&
                    _.uniqBy(projects, 'id')
                      .filter(({ status }) => status === 'ended')
                      .sort(
                        (a, b) => b.collaboratorsTotal - a.collaboratorsTotal
                      )
                      .map((card, index) => (
                        <GridItem
                          key={`card-${card.id}-index-${index}`}
                          colSpan={1}
                        >
                          <ProjectCard
                            card={card}
                            raisedMoney={thousandSeparator(
                              convert({
                                amount: card.amount,
                                currencyRate:
                                  settings?.currency[currency.current],
                              }),
                              currency.current
                            )}
                            needCategories={needCategories?.filter(
                              categoriesFilter(card)
                            )}
                            inNeedOfText={t('inNeedOf')}
                            thousandSeparatorText={thousandSeparator(
                              currency.current === 'AMD'
                                ? card.amount + Number(card.updateAmount)
                                : card.amount /
                                    Number(
                                      settings?.currency[currency.current]
                                    ) +
                                    Number(card.updateAmount),
                              currency.current
                            )}
                            edit={false}
                            type="collaborations"
                            calculatedProjectDurationLeft={calculateProjectDurationLeft(
                              {
                                t,
                                campaignImplementorPeriod_en:
                                  card.campaignImplementorPeriod_en,
                                fundraisingType: card.fundraisingType,
                              }
                            )}
                            language={language}
                            currency={currency}
                            settings={settings}
                            changeDefaultCurrency={changeDefaultCurrency}
                            navigationPush={navigationPush}
                            cardStatusText={t(`status@${card.status}`)}
                            cardCategoryText={t(`category@${card.category}`)}
                            editText={t('edit')}
                            unsubscribeText={t('unsubscribe')}
                            monthlyGoalText={t('monthlyGoal')}
                            raisedText={t('raised')}
                            collaborationProposalsText={t(
                              card?.collaboratorsTotal === 1
                                ? 'collaboration proposal'
                                : 'collaboration proposals'
                            )}
                            collaborationProposalsTextRuEdit={t(
                              'collaboration proposals'
                            )
                              .split('...')[1]
                              .replace('запросов', 'запроса')}
                          />
                        </GridItem>
                      ))}
                  {/* CLOSED PROJECTS */}
                  {!chosenCategories.length &&
                    _.uniqBy(projects, 'id')
                      .filter(({ status }) => status === 'closed')
                      .map((card, index) => (
                        <GridItem
                          key={`card-${card.id}-index-${index}`}
                          colSpan={1}
                        >
                          <ProjectCard
                            card={card}
                            raisedMoney={thousandSeparator(
                              convert({
                                amount: card.amount,
                                currencyRate:
                                  settings?.currency[currency.current],
                              }),
                              currency.current
                            )}
                            thousandSeparatorText={thousandSeparator(
                              currency.current === 'AMD'
                                ? card.amount + Number(card.updateAmount)
                                : card.amount /
                                    Number(
                                      settings?.currency[currency.current]
                                    ) +
                                    Number(card.updateAmount),
                              currency.current
                            )}
                            edit={false}
                            type="collaborations"
                            needCategories={needCategories?.filter(
                              categoriesFilter(card)
                            )}
                            inNeedOfText={t('inNeedOf')}
                            calculatedProjectDurationLeft={calculateProjectDurationLeft(
                              {
                                t,
                                campaignImplementorPeriod_en:
                                  card.campaignImplementorPeriod_en,
                                fundraisingType: card.fundraisingType,
                              }
                            )}
                            language={language}
                            currency={currency}
                            settings={settings}
                            changeDefaultCurrency={changeDefaultCurrency}
                            navigationPush={navigationPush}
                            cardStatusText={t(`status@${card.status}`)}
                            cardCategoryText={t(`category@${card.category}`)}
                            editText={t('edit')}
                            unsubscribeText={t('unsubscribe')}
                            monthlyGoalText={t('monthlyGoal')}
                            raisedText={t('raised')}
                            collaborationProposalsText={t(
                              card?.collaboratorsTotal === 1
                                ? 'collaboration proposal'
                                : 'collaboration proposals'
                            )}
                            collaborationProposalsTextRuEdit={t(
                              'collaboration proposals'
                            )
                              .split('...')[1]
                              .replace('запросов', 'запроса')}
                          />
                        </GridItem>
                      ))}
                  {total > projects?.length && (
                    <Box
                      position="absolute"
                      bottom="30%"
                      zIndex={1000}
                      ref={inViewRef}
                      w="full"
                      h={20}
                      visibility="hidden"
                    />
                  )}
                </>
              ) : (
                <Flex
                  top="0"
                  left="0"
                  position="absolute"
                  alignItems="center"
                  justifyContent="center"
                  h="full"
                  w="full"
                >
                  <Heading as="h2" fontSize="4xl" color="blue.400">
                    {t('noResults')}
                  </Heading>
                </Flex>
              )}
            </SimpleGrid>

            {total > projects?.length && (
              <Flex w="full" align="center" justify="center">
                <Spinner color="blue.400" />
              </Flex>
            )}
          </Box>
        )}
      </AllProductsWrapper>
    </>
  )
}

const withProjects =
  (Component) =>
  ({ ...props }) => {
    const size = 12
    const queryParams = useQueryParams()
    const { language } = useTranslation()

    const search = queryParams.get('text')
    const collaborationsQueryObject = collaborationsQuery({
      category: queryParams.get('category'),
      search,
      language,
    })

    const cacheKey = buildFilterKey(queryParams)
    const { projects, isLoading, setFilters } = useProjects(
      collaborationsQueryObject,
      true,
      search ? cacheKey : 'collaborations',
      cacheKey
    )

    const refetch = () => {
      setFilters((filters) => {
        return {
          ...filters,
          from: (filters.from || 0) + size,
        }
      })
    }
    const updateFilters = (category) => {
      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          ...collaborationsQuery({ category, search, language }),
          from: 0,
        }
      })
    }
    return (
      <Component
        {...props}
        updateFilters={updateFilters}
        cachedItems={projects?.cachedItems}
        items={projects?.items || []}
        total={projects?.total?.value}
        isLoading={isLoading}
        refetch={refetch}
      />
    )
  }

const areEqual = (prevProps, nextProps) => {
  if (!nextProps.isLoading) {
    // RE-RENDER
    return false
  }
  // PREVENT RENDER
  return true
}

export default withProjects(memo(Initiatives, areEqual))
