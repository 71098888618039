import { Stack } from '@chakra-ui/react'
import StatusCard from './StatusCard'
import UnsubscribeFromAllButton from './UnsubscribeFromAllButton'

export default function Status({ subscription, refetch }) {
  return (
    <>
      <Stack spacing={6}>
        {subscription?.basket?.map((basketItem) => (
          <StatusCard
            key={basketItem.projectId}
            subscription={subscription}
            basketItem={basketItem}
            refetch={refetch}
          />
        ))}
      </Stack>
      <UnsubscribeFromAllButton subscription={subscription} refetch={refetch} />
    </>
  )
}
