import { Box } from '@chakra-ui/react'
import Header from './Header'
import { useScreenSize } from 'contexts'
import AmountInfo from './AmountInfo'
import GiftCardAmount from './GiftCardAmount'

export default function Web({
  donation,
  isGiftCardDisabled,
  isContribution,
  project,
  giftCardMode,
  isBuyingGiftCard,
  isRecurring,
}) {
  const { isMobile } = useScreenSize()

  return (
    <Box
      position="sticky"
      top="20px"
      maxW="430px"
      width="full"
      my={{ base: 6, sm: 0 }}
    >
      <Box
        mx="auto"
        borderWidth={{
          base: isContribution ? 0 : '1px',
          sm: '1px',
        }}
        borderColor="border.100"
        borderRadius="16px"
        py={{ base: 4, sm: 0 }}
      >
        {(isBuyingGiftCard || !isMobile) && (
          <>
            <Header
              project={project}
              isContribution={isContribution}
              isBuyingGiftCard={isBuyingGiftCard}
            />

            <Box background="border.100" height="1px" />
          </>
        )}

        {!giftCardMode && (
          <AmountInfo
            donation={donation}
            isBuyingGiftCard={isBuyingGiftCard}
            isContribution={isContribution}
            isGiftCardDisabled={isGiftCardDisabled}
            isRecurring={isRecurring}
          />
        )}

        {giftCardMode && <GiftCardAmount donation={donation} />}
      </Box>
    </Box>
  )
}
