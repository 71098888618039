/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onCreateUser(filter: $filter, id: $id) {
      id
      cognitoId
      cosmoId
      customerId
      expertId
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      currency
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      language
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
        __typename
      }
      stripeAttachedCards {
        id
        type
        last4
        __typename
      }
      isSeenExpertPopup
      unreadNotifications
      isReArmeniaInvestor
      isInitiator
      isFundraiserCreator
      isGoodraiserCreator
      investmentOneTimeTotal
      investmentRecurringTotal
      investmentBasketTotal
      investmentBasketMonthlyActive
      notificationSettings
      totalOneTimeDonation
      totalRecurringDonation
      selectedCategories
      selectedLocations
      charityBasket {
        projectId
        amount
        projectCover
        projectTitle_en
        projectTitle_ru
        projectTitle_hy
        currency
        currencyRate
        lastChargedAmount
        unsubscribedDate
        __typename
      }
      isSeenExpertProfileNotification
      isSeenExpertProfileModal
      lastSignedInAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onUpdateUser(filter: $filter, id: $id) {
      id
      cognitoId
      cosmoId
      customerId
      expertId
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      currency
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      language
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
        __typename
      }
      stripeAttachedCards {
        id
        type
        last4
        __typename
      }
      isSeenExpertPopup
      unreadNotifications
      isReArmeniaInvestor
      isInitiator
      isFundraiserCreator
      isGoodraiserCreator
      investmentOneTimeTotal
      investmentRecurringTotal
      investmentBasketTotal
      investmentBasketMonthlyActive
      notificationSettings
      totalOneTimeDonation
      totalRecurringDonation
      selectedCategories
      selectedLocations
      charityBasket {
        projectId
        amount
        projectCover
        projectTitle_en
        projectTitle_ru
        projectTitle_hy
        currency
        currencyRate
        lastChargedAmount
        unsubscribedDate
        __typename
      }
      isSeenExpertProfileNotification
      isSeenExpertProfileModal
      lastSignedInAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onDeleteUser(filter: $filter, id: $id) {
      id
      cognitoId
      cosmoId
      customerId
      expertId
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      currency
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      language
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
        __typename
      }
      stripeAttachedCards {
        id
        type
        last4
        __typename
      }
      isSeenExpertPopup
      unreadNotifications
      isReArmeniaInvestor
      isInitiator
      isFundraiserCreator
      isGoodraiserCreator
      investmentOneTimeTotal
      investmentRecurringTotal
      investmentBasketTotal
      investmentBasketMonthlyActive
      notificationSettings
      totalOneTimeDonation
      totalRecurringDonation
      selectedCategories
      selectedLocations
      charityBasket {
        projectId
        amount
        projectCover
        projectTitle_en
        projectTitle_ru
        projectTitle_hy
        currency
        currencyRate
        lastChargedAmount
        unsubscribedDate
        __typename
      }
      isSeenExpertProfileNotification
      isSeenExpertProfileModal
      lastSignedInAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onCreateNotification(filter: $filter) {
      body
      userId
      language
      isRead
      image
      link
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onUpdateNotification(filter: $filter) {
      body
      userId
      language
      isRead
      image
      link
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onDeleteNotification(filter: $filter) {
      body
      userId
      language
      isRead
      image
      link
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const onCreateGiftCard = /* GraphQL */ `
  subscription OnCreateGiftCard($filter: ModelSubscriptionGiftCardFilterInput) {
    onCreateGiftCard(filter: $filter) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      image
      currency
      currencyRate
      paymentStatus
      language
      fromEmail
      used
      usedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGiftCard = /* GraphQL */ `
  subscription OnUpdateGiftCard($filter: ModelSubscriptionGiftCardFilterInput) {
    onUpdateGiftCard(filter: $filter) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      image
      currency
      currencyRate
      paymentStatus
      language
      fromEmail
      used
      usedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGiftCard = /* GraphQL */ `
  subscription OnDeleteGiftCard($filter: ModelSubscriptionGiftCardFilterInput) {
    onDeleteGiftCard(filter: $filter) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      image
      currency
      currencyRate
      paymentStatus
      language
      fromEmail
      used
      usedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLambdaInvocation = /* GraphQL */ `
  subscription OnCreateLambdaInvocation(
    $filter: ModelSubscriptionLambdaInvocationFilterInput
  ) {
    onCreateLambdaInvocation(filter: $filter) {
      id
      functionName
      awsRequestId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLambdaInvocation = /* GraphQL */ `
  subscription OnUpdateLambdaInvocation(
    $filter: ModelSubscriptionLambdaInvocationFilterInput
  ) {
    onUpdateLambdaInvocation(filter: $filter) {
      id
      functionName
      awsRequestId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLambdaInvocation = /* GraphQL */ `
  subscription OnDeleteLambdaInvocation(
    $filter: ModelSubscriptionLambdaInvocationFilterInput
  ) {
    onDeleteLambdaInvocation(filter: $filter) {
      id
      functionName
      awsRequestId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSubscriptionCharge = /* GraphQL */ `
  subscription OnCreateSubscriptionCharge(
    $filter: ModelSubscriptionSubscriptionChargeFilterInput
  ) {
    onCreateSubscriptionCharge(filter: $filter) {
      id
      subscriptionId
      subscriptionIdDate
      isFailedAttempt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSubscriptionCharge = /* GraphQL */ `
  subscription OnUpdateSubscriptionCharge(
    $filter: ModelSubscriptionSubscriptionChargeFilterInput
  ) {
    onUpdateSubscriptionCharge(filter: $filter) {
      id
      subscriptionId
      subscriptionIdDate
      isFailedAttempt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSubscriptionCharge = /* GraphQL */ `
  subscription OnDeleteSubscriptionCharge(
    $filter: ModelSubscriptionSubscriptionChargeFilterInput
  ) {
    onDeleteSubscriptionCharge(filter: $filter) {
      id
      subscriptionId
      subscriptionIdDate
      isFailedAttempt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDonationSubscription = /* GraphQL */ `
  subscription OnCreateDonationSubscription(
    $filter: ModelSubscriptionDonationSubscriptionFilterInput
    $userEmail: String
  ) {
    onCreateDonationSubscription(filter: $filter, userEmail: $userEmail) {
      id
      projectId
      peerProjectId
      bindingId
      userId
      userEmail
      basketId
      projectIds
      basket {
        projectId
        amount
        projectCover
        projectTitle_en
        projectTitle_ru
        projectTitle_hy
        currency
        currencyRate
        lastChargedAmount
        unsubscribedDate
        __typename
      }
      firstName
      image
      lastName
      donationToReArmenia
      projectTitle
      isAnonymous
      isInfoAnonymous
      donationToProject
      lastChargedAmount
      totalDonatedAmount
      unsubscribedDate
      month
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      day
      stripeData
      currency
      language
      paymentMethod
      paypalSubscriptionId
      paypalCustomId
      currencyRate
      nextChargeDate
      lastChargedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDonationSubscription = /* GraphQL */ `
  subscription OnUpdateDonationSubscription(
    $filter: ModelSubscriptionDonationSubscriptionFilterInput
    $userEmail: String
  ) {
    onUpdateDonationSubscription(filter: $filter, userEmail: $userEmail) {
      id
      projectId
      peerProjectId
      bindingId
      userId
      userEmail
      basketId
      projectIds
      basket {
        projectId
        amount
        projectCover
        projectTitle_en
        projectTitle_ru
        projectTitle_hy
        currency
        currencyRate
        lastChargedAmount
        unsubscribedDate
        __typename
      }
      firstName
      image
      lastName
      donationToReArmenia
      projectTitle
      isAnonymous
      isInfoAnonymous
      donationToProject
      lastChargedAmount
      totalDonatedAmount
      unsubscribedDate
      month
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      day
      stripeData
      currency
      language
      paymentMethod
      paypalSubscriptionId
      paypalCustomId
      currencyRate
      nextChargeDate
      lastChargedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDonationSubscription = /* GraphQL */ `
  subscription OnDeleteDonationSubscription(
    $filter: ModelSubscriptionDonationSubscriptionFilterInput
    $userEmail: String
  ) {
    onDeleteDonationSubscription(filter: $filter, userEmail: $userEmail) {
      id
      projectId
      peerProjectId
      bindingId
      userId
      userEmail
      basketId
      projectIds
      basket {
        projectId
        amount
        projectCover
        projectTitle_en
        projectTitle_ru
        projectTitle_hy
        currency
        currencyRate
        lastChargedAmount
        unsubscribedDate
        __typename
      }
      firstName
      image
      lastName
      donationToReArmenia
      projectTitle
      isAnonymous
      isInfoAnonymous
      donationToProject
      lastChargedAmount
      totalDonatedAmount
      unsubscribedDate
      month
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      day
      stripeData
      currency
      language
      paymentMethod
      paypalSubscriptionId
      paypalCustomId
      currencyRate
      nextChargeDate
      lastChargedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFailedMonthlyAttempts = /* GraphQL */ `
  subscription OnCreateFailedMonthlyAttempts(
    $filter: ModelSubscriptionFailedMonthlyAttemptsFilterInput
  ) {
    onCreateFailedMonthlyAttempts(filter: $filter) {
      id
      projectId
      userId
      email
      firstName
      lastName
      amount
      subscriptionId
      doneAttempts
      itRetryCanceled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFailedMonthlyAttempts = /* GraphQL */ `
  subscription OnUpdateFailedMonthlyAttempts(
    $filter: ModelSubscriptionFailedMonthlyAttemptsFilterInput
  ) {
    onUpdateFailedMonthlyAttempts(filter: $filter) {
      id
      projectId
      userId
      email
      firstName
      lastName
      amount
      subscriptionId
      doneAttempts
      itRetryCanceled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFailedMonthlyAttempts = /* GraphQL */ `
  subscription OnDeleteFailedMonthlyAttempts(
    $filter: ModelSubscriptionFailedMonthlyAttemptsFilterInput
  ) {
    onDeleteFailedMonthlyAttempts(filter: $filter) {
      id
      projectId
      userId
      email
      firstName
      lastName
      amount
      subscriptionId
      doneAttempts
      itRetryCanceled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBindingCard = /* GraphQL */ `
  subscription OnCreateBindingCard(
    $filter: ModelSubscriptionBindingCardFilterInput
    $userEmail: String
  ) {
    onCreateBindingCard(filter: $filter, userEmail: $userEmail) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBindingCard = /* GraphQL */ `
  subscription OnUpdateBindingCard(
    $filter: ModelSubscriptionBindingCardFilterInput
    $userEmail: String
  ) {
    onUpdateBindingCard(filter: $filter, userEmail: $userEmail) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBindingCard = /* GraphQL */ `
  subscription OnDeleteBindingCard(
    $filter: ModelSubscriptionBindingCardFilterInput
    $userEmail: String
  ) {
    onDeleteBindingCard(filter: $filter, userEmail: $userEmail) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
    $userId: String
  ) {
    onCreateTransaction(filter: $filter, userId: $userId) {
      id
      userId
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      giftCardId
      transferredToId
      transferredFromId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      peerProjectId
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      orderStatus
      statusCode
      statusDescription
      amount
      orderId
      subscriptionId
      basketId
      perkIndex
      perk {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      donationsCountWithEmail
      currency
      currencyRate
      matchedAmount
      matchedBy
      language
      isValid
      isManual
      isEdit
      isAnonymous
      isMonthlyCharge
      isStripe
      provider
      isInfoAnonymous
      projectClosedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
    $userId: String
  ) {
    onUpdateTransaction(filter: $filter, userId: $userId) {
      id
      userId
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      giftCardId
      transferredToId
      transferredFromId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      peerProjectId
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      orderStatus
      statusCode
      statusDescription
      amount
      orderId
      subscriptionId
      basketId
      perkIndex
      perk {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      donationsCountWithEmail
      currency
      currencyRate
      matchedAmount
      matchedBy
      language
      isValid
      isManual
      isEdit
      isAnonymous
      isMonthlyCharge
      isStripe
      provider
      isInfoAnonymous
      projectClosedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
    $userId: String
  ) {
    onDeleteTransaction(filter: $filter, userId: $userId) {
      id
      userId
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      giftCardId
      transferredToId
      transferredFromId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      peerProjectId
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      orderStatus
      statusCode
      statusDescription
      amount
      orderId
      subscriptionId
      basketId
      perkIndex
      perk {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      donationsCountWithEmail
      currency
      currencyRate
      matchedAmount
      matchedBy
      language
      isValid
      isManual
      isEdit
      isAnonymous
      isMonthlyCharge
      isStripe
      provider
      isInfoAnonymous
      projectClosedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUpdate = /* GraphQL */ `
  subscription OnCreateUpdate($filter: ModelSubscriptionUpdateFilterInput) {
    onCreateUpdate(filter: $filter) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUpdate = /* GraphQL */ `
  subscription OnUpdateUpdate($filter: ModelSubscriptionUpdateFilterInput) {
    onUpdateUpdate(filter: $filter) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUpdate = /* GraphQL */ `
  subscription OnDeleteUpdate($filter: ModelSubscriptionUpdateFilterInput) {
    onDeleteUpdate(filter: $filter) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFeed = /* GraphQL */ `
  subscription OnCreateFeed($filter: ModelSubscriptionFeedFilterInput) {
    onCreateFeed(filter: $filter) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      userId
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFeed = /* GraphQL */ `
  subscription OnUpdateFeed($filter: ModelSubscriptionFeedFilterInput) {
    onUpdateFeed(filter: $filter) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      userId
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFeed = /* GraphQL */ `
  subscription OnDeleteFeed($filter: ModelSubscriptionFeedFilterInput) {
    onDeleteFeed(filter: $filter) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      userId
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport($filter: ModelSubscriptionReportFilterInput) {
    onCreateReport(filter: $filter) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport($filter: ModelSubscriptionReportFilterInput) {
    onUpdateReport(filter: $filter) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport($filter: ModelSubscriptionReportFilterInput) {
    onDeleteReport(filter: $filter) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject(
    $filter: ModelSubscriptionProjectFilterInput
    $owner: String
  ) {
    onCreateProject(filter: $filter, owner: $owner) {
      id
      peerProjectId
      occasion
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isInKind
      isFeatured
      isUrgent
      isHidden
      isPopular
      isTestProject
      isIndividual
      isLive
      isProject
      isCollaboration
      isOneTimeDisabled
      isCompany
      noCTA
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
        __typename
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      mediaLink
      creatorImage
      cover
      metaImage
      metaImage_ru
      metaImage_hy
      metaImage_en
      TIN
      category
      status
      language
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      implementor {
        id
        email
        fullName
        __typename
      }
      collaborators {
        id
        email
        fullName
        __typename
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      topDonors {
        email
        fullName
        totalAmount
        __typename
      }
      fbPixel
      stripeId
      isStripeOnBoardingDone
      isIdramOnBoardingDone
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject(
    $filter: ModelSubscriptionProjectFilterInput
    $owner: String
  ) {
    onUpdateProject(filter: $filter, owner: $owner) {
      id
      peerProjectId
      occasion
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isInKind
      isFeatured
      isUrgent
      isHidden
      isPopular
      isTestProject
      isIndividual
      isLive
      isProject
      isCollaboration
      isOneTimeDisabled
      isCompany
      noCTA
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
        __typename
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      mediaLink
      creatorImage
      cover
      metaImage
      metaImage_ru
      metaImage_hy
      metaImage_en
      TIN
      category
      status
      language
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      implementor {
        id
        email
        fullName
        __typename
      }
      collaborators {
        id
        email
        fullName
        __typename
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      topDonors {
        email
        fullName
        totalAmount
        __typename
      }
      fbPixel
      stripeId
      isStripeOnBoardingDone
      isIdramOnBoardingDone
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject(
    $filter: ModelSubscriptionProjectFilterInput
    $owner: String
  ) {
    onDeleteProject(filter: $filter, owner: $owner) {
      id
      peerProjectId
      occasion
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isInKind
      isFeatured
      isUrgent
      isHidden
      isPopular
      isTestProject
      isIndividual
      isLive
      isProject
      isCollaboration
      isOneTimeDisabled
      isCompany
      noCTA
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
        __typename
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      mediaLink
      creatorImage
      cover
      metaImage
      metaImage_ru
      metaImage_hy
      metaImage_en
      TIN
      category
      status
      language
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      implementor {
        id
        email
        fullName
        __typename
      }
      collaborators {
        id
        email
        fullName
        __typename
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      topDonors {
        email
        fullName
        totalAmount
        __typename
      }
      fbPixel
      stripeId
      isStripeOnBoardingDone
      isIdramOnBoardingDone
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProjectSystem = /* GraphQL */ `
  subscription OnCreateProjectSystem(
    $filter: ModelSubscriptionProjectSystemFilterInput
    $userId: String
  ) {
    onCreateProjectSystem(filter: $filter, userId: $userId) {
      id
      projectId
      userId
      mailerLiteApiKey
      iDramApiKey
      iDramAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateProjectSystem = /* GraphQL */ `
  subscription OnUpdateProjectSystem(
    $filter: ModelSubscriptionProjectSystemFilterInput
    $userId: String
  ) {
    onUpdateProjectSystem(filter: $filter, userId: $userId) {
      id
      projectId
      userId
      mailerLiteApiKey
      iDramApiKey
      iDramAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteProjectSystem = /* GraphQL */ `
  subscription OnDeleteProjectSystem(
    $filter: ModelSubscriptionProjectSystemFilterInput
    $userId: String
  ) {
    onDeleteProjectSystem(filter: $filter, userId: $userId) {
      id
      projectId
      userId
      mailerLiteApiKey
      iDramApiKey
      iDramAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDraft = /* GraphQL */ `
  subscription OnCreateDraft($filter: ModelSubscriptionDraftFilterInput) {
    onCreateDraft(filter: $filter) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      creatorSocialLink
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      isOneTimeDisabled
      otherPlayers
      phoneNumber
      occasion
      creatorName_hy
      creatorName_ru
      creatorName_en
      peerProjectId
      files
      fundraisingType
      isInKind
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      mediaLink
      TIN
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDraft = /* GraphQL */ `
  subscription OnUpdateDraft($filter: ModelSubscriptionDraftFilterInput) {
    onUpdateDraft(filter: $filter) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      creatorSocialLink
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      isOneTimeDisabled
      otherPlayers
      phoneNumber
      occasion
      creatorName_hy
      creatorName_ru
      creatorName_en
      peerProjectId
      files
      fundraisingType
      isInKind
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      mediaLink
      TIN
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDraft = /* GraphQL */ `
  subscription OnDeleteDraft($filter: ModelSubscriptionDraftFilterInput) {
    onDeleteDraft(filter: $filter) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      creatorSocialLink
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      isOneTimeDisabled
      otherPlayers
      phoneNumber
      occasion
      creatorName_hy
      creatorName_ru
      creatorName_en
      peerProjectId
      files
      fundraisingType
      isInKind
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      mediaLink
      TIN
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onCreateCompany(filter: $filter) {
      id
      name
      domain
      amount
      period
      from
      to
      projectIds
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onUpdateCompany(filter: $filter) {
      id
      name
      domain
      amount
      period
      from
      to
      projectIds
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onDeleteCompany(filter: $filter) {
      id
      name
      domain
      amount
      period
      from
      to
      projectIds
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInitiative = /* GraphQL */ `
  subscription OnCreateInitiative(
    $filter: ModelSubscriptionInitiativeFilterInput
  ) {
    onCreateInitiative(filter: $filter) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
        __typename
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
        __typename
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      collaboratorsTotal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInitiative = /* GraphQL */ `
  subscription OnUpdateInitiative(
    $filter: ModelSubscriptionInitiativeFilterInput
  ) {
    onUpdateInitiative(filter: $filter) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
        __typename
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
        __typename
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      collaboratorsTotal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInitiative = /* GraphQL */ `
  subscription OnDeleteInitiative(
    $filter: ModelSubscriptionInitiativeFilterInput
  ) {
    onDeleteInitiative(filter: $filter) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
        __typename
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
        __typename
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      collaboratorsTotal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateContent = /* GraphQL */ `
  subscription OnCreateContent(
    $filter: ModelSubscriptionContentFilterInput
    $userId: String
  ) {
    onCreateContent(filter: $filter, userId: $userId) {
      id
      pk
      sk
      sequence
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      cv
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      isPrivate
      isVolunteer
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
        __typename
      }
      socialLinks
      interestedIn {
        interested_in
        __typename
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
        __typename
      }
      isEngFilled
      isArmFilled
      isRusFilled
      additionalInfo
      expert_status
      email
      slug
      phone
      imageUrl
      linkedinLink
      link
      location_en
      location_ru
      location_hy
      language
      countryCode
      userId
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateContent = /* GraphQL */ `
  subscription OnUpdateContent(
    $filter: ModelSubscriptionContentFilterInput
    $userId: String
  ) {
    onUpdateContent(filter: $filter, userId: $userId) {
      id
      pk
      sk
      sequence
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      cv
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      isPrivate
      isVolunteer
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
        __typename
      }
      socialLinks
      interestedIn {
        interested_in
        __typename
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
        __typename
      }
      isEngFilled
      isArmFilled
      isRusFilled
      additionalInfo
      expert_status
      email
      slug
      phone
      imageUrl
      linkedinLink
      link
      location_en
      location_ru
      location_hy
      language
      countryCode
      userId
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteContent = /* GraphQL */ `
  subscription OnDeleteContent(
    $filter: ModelSubscriptionContentFilterInput
    $userId: String
  ) {
    onDeleteContent(filter: $filter, userId: $userId) {
      id
      pk
      sk
      sequence
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      cv
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      isPrivate
      isVolunteer
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
        __typename
      }
      socialLinks
      interestedIn {
        interested_in
        __typename
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
        __typename
      }
      isEngFilled
      isArmFilled
      isRusFilled
      additionalInfo
      expert_status
      email
      slug
      phone
      imageUrl
      linkedinLink
      link
      location_en
      location_ru
      location_hy
      language
      countryCode
      userId
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateExpertInitiatorConnection = /* GraphQL */ `
  subscription OnCreateExpertInitiatorConnection(
    $filter: ModelSubscriptionExpertInitiatorConnectionFilterInput
    $expertId: String
  ) {
    onCreateExpertInitiatorConnection(filter: $filter, expertId: $expertId) {
      id
      adminStatus
      expertStatus
      initiatorFirstName
      initiatorLastName
      initiatorEmail
      initiatorPhone
      initiatorLink
      initiatorMessage
      initiatorSupport
      projectTitle
      expertId
      expertFirstName
      expertLastName
      expertEmail
      expertPhone
      expertLinkedinLink
      expertLink
      expertImageUrl
      expertTitle
      expertMessage
      expertType
      expertHours
      expertSpheres
      expertDirections
      expertOccupation
      expertLanguage
      connectionStatus
      files
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateExpertInitiatorConnection = /* GraphQL */ `
  subscription OnUpdateExpertInitiatorConnection(
    $filter: ModelSubscriptionExpertInitiatorConnectionFilterInput
    $expertId: String
  ) {
    onUpdateExpertInitiatorConnection(filter: $filter, expertId: $expertId) {
      id
      adminStatus
      expertStatus
      initiatorFirstName
      initiatorLastName
      initiatorEmail
      initiatorPhone
      initiatorLink
      initiatorMessage
      initiatorSupport
      projectTitle
      expertId
      expertFirstName
      expertLastName
      expertEmail
      expertPhone
      expertLinkedinLink
      expertLink
      expertImageUrl
      expertTitle
      expertMessage
      expertType
      expertHours
      expertSpheres
      expertDirections
      expertOccupation
      expertLanguage
      connectionStatus
      files
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteExpertInitiatorConnection = /* GraphQL */ `
  subscription OnDeleteExpertInitiatorConnection(
    $filter: ModelSubscriptionExpertInitiatorConnectionFilterInput
    $expertId: String
  ) {
    onDeleteExpertInitiatorConnection(filter: $filter, expertId: $expertId) {
      id
      adminStatus
      expertStatus
      initiatorFirstName
      initiatorLastName
      initiatorEmail
      initiatorPhone
      initiatorLink
      initiatorMessage
      initiatorSupport
      projectTitle
      expertId
      expertFirstName
      expertLastName
      expertEmail
      expertPhone
      expertLinkedinLink
      expertLink
      expertImageUrl
      expertTitle
      expertMessage
      expertType
      expertHours
      expertSpheres
      expertDirections
      expertOccupation
      expertLanguage
      connectionStatus
      files
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSettings = /* GraphQL */ `
  subscription OnCreateSettings($filter: ModelSubscriptionSettingsFilterInput) {
    onCreateSettings(filter: $filter) {
      id
      currency
      version
      bigQueryLastSyncDate
      payPayAccessToken
      payPayAccessExpires
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSettings = /* GraphQL */ `
  subscription OnUpdateSettings($filter: ModelSubscriptionSettingsFilterInput) {
    onUpdateSettings(filter: $filter) {
      id
      currency
      version
      bigQueryLastSyncDate
      payPayAccessToken
      payPayAccessExpires
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSettings = /* GraphQL */ `
  subscription OnDeleteSettings($filter: ModelSubscriptionSettingsFilterInput) {
    onDeleteSettings(filter: $filter) {
      id
      currency
      version
      bigQueryLastSyncDate
      payPayAccessToken
      payPayAccessExpires
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNeeds = /* GraphQL */ `
  subscription OnCreateNeeds($filter: ModelSubscriptionNeedsFilterInput) {
    onCreateNeeds(filter: $filter) {
      id
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNeeds = /* GraphQL */ `
  subscription OnUpdateNeeds($filter: ModelSubscriptionNeedsFilterInput) {
    onUpdateNeeds(filter: $filter) {
      id
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNeeds = /* GraphQL */ `
  subscription OnDeleteNeeds($filter: ModelSubscriptionNeedsFilterInput) {
    onDeleteNeeds(filter: $filter) {
      id
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
