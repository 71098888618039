import { Square, useId, useRadio } from '@chakra-ui/react'
import * as React from 'react'

const RadioOption = (props) => {
  const { isChecked, itemHeight, inWhiteBackground, ...rest } = props

  const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(props)
  const id = useId()

  return (
    <label style={{ width: '100%', height: '100%' }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />

      <Square
        id={id}
        px={{ base: 0.5, sm: 4 }}
        borderRadius="8px"
        fontSize="lg"
        transition="all 0.2s"
        cursor="pointer"
        _hover={{ bg: 'rgba(1, 47, 117, 0.15)' }}
        _checked={{ bg: inWhiteBackground ? 'white' : '#D9E0EA' }}
        color="gray.800"
        height={itemHeight}
        {...getCheckboxProps(rest)}
      />
    </label>
  )
}

export default RadioOption
