import React from 'react'
import { Flex, Link } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import { ReactComponent as Logo } from 'assets/icons/darkLogo.svg'
import AuthButtons from './AuthButtons'
import LanguageSelector from './LanguageSelector'

function MinimalNavbar() {
  return (
    <Flex
      px={{ base: '24px', md: '60px' }}
      w="full"
      as="nav"
      bg="white"
      boxShadow="0 2px 26px 0 rgba(0, 0, 0, 0.1)"
      height="80px"
      alignItems="center"
      justifyContent={{ base: 'center', md: 'space-between' }}
    >
      <Link as={ReactLink} width="166px" to="/">
        <Logo width="100%" />
      </Link>
      <Flex gap={3}>
        <LanguageSelector />
        <AuthButtons />
      </Flex>
    </Flex>
  )
}

export default MinimalNavbar
