import donationSubscriptionUseCase from './donationSubscriptionUseCase'
import { DonationSubscriptionService, ProjectService } from 'core/services'
import projectUseCase from '../project/projectUseCase'

const donationSubscriptionFactory = ({ appSyncRequest, auth, api }) => {
  return donationSubscriptionUseCase(
    DonationSubscriptionService(appSyncRequest, auth),
    projectUseCase(ProjectService(appSyncRequest, auth, api))
  )
}

export default donationSubscriptionFactory
