import { Box, Image } from '@chakra-ui/react'
import ImageWrapper from 'components/ImageWrapper'

export default function DonationImage({ image }) {
  return (
    <Box w="50px" h="50px" mr={3} flexShrink={0}>
      {image ? (
        <ImageWrapper
          borderRadius="50%"
          cursor="pointer"
          objectFit="cover"
          width="50px"
          bgColor="border.100"
          h="50px"
          ignoreFallback={true}
          src={`/thumbnail/small/${image}`}
        />
      ) : (
        <Image
          cursor="pointer"
          objectFit="cover"
          borderRadius="50%"
          bgColor="border.100"
          ignoreFallback={true}
          src="/assets/images/noImg.svg"
        />
      )}
    </Box>
  )
}
