import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { post } from 'aws-amplify/api'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import { useUser, useQueryParams } from 'hooks'
import useCustomToast from 'hooks/useCustomToast'
import { getResponseToJSON } from 'utils/api'
import { useScreenSize } from 'contexts'

const CheckBinding = () => {
  const toast = useCustomToast()
  const location = useLocation()
  const queryParams = useQueryParams()
  const { navigationReplace } = useNavigation()
  const { t } = useTranslation()
  const { getUser } = useUser()
  const { isMobile } = useScreenSize()

  useEffect(() => {
    ;(async () => {
      const query = new URLSearchParams(location.search)
      const orderId = query.get('orderId')
      if (orderId) {
        const body = {
          orderId: orderId,
        }
        try {
          const response = post({
            apiName: 'paymentAPI',
            path: '/bindingStatus',
            options: {
              body,
            },
          })
          const res = await getResponseToJSON(response)

          if (res.success) {
            getUser()
            toast({
              title: ['0', '2', '3', '6'].includes(String(res.orderStatus))
                ? t(`payment@orderStatus-${res.orderStatus}`)
                : t('payment@orderStatus-general'),
              status: res.orderStatus === 2 ? 'success' : 'error',
              duration: 5000,
              isClosable: true,
            })
          }

          if (queryParams.has('redirectUrl')) {
            return navigationReplace(query.get('redirectUrl'))
          }
          if (queryParams.has('donationInfo')) {
            const [projectId, amount] = queryParams
              .get('donationInfo')
              .split(',')
            let path = `/donation?projectId=${projectId}`
            if (amount && amount !== 'null') {
              path += `&amount=${amount}`
            }
            return navigationReplace(path)
          }
          if (!isMobile) {
            return navigationReplace('/profile/settings/payments')
          }
          navigationReplace('/my-settings/payments')
        } catch (e) {
          console.log(e)
          navigationReplace('/')
        }
      }
    })()
  }, [])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}

export default CheckBinding
