import React, { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import Header from './Header'
import Explaining from './Explaining'
import Collaborations from './Collaborations'
import Fundraisers from './Fundraisers'
import CreateProject from './CreateProject'
import Subscribe from './Subscribe'
import BoardOfTrusters from './BoardOfTrusters'
import DonationToRearmenia from './DonationToRearmenia'
import SocialMedias from './SocialMedias'
import Updates from './Updates'
import Testimonials from './Testimonials'
import Blog from './Blog'
import { useListUpdates, useLocalStorage } from 'hooks'
import amplitude from 'amplitude-js'
import Impact from '../web/Impact'
const Mobile = ({
  featuredProjects,
  isProjectsLoading,
  projects,
  initiatives,
  isPopularLoading,
}) => {
  const { data: updates, isLoading: isUpdatesLoading } = useListUpdates()
  const { get, remove } = useLocalStorage()
  if (get('scrollPos')) {
    window.scrollTo(0, JSON.parse(get('scrollPos')).y)
    remove('scrollPos')
  }
  const fundraisersRef = useRef()
  const collaborationsRef = useRef()
  const updatesRef = useRef()

  useEffect(() => {
    amplitude.getInstance().logEvent('Mobile homepage view')
    console.log('sent event to amplitude')
  }, [])

  return (
    <Box pt="88px">
      <Header projects={featuredProjects} isLoading={isProjectsLoading} />
      <Explaining />
      <Fundraisers
        projects={projects}
        isLoading={isPopularLoading}
        fundraisersRef={fundraisersRef}
      />
      <Impact />
      <Collaborations
        initiatives={initiatives.sort(
          (item, next) => next.collaboratorsTotal - item.collaboratorsTotal
        )}
        isLoading={isPopularLoading}
        collaborationsRef={collaborationsRef}
      />
      <Updates
        updates={updates}
        isLoading={isUpdatesLoading}
        updatesRef={updatesRef}
      />
      {/* // TODO: UNCOMMENT WHEN GIFTS CARD WILL BE ENABLED */}
      {/* <GiftCard/> */}
      <CreateProject />
      <Testimonials />
      <Blog />
      <Subscribe />
      {/* // TODO: UNCOMMENT WHEN SuccessStories WILL BE READY */}
      {/* <SuccessStories /> */}
      <BoardOfTrusters />
      <DonationToRearmenia />
      <SocialMedias />
    </Box>
  )
}

export default Mobile
