import { useMemo } from 'react'
import moment from 'moment'
import _ from 'lodash'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import NoScrollbar from 'components/NoScrollbar'
import ListItem from 'components/ListItem'
import {
  CurrencyConverter,
  useCurrencySettings,
  SUPPORTED_CURRENCY_SYMBOLS,
} from 'contexts/CurrencySettingsContext'
import { mapTopDonorFirstName } from 'pages/Donation/helpers/donationHelpers'

const SeeAllModal = ({
  onClose,
  isOpen,
  transactions,
  isMoreLoading,
  setLoadMore,
  nextToken,
  donationPage,
  project,
}) => {
  const { t, language } = useTranslation()
  const { currency } = useCurrencySettings()

  const data = useMemo(() => {
    if (!transactions?.items) return []
    return _.uniqBy(transactions.items, 'id')
  }, [transactions])

  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w="full" position="relative" pb="4" maxW="532px">
        <ModalHeader>{t('Donors')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <NoScrollbar Component={Box} maxH="500px" overflow="scroll">
            {data.map((transaction, index) => (
              <ListItem
                key={`one-time-transaction-${transaction.id}`}
                data={mapTopDonorFirstName(transaction, project.topDonors)}
                index={index}
                currency={currency}
                SUPPORTED_CURRENCY_SYMBOLS={SUPPORTED_CURRENCY_SYMBOLS}
                language={language}
                dateFromNow={moment(transaction.createdAt).fromNow()}
                goalAmount={CurrencyConverter({
                  amount: transaction.amount,
                  currency: currency.current,
                })}
                dataLength={data.length}
                variant={'donor'}
                donationPage={donationPage}
                t={t}
              />
            ))}
            {/* {nextToken !== null && (
              <Button
                mt="6"
                mx="auto"
                onClick={() => {
                  setLoadMore()
                }}
                isLoading={isMoreLoading}
                variant="transparent"
              >
                {t('more')}
              </Button>
            )} */}
            {nextToken !== null && (
              <Button
                mt="4"
                minH="40px"
                w="full"
                variant="green"
                isLoading={isMoreLoading}
                onClick={() => {
                  setLoadMore()
                }}
              >
                {t('project@showMore')}
              </Button>
            )}
          </NoScrollbar>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SeeAllModal
