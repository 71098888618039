import {
  Button,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  Text,
  Flex,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as SuccessIcon } from 'assets/icons/expert_success.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg'
import { ReactComponent as InfoImage } from 'assets/illustrations/expert_pending.svg'
import { ReactComponent as FailIcon } from 'assets/icons/expert_fail.svg'
import { useMemo } from 'react'
import { useStringManipulations, useUser } from 'hooks'
import { useGetExpertById } from 'hooks'

export default function ExpertNotificationModal({
  closeModal,
  type = 'pending',
  firstName,
}) {
  const { t, language } = useTranslation()
  const { findNamingTranslation } = useStringManipulations()
  const { user } = useUser()
  const { data: expert } = useGetExpertById(user?.expertId || user?.email)

  const heading = useMemo(() => {
    if (type === 'approved') {
      return t('Congratulations!').replace(
        '(name)',
        findNamingTranslation({
          parentObject: expert,
          value: 'firstName',
          language,
        })
      )
    }
    if (type === 'rejected') {
      return t('Your application is rejected')
    }
    if (type === 'pending') {
      return t(
        `Thank you, [name], for your willingness to make positive changes in Armenia!`
      ).replace(
        '[name]',
        findNamingTranslation({
          parentObject: expert,
          value: 'firstName',
          language,
        })
      )
    }
    if (type === 'application') {
      return t(`Dear [name],`).replace(
        '[name]',
        firstName ||
          findNamingTranslation({
            parentObject: expert,
            value: 'firstName',
            language,
          }) ||
          ''
      )
    }
  }, [type])
  const description = useMemo(() => {
    if (type === 'approved') {
      return 'Your application to become an expert has been approved.'
    }
    if (type === 'rejected') {
      return 'We are sorry, but your application to become an expert is rejected. You can always update your information and apply again.'
    }
    if (type === 'pending') {
      return 'We’ve sent you an email about the further steps.'
    }
    if (type === 'application') {
      return 'Welcome to the Expert Community. What about filling out your Expert Card & going public?'
    }
  }, [type])
  const button = useMemo(() => {
    if (type === 'approved') {
      return 'OK, GREAT'
    }
    if (type === 'rejected') {
      return 'OK'
    }
    if (type === 'pending') {
      return 'OK'
    }
    if (type === 'application') {
      return 'Let’s Start'
    }
  }, [type])
  return (
    <>
      <ModalCloseButton
        color="#000"
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
      />
      <ModalBody
        mt={type === 'application' ? 0 : 16}
        px={type === 'application' ? 0 : 8}
        pt={type === 'application' ? 0 : 2}
        overflow="hidden"
        textAlign="center"
      >
        <Flex justifyContent="center">
          <Icon type={type} />
        </Flex>
        <Heading
          fontSize={{ base: '2xl', sm: '6xl' }}
          my={{ base: 6, sm: 10 }}
          fontWeight={600}
        >
          {heading}
        </Heading>
        <Text px={{ base: 5, sm: 10 }} fontSize={{ base: 'md', sm: '4xl' }}>
          {t(description)}
        </Text>
      </ModalBody>
      <ModalFooter
        justifyContent="center"
        my={{ base: 2, sm: 6 }}
        px={{ base: 5, sm: 10 }}
      >
        <Button
          variant="green"
          minW="full"
          onClick={() => {
            closeModal({}, { action: 'save' })
          }}
          id="draft_modal_save"
        >
          {t(button)}
        </Button>
      </ModalFooter>
    </>
  )
}
const Icon = ({ type }) => {
  if (type === 'rejected') {
    return <FailIcon />
  }
  if (type === 'approved') {
    return <SuccessIcon />
  }
  if (type === 'application') {
    return <InfoImage style={{ height: 'auto' }} />
  }

  return <EmailIcon />
}
