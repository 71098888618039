import { useEffect, useState } from 'react'
import { useUser } from 'hooks'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Box,
  Flex,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as IconNew } from './images/new.svg'
import GuidPerson from './GuidPerson'
import CreateButton from './CreateButton'

const CreateAccountModal = () => {
  const { t } = useTranslation()
  const { user } = useUser()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isModalOnceOpened, setIsModalOnceOpened] = useState(false)

  useEffect(() => {
    setTimeout(() => onOpen(), 2000)
  }, [])

  const isLoggedIn = user?.id !== 'guest'

  return (
    <Modal
      isOpen={isOpen && !isLoggedIn && !isModalOnceOpened}
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent
        overflow="hidden"
        top={{ base: '230px', sm: '270px' }}
        background="blue.400"
        borderRadius="24px"
        minWidth={{ base: 'calc(100% - 40px)', lg: '680px' }}
        margin={0}
        marginX={{ base: 5, lg: 0 }}
      >
        <ModalHeader>
          <IconNew />
          <ModalCloseButton
            color="orange.400"
            top={6}
            right={6}
            size={6}
            width={6}
            height={6}
          />
        </ModalHeader>
        <ModalBody position="relative" padding="0 24px 24px 24px">
          <Box width={{ base: 'calc(100% - 14px)', lg: 'calc(100% - 245px)' }}>
            <Text
              color="white"
              fontSize={{ base: '18px', lg: '24px' }}
              fontWeight="600"
              marginBottom="16px"
            >
              {t('thankyou@createAccountTitle')}
            </Text>
          </Box>

          <Flex
            width={{ base: 'calc(100% - 120px)', lg: 'calc(100% - 245px)' }}
            flexDirection="column"
            gap="12px"
            marginBottom="50px"
            minHeight="90px"
          >
            <Text color="white" fontSize="16px">
              {t('thankyou@createAccountText')}
            </Text>
          </Flex>

          <CreateButton
            setIsModalOnceOpened={setIsModalOnceOpened}
            onClose={onClose}
          />

          <GuidPerson />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateAccountModal
