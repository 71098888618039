import { Button, Grid, GridItem } from '@chakra-ui/react'
import {
  predefinedAmountsMonthly,
  predefinedAmountsOneTime,
} from '../../amounts'

function PredefinedAmounts({ isRecurring, amount, currency, onChoose }) {
  const onAmountChoose = (event, chosenAmount) => {
    event.preventDefault()
    onChoose(chosenAmount)
  }

  const predefinedAmounts = isRecurring
    ? predefinedAmountsMonthly
    : predefinedAmountsOneTime

  const items = predefinedAmounts[currency]
  return (
    <Grid
      templateColumns={{
        base: 'repeat(3, 1fr)',
        sm: `repeat(${Math.min(items.length, 6)}, 1fr)`,
      }}
      width="100%"
      gap={{ base: 3, sm: 2 }}
    >
      {items.map((value) => {
        const isSelectedAmount = amount?.toString() === value.value?.toString()

        return (
          <GridItem key={`predefinedAmounts/${currency}/${value.label}`}>
            <Button
              height={{ base: 10, sm: 8 }}
              borderRadius={{ base: '12px', sm: '8px' }}
              borderStyle="solid"
              borderWidth="1px"
              borderColor="border.100"
              width="100%"
              background={isSelectedAmount ? 'blue.400' : 'white'}
              color={isSelectedAmount ? 'white' : '#292929'}
              onClick={(e) => onAmountChoose(e, value.value)}
            >
              {value.label}
            </Button>
          </GridItem>
        )
      })}
    </Grid>
  )
}

export default PredefinedAmounts
