import {
  Box,
  Button,
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  Popover,
  Text,
} from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import { InputField } from 'components/InputField'
import PopoverWrapper from 'components/PopoverWrapper'

//** Icons
import { ReactComponent as BigPlus } from 'assets/icons/big_plus.svg'
import { ReactComponent as SmallPlus } from 'assets/icons/small_plus.svg'
import { ReactComponent as Social } from 'assets/icons/social.svg'
import { ReactComponent as Delete } from 'assets/icons/round_close.svg'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'contexts/TranslationContext'

const Header = ({ isEmptyFields, language }) => {
  const { t } = useTranslation()

  if (isEmptyFields) {
    return (
      <>
        <Flex align="center">
          <Social />
          <Text ml={3} fontSize="md" color="gray.800">
            {t('Social media links', language)}
          </Text>
        </Flex>
        <SmallPlus />
      </>
    )
  }
  return <BigPlus />
}

export default function LinksPopover({
  isEmptyFields,
  data,
  remove,
  update,
  onAddField,
  isInvalid,
  trigger,
  LIMIT,
  isDisabled,
  language,
  register,
  registerKey,
}) {
  const { t } = useTranslation()
  const [isAddDisabled, setIsDisabled] = useState(
    data?.length === 1 && data?.[0]?.url === ''
  )

  useEffect(() => {
    setIsDisabled(data?.length === 1 && data?.[0]?.url === '')
  }, [data])

  const onAdd = (onClose) => {
    data.forEach((field, index) => {
      if (field.url) {
        update(index, { ...field, added: true })
      }
    })
    trigger(registerKey)
    onClose()
  }
  const debouncedChangeHandler = useMemo(
    () => debounce((value) => setIsDisabled(!value && data.length === 1), 300),
    [data]
  )

  return (
    <Popover placement="bottom">
      {({ isOpen, onClose }) => (
        <PopoverWrapper
          w={isEmptyFields ? 'full' : 12}
          contentProps={{
            minW: { base: '277px', sm: 'max-content' },
            width: { sm: '277px' },
          }}
          justify={isEmptyFields ? 'space-between' : 'center'}
          isOpen={isOpen}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          Header={() => (
            <Header isEmptyFields={isEmptyFields} language={language} />
          )}
          wrapperProps={{ flex: isEmptyFields ? 1 : 'unset' }}
        >
          <Text fontSize="lg" color="gray.800" fontWeight={700} mb={2}>
            {t('Add a link', language)} ({data.length}/{LIMIT})
          </Text>
          {data.map((field, index) => (
            <InputGroup key={field.id}>
              <InputField
                mb={3}
                {...register(`${registerKey}.${index}.url`, {
                  onBlur: (e) => {
                    update(index, {
                      ...field,
                      url: e.target.value,
                    })
                  },
                })}
                onChange={(e) => debouncedChangeHandler(e.target.value)}
                defaultValue={field.url}
              />
              {data.length > 1 && (
                <InputRightElement>
                  <IconButton
                    zIndex={4}
                    pointerEvents="all"
                    onMouseUp={() => remove(index)}
                  >
                    <Delete />
                  </IconButton>
                </InputRightElement>
              )}
              <input
                style={{ display: 'none' }}
                type="checkbox"
                checked={field.added}
                {...register(`${registerKey}.${index}.added`)}
              />
            </InputGroup>
          ))}

          {data.length < LIMIT && (
            <Button
              mb={4}
              fontSize="md"
              color="gray.800"
              onClick={onAddField}
              textTransform="none"
              alignSelf="flex-start"
            >
              <Box mr={2}>
                <Plus width="24px" />
              </Box>
              {t('Add another link', language)}
            </Button>
          )}
          <Button
            w="full"
            variant="blue"
            onClick={() => onAdd(onClose)}
            isDisabled={isAddDisabled}
          >
            {t('Add', language)}
          </Button>
        </PopoverWrapper>
      )}
    </Popover>
  )
}
