import { useState, useMemo, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { PAYMENT_METHODS } from './helpers/constants'
import {
  createEmptyTransactionBody,
  getDefaultBindingId,
} from './helpers/donationHelpers'

const useDonationForm = ({
  projectId,
  user,
  project,
  hasDefaultAmount,
  defaultAmount,
  hasFirstName,
  firstName,
  hasLastName,
  lastName,
  hasEmail,
  email,
  giftCard,
  giftCardMode,
  isContribution,
  peerProjectId,
  showStripe,
}) => {
  const appliedGiftCard = useRef({})
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: createEmptyTransactionBody({
      user,
      project,
      hasDefaultAmount,
      defaultAmount,
      hasFirstName,
      firstName,
      hasLastName,
      lastName,
      hasEmail,
      email,
      isContribution,
    }),
  })

  const [isValid, toggleValid] = useState(false)
  const checkObject = {
    firstName: watch('firstName'),
    lastName: watch('lastName'),
    email: watch('email'),
    amount: watch('amount'),
    yourMessage: watch('yourMessage'),
    from: watch('from'),
    toEmail: watch('toEmail'),
  }
  useEffect(() => {
    let valid = true
    if (!checkObject.firstName) {
      valid = false
    }
    if (!checkObject.lastName) {
      valid = false
    }
    if (!checkObject.email) {
      valid = false
    }
    if (
      giftCardMode &&
      checkObject.firstName &&
      checkObject.lastName &&
      checkObject.email
    ) {
      toggleValid(true)
      return
    }
    if (!checkObject.amount || parseFloat(checkObject.amount) <= 0) {
      valid = false
    }
    if (giftCard) {
      if (!checkObject.from) {
        valid = false
      }
      if (!checkObject.toEmail) {
        valid = false
      }
    }
    toggleValid(valid)
  }, [checkObject])

  useEffect(() => {
    if (user.email !== 'guest') {
      setValue('firstName', user.firstName)
      setValue('lastName', user.lastName)
      setValue('email', user.email)
      const selectedCard = getDefaultBindingId(user, project, showStripe)
      if (selectedCard) {
        setValue('attachCard', getValues('paymentType') === 'recurring')
        setValue('bindingId', selectedCard)
        setValue('paymentMethod', selectedCard)
      } else {
        setValue('paymentMethod', PAYMENT_METHODS.ARCA_VISA_MASTER)
      }
    } else {
      if (showStripe && project?.stripeId) {
        setValue('paymentMethod', 'stripe')
      } else {
        setValue('paymentMethod', PAYMENT_METHODS.ARCA_VISA_MASTER)
      }
    }
  }, [user, project])

  const afterSignUpRedirectURL = useMemo(() => {
    let url = `/donation?projectId=${projectId}&amount=${getValues('amount')}`

    if (peerProjectId) {
      url += `&peerProjectId=${peerProjectId}`
    }

    if (giftCard) {
      url = `/order-gift-card?projectId=${projectId}&amount=${getValues(
        'amount'
      )}&giftCard=${giftCard}`
    }
    return url
  }, [watch('amount')])

  const showAttachCardCheckbox = useMemo(() => {
    if (user.id === 'guest') {
      return false
    }
    localStorage.setItem('paymentMethod', getValues('paymentMethod'))
    if (
      ['stripe', ...Object.values(PAYMENT_METHODS)].includes(
        getValues('paymentMethod')
      )
    ) {
      setValue('attachCard', true)
      setValue('bindingId', '')
      return true
    }
    return false
  }, [user, watch('paymentMethod')])

  useEffect(() => {
    localStorage.setItem('redirectURL', afterSignUpRedirectURL)
  }, [afterSignUpRedirectURL])

  useEffect(() => {
    if (!project) return
    setValue(
      'paymentType',
      project.fundraisingType === 'recurring' ? 'recurring' : 'oneTime'
    )
  }, [project])

  const paymentType = watch('paymentType')
  useEffect(() => {
    if (paymentType) {
      appliedGiftCard.current = {}
    }
    if (paymentType === 'recurring') {
      setValue('attachCard', true)
    }
  }, [paymentType])

  return {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    isValid,
    toggleValid,
    afterSignUpRedirectURL,
    appliedGiftCard,
    showAttachCardCheckbox,
  }
}

export default useDonationForm
