import { Stack } from '@chakra-ui/react'
import { useListTransactionsByBasketId } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import HistoryCard from './HistoryCard'

export default function History({ subscription }) {
  const { language } = useTranslation()
  const { data: transactions } = useListTransactionsByBasketId({
    basketId: subscription.basketId,
    sortDirection: 'DESC',
    filter: {
      status: { eq: 'DONE' },
    },
  })

  return (
    <Stack
      spacing={4}
      maxH={{ base: 'auto', md: '420px' }}
      overflowY="auto"
      pb={4}
      px={0.5}
    >
      {transactions?.items?.map((transaction) => (
        <HistoryCard
          key={transaction.id}
          image={transaction.projectImage}
          date={transaction.createdAt}
          title={transaction[`projectTitle_${language}`]}
          amount={transaction.donationToProject}
        />
      ))}
    </Stack>
  )
}
