import { Box, Heading, Text, Flex, Grid, GridItem } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Badge } from 'assets/icons/Badge.svg'
import { ReactComponent as Collab } from 'assets/icons/collaboration.svg'
import { ReactComponent as Users } from 'assets/icons/users.svg'
import { ReactComponent as Donate } from 'assets/icons/Donate.svg'
import { useScreenSize } from 'contexts'
import { useState, useEffect, useRef } from 'react'
const Impact = () => {
  const { t } = useTranslation()
  return (
    <Box
      p={{ base: '1.5rem 2.5rem', lg: '1.5rem 5.5rem' }}
      mt={{ base: '1.5rem ', sm: '6.25rem' }}
      bg="#fafafa"
    >
      <Heading
        pb={{ base: '1.5rem ', sm: '3.125rem' }}
        fontSize="32px"
        fontWeight="semibold"
        textAlign="center"
      >
        {t('impact@Header')}
      </Heading>
      <Flex direction="row" alignItems="center" justifyContent="center">
        <InfoBoxTest />
      </Flex>
    </Box>
  )
}

export default Impact

const InfoBoxTest = () => {
  const { t } = useTranslation()

  const impactNumbers = [
    {
      heading: 1824500000,
      text: t('impact@Donations'),
      specialChar: '֏',
      icon: (
        <Donate style={{ fill: '#ED8B00', width: '36px', height: '36px' }} />
      ),
    },
    {
      heading: 180,
      text: t('impact@SuccessfulProjects'),
      icon: (
        <Badge style={{ stroke: '#ED8B00', width: '36px', height: '36px' }} />
      ),
    },
    {
      heading: 22000,
      text: t('impact@ChangedLives'),
      icon: (
        <Users style={{ stroke: '#ED8B00', width: '36px', height: '36px' }} />
      ),
    },
    {
      heading: 1000,
      text: t('impact@Collaborations'),
      icon: (
        <Collab style={{ stroke: '#ED8B00', width: '36px', height: '36px' }} />
      ),
    },
  ]
  return (
    <Box
      textAlign="center"
      borderRadius="24px"
      border="1px solid"
      borderColor="border.100"
      bg="#ffffff"
      width="100%"
    >
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)' }}>
        {impactNumbers.map(({ heading, text, specialChar, icon }, i) => (
          <GridItem
            key={i}
            borderLeft={{ sm: i !== 0 ? '1px solid #DDDDDD' : 'none' }}
            borderBottom={{
              base: i !== impactNumbers.length - 1 && '1px solid',
              md: 'none',
            }}
            whiteSpace="pre"
            borderColor="border.100"
            padding="24px"
          >
            <Flex direction="column" alignItems="center">
              {icon}
              <Heading color="gray.800" fontSize="28px" pt="12px">
                {specialChar} <CountUp target={heading} duration={1500} />+
              </Heading>
              <Text pt="6px" color="gray.700" fontSize="18px">
                {text}
              </Text>
            </Flex>
          </GridItem>
        ))}
      </Grid>
    </Box>
  )
}

const CountUp = ({ target, duration = 2000 }) => {
  const [count, setCount] = useState(0)
  const [hasStarted, setHasStarted] = useState(false)
  const elementRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHasStarted(true)
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the element is visible
    )

    if (elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!hasStarted) return

    const startTime = performance.now()

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime
      const progress = Math.min(elapsedTime / duration, 1) // Cap progress at 1
      const currentCount = Math.floor(progress * target)

      setCount(currentCount)

      if (progress < 1) {
        requestAnimationFrame(animate)
      }
    }

    requestAnimationFrame(animate)
  }, [hasStarted, target, duration])

  return (
    <Text as="span" ref={elementRef}>
      {count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    </Text>
  )
}
