import { useState } from 'react'
import { post } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useQuery, useMutation } from 'react-query'
import contentFactory from 'core/factories/content'
import useAppSyncRequest from './useAppSyncRequest'

export const useContentFactory = () => {
  const appSyncRequest = useAppSyncRequest()
  const auth = { fetchAuthSession }
  const api = { post }

  return contentFactory({ appSyncRequest, auth, api })
}

export const useGetNeeds = () => {
  const contentUseCase = useContentFactory()
  return useQuery(['needs'], contentUseCase.getNeeds)
}

export const useGetExperts = (variables) => {
  const contentUseCase = useContentFactory()

  return useQuery([`experts`], () => contentUseCase.getExperts(variables))
}

export const useGetExpertById = (id) => {
  const contentUseCase = useContentFactory()
  return useQuery([`expert-${id}`], () => contentUseCase.getExpert(id))
}
export const useGetExpertBySlug = (slug) => {
  const contentUseCase = useContentFactory()
  return useQuery([`expert-${slug}`], () => contentUseCase.getItemBySlug(slug))
}

export const useGetGiftCardsPageData = () => {
  const contentUseCase = useContentFactory()
  return useQuery(['gift-card'], contentUseCase.getGiftCard)
}

export const useGetHomepageBlog = () => {
  const contentUseCase = useContentFactory()
  return useQuery(['blog'], contentUseCase.getHomepageBlog)
}

export const useGetHomepageBlogById = (slug) => {
  const contentUseCase = useContentFactory()
  return useQuery([`blog_${slug}`], () => contentUseCase.getItemBySlug(slug))
}

export const useGetHomepagePartners = () => {
  const contentUseCase = useContentFactory()
  return useQuery(['partners'], contentUseCase.getHomepagePartners)
}

export const useGetHomepageImpacts = () => {
  const contentUseCase = useContentFactory()
  return useQuery(['impacts'], contentUseCase.getHomepageImpacts)
}

export const useGetHomepageTestimonials = () => {
  const contentUseCase = useContentFactory()
  return useQuery(['testimonials'], contentUseCase.getHomepageTestimonials)
}

export const useGetExpertsCategories = () => {
  const contentUseCase = useContentFactory()
  return useQuery(['categories'], contentUseCase.getExpertsCategories)
}

export const useGetExpertsCountries = () => {
  const contentUseCase = useContentFactory()
  return useQuery(['countries'], contentUseCase.useGetExpertsCountries)
}

export const useContentFromElastic = (
  initialFilter,
  key = 'get_content',
  page,
  hasSearch
) => {
  const contentUseCase = useContentFactory()
  const [filters, setFilters] = useState(initialFilter)
  const { error, refetch, isLoading, data, ...rest } = useQuery(
    [key, filters],
    async () => {
      return await contentUseCase.getContentFromElastic(
        filters,
        page,
        hasSearch
      )
    },
    {
      enabled: true,
    }
  )

  return {
    data,
    refetch,
    isLoading,
    filters,
    setFilters,
    error,
    ...rest,
  }
}

export const useCreateContentItem = () => {
  const contentUseCase = useContentFactory()
  return useMutation(contentUseCase.createContentItem)
}

export const useUpdateContentItem = () => {
  const contentUseCase = useContentFactory()
  return useMutation(contentUseCase.updateContentItem)
}

export const useDeleteContentItem = () => {
  const contentUseCase = useContentFactory()
  return useMutation(contentUseCase.deleteContentItem)
}
