import { Flex, FormControl, FormLabel, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import PredefinedAmounts from './PredefinedAmounts'
import AmountInput from './AmountInput'

function EnterAmount({
  isRecurring,
  donation,
  isBuyingGiftCard,
  note,
  progressData,
  setValue,
  errors,
  register,
}) {
  const { currency } = useCurrencySettings()
  const { t } = useTranslation()

  return (
    <Flex flexDirection="column" gap={{ base: 3, sm: 2 }}>
      <Flex width="100%" flexDirection="column" gap={4}>
        <Text fontSize={16} fontWeight={600}>
          {!isRecurring
            ? t('donation@labelEnterAmount')
            : t('donation@labelEnterAmountMonthly')}
        </Text>

        <PredefinedAmounts
          isRecurring={isRecurring}
          currency={currency.current}
          amount={donation.amount}
          onChoose={(amount) => setValue('amount', amount, '')}
        />

        <FormControl zIndex={1}>
          {(isBuyingGiftCard || note) && (
            <FormLabel
              fontSize="xl"
              fontWeight="semibold"
              color="black"
              zIndex={2}
            >
              {isBuyingGiftCard && t('giftCardAmount')}
              {note && (
                <Text fontSize="sm" fontWeight={400} color="gray.700">
                  {note}{' '}
                </Text>
              )}
            </FormLabel>
          )}

          <AmountInput
            progressData={progressData}
            donation={donation}
            errors={errors}
            register={register}
            setValue={setValue}
          />
        </FormControl>
      </Flex>
    </Flex>
  )
}

export default EnterAmount
