import React from 'react'
import { useTranslation, LANGUAGES } from 'contexts/TranslationContext'
import { BlueSelect } from 'components/Select/BlueSelect'

function LanguageSelector() {
  const { t, language, setLanguage } = useTranslation()
  const selectOption = (option) => setLanguage(option)

  return (
    <BlueSelect
      width="unset"
      display="flex"
      type="language"
      variant="noBorder"
      white={false}
      label={t(`language@${language}LabelShort`)}
      currentSelectedOption={t(`language@${language}Label`)}
      options={Object.keys(LANGUAGES).map((key) => ({
        title: t(`language@${key}Label`),
        value: key,
      }))}
      selectOption={selectOption}
      offset={[0, 7]}
    />
  )
}

export default LanguageSelector
