import {
  Checkbox,
  Text,
  Flex,
  Tooltip,
  Center,
  useDisclosure,
} from '@chakra-ui/react'
import { ReactComponent as IconInfoButton } from 'assets/icons/info-button.svg'
import { forwardRef } from 'react'

const CheckboxElement = forwardRef(({ children, tooltip, ...props }, ref) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure()

  return (
    <Flex height={8}>
      <Checkbox ref={ref} {...props}>
        <Text fontSize="sm" lineHeight={4}>
          {children}
        </Text>
      </Checkbox>

      {tooltip && (
        <Tooltip
          isOpen={isOpen}
          label={tooltip}
          placement="top"
          hasArrow={true}
          background="gray.900"
          arrowSize={20}
          borderRadius="12px"
          padding={4}
          maxWidth={80}
          fontWeight={400}
          fontSize="lg"
          lineHeight={6}
        >
          <Center
            marginLeft={2}
            borderRadius="4px"
            height={8}
            width={8}
            _hover={{
              background: 'gray.400',
            }}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            onClick={onToggle}
          >
            <IconInfoButton height="22px" width="22px" />
          </Center>
        </Tooltip>
      )}
    </Flex>
  )
})

CheckboxElement.displayName = 'CheckboxElement'

export default CheckboxElement
