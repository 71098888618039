import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import CheckboxElement from './CheckboxElement'

const CheckboxesArea = ({
  register,
  giftCard,
  giftCardMode,
  isBuyingGiftCard,
}) => {
  const { t } = useTranslation()

  return (
    <Flex flexDirection="column">
      {!giftCard && (
        <CheckboxElement
          {...register('isAnonymous')}
          tooltip={t('donation@donateAnonymouslyNotice')}
        >
          {giftCardMode ? (
            t('redeemAnonymous')
          ) : (
            <>
              <b>{t('donation@donateAnonymouslyText').split('(bold)')[0]}</b>
              {t('donation@donateAnonymouslyText').split('(bold)')[1]}
            </>
          )}
        </CheckboxElement>
      )}

      {!isBuyingGiftCard && (
        <CheckboxElement {...register('isInfoAnonymous')}>
          <b>
            {
              t("Don't share my email with the project creator").split(
                '(bold)'
              )[0]
            }
          </b>
          {
            t("Don't share my email with the project creator").split(
              '(bold)'
            )[1]
          }
        </CheckboxElement>
      )}

      <CheckboxElement {...register('subscribeNews')}>
        {t('donation@subscribeOnDonateText').split('(bold)')[0]}
        <b>{t('donation@subscribeOnDonateText@bold')}</b>
        {t('donation@subscribeOnDonateText').split('(bold)')[1]}
      </CheckboxElement>
    </Flex>
  )
}

export default CheckboxesArea
