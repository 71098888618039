import { Flex, Heading, HStack } from '@chakra-ui/react'
import { Link } from 'pages'
import { ReactComponent as Back } from 'assets/icons/back.svg'
import { ReactComponent as Share } from 'assets/icons/share.svg'

export default function MobileHeader({ heading, backTo, onBack, share }) {
  const onBackClick = (e) => {
    if (typeof onBack === 'function') {
      e.stopPropagation()
      e.preventDefault()
      onBack()
    }
  }

  const onRightButtonClick = async () => {
    if (share && navigator.share) {
      await navigator.share({
        title: share.title,
        url: window.location.href,
        text: share.description,
      })
    }
  }

  return (
    <HStack
      py={2}
      px={5}
      h="auto"
      position="relative"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="border.100"
    >
      <Flex
        as={Link}
        width="30px"
        height="20px"
        justifyContent="center"
        alignItems="center"
        to={backTo}
        onClick={onBackClick}
      >
        <Back width="10px" height="20px" />
      </Flex>
      <Heading
        as="h1"
        fontSize="2xl"
        noOfLines={1}
        textAlign="center"
        px={5}
        marginLeft="0 !important"
        fontWeight="500"
        w="100%"
      >
        {heading}
      </Heading>
      <Flex
        onClick={onRightButtonClick}
        width="30px"
        height="30px"
        justifyContent="center"
        alignItems="center"
      >
        {share && <Share />}
      </Flex>
    </HStack>
  )
}
