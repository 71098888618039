import React from 'react'
import { Flex, Text, Button, Box } from '@chakra-ui/react'
import { useModal } from 'providers/ModalProvider'
import { useTranslation } from 'contexts/TranslationContext'
import ProfileDropdown from '../../ProfileDropdown'
import { useUser } from 'hooks'

function AuthButton({ modal, translationKey }) {
  const { t } = useTranslation()
  const { openModal } = useModal()

  return (
    <Button
      color="blue.400"
      textTransform="initial"
      marginX={3}
      onClick={() => {
        openModal(modal)
      }}
    >
      {t(translationKey)}
    </Button>
  )
}

function Divider() {
  return <Box height={8} width="1px" background="blue.400" />
}

function AuthButtons() {
  const { user } = useUser()
  const isGuest = user.id === 'guest'
  const username = user.firstName

  if (!isGuest) {
    return (
      <Flex alignItems="center">
        <Text fontWeight="bold" fontSize="md">
          {username}
        </Text>
        <ProfileDropdown black />
      </Flex>
    )
  }

  return (
    <Flex fontWeight="500" display="flex" alignItems="center">
      <AuthButton modal="signIn" translationKey="nav@signIn" />
      <Divider />
      <AuthButton modal="signUp" translationKey="nav@signUp" />
    </Flex>
  )
}

export default AuthButtons
