const Input = {
  sizes: {
    sm: {
      field: {
        height: '40px',
        fontSize: '1rem',
      },
    },
    md: {
      field: {
        height: '43px',
        fontSize: '1rem',
      },
    },
    lg: {
      field: {
        height: '48px',
        fontSize: '1rem',
      },
    },
  },
}

export default Input
