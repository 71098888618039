import { Box, Button, Flex, Stack, useDisclosure } from '@chakra-ui/react'
import { useTransactions } from 'contexts'
import { useTranslation } from 'contexts/TranslationContext'
import React, { useState } from 'react'
import EditTransactionModal from './EditTransactionModal'

export default function TransactionList() {
  const { setLoadMore, nextToken, data, isMoreLoading } = useTransactions({
    isAdmin: true,
  })
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedTransaction, setSelectedTransaction] = useState(null)

  const onEditSuccess = () => {
    window.location.reload()
    onClose()
  }

  return (
    <>
      <EditTransactionModal
        isOpen={isOpen}
        onSuccessfulSubmit={onEditSuccess}
        onClose={() => {
          setSelectedTransaction(null)
          onClose()
        }}
        transaction={selectedTransaction}
      />
      <Stack>
        {data.map((transaction) => (
          <Box
            key={transaction.id}
            p={4}
            border="1px solid"
            borderRadius={10}
            borderColor="border.200"
          >
            <Flex>
              {transaction.firstName} {transaction.lastName} |{' '}
              {transaction.email}
              <Button
                variant="blue"
                ml={4}
                onClick={() => {
                  setSelectedTransaction(transaction)
                  onOpen()
                }}
              >
                EDIT
              </Button>
            </Flex>
            <Flex>
              {transaction.donationToProject} AMD |{' '}
              {transaction.donationToReArmenia} AMD (tip)
            </Flex>
          </Box>
        ))}
      </Stack>

      {nextToken !== null && (
        <Button
          mt="4"
          minH="40px"
          w="full"
          variant="green"
          isLoading={isMoreLoading}
          onClick={() => {
            setLoadMore()
          }}
        >
          {t('project@showMore')}
        </Button>
      )}
    </>
  )
}
