import { post } from 'aws-amplify/api'
import { getInitiative, listInitiatives } from '../../graphql/queries'
import {
  createInitiative as create,
  updateInitiative as update,
  deleteInitiative as removeInitiative,
} from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api'
import { getResponseToJSON } from 'utils/api'
const client = generateClient()

const initiativeService = () => {
  const createInitiative = async (initiative) => {
    await client.graphql({ query: create, variables: { input: initiative } })
  }

  const addCollaborator = async ({ id, collaborator }) => {
    await client.graphql({
      query: updateInitiative,
      variables: {
        input: { id, collaborators: [collaborator] },
      },
    })
  }

  const setImplementor = async ({ id, collaborator }) => {
    await client.graphql({
      query: updateInitiative,
      variables: {
        input: { id, implementor: collaborator },
      },
    })
  }

  const updateStatus = async (params) => {
    await client.graphql({
      query: updateInitiative,
      variables: { input: params },
    })
  }

  const updateInitiative = (initiative) =>
    client.graphql({ query: update, variables: { input: initiative } })

  const retrieveInitiative = async (id) => {
    const initiative = await client.graphql({
      query: getInitiative,
      variables: { id },
    })

    return initiative.data.getInitiative
  }

  const getInitiatives = async (filter) => {
    const condition = {}
    if (Object.keys(filter).length > 0) {
      condition.filter = filter
    }

    const projects = await client.graphql({
      query: listInitiatives,
      variables: condition,
    })
    return projects.data.listInitiatives.items
  }

  const collaboratorRequest = async (params) => {
    try {
      const res = post({
        apiName: 'email',
        path: '/collabEmail',
        options: {
          body: params,
        },
      })
      await getResponseToJSON(res)
    } catch (err) {
      console.log('Error', err)
    }
  }

  const deleteInitiative = async (initiative) => {
    await client.graphql({
      query: removeInitiative,
      variables: { input: initiative },
    })
  }

  return {
    createInitiative,
    addCollaborator,
    updateStatus,
    updateInitiative,
    retrieveInitiative,
    getInitiatives,
    collaboratorRequest,
    setImplementor,
    deleteInitiative,
  }
}

export default initiativeService
