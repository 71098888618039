import {
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { BlueSelect } from 'components/Select/BlueSelect'
import {
  SUPPORTED_CURRENCIES,
  SUPPORTED_CURRENCY_SYMBOLS,
  convertTo,
  thousandSeparator,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'
import { useState } from 'react'

export default function DonationInput({ register, errors, watch, setValue }) {
  const [showInputZero, setShowInputZero] = useState(false)
  const { currency, changeDefaultCurrency, settings } = useCurrencySettings()
  const { t } = useTranslation()

  const onSelectCurrency = (option) => {
    changeDefaultCurrency(option)
    setValue('amount', 0, '')
  }
  const amount = watch('amount')

  const curr = currency.current

  return (
    <FormControl zIndex={1}>
      <InputGroup alignItems="center">
        <InputLeftElement
          top="unset"
          width={36}
          borderRight="1px solid"
          borderColor="border.100"
          ml={2}
          children={
            <BlueSelect
              variant="big"
              display="flex"
              type="currency"
              labelFontSize="3xl"
              amount={+amount}
              label={`${SUPPORTED_CURRENCY_SYMBOLS[curr]} (${curr})`}
              options={SUPPORTED_CURRENCIES.map((option) => ({
                title: t(`currency@${option}`),
                amount: `${thousandSeparator(
                  convertTo({
                    from: currency.current,
                    to: option,
                    amount: +amount,
                    settings,
                  }),
                  option
                )} ${option}`,
                value: option,
              }))}
              selectOption={onSelectCurrency}
              currentSelectedOption={t(`currency@${curr}`)}
            />
          }
        />
        <Input
          autoFocus
          _focus={{
            boxShadow: 'none',
            color: 'black',
            fontWeight: 'extrabold',
          }}
          borderColor={errors.amount ? 'red.400 !important' : 'border.100'}
          _hover={{
            borderColor: 'blue.300',
          }}
          maxLength="12"
          value={showInputZero ? '' : thousandSeparator(amount)}
          {...register('amount', {
            required: true,
            min: 1,
            onChange: (e) => {
              setShowInputZero(!e.target.value)
            },
            onFocus: (e) => setShowInputZero(e.target.value === '0'),
            onBlur: () => setShowInputZero(false),
            setValueAs: (v) => {
              if (typeof v === 'number') {
                return v
              }
              return v?.replace(/[^0-9]|^0+(?!$)/g, '')
            },
          })}
          pr="6"
          color={Number(amount) === 0 ? '#CDCDCD' : 'black'}
          fontWeight={Number(amount) === 0 ? '400' : 'extrabold'}
          pattern="[0-9,]*"
          pl={44}
          textAlign={{ base: 'right', sm: 'left' }}
          fontSize="2rem"
          height="86px"
          borderRadius="12px"
          backgroundColor="white !important"
        />
      </InputGroup>
    </FormControl>
  )
}
