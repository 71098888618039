export const predefinedAmountsOneTime = {
  USD: [
    { value: 8, label: '$8' },
    { value: 13, label: '$13' },
    { value: 26, label: '$26' },
    { value: 50, label: '$50' },
    { value: 100, label: '$100' },
    { value: 200, label: '$200' },
  ],
  EUR: [
    { value: 8, label: '€8' },
    { value: 12, label: '€12' },
    { value: 24, label: '€24' },
    { value: 50, label: '€50' },
    { value: 100, label: '€100' },
    { value: 200, label: '€200' },
  ],
  AMD: [
    { value: 3_000, label: '֏3,000' },
    { value: 5_000, label: '֏5,000' },
    { value: 10_000, label: '֏10,000' },
    { value: 20_000, label: '֏20,000' },
    { value: 40_000, label: '֏40,000' },
    { value: 80_000, label: '֏80,000' },
  ],
  RUB: [
    { value: 600, label: '₽600' },
    { value: 1_000, label: '₽1,000' },
    { value: 2_000, label: '₽2,000' },
    { value: 4_000, label: '₽4,000' },
    { value: 8_000, label: '₽8,000' },
    { value: 15_000, label: '₽15,000' },
  ],
}

export const predefinedAmountsMonthly = {
  USD: [
    { value: 5, label: '$5' },
    { value: 10, label: '$10' },
    { value: 21, label: '$21' },
    { value: 40, label: '$40' },
    { value: 50, label: '$50' },
  ],
  EUR: [
    { value: 5, label: '€5' },
    { value: 10, label: '€10' },
    { value: 20, label: '€20' },
    { value: 40, label: '€40' },
    { value: 50, label: '€50' },
  ],
  AMD: [
    { value: 2_500, label: '֏2,500' },
    { value: 4_000, label: '֏4,000' },
    { value: 8_000, label: '֏8,000' },
    { value: 16_000, label: '֏16,000' },
    { value: 20_000, label: '֏20,000' },
  ],
  RUB: [
    { value: 500, label: '₽500' },
    { value: 800, label: '₽800' },
    { value: 1_600, label: '₽1,600' },
    { value: 3_000, label: '₽3,000' },
    { value: 4_000, label: '₽4,000' },
  ],
}
