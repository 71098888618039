// @TODO: split this huge file into multiple files/hooks/helpers/services/...

import { useState, useEffect, useMemo } from 'react'
import { Box, Flex, Spinner, VStack } from '@chakra-ui/react'
import BackButton from 'components/BackButton'
import { GridItem } from '@chakra-ui/react'
import {
  useUser,
  useQueryParams,
  useGetProject,
  useCreateCustomer,
  useUpdateUser,
  useLocalStorage,
} from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import CartAmountForm from 'components/CartAmountForm'
import Cart from 'components/Cart'
import AttachedCards from './AttachedCards'
import Layout from './Layout'
import Header from './MobileDonation/Header'
import useDonationForm from './useDonationForm'
import GiftCardForm from './GiftCardForm'
import GiftCardBanner from 'components/GiftCardBanner'
import { useGiftCard } from 'contexts/GiftCardContext'
import ContributeBanner from './ContributeBanner'
import ToRearmeniaTotal from './ToRearmeniaTotal'
import DonorList from './DonorList'
import { useRef } from 'react'
import amplitude from 'amplitude-js'
import ReArmeniaTip from './ReArmeniaTip'
import FbPixel from 'components/FbPixel'
import fbPixel from 'fbPixel'
import PaymentGuestUserForm from 'components/PaymentGuestUserForm'
import { useScreenSize } from 'contexts'
import CheckboxesArea from './CheckboxesArea'
import DonationAgreement from './DonationAgreement'
import DonateOrSubscribeButton from './DonateOrSubscribeButton'
import useDonation from './helpers/useDonation'
import ResponsiveElementsWrapper from './ResponsiveElementsWrapper'
import MonthlyNoticeBanner from './MonthlyNoticeBanner'
import { useCustomToast } from 'hooks'

const Donation = ({ rearmeniaProjectId }) => {
  const { isMobile } = useScreenSize()
  const { put } = useLocalStorage()
  const queryParams = useQueryParams()
  const { user } = useUser()
  const { updateUser } = useUpdateUser()
  const { currency } = useCurrencySettings()
  const { t, language } = useTranslation()
  const toast = useCustomToast()
  const projectId = queryParams.get('projectId') || rearmeniaProjectId
  const peerProjectId = queryParams.get('peerProjectId')
  const isBuyingGiftCard = projectId === 'giftCard'
  const giftCard = queryParams.get('giftCard')
  const hasPerkIndex = queryParams.has('perkIndex')
  const isContribution = projectId === 'rearmenia'

  const { isLoading: isLoadingProject, data: project } = useGetProject({
    projectId: projectId ?? '',
  })
  const { data: peerProject, isLoading: isLoadingPeerProject } = useGetProject({
    projectId: peerProjectId ?? '',
  })

  const { mutate: createCustomer } = useCreateCustomer()
  const [percent, setPercent] = useState(7.5)
  const buttonRef = useRef(null)
  const [nav, setNav] = useState('none')
  const {
    giftCardMode: isGiftCardMode,
    giftCard: giftCardInfo,
    setGiftCardMode,
    setGiftCard,
  } = useGiftCard()

  const giftCardMode = useMemo(
    () =>
      !isContribution &&
      projectId !== 'giftCard' &&
      (!project?.isOneTimeDisabled || project?.fundraisingType === 'oneTime') &&
      isGiftCardMode,
    [isContribution, projectId, project, isGiftCardMode]
  )

  const showStripe =
    (project?.stripeId && project?.isStripeOnBoardingDone) ||
    isContribution ||
    isBuyingGiftCard

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    appliedGiftCard,
  } = useDonationForm({
    giftCard,
    giftCardMode,
    user,
    project,
    hasDefaultAmount: queryParams.has('amount'),
    defaultAmount:
      queryParams.get('amount') === 'undefined'
        ? 0
        : Number(queryParams.get('amount')),
    hasFirstName: queryParams.has('firstName'),
    firstName: queryParams.get('firstName'),
    hasLastName: queryParams.has('lastName'),
    lastName: queryParams.get('lastName'),
    hasEmail: queryParams.has('email'),
    email: queryParams.get('email'),
    projectId,
    isContribution,
    showStripe,
    peerProjectId,
  })

  const isGiftCardDisabled =
    queryParams.has('giftCard') || watch('paymentType') !== 'oneTime'

  useEffect(() => {
    if (user.id === 'guest') {
      setValue('bindingId', null)
    }
  }, [user])

  const {
    onDonate,
    isLoading,
    clientSecret,
    setPaymentMethod,
    setTokenId,
    setNewStripeCard,
  } = useDonation({
    getValues,
    watch,
    setError,
    giftCardMode,
    giftCard,
    giftCardInfo,
    project,
    projectId,
    peerProject,
    peerProjectId,
    appliedGiftCard,
    isContribution,
    isBuyingGiftCard,
    setGiftCardMode,
    setGiftCard,
  })
  const onScroll = () => {
    // working for donate to rearmenia page (contribution)
    if (!buttonRef.current) {
      return
    }
    const current = window.scrollY
    const { y } = buttonRef.current
    if (current > y + 20) {
      setNav('block')
    }
    if (current < y) {
      setNav('none')
    }
  }

  const handleGiftCardAmount = (e) => {
    setPercent(Number(e))
    setValue('reArmenia', (Number(giftCardInfo.amount) * Number(e)) / 100)
  }

  useEffect(() => {
    if (!user || !project || user.customerId || user.id === 'guest') {
      return
    }
    if (project?.stripeId && project?.isStripeOnBoardingDone) {
      createCustomer(
        {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
        },
        {
          onSuccess: (res) => {
            const input = {
              id: user.id,
              customerId: res.customer.id,
            }
            updateUser(input, {
              onSuccess: (newUser) => {
                put('rearmenia_user', JSON.stringify(newUser.data.updateUser))
              },
              onError: (error) => {
                console.log('UPDATE USER ERROR', error)
              },
            })
          },
          onError: (error) => {
            console.log('CREATE CUSTOMER ERROR', error)
          },
        }
      )
    }
  }, [user, project])

  useEffect(() => {
    if (giftCardInfo && giftCardInfo.amount && giftCardMode) {
      setValue(
        'reArmenia',
        (Number(giftCardInfo.amount) * Number(percent)) / 100
      )
    }
  }, [giftCardInfo, giftCardMode, percent])

  useEffect(() => {
    if (percent && !isContribution) {
      setValue('reArmenia', (getValues('amount') * percent) / 100)
    }
  }, [percent, watch('amount')])

  useEffect(() => {
    if (giftCardMode) {
      setValue('amount', giftCardInfo.amount)
    }
  }, [giftCardMode])

  useEffect(() => {
    if (project?.fbPixel) {
      fbPixel.pageView()
      fbPixel.track('Donation')
    }
  }, [project?.fbPixel])

  useEffect(() => {
    // add scroll event for donate to rearmenia page (contribution)
    if (!isContribution) {
      return
    }
    document.addEventListener('scroll', onScroll)

    return () => document.removeEventListener('scroll', onScroll)
  }, [onScroll])

  useEffect(() => {
    amplitude.getInstance().logEvent('Web Donation page view')
    if (isBuyingGiftCard) {
      setValue('paymentType', 'oneTime')
      setValue('attachCard', false)
    }
  }, [])

  useEffect(() => {
    if (project?.fundraisingType === 'non-profit') {
      setValue('paymentType', 'recurring')
    }
  }, [project])

  if (isLoadingProject || isLoadingPeerProject) {
    return (
      <Layout isLoading={isLoadingProject}>
        <Spinner color="blue.700" />
      </Layout>
    )
  }

  const isRecurring =
    (getValues('paymentType') === 'recurring' ||
      (project?.fundraisingType === 'non-profit' &&
        project?.isOneTimeDisabled)) &&
    !isBuyingGiftCard

  const isOneTimeOrMonthly =
    (project?.fundraisingType === 'recurring' &&
      !project?.isOneTimeDisabled &&
      !isContribution &&
      !giftCardMode) ||
    (project?.fundraisingType === 'non-profit' && !project?.isOneTimeDisabled)

  const onError = (errors) => {
    console.log(errors)
    if (errors) {
      toast({
        title: t(
          errors.amount ? errors.amount.message : 'donation@addPersonalInfo'
        ),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: isMobile ? 'top' : undefined,
      })
    }
  }

  return (
    <>
      {project?.fbPixel && <FbPixel pixelId={project?.fbPixel} />}
      {isMobile && (
        <Header
          isBuyingGiftCard={isBuyingGiftCard}
          project={peerProject?.id ? peerProject : project}
          language={language}
          isRecurring={isRecurring}
          isOneTimeOrMonthly={isOneTimeOrMonthly}
          reArmenia={watch('reArmenia')}
          giftCardMode={giftCardMode}
          giftCardInfo={giftCardInfo}
          GiftCardBanner={GiftCardBanner}
          isContribution={isContribution}
          hasPerkIndex={hasPerkIndex}
        />
      )}

      <Layout
        onFormSubmit={handleSubmit(onDonate, onError)}
        spacingX={!isMobile}
        spacingY={!isMobile}
      >
        <GridItem colSpan={{ base: 12, sm: 6 }}>
          {!isMobile && (
            <BackButton
              to={
                projectId === 'giftCard'
                  ? '/select-gift-card'
                  : isContribution
                  ? '/'
                  : `/fundraisers/${peerProjectId || projectId}`
              }
            />
          )}
          {giftCardMode && (
            <>
              {!isMobile && (
                <GiftCardBanner
                  giftCardAmount={giftCardInfo.amountWithCurrency}
                  giftCardCurrency={giftCardInfo.currency}
                  border
                />
              )}
              <ReArmeniaTip
                price={giftCardInfo.amount / giftCardInfo.currencyRate}
                onChange={handleGiftCardAmount}
                currency={giftCardInfo.currency}
              />

              {user.id === 'guest' && (
                <PaymentGuestUserForm
                  register={register}
                  errors={errors}
                  title={t('donation@addPersonalInfo')}
                  firstName={t('donation@firstName')}
                  lastName={t('donation@lastName')}
                  email={t('donation@email')}
                  emailErrorMessage={t(errors?.email?.message)}
                />
              )}
            </>
          )}
          {isContribution && <ContributeBanner />}
          <Box w="full" mb="12">
            {!giftCardMode && (
              <>
                {!isMobile && (isRecurring || isOneTimeOrMonthly) && (
                  <Box marginBottom={8}>
                    <MonthlyNoticeBanner
                      isOneTimeOrMonthly={isOneTimeOrMonthly}
                    />
                  </Box>
                )}

                <CartAmountForm
                  isBuyingGiftCard={isBuyingGiftCard}
                  project={peerProject?.id ? peerProject : project}
                  giftCardMode={giftCardMode}
                  isContribution={isContribution}
                  donation={{
                    amount: watch('amount'),
                    reArmenia: watch('reArmenia'),
                  }}
                  setValue={setValue}
                  errors={errors}
                  register={register}
                  getValues={getValues}
                  setPercent={setPercent}
                  note={isContribution && t('donation@donateToRearmeniaNote')}
                  isRecurring={isRecurring}
                  isOneTimeOrMonthly={isOneTimeOrMonthly}
                  showStripe={showStripe}
                />

                <ResponsiveElementsWrapper>
                  <AttachedCards
                    watch={watch}
                    setPaymentMethod={setPaymentMethod}
                    setError={setError}
                    getValues={getValues}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    trigger={trigger}
                    onChange={(bindingId) => {
                      if (bindingId) {
                        setValue('attachCard', false)
                      }
                      setValue('bindingId', bindingId)
                    }}
                    isRecurring={isRecurring}
                    isContribution={isContribution}
                    showGiftCard={
                      !(
                        queryParams.has('giftCard') ||
                        watch('paymentType') !== 'oneTime'
                      ) &&
                      !giftCardMode &&
                      !isContribution
                    }
                    register={register}
                    errors={errors}
                    isGiftCardDisabled={isGiftCardDisabled}
                    donationPage
                    clientSecret={clientSecret}
                    showStripe={showStripe}
                    setTokenId={setTokenId}
                    setNewStripeCard={setNewStripeCard}
                    isCharityBucket={false}
                  />
                </ResponsiveElementsWrapper>
              </>
            )}

            <ResponsiveElementsWrapper>
              {giftCard && (
                <GiftCardForm
                  user={user}
                  register={register}
                  errors={errors}
                  mt={6}
                />
              )}

              {isMobile && !giftCardMode && (
                <>
                  {isContribution ? (
                    <ToRearmeniaTotal
                      amount={watch('amount')}
                      currency={currency.current}
                      mt={6}
                    />
                  ) : (
                    <Cart
                      project={peerProject?.id ? peerProject : project}
                      isContribution={isContribution}
                      donation={{
                        amount: watch('amount'),
                        reArmenia:
                          watch('reArmenia') / (giftCardInfo.currencyRate || 1),
                      }}
                      isGiftCardDisabled={isGiftCardDisabled}
                      isBuyingGiftCard={isBuyingGiftCard}
                      giftCardMode={giftCardMode}
                      isRecurring={isRecurring}
                    />
                  )}
                </>
              )}

              <CheckboxesArea
                giftCard={giftCard}
                giftCardMode={giftCardMode}
                isBuyingGiftCard={isBuyingGiftCard}
                register={register}
              />

              <Box marginY="16px">
                <DonateOrSubscribeButton
                  isRecurring={isRecurring}
                  giftCard={giftCard}
                  giftCardMode={giftCardMode}
                  isContribution={isContribution}
                  currency={currency.current}
                  project={project}
                  peerProject={peerProject}
                  buttonRef={buttonRef}
                  nav={nav}
                  isLoading={isLoading}
                  getValues={getValues}
                  watch={watch}
                  trigger={trigger}
                  setError={setError}
                  clearErrors={clearErrors}
                  clientSecret={clientSecret}
                />
              </Box>

              <DonationAgreement
                isBuyingGiftCard={isBuyingGiftCard}
                isContribution={isContribution}
                project={project}
                giftCard={giftCard}
                giftCardMode={giftCardMode}
              />

              {isContribution && project?.id && isMobile && (
                <DonorList
                  project={project}
                  mt={6}
                  pt={12}
                  pb={28}
                  borderTopWidth="1px"
                  borderColor="border.100"
                />
              )}
            </ResponsiveElementsWrapper>
          </Box>
        </GridItem>
        {!isMobile && (
          <>
            <GridItem colSpan={1} />{' '}
            <GridItem colSpan={{ base: 6, sm: 5 }}>
              {isContribution ? (
                <VStack>
                  <ToRearmeniaTotal
                    currency={currency.current}
                    amount={watch('amount')}
                    mb={6}
                  />
                  {project?.id && <DonorList project={project} w="full" />}
                </VStack>
              ) : (
                <Flex justifyContent="flex-end">
                  <Cart
                    project={peerProject?.id ? peerProject : project}
                    isContribution={isContribution}
                    donation={{
                      amount: watch('amount'),
                      reArmenia:
                        watch('reArmenia') / (giftCardInfo.currencyRate || 1),
                    }}
                    isGiftCardDisabled={isGiftCardDisabled}
                    isBuyingGiftCard={isBuyingGiftCard}
                    giftCardMode={giftCardMode}
                    isRecurring={isRecurring}
                  />
                </Flex>
              )}
            </GridItem>
          </>
        )}
      </Layout>
    </>
  )
}

export default Donation
