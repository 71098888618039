import { Box, Flex, Text } from '@chakra-ui/react'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { useScreenSize } from 'contexts/ScreenSizeContext'
import PoweredBy from './PoweredBy'

const DonationAgreement = ({
  isRecurring,
  isBuyingGiftCard,
  isContribution,
  project,
  showStripe,
  giftCard,
  giftCardMode,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useScreenSize()

  return (
    <Flex flexDirection="column" gap="16px">
      {!giftCard && !giftCardMode && (
        <Text
          textAlign="center"
          fontSize="sm"
          color="gray.700"
          fontWeight="400"
        >
          {isRecurring
            ? t('donation@termsSubscribe')
            : t('donation@termsDonate')}{' '}
          <Link target="_blank" to="/terms-and-conditions">
            <Box color="gray.800" as="span" fontWeight="500">
              {t('donation@termsUse')}
            </Box>
          </Link>{' '}
          {t('donation@privacy')}
          <Link target="_blank" to="/privacy-policy">
            <Box color="gray.800" as="span" fontWeight="500">
              {' '}
              {t('donation@policy')}.
            </Box>
          </Link>
        </Text>
      )}

      <PoweredBy
        project={project}
        showStripe={
          (project?.stripeId && project?.isStripeOnBoardingDone) ||
          isBuyingGiftCard ||
          isContribution ||
          showStripe
        }
        pb={isMobile & !isContribution ? 12 : 0}
      />
    </Flex>
  )
}

export default DonationAgreement
