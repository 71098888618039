import { useEffect, useMemo } from 'react'
import { Box, Stack } from '@chakra-ui/react'
import { LANG_RU_SHORT, useTranslation } from 'contexts/TranslationContext'
import PerkCard from 'components/PerkCard'
import Progress from 'components/Progress'
import { calculateProjectDurationLeft, projectProgress } from 'hooks'
import {
  convert,
  CurrencyConverter,
  SUPPORTED_CURRENCIES,
  thousandSeparator,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import { useScreenSize } from 'contexts/ScreenSizeContext'
import { FUNDRAISER_NONPROFIT } from 'constants/fundraiser'
import Tip from './Tip'
import MonthlyToggle from './MonthlyToggle'
import ResponsiveElementsWrapper from 'pages/Donation/ResponsiveElementsWrapper'
import EnterAmount from './EnterAmount'

export default function Web({
  PRICE,
  donation,
  setValue,
  other,
  perk,
  errors,
  register,
  handleAmount,
  isOneTimeOrMonthly,
  project,
  isContribution,
  isBuyingGiftCard,
  note,
  isRecurring,
  showStripe,
}) {
  const { t, language } = useTranslation()
  const { isMobile } = useScreenSize()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const newProject = {
    ...project,
    amount: donation.amount,
  }

  const progressData = projectProgress(newProject, currency?.current)
  useEffect(() => {
    if (!project) return
    project.hideStats = true
  }, [])

  const progressPercent = useMemo(() => {
    const projectAmount = convert({
      amount: project?.amount || 0,
      currencyRate: settings?.currency[currency.current],
    })
    const projectGoal = convert({
      amount: project?.activeGoal?.amount || project?.goal || 0,
      currencyRate: settings?.currency[currency.current],
    })

    return ((projectAmount + +progressData.amount) / projectGoal) * 100
  }, [
    project?.amount,
    project?.goal,
    project?.activeGoal?.amount,
    progressData.amount,
    currency.current,
    settings?.currency,
  ])

  return (
    <Stack w="full" mt={{ base: 0, sm: isBuyingGiftCard ? 8 : 0 }} spacing={8}>
      <Box
        w="full"
        background={isBuyingGiftCard ? 'gray.300' : 'white'}
        px={{ base: 0, sm: '20px' }}
        py={{ base: isBuyingGiftCard ? 5 : '24px', sm: '20px' }}
        borderRadius={{ base: 0, sm: '16px' }}
        backgroundColor="gray.300"
      >
        <ResponsiveElementsWrapper>
          {perk !== null ? (
            <>
              <Box mb="1" as="span" fontWeight="500" fontSize="xl">
                {t('donation@perk')}
              </Box>
              <PerkCard
                title={perk[`title_${language}`] ?? ''}
                description={perk[`description_${language}`] ?? ''}
                price={perk.price ?? 0}
                image={perk.imageKey ?? perk.image}
                isSelectable={false}
                convertPrice={false}
              />
            </>
          ) : (
            <>
              <EnterAmount
                isRecurring={isRecurring}
                donation={donation}
                isBuyingGiftCard={isBuyingGiftCard}
                note={note}
                progressData={progressData}
                setValue={setValue}
                errors={errors}
                register={register}
              />

              {project?.isProject &&
                project.fundraisingType !== FUNDRAISER_NONPROFIT && (
                  <Progress
                    project={{ ...project, updateAmount: progressData.amount }}
                    raisedMoney={thousandSeparator(
                      convert({
                        amount: project.amount,
                        currencyRate: settings?.currency[currency.current],
                      }),
                      currency.current
                    )}
                    progress={progressPercent}
                    optionsTexts={SUPPORTED_CURRENCIES.map((option) => ({
                      text: t(`currency@${option}`),
                      amount: CurrencyConverter({
                        amount: project?.amount,
                        currency: option,
                      }),
                    }))}
                    donationPage
                    thousandSeparatorText={thousandSeparator(
                      currency.current === 'AMD'
                        ? project.amount + Number(progressData.amount)
                        : project.amount /
                            Number(settings?.currency[currency.current]) +
                            Number(progressData.amount),
                      currency.current
                    )}
                    monthlyGoalText={t('monthlyGoal')}
                    goalPrice={project.activeGoal?.amount || project.goal}
                    goalText={t('goal')}
                    raisedText={
                      language === LANG_RU_SHORT && isMobile ? '' : t('raised')
                    }
                    calculatedProjectDurationLeft={calculateProjectDurationLeft(
                      {
                        t,
                        campaignImplementorPeriod_en: {
                          ...project,
                          updateAmount: progressData.amount,
                        }.campaignImplementorPeriod_en,
                        fundraisingType: {
                          ...project,
                          updateAmount: progressData.amount,
                        }.fundraisingType,
                      }
                    )}
                    currency={currency}
                    settings={settings}
                    changeDefaultCurrency={changeDefaultCurrency}
                  />
                )}
            </>
          )}

          {isOneTimeOrMonthly && (
            <Box marginTop="16px">
              <MonthlyToggle setValue={setValue} />
            </Box>
          )}
        </ResponsiveElementsWrapper>
      </Box>

      {!isContribution && (
        <ResponsiveElementsWrapper>
          <Tip
            price={perk !== null ? perk.price : donation.amount}
            other={other}
            handleAmount={handleAmount}
            setValue={setValue}
            donation={donation}
            PRICE={PRICE}
          />
        </ResponsiveElementsWrapper>
      )}
    </Stack>
  )
}
