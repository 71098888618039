import React, { useCallback, useRef, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  Divider,
  Heading,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { useTranslation } from 'contexts/TranslationContext'
import CountrySelect from 'components/CountrySelectSearch'
import ProfilePictureUpload from 'components/ProfilePictureUpload'
import amplitude from 'amplitude-js'
import { Controller } from 'react-hook-form'
import { useQueryParams, useStringManipulations, useUploadFiles } from 'hooks'
import { useHistory } from 'react-router-dom'
import MultiSelect from 'components/MultiSelect'
import CustomSwitch from 'components/CustomSwitch'
import { TextAreaComponent } from 'components/TextAreaComponent'
import { EditSocialPopover } from './EditSocialPopover'
import { HoursPopover } from './HoursPopover'
import { LanguagesPopover } from './LanguagesPopover'

//** Icons
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as PrivateIcon } from 'assets/icons/isPrivate.svg'
import { ReactComponent as Upload } from 'assets/icons/upload.svg'
import { ReactComponent as Reached } from 'assets/icons/reachedBig.svg'
import { DirectionsPopover } from './DirectionsPopover'
import { DisabledBox } from 'components/DisabledBox'
import { ReactComponent as CheckedCheckboxIcon } from 'assets/icons/checkboxChecked.svg'
import { useNavigation } from 'pages'
import { useScreenSize } from 'contexts'
import LinksPopover from 'components/LinksPopover'

const CustomIcon = ({ isChecked }) =>
  isChecked ? <CheckedCheckboxIcon /> : null

const SOCIALS_LIMIT = 4
const SPHERES_LIMIT = 5

export default function ExpertProfileForm({
  errors,
  register,
  setValue,
  watch,
  getValues,
  control,
  language,
  isSubmitLoading,
  expert,
  clearErrors,
  clearForm,
  trigger,
  isDisabled,
  expertiseOptions,
  spheresArray,
  socials,
  appendSocial,
  removeSocial,
  updateSocial,
  customSpheres,
  appendCustomSpheres,
  removeCustomSpheres,
  updateCustomSpheres,
  spheres,
  appendSpheres,
  removeSpheres,
  updateSpheres,
  ...props
}) {
  const { t } = useTranslation()
  const params = useQueryParams()
  const isEdit = params.has('edit')
  const history = useHistory()
  const { navigationPush } = useNavigation()

  const handleRemoveSpheres = (indexToRemove) => {
    const spheres = spheresArray.filter((_, index) => index !== indexToRemove)
    setValue('spheres', spheres)
  }

  return (
    <Flex direction="column" gap={{ base: 4, sm: 6 }} {...props}>
      <MainForm
        errors={errors}
        register={register}
        setValue={setValue}
        watch={watch}
        getValues={getValues}
        control={control}
        language={language}
        countryCode={expert?.countryCode}
        expert={expert}
        trigger={trigger}
        isEdit={isEdit}
        clearErrors={clearErrors}
        isDisabled={isDisabled}
        expertiseOptions={expertiseOptions}
        spheresArray={spheresArray}
        handleRemoveSpheres={handleRemoveSpheres}
        socials={socials}
        appendSocial={appendSocial}
        removeSocial={removeSocial}
        updateSocial={updateSocial}
        customSpheres={customSpheres}
        appendCustomSpheres={appendCustomSpheres}
        removeCustomSpheres={removeCustomSpheres}
        updateCustomSpheres={updateCustomSpheres}
        spheres={spheres}
        appendSpheres={appendSpheres}
        removeSpheres={removeSpheres}
        updateSpheres={updateSpheres}
      />
      <Flex justify="flex-end" mt={{ base: 0, sm: 2 }}>
        {expert?.expert_status !== 'rejected' && (
          <Button
            variant="border"
            w={{ base: '100%', sm: '160px' }}
            bg="white"
            mr={4}
            isDisabled={isDisabled || isSubmitLoading}
            onClick={() => {
              if (isEdit) {
                history.goBack()
              } else {
                navigationPush('/profile/expert/card')
              }
              clearForm()
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
          >
            {t('cancel')}
          </Button>
        )}
        <Button
          type="submit"
          variant="green"
          w={{ base: '100%', sm: '170px' }}
          isLoading={isSubmitLoading}
          isDisabled={isDisabled}
        >
          {isDisabled ? t('sent') : isEdit ? t('save') : t('submit')}
        </Button>
      </Flex>
    </Flex>
  )
}

const MainForm = ({
  language,
  errors,
  register,
  setValue,
  watch,
  getValues,
  control,
  trigger,
  clearErrors,
  isDisabled,
  expertiseOptions,
  spheresArray,
  handleRemoveSpheres,
  socials,
  appendSocial,
  removeSocial,
  updateSocial,
  customSpheres,
  appendCustomSpheres,
  removeCustomSpheres,
  updateCustomSpheres,
  spheres,
  appendSpheres,
  removeSpheres,
  updateSpheres,
}) => {
  const { isMobile } = useScreenSize()
  const { tt } = useTranslation()
  const [loading, setLoading] = useState()
  const { mutate: filesUpload } = useUploadFiles()
  const { findNamingTranslation } = useStringManipulations()

  const isEmptyFields = !socials?.filter(({ url, added }) => url && added)
    .length

  const onAddSocialField = useCallback(() => {
    if (socials.length === SOCIALS_LIMIT) return
    appendSocial({
      url: '',
      added: false,
    })
  }, [socials])

  const onAddSphereField = useCallback(() => {
    if (customSpheres.length === SPHERES_LIMIT) return
    appendCustomSpheres({
      title_en: '',
      title_hy: '',
      title_ru: '',
      added: false,
    })
  }, [customSpheres])

  const inputRef = useRef(null)

  const handleClick = () => inputRef.current?.click()

  const uploadFiles = (event) => {
    const { files } = event.target
    if (files && files[0]) {
      setLoading(true)
      const newFiles = Object.values(files)
      filesUpload(
        {
          data: newFiles,
        },
        {
          onSuccess: (data) => {
            clearErrors('cv')
            const url = data[0].key
            setValue('cv', url)
            setLoading(false)
          },
          onError: (err) => {
            console.log('❌', err)
            setLoading(false)
          },
        }
      )
    }
  }
  const handleRemoveDirections = (indexToRemove) => {
    const directions = watch('interestedIn').filter(
      (_, index) => index !== indexToRemove
    )
    setValue('interestedIn', directions)
  }

  return (
    <>
      <Flex
        background="white"
        boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.06),0px 1px 2px 0px rgba(0, 0, 0, 0.05),0px 4px 4px 0px rgba(0, 0, 0, 0.05),0px 9px 5px 0px rgba(0, 0, 0, 0.03),0px 16px 6px 0px rgba(0, 0, 0, 0.01)"
        borderRadius={8}
        p={4}
        gap={2}
        alignSelf="flex-end"
        align="center"
        display={{ base: 'none', sm: 'flex' }}
        pos="relative"
      >
        {isDisabled && <DisabledBox />}
        <PrivateIcon width={24} />
        <Text fontSize="md">{tt('Publish My Card', language)}</Text>
        <CustomSwitch
          isChecked={String(watch('isPrivate')) === 'false'}
          onChange={() =>
            setValue('isPrivate', String(watch('isPrivate')) === 'false')
          }
        />
      </Flex>

      <Flex
        mt={{ base: '100px', sm: 4 }}
        direction={{ base: 'column', sm: 'row' }}
        borderRadius={20}
        border="solid 1px"
        borderColor="#E5E4E7"
        bg={{ base: 'white', sm: '#FBFAFD' }}
      >
        <Flex
          direction="column"
          w={{ base: 'full', sm: '317px' }}
          px={5}
          pb="30px"
          border={isMobile ? 'none' : 'solid 1px'}
          borderColor="border.400"
          borderRadius={20}
          bg="white"
        >
          <ProfilePictureUpload
            isDisabled={isDisabled}
            clearErrors={clearErrors}
            label={tt('expert@form@profilePicture', language)}
            image={getValues('imageUrl', language)}
            name="imageUrl"
            setValue={setValue}
            errorMessage={tt('createEditProject@thisFieldIsRequired', language)}
            isInvalid={!!errors.imageUrl}
            marginTop="-100px"
          />
          <Flex direction="column" gap={6}>
            <Flex
              gap={4}
              direction="column"
              w="full"
              mt={{ base: 8, sm: 7 }}
              mb={6}
            >
              <InputField
                _placeholder={{ fontSize: 'md' }}
                isDisabled={isDisabled}
                placeholder={tt('Add your name', language)}
                {...register(`firstName_${language}`, {
                  required: tt(
                    'profile@settings-firstNameErrorMessage',
                    language
                  ),
                })}
                isInvalid={errors[`firstName_${language}`]}
                background="white"
              />
              <InputField
                _placeholder={{ fontSize: 'md' }}
                isDisabled={isDisabled}
                {...register(`lastName_${language}`, {
                  required: tt(
                    'profile@settings-lastNameErrorMessage',
                    language
                  ),
                })}
                placeholder={tt('Add your surname', language)}
                isInvalid={errors[`lastName_${language}`]}
                background="white"
              />
              <InputField
                _placeholder={{ fontSize: 'md' }}
                isDisabled={isDisabled}
                errorMessage={tt(
                  'createEditProject@phoneNumberErrorMessage',
                  language
                )}
                isInvalid={!!errors.phone}
                placeholder={tt('signUp@phoneNumber', language)}
                {...register(`phone`, {
                  required: tt(
                    'createEditProject@phoneNumberErrorMessage',
                    language
                  ),
                  pattern: /^[0-9+-]+$/,
                  minLength: 8,
                  maxLength: 15,
                })}
                maxLength={15}
                background="white"
              />
              <TextAreaComponent
                isInvalid={!!errors[`title_${language}`]}
                placeholder={tt('expert@form@positionCompany', language)}
                maxLength={100}
                isDisabled={isDisabled}
                minH="48px"
                defaultValue={getValues(`title_${language}`)}
                {...register(`title_${language}`, {
                  required: tt(
                    'createEditProject@thisFieldIsRequired',
                    language
                  ),
                  maxLength: 100,
                })}
                errorMessage={tt(
                  'createEditProject@thisFieldIsRequired',
                  language
                )}
                _placeholder={{ fontSize: 'sm' }}
              />
            </Flex>
            <Divider borderColor="gray.500" />
            <Flex direction="column" gap={4}>
              <Flex justify="center" gap={4} pos="relative" w="full">
                {socials
                  ?.filter(({ added }) => added)
                  ?.map(({ id, url }, index) => (
                    <EditSocialPopover
                      key={id}
                      tt={tt}
                      language={language}
                      isDisabled={isDisabled}
                      update={updateSocial}
                      remove={removeSocial}
                      url={url}
                      getValues={getValues}
                      index={index}
                    />
                  ))}
                <LinksPopover
                  watch={watch}
                  language={language}
                  data={socials}
                  register={register}
                  trigger={trigger}
                  isEmptyFields={isEmptyFields}
                  remove={removeSocial}
                  append={appendSocial}
                  update={updateSocial}
                  isInvalid={errors.socials}
                  onAddField={onAddSocialField}
                  LIMIT={SOCIALS_LIMIT}
                  isDisabled={isDisabled}
                  registerKey="socials"
                />
              </Flex>
              <CountrySelect
                t={tt}
                language={language}
                setValue={setValue}
                getValues={getValues}
                isInvalid={!watch('country')?.code}
                watch={watch}
                isDisabled={isDisabled}
              />
              <HoursPopover
                tt={tt}
                language={language}
                watch={watch}
                control={control}
                isInvalid={errors.hrsPerWeek}
                isDisabled={isDisabled}
              />

              <LanguagesPopover
                tt={tt}
                watch={watch}
                control={control}
                isInvalid={errors.selectedLanguage}
                isDisabled={isDisabled}
                language={language}
              />
            </Flex>
          </Flex>
        </Flex>

        {isMobile && (
          <Box px={5}>
            <Divider borderColor="gray.500" />
          </Box>
        )}

        <Flex direction="column" gap={8} p={5} flex={1}>
          <Flex direction="column">
            <Flex w="full" justify="space-between" align="center" mb={2}>
              <Text
                fontSize="lg"
                fontWeight={600}
                bg="transparent"
                color={
                  errors[`description_${language}`] ? 'red.400' : 'gray.800'
                }
              >
                {tt('expert@form@aboutYou', language)}
                {errors[`description_${language}`] ? '*' : ''}
              </Text>
              <Box pos="relative">
                {isDisabled && <DisabledBox />}
                <Button
                  variant="white"
                  p={2}
                  align="center"
                  minH={{ base: '32px', sm: '44px' }}
                  h={{ base: '32px', sm: '44px' }}
                  borderColor={errors.cv ? 'red.400' : 'gray.800'}
                  isLoading={loading}
                  onClick={handleClick}
                >
                  {watch('cv') ? (
                    <>
                      <Reached width="23px" />
                      <Text
                        color="gray.700"
                        fontWeight={400}
                        fontSize="md"
                        ml={2}
                        maxW="164px"
                        overflow="hidden"
                        textTransform="none"
                      >
                        {watch('cv')}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Upload style={isMobile ? { height: '16px' } : {}} />
                      <Text
                        color="gray.700"
                        fontWeight={400}
                        fontSize="md"
                        ml={2}
                        textTransform="none"
                      >
                        {tt('Upload your CV', language)}
                      </Text>
                    </>
                  )}
                </Button>
                <input
                  style={{ display: 'none' }}
                  ref={inputRef}
                  type="file"
                  onChange={uploadFiles}
                  id="cv"
                />
              </Box>
            </Flex>

            <TextAreaComponent
              isDisabled={isDisabled}
              placeholder={tt('expert@form@describeSkills', language)}
              defaultValue={getValues(`description_${language}`)}
              _placeholder={{ color: 'gray.600', fontSize: 'md' }}
              {...register(`description_${language}`, {
                required: tt('createEditProject@thisFieldIsRequired', language),
                maxLength: 300,
              })}
              maxLength={300}
              background="white"
              errorOnLabel
              pb={6}
            />
          </Flex>

          <Flex direction="column" w="full">
            <Heading
              mb={1}
              fontWeight={600}
              fontSize="lg"
              color={errors.spheres ? 'red.400' : 'gray.800'}
            >
              {tt('My Expertise', language)}
              {errors.spheres ? '*' : ''}
            </Heading>
            <Text mb={2} fontSize="md" color="gray.700">
              {tt('Select your areas of expertise', language)}
            </Text>
            <MultipleSelect
              isDisabled={isDisabled}
              findNamingTranslation={findNamingTranslation}
              expertiseOptions={expertiseOptions}
              spheres={[
                ...spheres,
                ...customSpheres
                  .filter(({ added }) => added)
                  .map((customSphere, index) => ({ ...customSphere, index })),
              ]}
              appendSpheres={appendSpheres}
              removeSpheres={removeSpheres}
              removeCustomSpheres={removeCustomSpheres}
              updateSpheres={updateSpheres}
              spheresArray={spheresArray}
              setValue={setValue}
              language={language}
              t={tt}
              handleRemoveSpheres={handleRemoveSpheres}
              register={register}
              multipleInputsProps={{
                language,
                isDisabled,
                inputSpheres: customSpheres,
                onAddSphereField,
                removeCustomSpheres,
                updateCustomSpheres,
                register,
                t: tt,
                watch,
              }}
            />
          </Flex>
          <Flex direction="column" w="full">
            <Heading
              mb={1}
              fontWeight={600}
              fontSize="lg"
              color={errors.interestedIn ? 'red.400' : 'gray.800'}
            >
              {tt('Will Contribute to', language)}
              {errors.interestedIn ? '*' : ''}
            </Heading>
            <Text mb={2} fontSize="md" color="gray.700">
              {tt(
                'From which areas would you like initiators to ask for your advice?',
                language
              )}
            </Text>
            <Flex flexWrap="wrap">
              {watch('interestedIn')?.map((direction, index) => (
                <ListItem
                  key={direction + index}
                  index={index}
                  isDisabled={isDisabled}
                  text={tt(`category@` + direction, language)}
                  handleRemoveSpheres={handleRemoveDirections}
                />
              ))}
            </Flex>
            <DirectionsPopover
              tt={tt}
              control={control}
              language={language}
              isDisabled={isDisabled}
            />
          </Flex>
          <Flex
            justify="space-between"
            background="white"
            mt="-16px"
            py={2}
            px={4}
            gap={2}
            align="center"
            display={{ base: 'flex', sm: 'none' }}
            pos="relative"
            border="solid 1px"
            borderColor="border.400"
            borderRadius={8}
          >
            {isDisabled && <DisabledBox />}
            <Flex>
              <PrivateIcon width={24} />
              <Text fontSize="md">{tt('Publish My Card', language)}</Text>
            </Flex>
            <CustomSwitch
              isChecked={String(watch('isPrivate')) === 'false'}
              onChange={() =>
                setValue('isPrivate', String(watch('isPrivate')) === 'false')
              }
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export const RadioCheckGroup = ({
  options,
  name,
  label,
  isInvalid,
  errorMessage,
  control,
  isDisabled,
}) => {
  return (
    <Box>
      {label && (
        <Text
          fontWeight={500}
          _after={{
            content: `"*"`,
            color: 'orange.400',
          }}
          mb={2}
        >
          {label}
        </Text>
      )}
      {isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          fontWeight="semibold"
          color="red.400"
          mb={2}
        >
          {errorMessage || isInvalid?.message}
        </Box>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} isDisabled={isDisabled}>
            <Stack>
              {options.map((option) => (
                <Radio key={option.label} value={option.value.toString()}>
                  {option.label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}
        rules={{
          required: true,
        }}
      />
    </Box>
  )
}

export const CheckboxGroupItems = ({
  options,
  name,
  label,
  isInvalid,
  errorMessage,
  control,
  isDisabled,
  rowGap = 3,
}) => {
  return (
    <Box>
      {label && (
        <Text
          fontWeight={500}
          _after={{
            content: `"*"`,
            color: 'orange.400',
          }}
          mb={2}
        >
          {label}
        </Text>
      )}
      {isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          fontWeight="semibold"
          color="red.400"
          mb={2}
        >
          {errorMessage || isInvalid?.message}
        </Box>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { ...rest } }) => (
          <CheckboxGroup {...rest} isDisabled={isDisabled}>
            <Flex
              direction="column"
              rowGap={rowGap}
              w="full"
              flexWrap={{ base: 'nowrap', sm: 'wrap' }}
            >
              {options.map((option, index) => (
                <Checkbox
                  sx={{
                    '& > span.chakra-checkbox__control': {
                      boxSizing: 'content-box',
                    },
                  }}
                  icon={<CustomIcon />}
                  size="lg"
                  key={option.value + index}
                  value={option.value}
                >
                  {option.label}
                </Checkbox>
              ))}
            </Flex>
          </CheckboxGroup>
        )}
      />
    </Box>
  )
}

export const MultipleSelect = ({
  isDisabled,
  expertiseOptions,
  spheresArray,
  language,
  isInvalid,
  t,
  multipleInputsProps,
  findNamingTranslation,
  spheres,
  appendSpheres,
  removeSpheres,
  removeCustomSpheres,
}) => (
  <>
    <Box flexWrap="wrap" display={spheres.length > 0 ? 'flex' : 'none'}>
      {spheres?.map((expertise, index) => (
        <React.Fragment key={expertise.id}>
          <ListItem
            key={expertise.id}
            index={expertise.index >= 0 ? expertise.index : index}
            isDisabled={isDisabled}
            text={
              expertise[`title_${language}`] ||
              findNamingTranslation({
                parentObject: expertise,
                value: 'title',
                language,
              })
            }
            removeCustomSpheres={
              expertise.index >= 0 ? removeCustomSpheres : null
            }
            handleRemoveSpheres={removeSpheres}
          />
        </React.Fragment>
      ))}
    </Box>
    <Box
      onClick={() => {
        amplitude.getInstance().logEvent('Expertise click')
        console.log('sent event to amplitude (Expertise click)')
      }}
    >
      <MultiSelect
        variant="white"
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        options={expertiseOptions}
        selectedOptions={spheresArray}
        appendSphere={appendSpheres}
        lang={language}
        label={t('Select areas', language)}
        inputPlaceholder={t('Find an area', language)}
        containerProps={{
          height: '40px',
          marginTop: '8px',
          sx: { '> *': { height: '100%', background: 'white' } },
        }}
        onChange={(categories) => {
          amplitude.getInstance().logEvent('Expertise option chosen')
          console.log('sent event to amplitude (Expertise option chosen)')
          if (
            spheres.find(({ title_en }) => title_en === categories.title_en)
          ) {
            removeSpheres(
              spheres.findIndex(
                ({ title_en }) => title_en === categories.title_en
              )
            )
          } else {
            appendSpheres(categories)
          }
        }}
        disabledSelectedCount
        isProfileSelect={true}
        multipleInputsProps={multipleInputsProps}
      />
    </Box>
  </>
)

const ListItem = ({
  index,
  isDisabled,
  text,
  handleRemoveSpheres,
  removeCustomSpheres,
  ...props
}) => {
  const { isMobile } = useScreenSize()
  return isMobile ? (
    <Button
      h="24px"
      lineHeight="24px"
      borderRadius={16}
      border="1px solid"
      bg="transparent"
      px={3}
      fontSize="sm"
      fontWeight={400}
      textTransform="none"
      color="gray.700"
      mr={1}
      mb={2}
      noOfLines={1}
      isDisabled={isDisabled}
      sx={{ _hover: {} }}
      {...props}
      display="flex"
      onClick={() => {
        if (!isDisabled) {
          if (removeCustomSpheres) {
            removeCustomSpheres(index)
          } else {
            handleRemoveSpheres(index)
          }
        }
      }}
    >
      {`${text}`}
      <Box pl="6px" py="4px">
        <CloseIcon width="10px" fill="#737373" />
      </Box>
    </Button>
  ) : (
    <Tooltip label={text} placement="top" hasArrow bg="gray.900">
      <Button
        h="24px"
        lineHeight="24px"
        borderRadius={16}
        border="1px solid"
        bg="transparent"
        px={3}
        fontSize="sm"
        fontWeight={400}
        textTransform="none"
        color="gray.700"
        mr={1}
        mb={2}
        noOfLines={1}
        isDisabled={isDisabled}
        {...props}
        display="flex"
        onClick={() => {
          if (!isDisabled) {
            if (removeCustomSpheres) {
              removeCustomSpheres(index)
            } else {
              handleRemoveSpheres(index)
            }
          }
        }}
      >
        {`${text}`}
        <Box pl="6px" py="4px">
          <CloseIcon width="10px" fill="#737373" />
        </Box>
      </Button>
    </Tooltip>
  )
}
