import { LANG_HY_SHORT, validateStringLang } from 'contexts/TranslationContext'

export const getDefaultBindingId = (user, project, checkStripe) => {
  if (user.id === 'guest') {
    return ''
  }
  if (checkStripe && project?.stripeId) {
    if (user?.stripeAttachedCards?.length) {
      return user.stripeAttachedCards[user.stripeAttachedCards.length - 1]?.id
    }
    return 'stripe'
  }

  if (!user.creditCards || user.creditCards.length === 0) {
    return ''
  }

  return user.creditCards[user.creditCards.length - 1]?.bindingId
}

export const compareBonusAvailableAmount = (giftCard, amount) => {
  if (!giftCard.id) {
    return {
      makePayment: true,
      paymentAmount: amount,
    }
  }
  return {
    makePayment: giftCard.availableAmount < amount,
    paymentAmount: amount - giftCard.availableAmount,
    hasGiftCard: true,
  }
}

export const checkUtm = (string) => {
  if (string.search.includes('utm')) {
    const source = string.search.split('&')[0]
    const medium = string.search.split('&')[1]
    const campaign = string.search.split('&')[2]
    const newParam = source + '&' + medium + '&' + campaign

    return newParam
  }
  return null
}

const latinLetters = {
  ա: 'a',
  բ: 'b',
  գ: 'g',
  դ: 'd',
  ե: 'e',
  զ: 'z',
  է: 'e',
  ը: 'e',
  թ: 't',
  ժ: 'zh',
  ի: 'i',
  լ: 'l',
  խ: 'kh',
  ծ: 'ts',
  կ: 'k',
  հ: 'h',
  ձ: 'dz',
  ղ: 'gh',
  ճ: 'ch',
  մ: 'm',
  յ: 'y',
  ն: 'n',
  շ: 'sh',
  ո: 'o',
  չ: 'ch',
  պ: 'p',
  ջ: 'j',
  ռ: 'r',
  ս: 's',
  վ: 'v',
  տ: 't',
  ր: 'r',
  ց: 'ts',
  ու: 'u',
  փ: 'p',
  ք: 'k',
  և: 'ev',
  եւ: 'ev',
  օ: 'o',
  ֆ: 'f',
}

export const toLatinLetters = (str) =>
  validateStringLang(str, LANG_HY_SHORT)
    ? str
        .toLowerCase()
        .split('')
        .map((e, i) =>
          !latinLetters?.[e]
            ? e
            : i === 0
            ? latinLetters[e].toUpperCase()
            : latinLetters[e] || e
        )
        .join('')
    : str

export const generateDescription = ({
  firstName,
  lastName,
  email,
  paymentType,
  projectTitle,
  transactionId,
}) => {
  const projectName = projectTitle || 'reArmenia'
  const monthlyState = paymentType === 'recurring' ? 'monthly' : ''

  const description =
    `Dear ${toLatinLetters(firstName)} ${toLatinLetters(lastName)},` +
    ` you are ${monthlyState} donating to "${projectName}"` +
    ` with ${email} email address.` +
    ` ${transactionId ? `Transaction ID - ${transactionId}` : ''}`

  return description.trim().replace(/\s{2,}/g, ' ')
}

export const createEmptyTransactionBody = ({
  project,
  user,
  hasDefaultAmount,
  defaultAmount,
  hasFirstName,
  firstName,
  hasLastName,
  lastName,
  hasEmail,
  email,
  isContribution,
}) => {
  const isGuest = user?.id === 'guest'
  return {
    bindingId: getDefaultBindingId(user || {}),
    amount: hasDefaultAmount ? defaultAmount : 0,
    reArmenia: isContribution
      ? 0
      : hasDefaultAmount
      ? (Number(defaultAmount) * 7.5) / 100
      : 0,
    email: isGuest ? (hasEmail ? email : '') : user?.email,
    firstName: isGuest ? (hasFirstName ? firstName : '') : user?.firstName,
    lastName: isGuest ? (hasLastName ? lastName : '') : user?.lastName,
    paymentType:
      project?.fundraisingType === 'oneTime' ? 'oneTime' : 'recurring',
    attachCard: true,
    attachStripeCard: false,
    subscribeNews: true,
    isAnonymous: false,
    showAttachCardCheckbox: false,
    paymentMethod: '',
  }
}

export const createTransactionBody = ({
  user,
  projectId,
  project,
  peerProject,
  formAmount,
  currencyRate,
  email,
  firstName,
  lastName,
  language,
  data,
  paymentType,
  appliedGiftCard,
  isBuyingGiftCard,
  isContribution,
  createdGiftCard,
  perk,
  perkIndex,
  newStripeCard,
  paymentMethodData,
  paymentMethod,
  peerProjectId,
  isIDramPayment,
  isPayPalPayment,
  provider,
  isStripe,
  currency,
}) => {
  let amount = parseInt(formAmount)
  if (data.reArmenia && !isContribution) {
    amount += Number(data.reArmenia)
  }
  if (isIDramPayment || isPayPalPayment) {
    amount = parseInt(`${parseInt(amount * currencyRate)}`)
  } else {
    amount = parseInt(`${parseInt(amount * currencyRate)}00`)
  }

  let redirectUrl = `${window.location.protocol}//${window.location.host}/${language}/check-transaction`
  if (perkIndex || perkIndex === 0) {
    redirectUrl += `?perkIndex=${perkIndex}`
  }

  const body = {
    amount,
    language,
    description: isBuyingGiftCard
      ? 'You are buying gift card'
      : generateDescription({
          firstName,
          lastName,
          email,
          paymentType,
          projectTitle: peerProject?.title_en || project?.title_en,
        }),
    redirectUrl,
    transactionInfo: {
      transactionParams: {
        userId: user.id,
        userFirstName: firstName || user.firstName,
        userLastName: lastName || user.lastName,
        userEmail: email || user.email,
        data: {
          ...data,
          currencyRate,
          currency: currency.current,
          language,
        },
        perkIndex,
        perk,
        newStripeCard,
        paymentMethod,
        paymentMethodData,
        isBuyingGiftCard,
        createdGiftCard,
        projectId: projectId || project?.id,
        peerProjectId,
        isContribution,
        provider,
        isStripe,
      },

      subscribeForMonthly: isBuyingGiftCard
        ? false
        : paymentType === 'recurring',
      attachCard: user.id === 'guest' ? false : data.attachCard,
      paymentMethod,
      projectId: project?.id,
      peerProjectId: peerProject?.id,
    },
  }

  delete body.transactionInfo.transactionParams.project

  if (peerProjectId) {
    body.transactionInfo.peerProjectId = peerProjectId
  }
  body.clientId = email

  if (data.bindingId) {
    body.localBindingId = data.bindingId
    body.attachCard = false
  }

  const compareResult = compareBonusAvailableAmount(
    appliedGiftCard.current,
    amount
  )
  // if (compareResult.hasGiftCard) {
  //   body.amount = `${compareResult.paymentAmount}00`
  //   body.transactionInfo.giftCardAmount =
  //     appliedGiftCard.current.availableAmount
  //   body.transactionInfo.giftCardId = appliedGiftCard.current.id
  //   body.transactionInfo.giftCardCode = appliedGiftCard.current.code
  //   body.transactionInfo.giftCardTransactionType = 'spend'
  // }
  return { body, compareResult }
}

function safeEncode(str) {
  const encoded = encodeURIComponent(str)
  return btoa(encoded)
}

function hashEmail(email) {
  return `EM_${safeEncode(email.toLowerCase()).substring(0, 12)}`
}

export const isTopDonor = (transaction, topDonors) => {
  if (transaction.isAnonymous || !transaction.email) {
    return false
  }
  if (!topDonors) {
    return false
  }

  if (transaction.email.startsWith('EM_')) {
    return topDonors.map(({ email }) => email).includes(transaction.email)
  }

  return topDonors
    .map(({ email }) => email)
    .includes(hashEmail(transaction.email))
}

export const mapTopDonorFirstName = (transaction, topDonors) => {
  if (!isTopDonor(transaction, topDonors)) {
    return transaction
  }
  return {
    ...transaction,
    firstName: '⭐️ ' + transaction.firstName,
  }
}
