import { Flex, Box } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'

function AuthButton({ endpoint, translationKey }) {
  const { t } = useTranslation()

  const pathname = window.location.pathname.slice(3)
  const query = window.location.search
  const fromUrl = `${pathname}${query}`

  return (
    <Link
      color="blue.400"
      fontWeight="600"
      fontSize="14px"
      whiteSpace="nowrap"
      to={`${endpoint}?from=${encodeURIComponent(fromUrl)}`}
    >
      {t(translationKey)}
    </Link>
  )
}

function Divider() {
  return <Box height={4} width="1px" background="blue.400" />
}

function AuthButtons() {
  return (
    <Flex fontWeight="500" display="flex" alignItems="center" gap={2}>
      <AuthButton endpoint="/sign-in" translationKey="nav@signIn" />
      <Divider />
      <AuthButton endpoint="/sign-up" translationKey="nav@signUp" />
    </Flex>
  )
}

export default AuthButtons
