import { Divider, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import moment from 'moment'
import { ReactComponent as InvestorIcon } from 'assets/icons/dashboardInvestorIcon.svg'
import { getNextRecurringChargeDate } from 'utils/date'

export default function RecurringInvestmentCard({
  amount,
  isFinished,
  subscription,
  creditCard,
  t,
}) {
  const nextChargeDate = getNextRecurringChargeDate()
  const lastChargeDate = subscription.createdAt

  return (
    <Flex
      pt={4}
      px={{ base: 4, sm: '22px' }}
      pb={{ base: 5, sm: 6 }}
      direction="column"
      w={{ base: 'full', sm: '417px' }}
      bg="white"
      borderRadius={8}
      boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 16px 6px 0px rgba(0, 0, 0, 0.01), 0px 25px 7px 0px rgba(0, 0, 0, 0)"
    >
      <InvestorIcon height="64px" width="64px" style={{ marginTop: -28 }} />
      <Flex justifyContent="space-between" h={9} mt={6} mb={4}>
        <Text fontWeight={600} fontSize="lg" color="gray.700">
          {t('profile@dashboard@investor@recurring@lastCharge')}
        </Text>
        <Flex direction="column" gap={1}>
          <Text
            fontWeight={600}
            fontSize="lg"
            color="gray.800"
            textAlign="right"
            lineHeight="normal"
          >
            {amount}
          </Text>
          <Text
            fontWeight={500}
            fontSize="md"
            color="gray.800"
            lineHeight="normal"
          >
            {moment(lastChargeDate).format('MMMM DD, YYYY')}
          </Text>
        </Flex>
      </Flex>
      {!isFinished && (
        <Flex justifyContent="space-between">
          <Text fontWeight={600} fontSize="lg" color="gray.700">
            {t('profile@dashboard@investor@recurring@nextCharge')}
          </Text>
          <Flex direction="column" gap={1}>
            <Text
              fontWeight={600}
              fontSize="lg"
              color="gray.800"
              textAlign="right"
              lineHeight="normal"
            >
              {amount}
            </Text>
            <Text
              fontWeight={500}
              fontSize="md"
              color="gray.800"
              lineHeight="normal"
              textAlign="right"
            >
              {moment(nextChargeDate).format('MMMM DD, YYYY')}
            </Text>
          </Flex>
        </Flex>
      )}
      {creditCard && (
        <>
          <Divider h="1px" bg="#E06969" w="full" mt={4} mb={3} />
          <Flex justifyContent="space-between">
            <Text fontWeight={600} fontSize="lg" color="gray.700">
              {t('profile@dashboard@investor@recurring@paidWith')}
            </Text>
            <Text
              fontWeight={600}
              fontSize="lg"
              color="gray.800"
              textAlign="right"
              lineHeight="normal"
            >
              {creditCard?.pan}
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  )
}
