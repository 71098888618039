import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useImage } from 'hooks'
import { useRef } from 'react'
import { Box, Flex, Spinner } from '@chakra-ui/react'
import './index.scss'

function Editor({ content, onValueUpdate, isInvalid, prependStyle }) {
  const [loading, setLoading] = useState(false)
  const { uploadImage } = useImage()
  const quillObj = useRef(null)

  const imageHandler = async () => {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      setLoading(true)
      const file = input.files[0]
      const formData = new FormData()
      formData.append('image', file)
      const name = `content_${Date.now()}`
      const key = await uploadImage({ name, file })
      const range = quillObj.current.getEditorSelection()
      quillObj.current
        .getEditor()
        .insertEmbed(
          range.index,
          'image',
          `${process.env.REACT_APP_S3_BUCKET_URL}/public/${key}`
        )
      setLoading(false)
    }
  }

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            { header: [1, 2, false] },
          ],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  )

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'header',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ]

  const onUpdate = (editorValue) => {
    const updatedValue = prependStyle
      ? `<style>div[role="tabpanel"] p{margin: 0 !important} div[role="tabpanel"] p img{border-radius: 0 !important}</style>` +
        editorValue
      : editorValue
    console.log(updatedValue)
    onValueUpdate(updatedValue)
  }

  return (
    <Box position="relative">
      {!!isInvalid && (
        <Box
          top="calc(100% + 10px)"
          fontSize="sm"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {isInvalid?.message}
        </Box>
      )}
      <ReactQuill
        ref={quillObj}
        style={{ position: 'relative' }}
        className={isInvalid ? 'invalid' : ''}
        theme="snow"
        defaultValue={content}
        modules={modules}
        formats={formats}
        onChange={onUpdate}
        placeholder="Please Input"
      />
      {loading && (
        <Flex
          zIndex="1000"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          bg="rgba(0, 0, 0 ,0.15)"
          top="0"
          left="0"
          height="100%"
          width="100%"
        >
          <Spinner />
        </Flex>
      )}
    </Box>
  )
}

export default Editor
