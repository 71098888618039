import { post } from 'aws-amplify/api'
import { customGetDonationSubscriptions as customGetDonationSubscriptionsQuery } from '../../graphql/queries'
import { updateDonationSubscription as update } from '../../graphql/mutations'

const DonationSubscriptionService = (appSyncRequest, auth) => {
  const requestCustom = async (query, variables) => {
    const result = await appSyncRequest({
      query: customGetDonationSubscriptionsQuery,
      variables: {
        input: {
          query,
          variables:
            typeof variables === 'string'
              ? variables
              : JSON.stringify(variables),
        },
      },
    })

    const response = result.data.customGetDonationSubscriptions
    if (typeof response === 'string') {
      return { data: { [query]: JSON.parse(response) } }
    }

    return response
  }

  const updateDonationSubscription = async (donationSubscription) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession?.tokens?.idToken?.toString()
    return appSyncRequest({
      query: update,
      variables: { input: donationSubscription },
      authToken: jwtToken,
      authMode: 'userPool',
    })
  }

  const unsubscribeDonationSubscription = async (donationSubscriptionId) => {
    return post({
      apiName: 'paymentAPI',
      path: '/subscription/unsubscribe',
      options: {
        body: {
          subscriptionId: donationSubscriptionId,
        },
      },
    })
  }

  const donationSubscriptionsByUserEmail = async (condition) => {
    const response = await requestCustom(
      'sortedDonationSubscriptionsByUserEmail',
      condition
    )
    const items =
      response?.data?.sortedDonationSubscriptionsByUserEmail?.items ||
      response?.items ||
      []
    return { items }
  }

  const donationSubscriptionsByProjectId = (condition) =>
    requestCustom('donationSubscriptionsByProjectId', condition)

  const getDonationSubscription = (id) =>
    requestCustom('getDonationSubscription', { id })

  return {
    updateDonationSubscription,
    unsubscribeDonationSubscription,
    donationSubscriptionsByUserEmail,
    donationSubscriptionsByProjectId,
    getDonationSubscription,
  }
}

export default DonationSubscriptionService
