import {
  updateUserAttributes,
  confirmUserAttribute,
  updatePassword,
  signOut,
} from 'aws-amplify/auth'
import {
  companyByDomain,
  getUser as retrieveUser,
  usersByEmail as usersByEmailQuery,
} from '../../graphql/queries'
import {
  createUser as createUserMutation,
  updateUser as updateUserMutation,
} from '../../graphql/mutations'
import { onUpdateUser } from 'graphql/subscriptions'

const UserService = (appSyncRequest, auth) => {
  const createUser = async (input) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession?.tokens?.idToken?.toString()

    return appSyncRequest({
      query: createUserMutation,
      variables: { input },
      authToken: jwtToken,
      authMode: 'userPool',
    })
  }

  const updateUser = async (input) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession?.tokens?.idToken?.toString()

    return appSyncRequest({
      query: updateUserMutation,
      variables: { input },
      authToken: jwtToken,
      authMode: 'userPool',
    })
  }

  const getUser = async (cognitoUser) => {
    const groups =
      cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    const isAdmin = groups?.includes('admins')
    const dynamoUser = await getUserByEmail(
      cognitoUser?.email || cognitoUser?.session?.idToken?.payload?.email
    )
    dynamoUser.isAdmin = isAdmin
    return dynamoUser
  }

  const getUserById = async (id) => {
    const response = await appSyncRequest({
      query: retrieveUser,
      variables: { id },
    })

    return response.data.getUser
  }

  const getUserByEmail = async (email) => {
    if (!email) {
      return
    }
    const response = await appSyncRequest({
      query: usersByEmailQuery,
      variables: { email },
    })

    return response.data.usersByEmail.items[0]
  }

  const editEmail = async (email) => {
    await updateUserAttributes({ userAttributes: { email } })
  }

  const verifyEmail = async (code) => {
    await confirmUserAttribute({
      userAttributeKey: 'email',
      confirmationCode: code,
    })
  }

  const getCompany = (domain) => {
    return appSyncRequest({
      query: companyByDomain,
      variables: { domain },
    })
  }

  const editUserInfo = async ({ firstName, lastName }) => {
    await updateUserAttributes({
      userAttributes: {
        name: firstName,
        family_name: lastName,
      },
    })
  }

  const changePassword = async ({ oldPass, newPass }) => {
    await updatePassword({
      oldPassword: oldPass,
      newPassword: newPass,
    })
  }

  const signOutService = () =>
    new Promise((resolve, reject) => {
      signOut()
        .then(() => resolve('signed out'))
        .catch(() => reject('sign out error'))
    })

  const onUserUpdateSubscription = async ({ userId, callback }) => {
    const sub = await appSyncRequest({
      query: onUpdateUser,
      variables: { id: { eq: userId } },
    })
    sub.subscribe({
      next: ({ data }) => {
        callback(data.onUpdateUser)
      },
      error: (error) => console.log('Error on subscription: ', error),
    })

    return sub
  }
  return {
    getUser,
    getUserByEmail,
    getUserById,
    editEmail,
    verifyEmail,
    editUserInfo,
    changePassword,
    signOut: signOutService,
    updateUser,
    createUser,
    getCompany,
    onUserUpdateSubscription,
  }
}

export default UserService
