export const TRANSACTION_STATUS = {
  PENDING: 'PENDING',
  DONE: 'DONE',
  FAILED: 'FAILED',
}

export const TRANSACTION_STATUS_DESCRIPTION = {
  PENDING: 'Redirected to bank page',
  DONE: 'Successfully paid',
  FAILED: 'Transaction not paid',
}

export const PAYMENT_METHODS = {
  ARCA_VISA_MASTER: 'ARCA_VISA_MASTER',
  MIR: 'MIR',
}

export const PAYMENT_METHODS_DATA = {
  MIR: {
    image: '/assets/images/mir_card.png',
    title: 'MIR',
  },
  ARCA_VISA_MASTER: {
    image: '/assets/images/ArcaVisaMatster.png',
    title: 'ArCa/Mastercard/Visa',
  },
  IDRAM: {
    image: '/assets/images/idram_solid.svg',
    title: 'IDram',
  },
  APPLE_PAY: {
    image: '/assets/images/apple_pay.svg',
    title: 'Apple Pay',
  },
  GOOGLE_PAY: {
    image: '/assets/images/google_pay.svg',
    title: 'Google Pay',
  },
  PAYPAL: {
    image: '/assets/images/paypal.svg',
    title: 'PayPal',
  },
}
