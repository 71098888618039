import { Container } from '@chakra-ui/react'
import { useScreenSize } from 'contexts/ScreenSizeContext'

const ResponsiveElementsWrapper = ({ children }) => {
  const { isMobile } = useScreenSize()

  if (isMobile) {
    return <Container px={5}>{children}</Container>
  }

  return children
}

export default ResponsiveElementsWrapper
