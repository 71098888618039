import { Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import SegmentedControl from 'components/SegmentedControl'

const MonthlyToggle = ({ setValue }) => {
  const { t } = useTranslation()

  return (
    <SegmentedControl
      itemHeight="32px"
      inWhiteBackground={false}
      name="monthlyToggle"
      options={{
        oneTime: t('One-time'),
        recurring: t('Monthly'),
      }}
      defaultValue="recurring"
      onChange={(paymentType) => setValue('paymentType', paymentType)}
      renderItem={({ value }) => (
        <Text fontWeight="500" fontSize={{ base: 'sm', sm: 'md' }}>
          {value}
        </Text>
      )}
    />
  )
}

export default MonthlyToggle
