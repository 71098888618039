import { useEffect, useState } from 'react'
import _ from 'lodash'
import { useGetUserInvestments } from 'hooks/transaction'

const oneTime_cache_key = 'transaction-history-onetime'
const recurring_cache_key = 'transaction-history-recurring'

const useListInvestments = ({ email, isRecurring }) => {
  const [nextToken, setNextToken] = useState(null)
  const [projectId, setProjectId] = useState(process.env.REACT_APP_REARMENIA_ID)
  const [items, setItems] = useState([])
  const cacheKey = isRecurring ? recurring_cache_key : oneTime_cache_key
  const { data, isLoading, isRefetching, refetch } = useGetUserInvestments(
    {
      email,
      isRecurring,
      limit: 100,
      nextToken,
      projectIdList: [
        process.env.REACT_APP_REARMENIA_ID,
        process.env.REACT_APP_CONTRIBUTE_ID,
      ],
    },
    cacheKey
  )

  const onLoadNext = () => {
    if (data.nextToken) {
      setNextToken(data.nextToken)
      refetch()
    }
  }

  useEffect(() => {
    if (data?.items) {
      setItems((prev) =>
        _.uniqBy(
          [...prev, ...data.items].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ),
          'id'
        )
      )
    }

    setNextToken(data?.nextToken)
    if (
      projectId === process.env.REACT_APP_REARMENIA_ID &&
      data?.nextToken === null &&
      isRecurring
    ) {
      setProjectId(process.env.REACT_APP_CONTRIBUTE_ID)
    }
  }, [data])

  useEffect(() => {
    if (
      projectId === process.env.REACT_APP_CONTRIBUTE_ID &&
      data?.nextToken === null &&
      isRecurring
    ) {
      refetch()
    }
  }, [projectId])

  return {
    isLoading,
    isRefetching,
    refetch,
    onLoadNext,
    nextToken,
    setNextToken,
    items,
    setItems,
    projectId,
    setProjectId,
  }
}

export default useListInvestments
