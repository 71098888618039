const Radio = {
  defaultProps: {
    variant: 'primary',
  },

  baseStyle: {
    icon: {},
    label: {},
    control: {
      borderColor: 'border.200',
      borderWidth: 1,
      '&::before': {
        width: '6px !important',
        height: '6px !important',
      },
      _disabled: {
        bg: 'border.200',
        bgColor: 'border.200',
        borderColor: 'border.200',
      },
      _hover: {
        borderColor: 'orange.400',
      },
    },
  },

  variants: {
    primary: {
      control: {
        _checked: {
          borderColor: 'orange.400',
          bg: 'orange.400',
          border: 'none',
          _disabled: {
            bg: 'border.200',
            borderColor: 'border.200',
          },
          _hover: {
            borderColor: 'orange.400',
            bg: 'orange.400',
          },
        },
      },
    },

    secondary: {
      control: {
        borderWidth: 1,
        _checked: {
          borderColor: 'border.200',
          bg: 'transparent',
          '&::before': {
            bg: 'orange.400',
          },

          _disabled: {
            borderColor: 'border.200',
            '&::before': {
              bg: 'border.200',
            },
          },
          _hover: {
            borderColor: 'orange.400',
            bg: 'transparent',
          },
        },
      },
    },
  },
}

export default Radio
