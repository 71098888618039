import { Flex, Text, Radio } from '@chakra-ui/react'

const CardSavingSelection = ({ text, isSelected, Icon, ...props }) => (
  <Flex
    h="56px"
    p="13px 16px"
    align="center"
    borderRadius={{ base: '12px', sm: '16px' }}
    borderWidth="1px"
    borderColor={isSelected ? 'border.400' : 'border.500'}
    position="relative"
    alignItems="center"
    cursor={isSelected ? 'default' : 'pointer'}
    _hover={{
      'button[datatype="remove"]': {
        opacity: 1,
      },
    }}
    {...props}
  >
    <Flex alignItems="center" height="28px">
      <Flex width="36px" alignItems="center" paddingLeft="6px">
        <Radio value="1" isChecked={isSelected} />
      </Flex>

      <Flex alignItems="center">
        {Icon}
        <Text ml={4} color="black.100" fontSize="md">
          {text}
        </Text>
      </Flex>
    </Flex>
  </Flex>
)

export default CardSavingSelection
