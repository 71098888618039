import { forwardRef } from 'react'
import { Circle, IconButton } from '@chakra-ui/react'
import { ReactComponent as WhiteNotificationsIcon } from 'assets/icons/notifications_white_icon.svg'
import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications_icon.svg'

const NotificationsButton = forwardRef(
  ({ whiteVersion, unreadNotifications = [], ...props }, ref) => (
    <IconButton ref={ref} minW="auto" {...props}>
      <>
        {whiteVersion ? <WhiteNotificationsIcon /> : <NotificationsIcon />}

        {unreadNotifications > 0 && (
          <Circle
            size="11px"
            border="1px solid"
            borderColor="white"
            bg="red.400"
            position="absolute"
            top={0.5}
            right={0.5}
          />
        )}
      </>
    </IconButton>
  )
)

NotificationsButton.displayName = 'NotificationsButton'
export default NotificationsButton
