import axios from 'axios'
import { DEFAULT_LANG } from 'contexts/TranslationContext'

const getKeyByValue = (dataByKeys, countryCode, continentCode) => {
  return Object.entries(dataByKeys).reduce((acc, [currency, data]) => {
    if (
      (data.continents && data.continents.includes(continentCode)) ||
      (data.countries && data.countries.includes(countryCode))
    ) {
      return currency
    }
    return acc
  }, null)
}

const getCurrency = (countryCode, continentCode) => {
  const defaultCurrencies = {
    AMD: { countries: ['AM'] },
    RUB: { countries: ['RU', 'KZ', 'UZ', 'TM', 'KG', 'TJ'] },
    EUR: { continents: ['EU'] },
  }
  return getKeyByValue(defaultCurrencies, countryCode, continentCode)
}

const getLanguage = (countryCode, continentCode) => {
  const defaultLanguages = {
    hy: { countries: ['AM'] },
    ru: { countries: ['RU', 'KZ', 'UZ', 'TM', 'KG', 'TJ'] },
  }
  return getKeyByValue(defaultLanguages, countryCode, continentCode)
}

export const detectInfoByIpAddress = async () => {
  // @INFO: for testing you can add ip address before `/json`
  //        e.g. 'https://ipapi.co/89.104.121.138/json'
  const response = await axios.get('https://ipapi.co/json')

  const foundCurrency = getCurrency(
    response.data.country,
    response.data.continent_code
  )
  const foundLanguage = getLanguage(
    response.data.country,
    response.data.continent_code
  )

  return {
    countryCode: response.data.country,
    currency: foundCurrency || 'USD',
    language: foundLanguage || 'en',
  }
}

export const getDefaultValue = () => {
  return {
    countryCode: 'AM',
    currency: 'USD',
    language: DEFAULT_LANG[0],
  }
}
