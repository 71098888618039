import { Flex, Center, Heading } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import { ReactComponent as Back } from 'assets/icons/arrowLeftNew.svg'

function BackButton({
  project,
  isContribution,
  isBuyingGiftCard,
  showTitle = false,
}) {
  const { navigationPush } = useNavigation()
  const { t } = useTranslation()

  const goBack = () => {
    navigationPush(`/fundraisers/${project.slug || project.id}`)
  }

  return (
    <Flex flexDir="row" alignItems="center" gap={4}>
      <Center onClick={goBack} width={4} height={5}>
        <Back width="17px" height="17px" />
      </Center>
      {showTitle && (
        <Heading as="h1" fontSize="20px" fontWeight={500}>
          {isContribution
            ? t('banner@Heading')
            : isBuyingGiftCard
            ? t('Gift card')
            : t('payment@donate')}
        </Heading>
      )}
    </Flex>
  )
}

export default BackButton
