import { Flex, HStack, Input, Stack, Text } from '@chakra-ui/react'
import ImageWrapper from 'components/ImageWrapper'
import { thousandSeparator } from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'

export default function AmountCard({ basketItem, register, errors, index }) {
  const { language } = useTranslation()

  return (
    <Stack borderRadius={12} borderWidth={1} borderColor="gray.500" p={4}>
      <Stack spacing={2}>
        <HStack spacing={3} alignItems="center">
          <ImageWrapper
            src={`/thumbnail/small/${basketItem.projectCover}`}
            borderRadius="100px"
            w="50px"
            h="50px"
          />
          <Text fontWeight="600" fontSize="lg">
            {basketItem[`projectTitle_${language}`]}
          </Text>
        </HStack>
        <Flex alignItems="center" justifyContent="space-between">
          <Text color="gray.700" fontWeight="500" fontSize="lg">
            {thousandSeparator(basketItem.amount)} AMD
          </Text>
          <Input
            w="136px"
            borderColor="gray.600"
            textAlign="right"
            pattern="[0-9,]*"
            isInvalid={!!errors?.basket && !!errors.basket[index]?.amount}
            {...register(`basket.${index}.amount`, {
              required: true,
              validate: {
                positiveNumber: (value) => value > 0,
              },
            })}
          />
        </Flex>
      </Stack>
    </Stack>
  )
}
