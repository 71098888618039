import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useScreenSize } from 'contexts/ScreenSizeContext'
import { ReactComponent as IdramIcon } from 'assets/icons/Idram.svg'
import { ReactComponent as Stripe } from 'assets/icons/stripe.svg'

export default function PoweredBy({ project, showStripe, ...props }) {
  const { t } = useTranslation()
  const { isMobile } = useScreenSize()

  return (
    <Flex alignItems="center" justifyContent="center" w="full" {...props}>
      <Text
        color="gray.600"
        fontWeight={600}
        fontSize={{ base: '10px', sm: 'md' }}
        mr={2}
      >
        {t('Powered By')}
      </Text>

      <IdramIcon height={isMobile ? 22 : 40} width={isMobile ? 74 : 126} />
      {showStripe && (
        <Stripe height={isMobile ? 22 : 40} width={isMobile ? 52 : 90} />
      )}
    </Flex>
  )
}
