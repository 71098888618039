import moment from 'moment'

const DOUBLED_PROJECTS = [
  '3699d58d-d9d0-45e0-aba8-f36e41401211',
  '940391dc-f9e1-446c-a2b1-d023f7e9ddf1',
  '6036e751-9a86-4ce4-808f-30f5e7fcf25f',
]
const DOUBLED_PROJECTS_MAX = [20_000_000, 14_000_000, 13_512_000]
const duplicatedProjectAmount = (amount, max) => {
  if (amount < max) {
    return amount * 2
  }

  return max * 2 + (amount - max)
}

const getCurrentGoal = (project, amount) => {
  const { goals } = project
  if (!goals?.length) {
    return { amount: project.goal || project.totalAmount }
  }
  let goal = goals.find(
    (goal) =>
      // TODO: make sure 2nd goal cannot be greater than 1
      // so this logic works correctly
      // also confirm how it should work
      amount < goal?.amount
  )
  // if all goals are met, showing last goal
  if (!goal) {
    goal = goals[goals.length - 1]
  }
  return goal
}

const doubleProject = (project, newAmount, progress) => {
  let newDoubledProject = project
  const doublingMax = DOUBLED_PROJECTS_MAX[DOUBLED_PROJECTS.indexOf(project.id)]
  newAmount = duplicatedProjectAmount(project.amount, doublingMax)
  progress = (newAmount / project.goal) * 100
  newDoubledProject.amount = newAmount
  newDoubledProject.total.goalMetPercentage = parseFloat(progress)
  return newDoubledProject
}
const parseMonthlyOneTimeToJSON = (input) => {
  let result = input
  if (typeof input === 'string') {
    try {
      result = JSON.parse(input)
    } catch (e) {
      console.log('Error while parsing to json')
    }
  }

  return result
}
const getMonthlyProjectTotalAmount = (project) => {
  try {
    const currentMonthDate = moment().format('M-YYYY').toString()
    const oneTimeDonationsObject = parseMonthlyOneTimeToJSON(
      project.total.monthlyOneTime
    )
    const currentMonthDonations = oneTimeDonationsObject[currentMonthDate]
    if (!currentMonthDonations) {
      // every month all susbcribers are donating
      // even if they haven't yet it should be there
      return 0
    }

    return currentMonthDonations
  } catch (err) {
    console.log(err)
    return project.total.subscriptions
  }
}

const projectUseCase = (ProjectService) => {
  const deleteProject = async (project) =>
    await ProjectService.deleteProject(project)

  const createProject = async (project) =>
    await ProjectService.createProject(project)

  const updateProjectStatus = async ({ id, status }) =>
    ProjectService.updateProjectStatus({ id, status })

  const updateProject = async (input) => {
    const response = await ProjectService.updateProject(input)
    return response
  }
  const getProjectById = (id) => ProjectService.retrieveProject(id)
  const getProjectsBySlug = (slug) =>
    projectify(ProjectService.getProjectBySlug(slug))

  const retrieveProject = async (id, options = {}) => {
    if (!id) {
      return {}
    }
    if (validateUUID(id)) {
      return projectify(ProjectService.retrieveProject(id, options))
    }

    return projectify(ProjectService.getProjectBySlug(id, options))
  }

  const updateProjectPerks = (project) =>
    ProjectService.updateProjectPerks(project)

  const removeRedundantDataProjectList = (projects) => {
    return projects.map((project) => {
      const {
        beneficiaries,
        description,
        content_hy,
        content_en,
        content_ru,
        keyResults,
        objective,
        otherPlayers,
        phases,
        problem,
        projectTeam,
        risks,
        solution,
        summary,
        ...filteredProject
      } = project

      return {
        ...filteredProject,
      }
    })
  }

  const getProjects = async (filter, cacheKey) => {
    let condition = {}
    if (filter) {
      condition = filter
    }

    try {
      const projects = await ProjectService.getProjectsFromElastic(condition)
      let response = await Promise.all(
        projects.hits.hits.map(({ _source }) => projectify(_source))
      )

      response = removeRedundantDataProjectList(response)

      if (cacheKey && !condition.from) {
        const isProject = condition?.query?.bool?.must[0]

        if (isProject && isProject.match) {
          localStorageSetItem(`${cacheKey}`, JSON.stringify(response))
        } else {
          localStorageSetItem(cacheKey, JSON.stringify(response))
        }
      }
      return { items: response, total: projects.hits.total.value }
    } catch (err) {
      console.log('err', err)
    }
    return { items: [], total: 0 }
  }

  const getProjectsByUserId = async (filter, withNextToken = false) => {
    let condition = {}
    if (filter) {
      condition = filter
    }

    try {
      const projects = await ProjectService.getProjectsByUserId(condition)
      let response = await Promise.all(
        projects.data.projectByUserId.items.map(projectify)
      )
      if (withNextToken) {
        response = {
          items: response,
          nextToken: projects.data.listProjects.nextToken,
        }
      }
      return response
    } catch (err) {
      console.log('err', err)
    }
    return []
  }

  const getProjectsByPopular = async (filter, withNextToken = false) => {
    let condition = {}
    if (filter) {
      condition = filter
    }

    try {
      const projects = await ProjectService.getProjectsByPopular(condition)

      let response = await Promise.all(
        projects.data.projectBypopular.items.map(projectify)
      )
      if (withNextToken) {
        response = {
          items: response,
          nextToken: projects.data.projectBypopular.nextToken,
        }
      }
      return response
    } catch (err) {
      console.log('err', err)
    }
    return []
  }

  const getProjectsByFeatured = async (filter, withNextToken = false) => {
    let condition = {}
    if (filter) {
      condition = filter
    }
    try {
      const projects = await ProjectService.getProjectsByFeatured(condition)
      let response = await Promise.all(
        projects.data.projectByFeatured.items.map((project) =>
          projectify(project)
        )
      )

      if (withNextToken) {
        response = {
          items: response,
          nextToken: projects.data.projectByFeatured.nextToken,
        }
      }
      return response
    } catch (err) {
      console.log('featured:err', err)
    }
    return []
  }

  const searchProjects = async (filter) => {
    let condition = {}
    if (filter) {
      condition = filter
    }

    try {
      const projects = await ProjectService.getProjectsFromElastic(condition)
      let response = await Promise.all(
        projects.hits.hits.map(({ _source }) => projectify(_source))
      )
      return {
        items: response,
        total: projects.hits.total.value,
        meta: projects.hits.meta,
      }
    } catch (err) {
      console.log('err', err)
    }
    return { items: [], total: 0 }
  }

  const localStorageSetItem = (key, value) =>
    window.localStorage.setItem(key, value)
  const localStorageGetItem = (key) => window.localStorage.getItem(key)

  const subscribeToProjectNewsletter = ({ email, projectId }) => {
    if (!projectId || !email) {
      console.log(
        '>>> [projectUseCase] subscribeToProjectNewsletter: projectId and email are required'
      )

      return null
    }

    return ProjectService.subscribeToProjectNewsletter({ email, projectId })
  }

  const createMailerLiteFields = ({ projectId }) => {
    if (!projectId) {
      console.log(
        '>>> [projectUseCase] subscribeToProjectNewsletter: projectId are required'
      )

      return null
    }

    return ProjectService.createMailerLiteFields({ projectId })
  }

  return {
    createProject,
    updateProject,
    retrieveProject,
    updateProjectStatus,
    updateProjectPerks,
    getProjects,
    deleteProject,
    searchProjects,
    getProjectsByUserId,
    getProjectsByPopular,
    getProjectsBySlug,
    getProjectsByFeatured,
    getProjectById,
    subscribeToProjectNewsletter,
    createMailerLiteFields,
    localStorageSetItem,
    localStorageGetItem,
  }
}
const validateUUID = (uuid) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    uuid
  )
}

const normalizeProject = (project) => ({
  ...project,
  mainAmount: project?.amount ?? 0,
  collaborations: project.collaborations ?? '',
  category: project.category ?? '',
  goalType: project.goalType ?? '',
  description: project.problem ?? '',
  fundraisingType: project.fundraisingType ?? '',
  applicationTitle: project.applicationTitle ?? '',
  title_en: project.title_en ?? '',
  title_ru: project.title_ru ?? '',
  title_hy: project.title_hy ?? '',
  tagline_en: project.tagline_en ?? '',
  tagline_ru: project.tagline_ru ?? '',
  tagline_hy: project.tagline_hy ?? '',
  summary: project.summary ?? '',
  sequence: project.sequence ?? 1,
  isUrgent: project.isUrgent ?? false,
  isFeatured: project.isFeatured ?? false,
  isPopular: project.isPopular ?? false,
  region: project.region ?? '',
  status: project.status ?? '',
  goal: project.goal ?? 0,
  totalAmount: project.totalAmount ?? '',
  duration: project.duration,
  durationType: project.durationType,
  createdAt: project.createdAt,
  collaboratorsTotal: project.collaboratorsTotal,
  goals: project.goals ?? [],
  total: project.total ?? {},
})
const projectify = async (getProject) => {
  let project = await getProject
  if (project) {
    let newAmount = project.amount

    if (DOUBLED_PROJECTS.includes(project.id)) {
      project = doubleProject(project, newAmount)
      newAmount = project.amount
    }

    const isRecurring = project.fundraisingType === 'recurring'
    if (isRecurring) {
      const isMonthlyProjectHasOneTimePayments =
        project.total?.monthlyOneTime !== undefined
      const hasSubscribers = project.total?.subscriptions !== undefined

      if (isMonthlyProjectHasOneTimePayments && hasSubscribers) {
        newAmount = getMonthlyProjectTotalAmount(project)
      }
    }
    if (project.isCollaboration && project.collaborations) {
      project.collaborations = project.collaborations.map((collaboration) => {
        const normalizedCollab = collaboration
        if (!collaboration.needs_en && collaboration.needs) {
          normalizedCollab.needs_en = collaboration.needs
        }
        if (!collaboration.needs_hy && collaboration.needs) {
          normalizedCollab.needs_hy = collaboration.needs
        }
        if (!collaboration.needs_ru && collaboration.needs) {
          normalizedCollab.needs_ru = collaboration.needs
        }
        // if (
        //   !collaboration.needsDescription_en &&
        //   collaboration.needsDescription
        // ) {
        //   normalizedCollab.needsDescription_en = collaboration.needsDescription
        // }
        // if (
        //   !collaboration.needsDescription_hy &&
        //   collaboration.needsDescription
        // ) {
        //   normalizedCollab.needsDescription_hy = collaboration.needsDescription
        // }
        // if (
        //   !collaboration.needsDescription_ru &&
        //   collaboration.needsDescription
        // ) {
        //   normalizedCollab.needsDescription_ru = collaboration.needsDescription
        // }

        return normalizedCollab
      })
    }
    project.activeGoal = getCurrentGoal(project, newAmount)
    project.amount = newAmount
    const hasGoals = project.goals?.length > 0
    if (hasGoals) {
      // TODO: repo can change project structure as needed, use case should receive correct project object
      // use case SHOULD do calculations
      // should not do mappings
      // repo CAN do mappings
      // cannot do calculations
      project.goals =
        project.goals?.map((goal) => ({
          ...goal,
          isReached: goal?.amount <= project.amount,
        })) || []
    }

    // ADD AMERIA IN TOP DONORS FOR ZLEENK (RV-2754)
    const ZLEENK_ID = '2c1a8be7-58fb-489c-8117-779caa0aa39f'
    if (project.id === ZLEENK_ID) {
      project.topDonors[0] = {
        email: 'sargis@arides.am',
        fullName: 'Ameriabank',
        totalAmount: 2599000,
      }
    }
  }
  if (project) return normalizeProject(project)
}

export default projectUseCase
