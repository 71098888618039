import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Menu from '../../Menu'
import { Link } from 'pages'
import { ReactComponent as Logo } from 'assets/icons/darkLogo.svg'
import Web from './Web'
import Navigation from './Navigation'

function FullNavbar({
  shadow = '0 2px 26px 0 rgba(0, 0, 0, 0.1)',
  currentPath,
  onClick,
  disableLinks,
}) {
  return (
    <Box
      position="relative"
      zIndex="1000"
      w="full"
      as="header"
      bg="white"
      boxShadow={shadow}
      height="80px"
    >
      <Box mx="auto" px={{ base: '24px', md: '60px' }} h="100%">
        <Flex
          as="nav"
          alignItems="center"
          h="100%"
          w="100%"
          justifyContent="space-between"
        >
          <Box
            w="100%"
            justifyContent="space-between"
            align="center"
            display={{ base: 'flex', md: 'none' }}
          >
            <Menu isLink black={+true} />
          </Box>
          <Flex>
            <Box
              position={{ base: 'absolute', md: 'initial' }}
              left={{ base: 0, md: 'initial' }}
              right={{ base: 0, md: 'initial' }}
              mx="auto !important"
              my="0"
              width="144px"
              onClick={onClick}
              as={Link}
              to="/"
            >
              <Box>
                <Logo width="100%" />
              </Box>
            </Box>

            <Flex ml="16" alignItems="center">
              <Navigation currentPath={currentPath} onClick={onClick} />
            </Flex>
          </Flex>

          <Web
            disableLinks={disableLinks}
            onClick={onClick}
            currentPath={currentPath}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default FullNavbar
