import { Box, Heading, Text, Image } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import React from 'react'

export default function ContributeBanner() {
  const { t } = useTranslation()
  return (
    <Box mb={6}>
      <Box>
        <Heading
          color="#272727"
          fontSize="3xl"
          display={{ base: 'none', sm: 'block' }}
        >
          {t('banner@Heading')}
        </Heading>
        <Text color="#737373" fontSize="sm" mb={4}>
          {t('banner@Text')}
        </Text>
      </Box>
      <Image
        src="/assets/images/banner_contriubute.png"
        borderRadius="12px"
        h={{ base: '200px', sm: '255px' }}
        w="full"
        objectFit="cover"
      />
    </Box>
  )
}
