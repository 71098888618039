import { Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import { useModal } from 'providers/ModalProvider'

const ActionButton = (props) => {
  const { t } = useTranslation()

  return (
    <Button
      width={{ base: '100%', lg: 'unset' }}
      minWidth="280px"
      borderRadius="12px"
      variant="green"
      textTransform="unset"
      {...props}
    >
      {t('thankyou@createAccountButton')}
    </Button>
  )
}

const CreateButton = ({ setIsModalOnceOpened, onClose }) => {
  const { openModal } = useModal()
  const { navigationPush } = useNavigation()

  const closeModal = () => {
    setIsModalOnceOpened(true)
    onClose()
  }

  const openSignUpModal = () => {
    closeModal()
    openModal('signUp')
  }

  const openSignUpPage = () => {
    closeModal()
    navigationPush('/sign-up')
  }

  return (
    <>
      <ActionButton
        display={{ base: 'none', lg: 'block' }}
        onClick={openSignUpModal}
      />
      <ActionButton
        display={{ base: 'block', lg: 'none' }}
        onClick={openSignUpPage}
      />
    </>
  )
}

export default CreateButton
