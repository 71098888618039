import {
  Box,
  Button,
  Container,
  Flex,
  Tabs,
  Tab,
  TabList,
  Spinner,
  TabPanel,
  TabPanels,
  Center,
} from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import ExpertCard from 'components/ExpertCard'
import {
  LANG_EN_SHORT,
  LANG_HY_SHORT,
  LANG_RU_SHORT,
  useTranslation,
} from 'contexts/TranslationContext'
import {
  useCreateContentItem,
  useDeleteContentItem,
  useGetExpertById,
  useGetExpertsCategories,
  useUpdateContentItem,
} from 'hooks'
import {
  useCustomToast,
  useSessionStorage,
  useObjectManipulations,
  useQueryParams,
  useStringManipulations,
  useUpdateUser,
  useUser,
} from 'hooks'
import { useNavigation } from 'pages'
import { useModal } from 'providers/ModalProvider'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import COUNTRY_LIST from 'translations/countries.json'
import ExpertProfileForm from './ExpertProfileForm'
import Layout from './Layout'
import { useScreenSize } from 'contexts'

const isDevEnv = process.env.REACT_APP_ENV !== 'production'

export default function ExpertProfile() {
  const { isMobile } = useScreenSize()
  const pagePath = '/profile/expert/card'
  const [tabIndex, setTabIndex] = useState(0)

  const { t, language } = useTranslation()
  const params = useQueryParams()
  const { user, setUser, isLoading } = useUser()
  const { findNamingTranslation } = useStringManipulations()
  const { get, put } = useSessionStorage()
  const {
    data: expert,
    isLoading: isExpertLoading,
    refetch,
  } = useGetExpertById(user?.expertId || user?.email)
  const [isFormOpened, openForm] = useState(params.has('form'))
  const [formAutoSave, setFormAutoSave] = useState({})
  const [tabs, setTabs] = useState(['English', 'Armenian', 'Russian'])
  const [langs, setLangs] = useState([
    LANG_EN_SHORT,
    LANG_HY_SHORT,
    LANG_RU_SHORT,
  ])
  const { navigationPush } = useNavigation()
  const [localLang, setLocalLang] = useState(null)
  const { mutate: createExpert, isLoading: isExpertCreationLoading } =
    useCreateContentItem()
  const { mutate: updateExpert, isLoading: isExpertUpdateLoading } =
    useUpdateContentItem()
  const { deleteKey } = useObjectManipulations()

  const [options, setOptions] = useState([])
  const { data: categories } = useGetExpertsCategories()

  const langLongForms = {
    [LANG_EN_SHORT]: 'English',
    [LANG_HY_SHORT]: 'Armenian',
    [LANG_RU_SHORT]: 'Russian',
  }
  const imageUrl = useMemo(() => {
    if (!expert) {
      return null
    }
    if (expert.imageUrl?.includes('https://')) {
      return expert.imageUrl
    }
    return `${process.env.REACT_APP_S3_BUCKET_URL}/public/${expert.imageUrl}`
  }, [expert])

  useEffect(() => {
    if (!!isFormOpened && !expert) {
      openModal(
        'expertNotification',
        null,
        false,
        () => {},
        {
          type: 'application',
          firstName: user?.firstName || '',
        },
        {
          minWidth: !isMobile ? '710px' : 'auto',
          marginLeft: 4,
          marginRight: 4,
          marginTop: isMobile ? 52 : 16,
        }
      )
    }
  }, [isFormOpened])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
    control,
    setError,
    clearErrors,
    trigger,
    reset,
    resetField,
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: Object.keys(formAutoSave).length
      ? formAutoSave
      : {
          country: COUNTRY_LIST[7],
          countryIndex: 7,
          isPrivate: 'false',
          spheres: [],
          interestedIn: [],
          phone: expert?.phone,
          email: user.email,
          firstName_en: expert?.firstName_en || user.firstName,
          firstName_ru: expert?.firstName_ru || user.firstName,
          firstName_hy: expert?.firstName_hy || user.firstName,
          lastName_en: expert?.lastName_en || user.lastName,
          lastName_ru: expert?.lastName_ru || user.lastName,
          lastName_hy: expert?.lastName_hy || user.lastName,
          getInvolvedStatus: ['Volunteer'],
          socialLinks: expert?.socialLinks || [],
          socials: [
            {
              url: '',
              added: false,
            },
          ],
        },
  })

  const {
    fields: socials,
    append: appendSocial,
    remove: removeSocial,
    update: updateSocial,
  } = useFieldArray({
    control,
    name: 'socials',
    rules: {
      required: true,
      validate: (fields) => fields.every(({ added }) => added),
    },
  })

  const noSocials = (socials) => {
    if (socials.length === 0 || (socials.length === 1 && !socials[0].url)) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (expert?.socialLinks.length > 0 && noSocials(socials)) {
      const newSocialsValue = expert?.socialLinks.map((url) => ({
        url,
        added: true,
      }))
      const resetAsync = async () =>
        await reset({
          socials: newSocialsValue,
        })
      resetAsync().then((e) => {
        resetField('socials', {
          defaultValue: newSocialsValue,
        })
      })
    }
  }, [expert])

  const {
    fields: spheres,
    append: appendSpheres,
    remove: removeSpheres,
    update: updateSpheres,
  } = useFieldArray({
    control,
    name: 'spheres',
    rules: {
      validate: (fields) => fields.length > 0 || customSpheres?.length > 0,
    },
  })

  const {
    fields: customSpheres,
    append: appendCustomSpheres,
    remove: removeCustomSpheres,
    update: updateCustomSpheres,
  } = useFieldArray({
    control,
    shouldUnregister: true,
    name: 'customSpheres',
  })

  const spheresArray = watch('spheres')
  const clearForm = useCallback(() => {
    if (expert) {
      const getInvolvedStatus = ['Volunteer']
      reset({
        ...expert,
        selectedLanguage: expert.selectedLanguage.map(
          ({ language }) => language
        ),
        spheres: spheresArray.length ? spheresArray : expert.spheres,
        email: user.email,
        firstName_en: expert ? expert.firstName_en : user.firstName,
        firstName_ru: expert ? expert.firstName_ru : user.firstName,
        firstName_hy: expert ? expert.firstName_hy : user.firstName,
        lastName_en: expert ? expert.lastName_en : user.lastName,
        lastName_ru: expert ? expert.lastName_ru : user.lastName,
        lastName_hy: expert ? expert.lastName_hy : user.lastName,
        interestedIn: expert.interestedIn.map(
          ({ interested_in }) => interested_in
        ),
        country: COUNTRY_LIST.find(({ code }) => code === expert.countryCode),
        countryIndex: COUNTRY_LIST.findIndex(
          ({ code }) => code === expert.countryCode
        ),
        isPrivate: String(expert.isPrivate),
        getInvolvedStatus,
      })
    } else {
      reset({
        country: COUNTRY_LIST[7],
        countryIndex: 7,
        isPrivate: 'false',
        spheres: [],
        email: user.email,
        firstName_en: expert ? expert.firstName_en : user.firstName,
        firstName_ru: expert ? expert.firstName_ru : user.firstName,
        firstName_hy: expert ? expert.firstName_hy : user.firstName,
        lastName_en: expert ? expert.lastName_en : user.lastName,
        lastName_ru: expert ? expert.lastName_ru : user.lastName,
        lastName_hy: expert ? expert.lastName_hy : user.lastName,
      })
    }
  }, [expert, spheresArray])

  const toast = useCustomToast()
  const { openModal } = useModal()
  const [isFormDisabled, disableForm] = useState(false)
  const { updateUser, isLoading: isUpdateUserLoading } = useUpdateUser()
  const { mutate: deleteExpert, isLoading: isExpertDeleteLoading } =
    useDeleteContentItem()
  const onModalClose = () => {
    updateUser(
      {
        id: user.id,
        isSeenExpertProfileModal: true,
      },
      {
        onSuccess: (response) =>
          setUser((oldUser) => ({
            ...oldUser,
            user: response.data.updateUser,
          })),
      }
    )
  }
  const onTabChange = (e) => {
    let updateLang = tabs[e]
    if (updateLang === 'English') {
      updateLang = LANG_EN_SHORT
    }
    if (updateLang === 'Armenian') {
      updateLang = LANG_HY_SHORT
    }
    if (updateLang === 'Russian') {
      updateLang = LANG_RU_SHORT
    }
    setFormAutoSave(watch())
    setLocalLang(updateLang)
    setTabIndex(e)
  }

  const removeExtraNames = (input) => {
    const { isEngFilled, isRusFilled, isArmFilled } = input
    if (!isEngFilled) {
      delete input.firstName_en
      delete input.lastName_en
    }
    if (!isRusFilled) {
      delete input.firstName_ru
      delete input.lastName_ru
    }
    if (!isArmFilled) {
      delete input.firstName_hy
      delete input.lastName_hy
    }
  }

  const onSubmit = (data) => {
    let input = data
    input.userId = user.id
    input.isVolunteer = true
    input.getInvolvedStatus = 'Volunteer'
    input.interestedIn = data.interestedIn.map((interest) => ({
      interested_in: interest,
    }))
    input.spheres = [
      ...spheresArray,
      ...customSpheres.filter(({ added }) => added),
    ].map(({ title_en, title_ru, title_hy }) => ({
      title_en,
      title_ru: title_ru || title_en,
      title_hy: title_hy || title_en,
    }))
    input.selectedLanguage = data.selectedLanguage.map((language) => ({
      language,
      language_other: '',
    }))

    input.socialLinks = socials
      .filter(({ added }) => added)
      .map(({ url }) => url)
    if (input.socialLinks.length === 0) {
      setError('socials', { type: 'custom', message: 'Empty urls' })
      return
    }
    input.publishedAt = new Date().toISOString()

    if (
      input.description_en &&
      input.firstName_en &&
      input.lastName_en &&
      input.title_en
    ) {
      input.isEngFilled = true
    }
    if (
      input.description_hy &&
      input.firstName_hy &&
      input.lastName_hy &&
      input.title_hy
    ) {
      input.isArmFilled = true
    }
    if (
      input.description_ru &&
      input.firstName_ru &&
      input.lastName_ru &&
      input.title_ru
    ) {
      input.isRusFilled = true
    }
    input.language = language || LANG_EN_SHORT
    input.countryCode = input.country.code
    input.pk = 'expert'
    input.userId = user.id
    delete input.country
    deleteKey(input, '__typename')

    const isEditing = params.has('edit') || expert?.expert_status === 'rejected'
    removeExtraNames(input)
    console.log('SUBMIT', input)
    delete input.customSpheres
    delete input.countryIndex
    delete input.socials
    if (isEditing) {
      input.id = expert.id
      if (expert.expert_status !== 'approved') {
        input.expert_status = 'pending'
      }
      updateExpert(input, {
        onSuccess(res) {
          console.log(res)
          navigationPush(pagePath)
          openForm(false)
          refetch()
          toast({
            status: 'success',
            title: t('expert@successUpdateToast'),
          })
          updateUser({
            id: user.id,
            expertId: res.data.updateContent.id,
          })
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        },
      })
    } else {
      input.expert_status = 'pending'
      createExpert(input, {
        onSuccess(res) {
          console.log(res)
          updateUser(
            {
              id: user.id,
              expertId: res.data.createContent.id,
            },
            {
              onSuccess: (response) => {
                setUser((oldUser) => ({
                  ...oldUser,
                  user: response.data.updateUser,
                }))
                navigationPush(pagePath)
                openForm(false)
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              },
              onError: (err) => {
                console.log('❌', err)
              },
            }
          )
        },
        onError: (err) => {
          console.log('❌', err)
        },
      })
    }
    for (let i = customSpheres.length - 1; i >= 0; i--) {
      removeCustomSpheres(i)
    }
  }

  const onTestDelete = () => {
    updateUser(
      {
        id: user.id,
        isSeenExpertProfileModal: false,
        isSeenExpertProfileNotification: false,
        expertId: null,
      },
      {
        onSuccess: (response) => {
          setUser((oldUser) => ({
            ...oldUser,
            user: response.data.updateUser,
          }))

          deleteExpert(
            { id: expert.id },
            {
              onSuccess() {
                toast({
                  title: 'Expert successfully deleted',
                  status: 'success',
                })
                window.location.reload()
              },
            }
          )
        },
      }
    )
  }

  useEffect(() => {
    if (expert && expert.expert_status !== 'approved') {
      let path = '?form=true&edit=true'
      if (expert.expert_status === 'pending') {
        disableForm(true)
        path = '?form=true'
      }
      navigationPush(pagePath + path)
      openForm(true)
    }
  }, [])

  useEffect(() => {
    openForm(params.has('form'))
    //? Register a field without providing a value initially
    register('imageUrl', { required: true })
  }, [params.get('form')])

  useEffect(() => {
    if (!expert) {
      return
    }
    let lang = LANG_EN_SHORT
    if (expert.isArmFilled) {
      lang = LANG_HY_SHORT
    }
    if (expert.isRusFilled) {
      lang = LANG_RU_SHORT
    }
    if (expert.isEngFilled) {
      lang = LANG_EN_SHORT
    }
    setTabs(
      tabs.sort((a, b) => {
        if (b === langLongForms[language]) {
          return 1
        }
        if (a === langLongForms[language]) {
          return -1
        }
        const aFilled =
          (a === 'Armenian' &&
            expert.isArmFilled !== null &&
            expert.isArmFilled) +
          (a === 'Russian' &&
            expert.isRusFilled !== null &&
            expert.isRusFilled) +
          (a === 'English' && expert.isEngFilled !== null && expert.isEngFilled)
        const bFilled =
          (b === 'Armenian' &&
            expert.isArmFilled !== null &&
            expert.isArmFilled) +
          (b === 'Russian' &&
            expert.isRusFilled !== null &&
            expert.isRusFilled) +
          (b === 'English' && expert.isEngFilled !== null && expert.isEngFilled)
        return bFilled - aFilled
      })
    )
    setLangs(
      langs.sort((a, b) => {
        if (b === language) {
          return 1
        }
        if (a === language) {
          return -1
        }
        const aFilled =
          (a === LANG_HY_SHORT &&
            expert.isArmFilled !== null &&
            expert.isArmFilled) +
          (a === LANG_RU_SHORT &&
            expert.isRusFilled !== null &&
            expert.isRusFilled) +
          (a === LANG_EN_SHORT &&
            expert.isEngFilled !== null &&
            expert.isEngFilled)
        const bFilled =
          (b === LANG_HY_SHORT &&
            expert.isArmFilled !== null &&
            expert.isArmFilled) +
          (b === LANG_RU_SHORT &&
            expert.isRusFilled !== null &&
            expert.isRusFilled) +
          (b === LANG_EN_SHORT &&
            expert.isEngFilled !== null &&
            expert.isEngFilled)
        return bFilled - aFilled
      })
    )

    if (expert.expert_status !== 'approved') {
      if (expert.expert_status === 'pending') {
        disableForm(true)
      }
      navigationPush(pagePath)
      openForm(true)
    }

    setLocalLang(lang)
    const getInvolvedStatus = ['Volunteer']
    reset({
      ...expert,
      selectedLanguage: expert.selectedLanguage.map(({ language }) => language),
      spheres: expert.spheres,
      email: user.email,
      firstName_en: expert ? expert.firstName_en : user.firstName,
      firstName_ru: expert ? expert.firstName_ru : user.firstName,
      firstName_hy: expert ? expert.firstName_hy : user.firstName,
      lastName_en: expert ? expert.lastName_en : user.lastName,
      lastName_ru: expert ? expert.lastName_ru : user.lastName,
      lastName_hy: expert ? expert.lastName_hy : user.lastName,
      interestedIn: expert.interestedIn.map(
        ({ interested_in }) => interested_in
      ),
      country: COUNTRY_LIST.find(({ code }) => code === expert.countryCode),
      countryIndex: COUNTRY_LIST.findIndex(
        ({ code }) => code === expert.countryCode
      ),
      isPrivate: String(expert.isPrivate),
      getInvolvedStatus,
    })
    if (!user.isSeenExpertProfileModal) {
      openModal(
        'expertNotification',
        null,
        false,
        () => {
          onModalClose()
        },
        {
          type: expert.expert_status,
        },
        {
          minWidth: !isMobile ? '710px' : 'auto',
          marginLeft: 4,
          marginRight: 4,
          marginTop: isMobile ? 52 : 16,
        }
      )
    }
  }, [expert, user, language])

  useEffect(() => {
    const cachedLang = get('language_expert_page')
    if (!!cachedLang && cachedLang !== language) {
      window.location.reload()
    }
    put('language_expert_page', language)
  }, [language])

  useEffect(() => {
    if (!categories?.length) {
      return
    }
    setOptions(categories)
  }, [categories])
  if (isLoading || isExpertLoading) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        margin="auto"
        h={{ md: '100%', base: '300px' }}
      >
        <Spinner color="blue.400" />
      </Flex>
    )
  }

  if (!user.expertId && !isFormOpened && !expert) {
    return (
      <Layout h="full">
        <Center h="100%" w="full">
          <EmptyState
            title={t('expert@profile@emptyTitle')}
            description={t('expert@profile@emptyText')}
            button={t('expert@profile@join')}
            link={pagePath + '?form=true'}
            mobileMT={0}
          />
        </Center>
      </Layout>
    )
  }
  if (isFormOpened || (expert && expert.expert_status !== 'approved')) {
    return (
      <Layout expert={expert} isFormOpen={isFormOpened}>
        <Container mx={0} px={0} mt={4} w="full" maxW="916px">
          <Tabs
            height="100%"
            index={tabIndex}
            onChange={onTabChange}
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            isLazy
          >
            <TabList borderBottom="1px solid #DDD">
              {tabs.map((tabTitle, index) => (
                <Tab
                  key={`tabTitle-${index}`}
                  _active={{ background: 'initial' }}
                  _selected={{
                    color: 'black',
                    borderColor: 'blue.300',
                  }}
                  color="gray.700"
                  fontSize="md"
                  minW="85px"
                  p={4}
                  fontWeight={500}
                >
                  {t(`expert@form@${tabTitle}`)}
                </Tab>
              ))}
            </TabList>
            <TabPanels px={{ sm: 4 }}>
              {tabs.map((languageCode, index) => (
                <TabPanel
                  key={`project-content-tab-panel-${languageCode}`}
                  tabIndex={index}
                  p={0}
                  mt={12}
                >
                  <ExpertProfileForm
                    language={langs[index]}
                    isSubmitLoading={
                      isExpertCreationLoading || isExpertUpdateLoading
                    }
                    isDisabled={
                      isFormDisabled ||
                      isExpertCreationLoading ||
                      isExpertUpdateLoading
                    }
                    expertiseOptions={options}
                    {...{
                      socials,
                      trigger,
                      watch,
                      clearErrors,
                      register,
                      setValue,
                      clearForm,
                      control,
                      expert,
                      getValues,
                      errors,
                      appendSocial,
                      removeSocial,
                      updateSocial,
                      customSpheres,
                      appendCustomSpheres,
                      removeCustomSpheres,
                      updateCustomSpheres,
                      spheres,
                      appendSpheres,
                      removeSpheres,
                      updateSpheres,
                      spheresArray,
                    }}
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Container>
      </Layout>
    )
  }
  return (
    <Layout expert={expert} isFormOpen={isFormOpened}>
      {!!expert && !expert.isPrivate && expert.expert_status === 'approved' ? (
        <>
          <Tabs height="100%" index={tabIndex} onChange={onTabChange}>
            <TabList borderBottom="1px solid #DDD">
              {tabs.map((tabTitle, index) => (
                <Tab
                  key={`tabTitle-${index}`}
                  _active={{ background: 'initial' }}
                  _selected={{
                    color: 'black',
                    borderColor: 'blue.300',
                  }}
                  color="gray.700"
                  fontSize="md"
                  minW="85px"
                  p={4}
                  fontWeight={500}
                >
                  {t(`expert@form@${tabTitle}`)}
                </Tab>
              ))}
            </TabList>

            <TabPanels px={4}>
              {tabs.map((languageCode, index) => (
                <TabPanel
                  key={`project-content-tab-panel-${languageCode}`}
                  tabIndex={index}
                  p={0}
                >
                  {expert[`is${tabs[index].slice(0, 3)}Filled`] ? (
                    <>
                      {' '}
                      <ExpertCard
                        slug={expert?.slug}
                        id={expert?.id}
                        noLazyLoad
                        image={imageUrl}
                        workTime={expert.hrsPerWeek}
                        firstName={expert?.[`firstName_${langs[index]}`]}
                        lastName={expert?.[`lastName_${langs[index]}`]}
                        position={expert?.[`title_${langs[index]}`]}
                        linkedin={expert?.linkedinLink}
                        otherLink={expert?.link}
                        description={expert?.[`description_${langs[index]}`]}
                        expertise={expert?.spheres}
                        languages={expert?.selectedLanguage?.map(
                          ({ language }) => language
                        )}
                        directions={expert?.interestedIn}
                        getInvolvedStatus={expert?.getInvolvedStatus}
                        location={
                          findNamingTranslation({
                            parentObject: expert,
                            value: 'location',
                            language,
                          }) ||
                          COUNTRY_LIST.find(
                            (country) =>
                              country.code === (expert?.countryCode || 'am')
                          )[`name_${language}`]
                        }
                        email={expert?.email}
                        socials={expert?.socialLinks}
                        phone={expert?.phone}
                        openModal={openModal}
                        lang={langs[index]}
                        disabled
                        isVolunteer={expert?.isVolunteer}
                      />
                      <Flex justify="flex-end">
                        <Button
                          mt={4}
                          minW={{ base: '100%', sm: '160px' }}
                          variant="blue"
                          onClick={() => {
                            navigationPush(pagePath + '?form=true&edit=true')
                            openForm(true)
                          }}
                        >
                          {t('edit')}
                        </Button>
                        {isDevEnv && !isMobile && (
                          <Button
                            mt={4}
                            ml={4}
                            colorScheme="red"
                            variant="solid"
                            onClick={onTestDelete}
                            isLoading={
                              isUpdateUserLoading || isExpertDeleteLoading
                            }
                          >
                            Delete Expert
                          </Button>
                        )}
                      </Flex>
                    </>
                  ) : (
                    <Center h="100%" my={12}>
                      <EmptyState
                        description={t('expert@addLang').replace(
                          '[lang]',
                          t(tabs[index] + 'Accusative')
                        )}
                        button={t('edit')}
                        mobileMT={0}
                        link={pagePath + '?form=true&edit=true'}
                      />
                    </Center>
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <Box mx={2}>
          <ExpertCard
            noLazyLoad
            slug={expert?.slug}
            id={expert?.id}
            image={imageUrl}
            workTime={expert?.hrsPerWeek}
            firstName={expert?.[`firstName_${localLang}`]}
            lastName={expert?.[`lastName_${localLang}`]}
            position={expert?.[`title_${localLang}`]}
            linkedin={expert?.linkedinLink}
            otherLink={expert?.link}
            languages={expert?.selectedLanguage?.map(
              ({ language }) => language
            )}
            description={expert?.[`description_${localLang}`]}
            expertise={expert?.spheres}
            directions={expert?.interestedIn}
            location={
              findNamingTranslation({
                parentObject: expert,
                value: 'location',
                language,
              }) ||
              COUNTRY_LIST.find(
                (country) => country.code === (expert?.countryCode || 'am')
              )[`name_${language}`]
            }
            getInvolvedStatus={expert?.getInvolvedStatus}
            email={expert?.email}
            phone={expert?.phone}
            openModal={openModal}
            lang={localLang}
            disabled
            isVolunteer={expert?.isVolunteer}
          />
          <Flex justify="flex-end">
            <Button
              mt={4}
              minW={{ base: '100%', sm: '160px' }}
              variant="blue"
              onClick={() => {
                navigationPush(pagePath + '?form=true&edit=true')
                openForm(true)
              }}
            >
              {t('edit')}
            </Button>
            {isDevEnv && !isMobile && (
              <Button
                mt={4}
                ml={4}
                colorScheme="red"
                variant="solid"
                onClick={onTestDelete}
                isLoading={isUpdateUserLoading || isExpertDeleteLoading}
              >
                Delete Expert
              </Button>
            )}
          </Flex>
        </Box>
      )}
    </Layout>
  )
}
