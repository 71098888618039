import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { alertAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys)

const stylesByStatus = {
  info: {
    container: { background: '#EBF3FF', borderColor: '#1D6ECF' },
    title: { color: '#012F73' },
    description: { color: '#1D6ECF' },
  },
}

const Alert = defineMultiStyleConfig({
  variants: {
    'static-alert': definePartsStyle(
      /**
       * @param {import('@chakra-ui/react').AlertProps} props
       */
      (props) => {
        const container = {
          ...(stylesByStatus[props.status]?.container || {}),
          gap: '8px',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderRadius: '16px',
          alignItems: 'flex-start',
        }

        const icon = {
          ...(stylesByStatus[props.status]?.icon || {}),
          boxSize: '18px',
          marginRight: '8px',
          children: null,
        }

        const title = {
          ...(stylesByStatus[props.status]?.title || {}),
          fontFamily: 'MontserratArm, Montserrat',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '30px',
        }

        const description = {
          ...(stylesByStatus[props.status]?.description || {}),
          fontFamily: 'MontserratArm, Montserrat',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '20px',
        }

        return { container, icon, title, description }
      }
    ),
  },
})

export default Alert
