import {
  createContent as createContentMutation,
  deleteContent as deleteContentMutation,
  updateContent as updateContentMutation,
} from '../../graphql/mutations'
import {
  getContent as getContentQuery,
  contentBySk as contentBySkQuery,
  expertByEmail as expertByEmailQuery,
  expertBySlug as expertBySlugQuery,
} from '../../graphql/queries'
import { getResponseToJSON } from 'utils/api'

const ContentService = (appSyncRequest, auth, api) => {
  const getExperts = async (variables) => {
    const appSyncResponse = await appSyncRequest({
      query: contentBySkQuery,
      variables: {
        pk: 'expert',
        limit: variables.limit,
        nextToken: variables.nextToken,
      },
    })
    return appSyncResponse.data.contentBySk
  }

  const getExpertByEmail = async (email) => {
    const appSyncResponse = await appSyncRequest({
      query: expertByEmailQuery,
      variables: {
        email,
      },
    })
    return appSyncResponse.data.expertByEmail.items[0]
  }

  const getNeeds = async () => {
    const appSyncResponse = await appSyncRequest({
      query: contentBySkQuery,
      variables: { pk: 'need' },
    })
    return appSyncResponse.data.contentBySk.items
  }

  const getItemById = async (id) => {
    const appSyncResponse = await appSyncRequest({
      query: getContentQuery,
      variables: { id },
    })
    return appSyncResponse.data.getContent
  }

  const getItemBySlug = async (slug) => {
    const appSyncResponse = await appSyncRequest({
      query: expertBySlugQuery,
      variables: { slug },
    })
    return appSyncResponse.data.expertBySlug.items[0]
  }

  const getGiftCard = async () => {
    const appSyncResponse = await appSyncRequest({
      query: contentBySkQuery,
      variables: { pk: 'gift-card' },
    })
    return appSyncResponse.data.contentBySk.items
  }

  const getHomepageBlog = async () => {
    const appSyncResponse = await appSyncRequest({
      query: contentBySkQuery,
      variables: { pk: 'blog' },
    })
    return appSyncResponse.data.contentBySk.items
  }

  const createContentItem = async (input) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession?.tokens?.idToken?.toString()

    const appSyncResponse = await appSyncRequest({
      query: createContentMutation,
      variables: { input },
      authToken: jwtToken,
      authMode: 'userPool',
    })
    return appSyncResponse
  }

  const updateContentItem = async (input) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession?.tokens?.idToken?.toString()

    if (input.pk === 'expert') {
      input.spheres = input.spheres.map(({ title_en, title_hy, title_ru }) => ({
        title_en: title_en?.trim() || null,
        title_hy: title_hy?.trim() || null,
        title_ru: title_ru?.trim() || null,
      }))
    }

    const appSyncResponse = await appSyncRequest({
      query: updateContentMutation,
      variables: { input },
      authToken: jwtToken,
      authMode: 'userPool',
    })
    return appSyncResponse
  }

  const deleteContentItem = async (input) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession?.tokens?.idToken?.toString()

    const appSyncResponse = await appSyncRequest({
      query: deleteContentMutation,
      variables: { input },
      authToken: jwtToken,
      authMode: 'userPool',
    })
    return appSyncResponse
  }

  const getContentFromElastic = async (condition) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession?.tokens?.accessToken?.toString()
    let environment = process.env.REACT_APP_ENV

    if (environment === 'local') {
      environment = 'development'
    }

    const res = api.post({
      apiName: 'elastic',
      path: '/proxy',
      options: {
        body: {
          index: `content_${environment}`,
          path: '_search',
          method: 'POST',
          data: condition,
        },
        headers: {
          Authorization: jwtToken,
        },
      },
    })

    return await getResponseToJSON(res)
  }

  return {
    getExperts,
    getExpertByEmail,
    getNeeds,
    getItemById,
    getGiftCard,
    getHomepageBlog,
    getItemBySlug,
    getContentFromElastic,
    createContentItem,
    updateContentItem,
    deleteContentItem,
  }
}

export default ContentService
