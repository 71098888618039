import React, { useRef, useState } from 'react'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useModal } from 'providers/ModalProvider'
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedinIcon.svg'
import { ReactComponent as FacebookIcon } from 'assets/icons/facebookIcon.svg'
import { ReactComponent as XIcon } from 'assets/icons/xIcon.svg'
import { ReactComponent as GlobeIcon } from 'assets/icons/globeIcon.svg'
import { ReactComponent as Location } from 'assets/icons/location.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/expert_share_icon.svg'
import COUNTRY_LIST from 'translations/countries.json'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import StatusTexts from './StatusTexts'
import {
  useCustomToast,
  useLocalStorage,
  useStringManipulations,
  useUser,
} from 'hooks'
import { useGetExpertsCategories } from 'hooks'
import LazyLoad from 'react-lazyload'
import { useScreenSize } from 'contexts'

export default function ExpertCard({
  image,
  workTime,
  position,
  linkedin,
  otherLink,
  description,
  expertise,
  directions,
  socials,
  getInvolvedStatus,
  firstName,
  languages,
  lastName,
  email,
  id,
  location,
  phone,
  index,
  disabled = false,
  isVolunteer,
  noButton = false,
  slug,
  localFilters = {},
  setLocalFilters = () => {},
  fromCardList,
  noLazyLoad,
}) {
  const bio = useRef(null)
  const { navigationPush } = useNavigation()
  const { put } = useLocalStorage()
  const { user } = useUser()
  const { openModal } = useModal()
  const { t, language } = useTranslation()
  const { lowerCase, findNamingTranslation } = useStringManipulations()
  const { isOpen: isBioOpen, onToggle: onBioToggle } = useDisclosure()
  const { data: categories } = useGetExpertsCategories()
  const toast = useCustomToast()
  const { isMobile } = useScreenSize()
  const languageFlags = {
    armenian: '/assets/flags/am.png',
    english: '/assets/flags/us.png',
    russian: '/assets/flags/ru.png',
    french: '/assets/flags/fr.png',
    german: '/assets/flags/de.png',
  }
  const LINE_HEIGHT = 24
  const bioLines = bio?.current?.scrollHeight / LINE_HEIGHT
  const isToggleNeeded = bioLines > 3 && isMobile

  const expertiseChosen = ({ title_en }) =>
    lowerCase(localFilters?.categories)?.includes(lowerCase(title_en))
  const findTagParent = (tagTitle) =>
    categories?.find(({ tags_en }) =>
      lowerCase(tags_en?.split(','))?.includes(lowerCase(tagTitle))
    ) || {}

  const inactiveExpertiseLength = expertise
    ?.map(({ title_en }) => findTagParent(title_en))
    ?.filter((e) => !expertiseChosen(e)).length
  const activeExpertiseLength = expertise
    ?.map(({ title_en }) => findTagParent(title_en))
    ?.filter((e) => expertiseChosen(e)).length

  const [expertiseLimit, setExpertiseLimit] = useState(
    isMobile ? activeExpertiseLength + 2 : undefined
  )

  const sortExpertise = (a, b) => {
    const aText = findNamingTranslation({
      parentObject: a,
      value: 'title',
      language,
    })
    const bText = findNamingTranslation({
      parentObject: b,
      value: 'title',
      language,
    })

    if (isMobile && expertiseChosen(b) !== expertiseChosen(a)) {
      return expertiseChosen(b) - expertiseChosen(a)
    }
    if (aText < bText) {
      return -1
    }
    if (aText > bText) {
      return 1
    }
    return 0
  }

  const directionChosen = (category) =>
    localFilters?.directions?.includes(category)
  const inactiveDirectionsLength = directions
    ?.map(({ interested_in }) => interested_in)
    ?.filter((e) => !directionChosen(e)).length
  const activeDirectionsLength = directions
    ?.map(({ interested_in }) => interested_in)
    ?.filter((e) => directionChosen(e)).length
  const [directionLimit, setDirectionLimit] = useState(
    isMobile ? activeDirectionsLength + 2 : undefined
  )

  const sortDirections = (a, b) => {
    if (isMobile && directionChosen(b) !== directionChosen(a)) {
      return directionChosen(b) - directionChosen(a)
    }
    if (t(a) < t(b)) {
      return -1
    }
    if (t(a) > t(b)) {
      return 1
    }
    return 0
  }

  const onCopy = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/${language}/experts/${slug || id}`
    )
    toast({
      title: t('copied'),
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }
  const countryCode = COUNTRY_LIST.find(
    (country) => country[`name_${language}`] === location
  )?.code

  const onConnect = () => {
    const expert = {
      image,
      workTime,
      firstName,
      lastName,
      position,
      linkedin,
      otherLink,
      description,
      expertise,
      directions,
      getInvolvedStatus,
      email,
      phone,
      id,
    }
    if (user?.id === 'guest') {
      put('expertToConnect', JSON.stringify(expert))
      if (isMobile) {
        navigationPush(
          `/sign-in?from=/${encodeURIComponent(
            `experts/connectModal?id=${id}`
          )}`
        )
      } else {
        openModal('signIn', '/experts?action=connect')
      }
    } else {
      if (isMobile) {
        navigationPush(`/experts/connectModal?id=${id}`)
      } else {
        openModal('connect', null, false, null, {
          expert,
        })
      }
    }
  }

  return (
    <Flex
      w="full"
      mt={{ md: fromCardList ? 0 : 20, base: index === 0 ? '84px' : '104px' }}
      mb={{ md: fromCardList ? 20 : 0 }}
      border="1px solid"
      borderColor="#DDDDDD"
      direction={{ base: 'column', lg: 'row' }}
      bg={{ lg: 'gray.300', base: 'white' }}
      borderRadius="20px"
    >
      <Flex
        w={{ base: 'full', lg: '318px' }}
        direction="column"
        alignItems="center"
        pos="relative"
        flexShrink={0}
        borderRadius="20px"
        boxShadow="51px 0px 31px rgba(39, 35, 48, 0.01), 23px 0px 23px rgba(39, 35, 48, 0.02), 6px 0px 13px rgba(39, 35, 48, 0.02), 0px 0px 0px rgba(39, 35, 48, 0.02)"
        bg="white"
      >
        <Center
          w="156px"
          h="156px"
          border="solid 4px white"
          borderRadius="50%"
          overflow="hidden"
          mt={{ base: '-72px', lg: '-60px' }}
          bg="white"
          boxShadow="0px 98px 39px rgba(0, 0, 0, 0.01), 0px 55px 33px rgba(0, 0, 0, 0.05), 0px 24px 24px rgba(0, 0, 0, 0.09), 0px 6px 13px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)"
        >
          {noLazyLoad ? (
            <Image w="149px" h="149px" src={image} objectFit="cover" />
          ) : (
            <LazyLoad height="149px" once={false}>
              <Image w="149px" h="149px" src={image} objectFit="cover" />
            </LazyLoad>
          )}
        </Center>
        <IconButton
          onClick={onCopy}
          pos="absolute"
          right="20px"
          top="20px"
          minW="unset"
        >
          <ShareIcon />
        </IconButton>
        <Text
          as="h1"
          fontSize={{ lg: 'xl', base: 'xl' }}
          mt={7}
          color="gray.800"
          fontWeight="bold"
        >
          {firstName} {lastName}
        </Text>
        <Text
          as="h2"
          mt={{ lg: 2, base: 1 }}
          fontSize="md"
          color="gray.700"
          textAlign="center"
          px={1}
        >
          {position}
        </Text>
        <HStack mt={2} mb={3} alignItems="center" spacing={5}>
          {socials?.map((url, index) => (
            <a key={url + index} target="_blank" href={url} rel="noreferrer">
              <GetSocialMediaIcon url={url} mr={4} />
            </a>
          ))}
        </HStack>
        {location && (
          <Flex
            alignItems="center"
            fontSize="md"
            color="gray.700"
            px="30px"
            textAlign="center"
          >
            <Location style={{ marginRight: '8px' }} />
            {location}
            {countryCode && (
              <Image
                ml={1}
                src={`/assets/flags/${
                  COUNTRY_LIST.find(({ code }) => code === countryCode).image
                }`}
                h="10px"
              />
            )}
          </Flex>
        )}
        <StatusTexts workTime={workTime} isVolunteer={isVolunteer} mt={3} />
        <Text mt={2} fontSize="md" color="gray.700">
          {t('filters@language')}
          {languages &&
            languages?.map((language) => (
              <Image
                key={language}
                src={languageFlags[language?.toLowerCase()]}
                h="10px"
                ml={2}
                display="inline"
              />
            ))}
        </Text>
        <Box
          px="30px"
          w="full"
          mt={{ base: 2, lg: 5 }}
          mb={{ base: 3, lg: '30px' }}
        >
          {!noButton && (
            <Button
              isDisabled={disabled}
              w="full"
              variant="green"
              onClick={onConnect}
            >
              {t('connect')}
            </Button>
          )}
        </Box>
      </Flex>

      <Flex
        px={5}
        w="full"
        justifyContent="center"
        display={{ base: 'flex', lg: 'none' }}
      >
        <Divider h="1px" bg="gray.500" />
      </Flex>

      <Flex
        w="full"
        px={{ base: 5, lg: '30px' }}
        pt={{ base: 3, lg: 5 }}
        pb={5}
        direction="column"
      >
        <Text as="h2" fontSize="md" fontWeight="semibold" color="gray.800">
          {t('experts@aboutMe')}
        </Text>
        <Text
          ref={bio}
          onClick={onBioToggle}
          overflow="hidden"
          lineHeight={{ base: `${LINE_HEIGHT}px`, sm: 'unset' }}
          maxH={{
            base: isBioOpen ? 'initial' : `${3 * LINE_HEIGHT}px`,
            sm: 'unset',
          }}
          pos="relative"
          sx={{
            '&:after': {
              content: `"${
                isToggleNeeded
                  ? isBioOpen
                    ? `... ${t('seeLess')}`
                    : `... ${t('seeMore')}`
                  : ''
              }"`,
              display: 'inline-block',
              fontWeight: 'bold',
              position: 'absolute',
              top: isBioOpen ? 'unset' : `calc(${LINE_HEIGHT}px * 2)`,
              right: isBioOpen ? 'unset' : 0,
              background: 'white',
            },
          }}
          mt={2}
          fontSize="lg"
          color="gray.800"
        >
          {description}
        </Text>
        <Box mt={3}>
          <Text
            as="h2"
            fontSize="md"
            fontWeight="semibold"
            color="gray.800"
            mb={1}
          >
            {t('expertsCard@sphere')}
          </Text>
          <Flex flexWrap="wrap">
            {expertise
              ?.sort(sortExpertise)
              ?.slice(0, expertiseLimit)
              ?.map((item, index) => (
                <ListItem
                  key={`expertise_${index}`}
                  isChecked={expertiseChosen(findTagParent(item?.title_en))}
                  text={findNamingTranslation({
                    parentObject: item,
                    value: 'title',
                    language,
                  })}
                  onClick={() => {
                    const parentExpertise = findTagParent(item?.title_en)
                    let updatedSelection = [...(localFilters?.categories || [])]
                    if (expertiseChosen(parentExpertise)) {
                      updatedSelection = updatedSelection.filter(
                        (t) =>
                          !lowerCase(
                            parentExpertise.tags_en.split(',')
                          ).includes(lowerCase(t))
                      )
                    } else {
                      updatedSelection = [
                        ...updatedSelection,
                        ...(parentExpertise.tags_en?.split(',') || []),
                      ]
                    }
                    setLocalFilters({
                      ...localFilters,
                      categories: updatedSelection,
                    })
                  }}
                />
              ))}
            {expertiseLimit && inactiveExpertiseLength > expertiseLimit && (
              <ListItem
                text={`+${inactiveExpertiseLength - expertiseLimit}`}
                fontWeight={500}
                bg="gray.400"
                color="gray.800"
                onClick={() => setExpertiseLimit(undefined)}
              />
            )}
          </Flex>
        </Box>
        <Box mt={3}>
          <Text
            as="h2"
            fontSize="md"
            fontWeight="semibold"
            color="gray.800"
            mb={1}
          >
            {t('expertsCard@interestedDirections')}
          </Text>
          <Flex flexWrap="wrap">
            {directions
              ?.map(({ interested_in }) => interested_in)
              ?.sort(sortDirections)
              ?.slice(0, directionLimit)
              ?.map((item, index) => (
                <ListItem
                  isChecked={directionChosen(item)}
                  key={`direction_${index}`}
                  text={t(item)}
                  onClick={() => {
                    let updatedSelection = [...(localFilters?.directions || [])]
                    if (directionChosen(item)) {
                      updatedSelection = updatedSelection.filter(
                        (t) => t !== item
                      )
                    } else {
                      updatedSelection.push(item)
                    }
                    setLocalFilters({
                      ...localFilters,
                      directions: updatedSelection,
                    })
                  }}
                />
              ))}
            {directionLimit && inactiveDirectionsLength > directionLimit && (
              <ListItem
                text={`+${inactiveDirectionsLength - directionLimit}`}
                fontWeight={500}
                bg="gray.400"
                color="gray.800"
                onClick={() => setDirectionLimit(undefined)}
              />
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

const ListItem = ({ text, isChecked, count = '', ...props }) => (
  <Button
    h="24px"
    lineHeight="24px"
    borderRadius={16}
    border="1px solid"
    borderColor={isChecked ? 'orange.400' : 'border.400'}
    bg={isChecked ? 'orange.400' : 'white'}
    fontSize="sm"
    fontWeight={400}
    textTransform="none"
    color={isChecked ? 'white' : 'gray.700'}
    px={3}
    mr={2}
    mt={2}
    noOfLines={1}
    _hover={{
      background: 'white',
      color: 'orange.400',
      borderColor: 'orange.400',
    }}
    {...props}
  >
    {`${text} ${count}`}
  </Button>
)

const GetSocialMediaIcon = ({ url }) => {
  if (url.includes('facebook.com') || url.includes('fb.com')) {
    return <FacebookIcon />
  }
  if (url.includes('linkedin.com')) {
    return <LinkedinIcon />
  }
  if (url.includes('x.com') || url.includes('twitter.com')) {
    return <XIcon />
  }
  return <GlobeIcon />
}
