import React from 'react'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Space from '../Space'
import Cosmo from '../Cosmo'
import ExpertProfile from 'pages/ExpertProfile'
import TabsLayout from 'components/Layouts/TabsLayout'

const TABS = [
  {
    value: 'card',
    title: 'profile@expertSpace@myCard',
    Panel: ExpertProfile,
  },
  {
    value: 'tasks',
    title: 'profile@expertSpace@myTasks',
    Panel: () => (
      <Cosmo workspaceId={process.env.REACT_APP_COSMO_EXPERT_PAGE_ID} />
    ),
  },
  // {
  //   value: 'archived',
  //   title: 'Archived',
  //   Panel: () => (
  //     <Cosmo
  //       workspaceId={`${process.env.REACT_APP_COSMO_EXPERT_PAGE_ID}?type=archived`}
  //     />
  //   ),
  // },
]

const ExpertSpace = () => {
  const { tab } = useParams()
  const { navigationPush } = useNavigation()
  const { t } = useTranslation()

  const onChange = (tab) => navigationPush(`/profile/expert/${TABS[tab].value}`)

  return (
    <Space title={t('profile@expertSpace')}>
      <TabsLayout
        defaultIndex={TABS.findIndex(({ value }) => value === tab)}
        onChange={onChange}
        TABS={TABS}
      />
    </Space>
  )
}

export default ExpertSpace
