import { useEffect, useState } from 'react'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  CurrencyConverter,
  SUPPORTED_CURRENCIES,
  SUPPORTED_CURRENCY_SYMBOLS,
  thousandSeparator,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import { BlueSelect } from 'components/Select/BlueSelect'

const AmountInput = ({
  errors,
  register,
  setValue,
  progressData,
  donation,
}) => {
  const { t } = useTranslation()
  const [showInputZero, setShowInputZero] = useState(false)
  const { currency, changeDefaultCurrency } = useCurrencySettings()

  const selectOption = (option) => {
    changeDefaultCurrency(option)
    setValue('amount', 0, '')

    // if (option !== 'AMD') {
    //   toast({
    //     status: 'warning',
    //     title: t('donation@otherCurrencyChanged').replace('{{currency}}', option),
    //     duration: 5000,
    //     isClosable: true,
    //   })
    //   setNonAmdNotified(true)
    // }
  }

  const [refInput, setRefInput] = useState()

  const formInputAmount = register('amount', {
    ref: refInput,
    required: {
      message: 'donation@labelEnterAmount',
      value: true,
    },
    min: {
      value: 1,
      message: 'donation@labelEnterAmount',
    },
    onChange: (e) => {
      setShowInputZero(!e.target.value)
    },
    onFocus: (e) => setShowInputZero(e.target.value === '0'),
    onBlur: () => setShowInputZero(false),
    setValueAs: (v) => {
      if (typeof v === 'number') {
        return v
      }
      return v?.replace(/[^0-9]|^0+(?!$)/g, '')
    },
  })

  useEffect(() => {
    if (refInput) {
      formInputAmount.ref(refInput)
      refInput.focus()
    }
  }, [refInput])

  const curr = currency.current

  return (
    <InputGroup alignItems="center">
      <InputLeftElement
        top="unset"
        width={36}
        borderRight="1px solid"
        borderColor="border.100"
        ml={2}
        children={
          <BlueSelect
            variant="big"
            display="flex"
            type="currency"
            labelFontSize="3xl"
            amount={+progressData.amount}
            label={`${SUPPORTED_CURRENCY_SYMBOLS[curr]} (${curr})`}
            options={SUPPORTED_CURRENCIES.map((option) => ({
              title: t(`currency@${option}`),
              amount: `${CurrencyConverter({
                currency: option,
                amount: +progressData.amount,
              })} ${option}`,
              value: option,
            }))}
            selectOption={selectOption}
            currentSelectedOption={t(`currency@${curr}`)}
          />
        }
      />

      <Input
        {...formInputAmount}
        ref={(e) => setRefInput(e)}
        autoFocus
        _focus={{
          // boxShadow: 'none',
          boxShadow: '0 0 0 3px #CDD5F2 !important',
          color: 'black',
          fontWeight: 'extrabold',

          // @INFO: negative margins will fix input glitch on focus, initial border weight is 1px
          //        when input focused value is 2px, so we need to make -1px margins from each side
          borderWidth: '2px',
          marginLeft: '-1px',
          marginRight: '-1px',
        }}
        borderColor={errors.amount ? 'red.400 !important' : 'blue.400'}
        _hover={{
          borderColor: 'blue.300',
        }}
        maxLength="12"
        value={showInputZero ? '' : thousandSeparator(donation.amount)}
        pr="6"
        color={Number(donation.amount) === 0 ? '#CDCDCD' : 'black'}
        fontWeight={Number(donation.amount) === 0 ? '400' : 'extrabold'}
        pattern="[0-9,]*"
        pl={44}
        textAlign={{ base: 'right', sm: 'left' }}
        fontSize="2rem"
        height="86px"
        borderRadius="16px"
        backgroundColor="white !important"
      />
    </InputGroup>
  )
}

export default AmountInput
