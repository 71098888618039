import React from 'react'
import { Checkbox, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import PaymentGuestUserFormDonationPage from 'components/PaymentGuestUserFormDonationPage'
import StaticAlert from 'components/StaticAlert'

const ArmenianCardForm = ({ isRecurring, user, register, errors }) => {
  const { t } = useTranslation()

  return (
    <>
      <StaticAlert
        marginBottom={4}
        marginTop={5}
        description={t('donation@armenianCardsAlert')}
        descriptionProps={{
          fontSize: 'sm',
          fontWeight: '500',
        }}
        borderRadius={8}
      />

      {user.id === 'guest' ? (
        <PaymentGuestUserFormDonationPage
          register={register}
          errors={errors}
          isRecurring={isRecurring}
          emailErrorMessage={t(errors?.email?.message)}
        />
      ) : (
        <>
          {isRecurring ? (
            <Text fontSize="md" mt={3}>
              <b>{t('donation@cardWillBeSaved').split('(bold)')[0]}</b>
              {t('donation@cardWillBeSaved').split('(bold)')[1]}
            </Text>
          ) : (
            <Checkbox mt={3} {...register('attachCard')}>
              <Text fontSize="md" fontWeight={400}>
                {t('donation@saveCard').split('(bold)')[0]}
                <b>{t('donation@saveCard').split('(bold)')[1]}</b>
                {t('donation@saveCard').split('(bold)')[2]}
              </Text>
            </Checkbox>
          )}
        </>
      )}
    </>
  )
}

export default ArmenianCardForm
