export const categoriesFilter =
  (card) =>
  ({ keywords }) => {
    if (!card?.collaborations) {
      return false
    }
    return keywords?.some((keyword) => {
      return card?.collaborations
        ?.map(({ needs_en }) => needs_en)
        ?.some((need) => need === keyword)
    })
  }
