import {
  Text,
  Button,
  HStack,
  Spinner,
  Box,
  background,
} from '@chakra-ui/react'
import { motion, AnimatePresence } from 'framer-motion'
import { useUser, useQueryParams } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { useScreenSize } from 'contexts/ScreenSizeContext'
import WalletPayButton from './WalletPayButton'
import { useState, useEffect } from 'react'

const DonateOrSubscribeButton = ({
  isRecurring,
  giftCard,
  giftCardMode,
  isContribution,
  currency,
  project,
  peerProject,
  buttonRef,
  nav,
  isLoading,
  getValues,
  watch,
  trigger,
  setError,
  clearErrors,
}) => {
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const { isMobile } = useScreenSize()
  const { user } = useUser()
  const [loadingPhrases, setLoadingText] = useState('')
  const NUMBER_OF_PHRASES = 6
  useEffect(() => {
    if (isLoading) {
      let remainingIndexes = [...Array(NUMBER_OF_PHRASES).keys()]
      let currentIndex = null
      let lastIndex = null

      const getRandomIndex = () => {
        if (remainingIndexes.length === 0) {
          remainingIndexes = [...Array(NUMBER_OF_PHRASES).keys()]
        }
        let randomIdx
        do {
          const randomIndex = Math.floor(
            Math.random() * remainingIndexes.length
          )
          randomIdx = remainingIndexes.splice(randomIndex, 1)[0]
        } while (randomIdx === lastIndex)
        lastIndex = randomIdx
        return randomIdx
      }

      currentIndex = getRandomIndex()
      setLoadingText(t(`donation@Phrase${currentIndex + 1}`))

      const interval = setInterval(() => {
        currentIndex = getRandomIndex()
        setLoadingText(t(`donation@Phrase${currentIndex + 1}`))
      }, 1500)

      return () => clearInterval(interval)
    }
  }, [isLoading])
  const donateOrSubscribeText = isRecurring
    ? t('donation@subscriber')
    : t('donation@donate')

  const paymentMethod = watch('paymentMethod')

  return (
    <>
      {!giftCard && giftCardMode && (
        <Text
          textAlign="left"
          fontSize="sm"
          color="gray.700"
          fontWeight="400"
          mb="4"
        >
          {t('giftCardRedeemSubscribe')}
        </Text>
      )}

      {['APPLE_PAY', 'GOOGLE_PAY'].includes(paymentMethod) ? (
        <WalletPayButton
          project={project}
          peerProject={peerProject}
          getValues={getValues}
          user={user}
          trigger={trigger}
          setError={setError}
          watch={watch}
          clearErrors={clearErrors}
          amount={Number(watch('amount')) + Number(watch('reArmenia'))}
          currency={currency}
          applicationFeeAmount={Number(watch('reArmenia'))}
          paymentMethod={paymentMethod}
        />
      ) : (
        <motion.div>
          <Button
            mt="2"
            variant="green"
            w="full"
            type="submit"
            ref={(el) => {
              if (!el) return
              const rect = el.getBoundingClientRect()
              const parentRect = el.offsetParent?.getBoundingClientRect()
              buttonRef.current = {
                bottom: parentRect?.bottom - rect.bottom,
                height: rect.height,
                left: rect.left - parentRect?.left,
                right: parentRect?.right - rect.right,
                top: rect.top - parentRect?.top,
                y: rect.top - parentRect?.top,
                width: rect.width,
              }
            }}
            _loading={{
              color: 'black',
            }}
            isDisabled={paymentMethod === 'giftCard' && !giftCardMode}
            borderRadius="12px"
            background={isLoading && 'gray.500'}
            color={isLoading ? 'black' : 'white'}
            _hover={{
              background: isLoading && 'gray.500',
              filter: 'brightness(1.1)',
            }}
            transition="0.3s"
          >
            {isLoading ? (
              <Box
                display="grid"
                gridTemplateColumns="auto 1fr"
                gap="8px"
                alignItems="center"
              >
                <Spinner size="sm" color="black" />{' '}
                <Box width="250px" display="flex" justifyContent="center">
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={loadingPhrases}
                      initial={{ opacity: 0, y: 5 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -5 }}
                      transition={{ duration: 0.3 }}
                    >
                      {loadingPhrases}
                    </motion.div>
                  </AnimatePresence>
                </Box>
              </Box>
            ) : (
              <AnimatePresence mode="wait">
                <motion.div
                  key="text"
                  initial={{ opacity: 0, y: 5 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -5 }}
                  transition={{ duration: 0.3 }}
                >
                  {giftCardMode
                    ? t('redeem')
                    : queryParams.has('giftCard')
                    ? t('order')
                    : donateOrSubscribeText}
                </motion.div>
              </AnimatePresence>
            )}
          </Button>
        </motion.div>
      )}

      {isMobile && isContribution && paymentMethod !== 'APPLE_PAY' && (
        <motion.div
          initial={{
            top: '100%',
            left: '0',
            position: 'fixed',
            zIndex: '50',
            width: '100%',
          }}
          animate={{ top: nav === 'block' ? '91%' : '100%' }}
          transition={{ duration: 0.5, stiffness: 1000 }}
        >
          <HStack
            zIndex="1000"
            borderTop="1px solid"
            borderColor="border.100"
            justifyContent="space-between"
            py="3"
            px="3"
            spacing="3"
            background="white"
          >
            <Button
              variant="green"
              w="full"
              type="submit"
              borderRadius="12px"
              isLoading={isLoading}
            >
              {donateOrSubscribeText}
            </Button>
          </HStack>
        </motion.div>
      )}

      {isMobile && isContribution && paymentMethod !== 'APPLE_PAY' && (
        <motion.div
          initial={{
            top: '100%',
            left: '0',
            position: 'fixed',
            zIndex: '50',
            width: '100%',
          }}
          animate={{ top: nav === 'block' ? '91%' : '100%' }}
          transition={{ duration: 0.5, stiffness: 1000 }}
        >
          <HStack
            zIndex="1000"
            borderTop="1px solid"
            borderColor="border.100"
            justifyContent="space-between"
            py="3"
            px="3"
            spacing="3"
            background="white"
          >
            <Button
              variant="green"
              w="full"
              type="submit"
              borderRadius="12px"
              isLoading={isLoading}
            >
              {donateOrSubscribeText}
            </Button>
          </HStack>
        </motion.div>
      )}
    </>
  )
}

export default DonateOrSubscribeButton
