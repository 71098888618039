import { useMemo } from 'react'
import { Heading, useToast } from '@chakra-ui/react'
import moment from 'moment'
import { validate as validateUUID } from 'uuid'
import CreditCards from 'components/CreditCards'
import { useTranslation } from 'contexts/TranslationContext'
import { useUpdateDonationSubscription, useUser } from 'hooks'
import { useScreenSize } from 'contexts'

export default function Cards({ subscription, refetch }) {
  const { t } = useTranslation()
  const { user } = useUser()
  const { isMobile } = useScreenSize()
  const toast = useToast()
  const {
    updateDonationSubscription,
    isLoading: isUpdateDonationSubscriptionLoading,
  } = useUpdateDonationSubscription()

  const paymentMethod = useMemo(() => {
    if (subscription.bindingId === 'stripe') {
      return JSON.parse(subscription?.stripeData || null)?.[0]?.payment_method
    }

    return subscription?.bindingId
  }, [subscription])

  const attachNewCardRedirectUrl = useMemo(() => {
    if (isMobile) {
      return `/manage-basket-subscription/${subscription.id}/cards`
    }

    return `/profile/history/non-profit?subscriptionId=${subscription.id}`
  }, [isMobile, subscription])

  const onSelect = (bindingId) => {
    if (isUpdateDonationSubscriptionLoading) {
      return
    }

    const updateDonationSubscriptionInput = {
      id: subscription.id,
    }

    if (validateUUID(bindingId)) {
      if (bindingId === subscription.bindingId) {
        return
      }
      const userCreditCard = user.creditCards.find(
        (creditCard) => creditCard.bindingId === bindingId
      )

      updateDonationSubscriptionInput.bindingId = bindingId
      updateDonationSubscriptionInput.stripeData = null
      updateDonationSubscriptionInput.creditCard = {
        pan: userCreditCard.pan,
        type: userCreditCard.type,
      }
    } else {
      const userCreditCard = user.stripeAttachedCards.find(
        (creditCard) => creditCard.id === bindingId
      )

      updateDonationSubscriptionInput.bindingId = 'stripe'
      updateDonationSubscriptionInput.stripeData = JSON.stringify([
        {
          payment_method: bindingId,
          customerId: user.customerId,
          donationDate: moment(subscription.createdAt).format('ll'),
          type: userCreditCard.type,
          pan: userCreditCard.last4,
        },
      ])
      updateDonationSubscriptionInput.creditCard = {
        type: userCreditCard.type,
        pan: userCreditCard.last4,
      }
    }

    const onSuccess = () => {
      refetch()
      toast({
        title: t('profile@updatedSuccessfully'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    }

    updateDonationSubscription(updateDonationSubscriptionInput, { onSuccess })
  }

  return (
    <>
      <Heading as="h2" fontSize="lg" mb={6} mt={0}>
        {t('Settings')}
      </Heading>

      <CreditCards
        page="manage-subscription"
        showIcons
        privacyPolicy={false}
        isRecurring
        isAttachNewCardEnabled
        subscriptionId={subscription.id}
        fullRedirectUrl={attachNewCardRedirectUrl}
        paymentMethod={paymentMethod}
        scheme={isUpdateDonationSubscriptionLoading ? 'gray' : 'white'}
        onClick={onSelect}
      />
    </>
  )
}
