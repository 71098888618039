import {
  Fragment,
  useCallback,
  useState,
  useContext,
  createContext,
  useEffect,
} from 'react'
import { Switch } from 'react-router-dom'
import useCustomToast from 'hooks/useCustomToast'
import Route from 'pages/Route'
import { useNavigation, Redirect } from 'pages'
import useRoutes from './useRoutes'
import {
  useGetProject,
  useUser,
  useUpdateProject,
  useDeleteProject,
} from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'

const ProjectContext = createContext({})

export const Routes = ({
  status,
  transactions,
  isEditMode,
  isPeerToPeerProject,
}) => {
  const routes = useRoutes({
    status,
    transactions,
    isEditMode,
    isPeerToPeerProject,
  })

  return (
    <Switch>
      {routes.map((route) => (
        <Route
          key={`edit-project-tab-content-${route.path}`}
          exact={false}
          secured={true}
          Layout={Fragment}
          path={route.path}
          component={route.component}
        />
      ))}
    </Switch>
  )
}

export const useProject = (options = {}) => {
  const { isAdmin } = options
  const context = useContext(ProjectContext)
  return {
    ...context,
    isAdmin,
  }
}

export const ProjectProvider = ({
  children,
  projectId,
  peerProjectId,
  isPeerToPeerProject,
  isEditMode,
  isAdmin,
}) => {
  const isCreatingPeerToPeerProject = isPeerToPeerProject && !isEditMode
  const { user } = useUser()
  const { t } = useTranslation()
  const { navigationPush } = useNavigation()
  const { mutate: deleteProject, isLoading: isRemoveLoading } =
    useDeleteProject()
  const { updateProject, isLoading: isUpdateProjectLoading } =
    useUpdateProject()
  const toast = useCustomToast()
  const {
    refetch,
    data: project,
    isLoading: isProjectLoading,
  } = useGetProject({
    projectId,
    isAdmin,
  })

  const { data: peerProject } = useGetProject(
    isPeerToPeerProject
      ? { projectId: peerProjectId || project?.peerProjectId }
      : {}
  )

  const [isLoading, toggleLoading] = useState(true)

  const onRemove = useCallback(
    ({ isLast, isProject }) => {
      const navigationPushPath = isLast
        ? '/profile/settings/profile-settings'
        : isProject
        ? '/profile/fundraisers/oneTime'
        : '/profile/initiator-space/collaborations'
      deleteProject(
        {
          id: projectId,
        },
        {
          onSuccess: () => {
            toast({
              status: 'success',
              title: t('createEditProject@projectRemovedSuccessfully'),
              isClosable: true,
              duration: 5000,
            })
            navigationPush(navigationPushPath)
          },
        }
      )
    },
    [projectId]
  )

  const onUpdate = useCallback((values) => {
    updateProject(values, {
      onSuccess: () => {
        refetch()
        toast({
          title: t('createEditProject@updatedSuccessfully'),
          status: 'success',
          isClosable: true,
          duration: 5000,
        })
      },
    })
  }, [])

  useEffect(() => {
    toggleLoading(isProjectLoading)
  }, [isProjectLoading])

  if (project === null) {
    return <Redirect to="/404" />
  }
  if (isProjectLoading) return <></>
  if (
    user?.id !== project?.owner &&
    !user?.isAdmin &&
    !isCreatingPeerToPeerProject
  )
    return <>Access denied</>

  return (
    <ProjectContext.Provider
      value={{
        project,
        peerProject,
        projectId,
        peerProjectId,
        isLoading,
        onUpdate,
        onRemove,
        isRemoveLoading,
        isUpdateProjectLoading,
        isEditMode,
        isPeerToPeerProject,
        isCreatingPeerToPeerProject,
        isAdmin,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

export const withProject = (Component) => {
  const WrappedComponent = ({ ...props }) => {
    return (
      <ProjectProvider {...props}>
        <Component {...props} />
      </ProjectProvider>
    )
  }

  return WrappedComponent
}
