import { Flex, HStack, Stack, Text } from '@chakra-ui/react'
import ImageWrapper from 'components/ImageWrapper'
import { thousandSeparator } from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'
import moment from 'moment'

export default function HistoryCard({ image, date, title, amount }) {
  const { t } = useTranslation()

  return (
    <Stack
      spacing={4}
      p={4}
      boxShadow=" 0px 25px 7px 0px rgba(0, 0, 0, 0.00), 0px 16px 6px 0px rgba(0, 0, 0, 0.01), 0px 9px 5px 0px rgba(0, 0, 0, 0.03), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.06)"
      borderRadius="16px"
    >
      <HStack spacing={3} alignItems="center">
        <ImageWrapper
          src={`/thumbnail/small/${image}`}
          borderRadius="100px"
          w="50px"
          h="50px"
        />
        <Text fontWeight="600" fontSize="lg">
          {title}
        </Text>
      </HStack>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="md" fontWeight="500" color="gray.700">
          {t('date')}
        </Text>
        <Text fontSize="lg" fontWeight="600" color="gray.800">
          {moment(date).format('ll')}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="md" fontWeight="500" color="gray.700">
          {t('Amount')}
        </Text>
        <Text fontSize="lg" fontWeight="600" color="gray.800">
          {thousandSeparator(amount)} AMD
        </Text>
      </Flex>
    </Stack>
  )
}
